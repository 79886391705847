import React, { useEffect } from "react";
import { Box, Typography } from "../../UI";
import styled from "styled-components";
import { useTranslate } from "../../contexts";
import { useDispatch } from "react-redux";
import { SUCCESS } from "../../config";
import { useGetRouter } from "../../hooks";
import { MAIN_ROUTE } from "../../routes/routes";

const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
`;
export const MaintenancePage = () => {
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();

  useEffect(() => {
    const start = () => {
      return setTimeout(() => {
        walletDispatch
          .getProfile({ cache: false })
          .then((res) => {
            if (res.data.status === SUCCESS) {
              handleRoute(MAIN_ROUTE);
            }
          })
          .catch((e) => start());
      }, 30000);
    };
    const id = start();

    // setTimeout(() => {
    //   handleRoute(MAIN_ROUTE);
    // }, 3000);

    return () => {
      clearTimeout(id);
    };
  }, [walletDispatch]);

  return (
    <Wrapper>
      <Typography m="48px 0 0 0" ta="center" fs="36px">
        🛠️
      </Typography>
      <Typography m="10px 0 0 0" variant="h1" ta="center">
        {t("maintenanceInProgress")}
      </Typography>
    </Wrapper>
  );
};
