import { AxiosGet, AxiosPost } from "../rest/axios";
import { URLS } from "../api/index";

export default {
  state: { info: [], faq: [] },
  reducers: {
    setFaq(state, payload) {
      return {
        ...state,
        faq: payload,
      };
    },
    setInfo(state, payload) {
      return {
        ...state,
        info: payload,
      };
    },
  },
  effects: {
    getFaq() {
      return AxiosGet({
        url: URLS.getFaq,
      }).then((res) => {
        this.setFaq(res?.data.result);
        return res?.data.result;
      });
    },

    getCardsFaq() {
      return AxiosGet({
        url: URLS.getCardsFaq,
      }).then((res) => {
        this.setFaq(res?.data.result);
        return res?.data.result;
      });
    },
    getCardsInfo() {
      return AxiosGet({
        url: URLS.getCardsInfo,
      }).then((res) => {
        this.setInfo(res?.data.result);
        return res?.data.result;
      });
    },
  },
};
