import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import debounce from "lodash.debounce";
import { PageLoader } from "./PageLoader/PageLoader";
import { Box } from "../UI";

const ScrollWrapper = styled.div`
  //height: fit-content;
`;

const ScrollWrapperScrollable = styled(Box)`
  height: ${({ height }) => (height ? height : "80vh")};
  min-height: ${({ minHeight }) => (minHeight ? minHeight : "auto")};
  overflow-y: scroll;
  padding: ${({ padding }) => padding || "0"};
`;

const BOTTOM = "bottom";
const NOT_BOTTOM = "not-bottom";

export const InfinityScroller = ({
  children,
  list,
  fetch,
  trigerFetch,
  setLoading,
  customHeight,
  minHeight,
}) => {
  const length = list?.length || 0;
  const scrollWrapper = useRef();

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(NOT_BOTTOM);
  // const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!scrollWrapper.current) return;
    const handleScroll = () => {
      const windowHeight =
        scrollWrapper.current?.getBoundingClientRect().height;
      const scrollTop = scrollWrapper.current?.scrollTop;
      const fullHeight = scrollWrapper.current?.scrollHeight;
      const scrolledToBottom =
        Number(windowHeight.toFixed(0)) + scrollTop === fullHeight;

      if (scrolledToBottom) {
        setIsScrolledToBottom(BOTTOM);
      }
    };

    const scrollMethod = debounce(handleScroll, 100);

    scrollWrapper.current?.addEventListener("scroll", scrollMethod);

    return () => {
      scrollWrapper.current?.removeEventListener("scroll", scrollMethod);
    };
  }, [scrollWrapper]);

  const startFetch = useRef(false);
  useEffect(() => {
    if (
      fetch &&
      !startFetch.current &&
      (isScrolledToBottom === BOTTOM || length === 0)
    ) {
      if (setLoading) {
        setLoading(true);
      }
      startFetch.current = true;

      fetch(length).finally(() => {
        setIsScrolledToBottom(NOT_BOTTOM);
        startFetch.current = false;

        if (setLoading) {
          setLoading(false);
        }
      });
    }
  }, [isScrolledToBottom, fetch]);

  return (
    <ScrollWrapperScrollable
      ref={scrollWrapper}
      height={customHeight}
      minHeight={minHeight}
    >
      <ScrollWrapper>{children}</ScrollWrapper>
    </ScrollWrapperScrollable>
  );
};
