import React, { useCallback, useEffect, useId, useRef, useState } from "react";
import {
  AccordionContent,
  AccordionContentWrapper,
  AccordionHeader,
  AccordionWrapper,
  ArrowAnimation,
  ArrowBlock,
  DropdownArrow,
  Wrapper,
} from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { Box, Row } from "../../UI";

export const Accordion = React.memo(
  ({
    value,
    className,
    data,
    ContentHeader,
    contentStyles,
    Content,
    z,
    onOpen,
    disable,
    withRef,
    noAbsolute,
    multiAccordion,
    AdditionHeader,
  }) => {
    const refHeader = useRef(null);
    const refContent = useRef(null);
    const [height, setHeight] = useState(0);
    const [openAccordion, setOpenAccordion] = useState(false);
    const id = useId();
    const { dropdownId } = useSelector(({ dropdown }) => dropdown);
    const dispatch = useDispatch();
    const headerRef = useRef(null);

    useEffect(() => {
      if (refHeader.current && !height) {
        setHeight(refHeader.current.offsetHeight);
      }
    }, [refHeader]);

    useEffect(() => {
      if (dropdownId === id) {
        if (onOpen) {
          onOpen(true);
        }
        setOpenAccordion(true);
      }

      if (dropdownId !== id) {
        if (onOpen) {
          onOpen(false);
        }

        setOpenAccordion(false);
      }
    }, [dropdownId]);

    useEffect(() => {
      if (refContent.current && openAccordion) {
        const clientRect = refContent.current.getBoundingClientRect();
        setHeight(clientRect.height);
      }
    }, [refContent, openAccordion]);

    const handleClose = useCallback(() => {
      if (onOpen) {
        onOpen(false);
      }
      setOpenAccordion(false);
      if (!multiAccordion) {
        dispatch.dropdown.setDropdownId(null);
      }
    }, []);

    return (
      <Wrapper className={className} z={z} ref={withRef}>
        <AccordionWrapper open={openAccordion} height={height}>
          <Box>
            <AccordionHeader
              ref={headerRef}
              onClick={() => {
                if (!multiAccordion) {
                  !disable &&
                    dispatch.dropdown.setDropdownId(
                      dropdownId === id ? null : id
                    );
                } else {
                  if (onOpen) {
                    onOpen((prev) => !prev);
                  }
                  setOpenAccordion((prev) => !prev);
                }
              }}
              open={openAccordion}
            >
              {ContentHeader({ close: handleClose, value })}
              {!disable && (
                <ArrowBlock>
                  <ArrowAnimation open={openAccordion}>
                    <DropdownArrow />
                  </ArrowAnimation>
                </ArrowBlock>
              )}
            </AccordionHeader>
            {!openAccordion && AdditionHeader}
          </Box>

          <AccordionContent
            open={openAccordion}
            height={height}
            noAbsolute={noAbsolute}
          >
            <AccordionContentWrapper
              ref={refContent}
              height={height}
              style={contentStyles}
              open={openAccordion}
            >
              {Content({ close: handleClose, data })}
            </AccordionContentWrapper>
          </AccordionContent>
        </AccordionWrapper>
      </Wrapper>
    );
  }
);
