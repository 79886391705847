const keyDictionaryToMessages = (key) => `codesMessages.${key}`;

export const WRONG_TFA_CODE = {
  code: 74109,
  messageKey: "notificationTexts.wrongTFACode",
};

export const NOT_ENOUGH_FUNDS = {
  code: 74102,
  messageKey: "notificationTexts.notEnoughFunds",
};

export const NOT_ENOUGH_CREDITS = {
  code: 74129,
  messageKey: "notificationTexts.notEnoughCredits",
};

export const AML_ALREADY_REQUESTED = {
  code: 74136,
  messageKey: "notificationTexts.amlAlreadyRequested",
};

export const NOT_ENOUGHT_MDAO_ON_BALANCE = {
  code: 74138,
  messageKey: "notificationTexts.notEnoughtMdaoOnBalance",
};

export const NOT_ENOUGHT_TICKETS_FOR_FREE_AML = {
  code: 74134,
  messageKey: "notificationTexts.notEnoughtTicketsForFreeAml",
};

export const VALIDATION_ERROR = {
  code: 74000,
  messageKey: "notificationTexts.validationError",
};

export const MAINTENANCE_ERROR = {
  code: 74002,
  messageKey: "maintEnance",
};

export const WRONG_OPERATION = {
  code: 74124,
  messageKey: "wrongOperation",
};

export const SESSION_EXPIRED = {
  code: 74106,
  messageKey: "sessionExpired",
};

export const BTC_RPC_ERROR = 74113;
export const KYC_ALREADY_PASSED = {
  code: 74115,
  messageKey: keyDictionaryToMessages("kycAlreadyPassed"),
};
export const codes = [
  WRONG_TFA_CODE,
  NOT_ENOUGHT_TICKETS_FOR_FREE_AML,
  NOT_ENOUGHT_MDAO_ON_BALANCE,
  NOT_ENOUGH_FUNDS,
  VALIDATION_ERROR,
  KYC_ALREADY_PASSED,
  WRONG_OPERATION,
  NOT_ENOUGH_CREDITS,
  AML_ALREADY_REQUESTED,
];

export const findErrorCode = (error, cb) => {
  const code = typeof error === "number" ? error : error?.response?.data?.code;
  const message = codes.find((el) => el.code === code)?.messageKey || null;

  if (cb) {
    cb(message);
  }
};
