import React from "react";
import styled from "styled-components";
import { PageHeader } from "../../components";

const Wrapper = styled.div``;

const Border = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.button};
  width: 100%;
  height: calc(100vh - 88px);
  border-radius: 20px;
  position: relative;
`;

const BorderContent = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.background};
  box-shadow: ${({ theme }) => `0px 0px 8px 10px ${theme.colors.background}`};
  border-radius: 13px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  top: 10px;
  left: 10px;
  right: 10px;
  bottom: 10px;
`;

const Steps = styled.div`
  display: flex;
  width: fit-content;
  margin: 10px auto 0 auto;
`;

const Step = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  margin-right: 8px;
  background-color: white;
  opacity: ${({ active }) => (active ? 1 : 0.25)};
  transition: 0.3s;
  &:last-child {
    margin-right: 0;
  }
`;

export const FAWrapper = ({
  content,
  controls,
  title,
  withRef,
  activeStep,
  stepsLength,
}) => {
  return (
    <Wrapper>
      <PageHeader title={title} />
      <Border ref={withRef}>
        <BorderContent>
          {content}
          {controls}
        </BorderContent>
      </Border>
      {stepsLength && (
        <Steps>
          {Array.from({ length: stepsLength }).map((el, index) => {
            return <Step key={el} active={activeStep === index} />;
          })}
        </Steps>
      )}
    </Wrapper>
  );
};
