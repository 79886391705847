import styled from "styled-components";
import { Box } from "../../UI";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled(Box)`
  //overflow: scroll;
`;
