import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Box, Link, Typography } from "../../UI";
import { InfoButton, PageHeader } from "../../components";
import { useTranslate } from "../../contexts";
import { DEFAULT_LANG, getSafeTranslate } from "../../utils/utils";

export const HTMLView = styled.div`
  a {
    color: ${({ theme }) => theme.colors.link};
  }
`;

const InfoButtonStyled = styled(InfoButton)`
  font-size: 16px;
`;
const Wrapper = styled.div`
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
`;

const BoxGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
  margin-top: 10px;
  width: 100%;
`;

const ContentWrapper = styled(Box)`
  overflow: scroll;
  height: 100%;
`;

const LinkStyled = styled(Link)`
  text-decoration: none;
`;

export const FaqContainer = ({ items, setContentTitle, setContent }) => {
  const { language } = useTranslate();
  const integer = items.length % 2 === 0;
  const firstElement = integer ? null : [...items].shift();

  const linkFirstElement =
    firstElement?.content?.[`${language}Link`] ||
    firstElement?.content?.[`${DEFAULT_LANG}Link`];
  const itemsWihoutFirstElement = integer
    ? items
    : items?.slice(1, items.length);

  return (
    <Box width={"100%"}>
      {firstElement && (
        <Box width={"100%"}>
          <LinkStyled href={linkFirstElement} target="_blank">
            <InfoButton
              variant="faqTitle"
              onClick={
                linkFirstElement
                  ? null
                  : () => {
                      setContent(
                        getSafeTranslate(firstElement.content, language)
                      );
                      setContentTitle(
                        getSafeTranslate(firstElement.title, language)
                      );
                    }
              }
              title={getSafeTranslate(firstElement.title, language)}
              disableRoute
            />
          </LinkStyled>
        </Box>
      )}
      <BoxGrid>
        {itemsWihoutFirstElement?.map((el, i) => {
          const title = getSafeTranslate(el?.title, language) || "";
          const content = getSafeTranslate(el?.content, language) || "";

          const link =
            el?.content?.[`${language}Link`] ||
            el?.content?.[`${DEFAULT_LANG}Link`];

          if (link) {
            return (
              <Box width="100%">
                <LinkStyled href={link} target="_blank">
                  <InfoButton title={title} disableRoute variant="faqTitle" />
                </LinkStyled>
              </Box>
            );
          }
          return (
            <Box
              width="100%"
              onClick={() => {
                if (content) {
                  setContent(content);
                }
                if (title) {
                  setContentTitle(title);
                }
              }}
            >
              <InfoButton title={title} disableRoute variant="faqTitle" />
            </Box>
          );
        })}
      </BoxGrid>
    </Box>
  );
};

export const PageWithInsideContent = ({
  insideContent,
  pageHeaderTitleKey,
  to,
  Content = () => <></>,
  setContent,
  contentTitle,
  setContentTitle,
}) => {
  const { t } = useTranslate();
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      contentRef.current.scrollTo(0, 0);
    }
  }, [insideContent, contentRef]);

  const handleShowContent = () => {
    if (insideContent && setContent) {
      setContent("");
    }
    setContentTitle("");
  };
  return (
    <Wrapper>
      <PageHeader
        title={contentTitle || t(pageHeaderTitleKey)}
        cb={handleShowContent}
        disableRoute={!!insideContent}
        to={to}
      />
      <ContentWrapper ref={contentRef}>
        {insideContent ? (
          <Typography m="16px 0 0 0" variant="input">
            <HTMLView dangerouslySetInnerHTML={{ __html: insideContent }} />
          </Typography>
        ) : (
          <Content />
        )}
      </ContentWrapper>
    </Wrapper>
  );
};
