import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  Box,
  Row,
  Typography,
  BackArrowIconStyled,
  NextArrowIconStyled,
} from "../../UI";
import { FAWrapper } from "./2FAWrapper";
import { QRCodeComponent } from "../../components/QR/QRCode";
import { CopyField } from "../../components";
import { ButtonStyled } from "./styled";
import { copyClipboard } from "../../utils/utils";
import { useSnackbar, useTranslate } from "../../contexts";
import { useScreen } from "../../hooks";
import styled from "styled-components";

const bot = window.Telegram?.WebApp;

const TitleStyled = styled(Typography)`
  font-size: 19px;
`;

const getHeightQrCode = (height) => {
  const values = [
    [517, "10%"],
    [537, "15%"],
    [557, "20%"],
    [575, "25%"],
    [593, "30%"],
    [600, "35%"],
    [612, "35%"],
    [635, "40%"],
    [669, "45%"],
    [670, "50%"],
    [687, "50%"],
    [700, "55%"],
    [707, "60%"],
    [724, "60%"],
    [736, "65%"],
  ];

  const value = values.find(([width]) => height < width);
  if (value) {
    return value[1];
  }
  if (height > 670) {
    return "70%";
  }

  return "50%";
};

export const FASetCode = ({
  onNext,
  onBack,
  code,
  activeStep,
  stepsLength,
}) => {
  const { showInfo } = useSnackbar();
  const { t } = useTranslate();
  const buttonRef = useRef();
  const borderRef = useRef();
  const { windowSize } = useScreen();

  const handleCopy = (hash) => {
    copyClipboard(hash);
    showInfo(t("notificationTexts.copied"));
  };

  return (
    <FAWrapper
      stepsLength={stepsLength}
      activeStep={activeStep}
      withRef={borderRef}
      title={t("tfaSetupTitle")}
      content={
        <Box p="27px 0 0 0">
          <Box>
            <TitleStyled variant="h1" ta="center">
              {t("addKeyToAuthenticator")}
            </TitleStyled>
            <Typography
              m="24px 0 0px 0"
              variant="link"
              color="hint"
              ta="center"
            >
              {t("copyKeyOrScan")}
            </Typography>
            <Typography
              m="16px 0 16px 0"
              variant="link"
              color="hint"
              ta="center"
            >
              {t("keepKeyInSafePlace")}
            </Typography>
            <Box m="0 0 10px 0">
              {code && (
                <QRCodeComponent
                  value={`otpauth://totp/Wallet?secret=${code}&issuer=MDAO Telegram`}
                  width={getHeightQrCode(windowSize.height)}
                />
              )}
              <Box m="24px 0 0px 0">
                {code && (
                  <CopyField
                    onClick={() => handleCopy(code)}
                    hideDisabled
                    title={t("tfaSecret")}
                    value={code}
                    variant="small"
                  />
                )}
              </Box>
            </Box>
          </Box>
          <Box></Box>
        </Box>
      }
      controls={
        <Box>
          <Row>
            <ButtonStyled
              variant="small"
              onClick={onBack}
              title={
                <div>
                  <BackArrowIconStyled /> {t("back")}
                </div>
              }
            />
            <ButtonStyled
              variant="small"
              withRef={buttonRef}
              onClick={onNext}
              title={
                <div>
                  {t("next")} <NextArrowIconStyled />
                </div>
              }
            />
          </Row>
        </Box>
      }
    />
  );
};
