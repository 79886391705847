import React from "react";
import { ReactComponent as BackArrow } from "../../assets/svg/back.svg";
import { Box, Typography, Row } from "../../UI";
import styled from "styled-components";
import { useGetRouter } from "../../hooks";

const Wrapper = styled(Row)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.background};
`;

const BackArrowStyled = styled(BackArrow)`
  min-width: 28px;
`;
const PaddingWrapper = styled(Box)`
  padding-bottom: 10px;
`;

const TypographyStyled = styled(Typography)`
  margin-left: ${({ noArrow }) => (noArrow ? 0 : "18px")};
`;

export const PageHeader = ({
  title,
  noArrow,
  to,
  content,
  cb,
  disableRoute,
  className,
}) => {
  const { handleRoute, handleGoBack } = useGetRouter();

  const handleRouteFunc = () => {
    if (!disableRoute) {
      if (to) {
        handleRoute(to);
      } else {
        handleGoBack();
      }
    }

    if (cb) {
      cb();
    }
  };

  return (
    <PaddingWrapper className={className}>
      <Wrapper alignItems="center">
        {!noArrow && <BackArrowStyled onClick={handleRouteFunc} />}
        {content ? (
          content
        ) : (
          <TypographyStyled m="0 0 0 18px" variant="h1" noArrow={noArrow}>
            {title}
          </TypographyStyled>
        )}
      </Wrapper>
    </PaddingWrapper>
  );
};
