import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button, Operations, PageHeader, PageLoader } from "../../components";
import { Box, STATUS_COLORS, Typography } from "../../UI";
import { useModal, useSnackbar, useTranslate } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { ContentWrapper, Wrapper } from "./styled";
import {
  FROM_PROJECT,
  MAKER_ROUTE,
  MDAO_MAKER_STAKE_ROUTE,
  MDAO_MAKER_TOPUP_STAKE_ROUTE,
  mdaoMakerToProjectRoute,
} from "../../routes/routes";
import { useGetMakerStakingInit, useGetRouter } from "../../hooks";
import {
  dateFormat,
  declOfNum,
  numberWithCommas,
} from "../../utils/formatting";
import { FinishedStakes } from "./FinishedStake";
import { CONFIRM_MODAL } from "../../components/Modals";
import {
  CLOSES_NAME,
  getLocalStorageStakes,
  removeStakeFromLocalStorage,
  STAKES_NAME,
  stakesAddLocalStorage,
  stakesUpdateLocalStorage,
  stakesUpdateLocalStorageClosesStakes,
} from "./utils";
import { SUCCESS } from "../../config";
import { getFutureDate } from "../../utils/utils";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { STATUSES } from "./helpers";
import { useParams } from "react-router-dom";

export const MdaoStakingPage = () => {
  const { t } = useTranslate();
  const { showError, showErrorCode } = useSnackbar();
  const { project_id } = useParams();
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { handleRoute } = useGetRouter();
  const { open } = useModal();
  const [mockStakes, setMockStakes] = useState([]);
  const [updateMockStakes, setUpdateMockStake] = useState([]);
  const navigationRef = useRef();
  const contentRef = useRef();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState();
  const [methodToTFA, setMethodToTFA] = useState(null);
  const [update, setUpdate] = useState(false);
  const [stakingsFiltered, setStakingsFiltered] = useState([]);
  const now = new Date();

  const { user } = useSelector(({ wallet }) => wallet);
  const TFA = user?.tfaStatus;
  const notUpdateClosesStake = useRef(false);

  const {
    rules,
    stakings,
    totalStakesBalance,
    totalTicketsBalance,
    closesStakingsWithPending,
    loading: loadingInit,
  } = useGetMakerStakingInit(updateMockStakes, [update, updateMockStakes]);

  useEffect(() => {
    const storageStakes = getLocalStorageStakes(STAKES_NAME);

    let stakes = [];
    let storageStakesHash = [];
    if (stakings?.length && storageStakes?.length) {
      storageStakesHash = storageStakes.reduce((acc, stake) => {
        acc[stake.index] = stake;
        return acc;
      }, {});

      const activeStakes = stakings.filter(
        (stake) =>
          stake.status === STATUSES.pending || stake.status === STATUSES.active
      );
      const otherStakes = stakings.filter(
        (stake) =>
          stake.status !== STATUSES.pending && stake.status !== STATUSES.active
      );

      const isCreate = (stake) =>
        stake.status === STATUSES.active &&
        (!storageStakesHash[stake.index] ||
          (storageStakesHash[stake.index] &&
            storageStakesHash[stake.index].status === STATUSES.pending &&
            !storageStakesHash[stake.index]?.isTopup));

      const isTopup = (stake) =>
        storageStakesHash[stake.index] &&
        storageStakesHash[stake.index]?.isTopup &&
        Number(storageStakesHash[stake.index].amount) === Number(stake.amount);

      const isProlong = (stake) =>
        storageStakesHash[stake.index] &&
        storageStakesHash[stake.index]?.prolong;

      const filtered = activeStakes.filter(
        (stake) => isCreate(stake) || isTopup(stake) || isProlong(stake)
      );

      stakes = [...filtered, ...otherStakes];
    } else {
      stakes = stakings;
    }

    stakesUpdateLocalStorage(
      STAKES_NAME,
      stakes?.filter(
        (stake) =>
          stake.status === STATUSES.active ||
          (storageStakesHash[stake.index] && stake.status === STATUSES.finished)
      ),
      setMockStakes
    );

    if (!notUpdateClosesStake.current) {
      stakesUpdateLocalStorageClosesStakes(
        CLOSES_NAME,
        stakes?.filter((stake) => stake.status === STATUSES.finished)
      );
    }

    setStakingsFiltered(stakes);
  }, [stakings, notUpdateClosesStake]);

  const optionsToContentHeight = useMemo(() => {
    return {
      minusIndent: 16,
    };
  }, []);

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    optionsToContentHeight
  );

  useEffect(() => {
    const storageStakes = getLocalStorageStakes(STAKES_NAME);

    const updated = storageStakes?.map((el) => {
      if (el.status !== STATUSES.pending && !el.prolong) {
        if (new Date(el.to) < now) {
          el.status = STATUSES.finished;
          removeStakeFromLocalStorage(STAKES_NAME, [el.index]);
        } else {
          el.viewInActiveList = true;
          el.status = STATUSES.active;
        }
      }
      return el;
    });

    setUpdateMockStake(updated);
  }, [mockStakes, update]);

  const mockWithRealStakes = [
    ...(stakingsFiltered || []),
    ...(updateMockStakes || {}),
  ];

  const finishedStakes = stakingsFiltered?.filter((stake) => {
    const found = updateMockStakes.find(
      (mockStake) => mockStake.index === stake.index
    );
    return (
      stake.status === STATUSES.finished &&
      !found &&
      !closesStakingsWithPending.includes(stake.index)
    );
  });

  const activeStakes = mockWithRealStakes?.filter(
    (stake) => stake.status !== STATUSES.finished || stake.viewInActiveList
  );

  const handleCheckTFA = (method) => {
    if (TFA) {
      setMethodToTFA(() => method);
      setShow(true);
    } else {
      method();
    }
  };

  const operationsListFormatted = useMemo(
    () =>
      activeStakes.map((stake) => {
        const rule = rules?.find((rule) => rule.ruleId === stake.ruleId);
        const pending = stake.status === STATUSES.pending;
        const subTitle = pending
          ? t("pendingTransaction")
          : `${dateFormat(stake.from, "dd-MM-yyyy HH:mm")} >> ${dateFormat(
              stake.to,
              "dd-MM-yyyy HH:mm"
            )}`;

        const periodValue = rule?.period[Object.keys(rule?.period || {})[0]];
        const periodName = Object.keys(rule?.period || {})[0];

        const periodTrans = periodValue
          ? declOfNum(periodValue, [
              t(`${periodName}1`),
              t(`${periodName}3`),
              t(`${periodName}2`),
            ])
          : "--";
        const ruleTitle = `${periodValue || "--"} ${periodTrans || "--"}`;

        const tickets = Number(stake?.amount / (rule?.usdtPerTicket || 1)) || 0;
        const subValue = `+${parseInt(tickets)} ${declOfNum(parseInt(tickets), [
          t("ticket1"),
          t("ticket3"),
          t("ticket2"),
        ])}`;

        return {
          ...stake,
          colors: {
            subValueColor: STATUS_COLORS.success,
            subTitleColor: pending
              ? STATUS_COLORS.pending
              : STATUS_COLORS.finished,
          },
          title: `${numberWithCommas(stake.amount) || 0} USDT`,
          subTitle: subTitle || "--",
          value: ruleTitle || "--",
          subValue,
        };
      }),
    [activeStakes, rules]
  );

  const handleProlongStake = (stake) => async (code) => {
    setLoading(true);
    return makerDispatch
      .prolongStake({ index: stake.index, code })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const rightRule = rules.find((rule) => rule.ruleId === stake.ruleId);

          const from = new Date().toISOString();
          const to = getFutureDate({
            period: rightRule.period,
            formatLess: true,
          });

          const forRest = { ...stake };
          delete forRest.wallet;

          const mockStake = {
            amount: stake.amount,
            index: stake.index,
            ruleId: stake.ruleId,
            status: STATUSES.pending,
            createdAt: new Date(),
            prevTo: stake.to,
            from,
            to,
            prolong: true,
            saveAfterLoad: true,
          };

          stakesAddLocalStorage(STAKES_NAME, mockStake);
          setUpdate((prev) => !prev);
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCloseStake = (stake) => async () => {
    setLoading(true);

    return makerDispatch
      .closeStake({ index: stake.index })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          const filtered = stakingsFiltered.filter(
            (stakeMemory) => stakeMemory.index !== stake.index
          );
          notUpdateClosesStake.current = true;
          stakesAddLocalStorage(CLOSES_NAME, stake.index);
          makerDispatch.setStakings(filtered);
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOpenFinishedStake = (stake) => {
    const closeStake = handleCloseStake(stake);
    const prolongStake = handleProlongStake(stake);
    open(CONFIRM_MODAL, {
      title: `${numberWithCommas(stake.amount) || 0} USDT`,
      description: t("yourStakeWasFinishedYouCanProlong"),
      buttons: [
        {
          title: t("closeStake"),
          isClose: true,
          color: "red",
          onClick: closeStake,
        },
        {
          title: t("prolongStake"),
          color: "success",
          isClose: true,
          onClick: () => handleCheckTFA(prolongStake),
        },
      ],
    });
  };

  const handleOpenActiveStake = (stake) => {
    if (
      (stake?.index || stake?.index === 0) &&
      (stake.ruleId || stake.ruleId === 0) &&
      !stake.mock
    ) {
      const route = `${MDAO_MAKER_TOPUP_STAKE_ROUTE}/${stake.index}/${stake.ruleId}`;

      handleRoute(route);
    }
  };

  const globalLoading = loading || loadingInit;

  const route = project_id ? mdaoMakerToProjectRoute(project_id) : MAKER_ROUTE;

  return (
    <PageLoader loading={globalLoading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={methodToTFA}
      >
        <Wrapper>
          <Box>
            <PageHeader title={t("stakingUsdt")} to={route} />
            <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
              <Box m="22px 0 0 0">
                <Typography ta="center" variant="huge">{`${
                  numberWithCommas(totalStakesBalance) || 0
                } USDT`}</Typography>
                <Typography
                  m="4px 0 0 0"
                  ta="center"
                  color="success"
                  variant="h2"
                >
                  {`${parseInt(totalTicketsBalance)} ${declOfNum(
                    parseInt(totalTicketsBalance),
                    [t("ticket1"), t("ticket3"), t("ticket2")]
                  )}`}
                </Typography>
              </Box>
              <Typography m="48px 0 8px 0" variant="h2">
                {t("activeStakes").toUpperCase()}
              </Typography>
              <Operations
                onClick={handleOpenActiveStake}
                heightFitContent
                operationsList={operationsListFormatted}
                emptyTitle={t("noActiveStakesFound")}
              />
              {finishedStakes?.length ? (
                <Box m="36px 0 0 0">
                  <FinishedStakes
                    stakes={finishedStakes}
                    onClick={handleOpenFinishedStake}
                    // onClick={handleOpenActiveStake}
                  />
                </Box>
              ) : null}
            </ContentWrapper>
          </Box>

          <Box ref={navigationRef} display="flex">
            <Button
              activeButton
              emoji="💼️"
              title={t("stakeUsdt")}
              onClick={() => handleRoute(MDAO_MAKER_STAKE_ROUTE)}
            />
          </Box>
        </Wrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
