import React, { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Paper, Row, Typography } from "../../UI";
import {
  Button,
  ButtonHorizontal,
  CopyField,
  PageHeader,
  PageLoader,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardIcon } from "../../components/Cards/Cards";
import { Transactions } from "../../components/Transactions/Transactions";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { MenuBlock } from "../Wallet/styled";
import { useSnackbar, useTranslate } from "../../contexts";
import styled from "styled-components";
import { useModal } from "../../contexts";
import { CONFIRM_MODAL } from "../../components/Modals";
import { CARDS_ROUTE, topUpRoute } from "../../routes/routes";
import Skeleton from "react-loading-skeleton";
import { CARD_STATUTES, SUCCESS } from "../../config";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { useGetRouter } from "../../hooks";

const ContentWrapper = styled(Box)`
  overflow: scroll;
`;

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  position: relative;
`;

export const CardInfoPage = () => {
  const { card_id } = useParams();
  const { t } = useTranslate();
  const { showError, showSuccess } = useSnackbar();
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const { handleRoute } = useGetRouter();
  const { schemes, templates } = useSelector(({ cards }) => cards);
  const { user } = useSelector(({ wallet }) => wallet);
  const [show, setShow] = useState(false);
  const [cardInfo, setCardInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingTransactions, setLoadingTransactions] = useState(false);
  const [showTFA, setShowTFA] = useState(false);
  const { open } = useModal();
  const contentRef = useRef();
  const navigationRef = useRef();
  const { contentHeight } = useGetMainContentHeight(contentRef, navigationRef, {
    minusIndent: 16,
  });
  const TFA = user?.tfaStatus;
  const [handleTFAFunction, setHandleTFAFunction] = useState(null);
  const [transactionItems, setTransactionItems] = useState([]);
  const cardClosed = cardInfo?.status === CARD_STATUTES.CLOSED;
  const cardFrozen = cardInfo?.status === CARD_STATUTES.INACTIVE;

  const { cardId, balance, binId } = cardInfo || {};

  const handleGetTransactions = () => {
    setLoadingTransactions(true);
    cardsDispatch
      .getCardTransactions({ cardId: card_id })
      .then((res) => {
        setTransactionItems(res?.data?.result);
      })
      .catch((e) => showError(e))
      .finally(() => setLoadingTransactions(false));
  };

  useEffect(() => {
    if (card_id) {
      cardsDispatch
        .getCard({ cardId: card_id, cache: false })
        .then((res) => {
          setCardInfo(res.data.result);
        })
        .catch((e) => showError(e));

      handleGetTransactions(0);
    }
  }, [cardsDispatch, card_id]);

  const freezedCard = cardInfo?.status === CARD_STATUTES.INACTIVE;

  const handleCloseCard = async (code) => {
    setLoading(true);
    try {
      return cardsDispatch.closeCard({ cardId: card_id, code }).then((res) => {
        if (res.data.status === SUCCESS) {
          showSuccess(t("cardClosed"));
          setCardInfo((prev) => ({ ...prev, ...res.data.result }));
        }
        setLoading(false);
        return res;
      });
    } catch (e) {
      showError(e);
      setLoading(false);
    }
  };

  const handleFreezeCard = async (code) => {
    setLoading(true);
    try {
      if (freezedCard) {
        return cardsDispatch.unfreezeCard({ cardId, code }).then((res) => {
          if (res?.data.status === SUCCESS) {
            setCardInfo((prev) => ({ ...prev, status: CARD_STATUTES.ACTIVE }));
            showSuccess(t("cardSuccessfullyUnFrozen"));
          }
          setLoading(false);
          return res;
        });
      } else {
        return cardsDispatch.freezeCard({ cardId, code }).then((res) => {
          if (res?.data.status === SUCCESS) {
            setCardInfo((prev) => ({
              ...prev,
              status: CARD_STATUTES.INACTIVE,
            }));
            showSuccess(t("cardSuccessfullyFrozen"));
            setLoading(false);
            return res;
          }
        });
      }
    } catch (e) {
      showError(e.message);
      setLoading(false);
    }
  };

  const handleConfirmDelete = () => {
    open(CONFIRM_MODAL, {
      title: `🗑  ${t("modalTexts.terminateCardTitle")}️`,
      description: t("modalTexts.terminateCardDescription"),
      buttons: [
        {
          title: t("modalTexts.terminate"),
          color: "red",
          isClose: true,
          onClick: () => {
            handleConfirmTFA(handleCloseCard);
          },
        },
        {
          title: t("modalTexts.cancel"),
          isClose: true,
          color: "success",
        },
      ],
    });
  };

  const handleConfirmTFA = (cb) => {
    if (TFA) {
      setHandleTFAFunction(() => cb);
      setShowTFA(true);
    } else {
      cb();
    }
  };

  const handleConfirmFreeze = () => {
    open(CONFIRM_MODAL, {
      title: `❄️ ${cardFrozen ? t("unfreezeCard") : t("freezeCard")}️`,
      description: t("modalTexts.areYouSure"),
      buttons: [
        {
          title: cardFrozen ? t("modalTexts.unfreeze") : t("modalTexts.freeze"),
          color: "red",
          isClose: true,
          onClick: () => {
            handleConfirmTFA(handleFreezeCard);
          },
        },
        {
          title: t("modalTexts.cancel"),
          isClose: true,
          color: "success",
        },
      ],
    });
  };

  const { number, cvv, expiryYear, name, expiryMonth } =
    cardInfo?.sensitive || {};

  const lastFourNumbers = number
    ?.toString()
    ?.slice(number?.toString().length - 4, number?.toString().length);

  const hideCardNumber = `**** **** **** ${lastFourNumbers}`;
  const formatCardNumber = number?.toString()?.replace(/\d{4}(?=.)/g, "$& ");
  const cardNumber = show ? formatCardNumber : hideCardNumber;
  const expirationDateValue = `${expiryMonth}/${expiryYear}`;
  const expirationDate = show ? expirationDateValue : "**/**";

  const { scheme, country, billingAddress } = schemes[binId] || {};

  const cardName =
    scheme && lastFourNumbers
      ? t("cardInfoTitle", { scheme, lastFourNumbers })
      : "...";

  const disabledPopup = templates?.find(
    (template) => template.binId === Number(binId)
  );

  const disableButton = cardFrozen || !cardInfo || cardClosed || !disabledPopup;

  return (
    <PageLoader loading={loading}>
      <TFACheckContainer
        show={showTFA}
        loading={loading}
        setShow={setShowTFA}
        onSuccess={handleTFAFunction}
      >
        <Wrapper>
          <PageHeader title={cardName || t("back")} to={CARDS_ROUTE} />
          <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
            <Box m="10px 0 8px 0 ">
              {cardInfo ? (
                <Card
                  frozen={cardFrozen}
                  closed={cardClosed}
                  IconComponent={CardIcon}
                  bin={binId}
                  cardIconVariant={1}
                  cardNumber={number}
                  title={`$ ${balance}`}
                  subTitle={cardName}
                />
              ) : (
                <Skeleton count={1} height="60px" borderRadius="12px" />
              )}
            </Box>
            <Box m="0 0 16px 0">
              {cardInfo ? (
                <Row justifyContent="space-between">
                  <Typography variant="bold">
                    {t("debitSchemeCounty", {
                      scheme: scheme || "...",
                      country: country || "...",
                    })}
                  </Typography>
                  <Typography variant="bold">{`BIN ${binId}`}</Typography>
                </Row>
              ) : (
                <Skeleton count={1} height="16px" borderRadius="12px" />
              )}
            </Box>

            <Row
              m="0 0 8px 0"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h2">
                {t("cardDetails").toUpperCase()}
              </Typography>
              <Paper p="8px" onClick={() => setShow((prev) => !prev)}>
                <Typography width="24px" lh="24px" fs="24px">
                  👁️
                </Typography>
              </Paper>
            </Row>
            <Box>
              {cardInfo ? (
                <CopyField
                  variant="small"
                  title={t("cardNumber")}
                  notTransform
                  value={cardNumber}
                  copyValue={formatCardNumber}
                />
              ) : (
                <Skeleton count={1} height="54px" borderRadius="12px" />
              )}
              <Row m="8px 0 0 0">
                <Box m="0 8px 0 0" width="50%">
                  {cardInfo ? (
                    <CopyField
                      variant="small"
                      title={t("expirationDate")}
                      notTransform
                      copyValue={expirationDateValue}
                      value={expirationDate}
                    />
                  ) : (
                    <Skeleton count={1} height="54px" borderRadius="12px" />
                  )}
                </Box>
                <Box width="50%">
                  {cardInfo ? (
                    <CopyField
                      variant="small"
                      title="CVC"
                      notTransform
                      copyValue={cvv}
                      value={show ? cvv : "***"}
                    />
                  ) : (
                    <Skeleton count={1} height="54px" borderRadius="12px" />
                  )}
                </Box>
              </Row>
            </Box>
            <Box m="8px 0 16px 0">
              {cardInfo ? (
                <CopyField
                  widthContent="90%"
                  variant="small"
                  title={t("billingAddress")}
                  notTransform
                  value={billingAddress || "..."}
                />
              ) : (
                <Skeleton count={1} height="88px" borderRadius="12px" />
              )}
            </Box>
            {cardClosed ? null : !cardInfo ? (
              <Box>
                <Skeleton width="71px" height="18px" borderRadius="12px" />
                <Skeleton
                  width="100%"
                  height="44px"
                  borderRadius="12px"
                  style={{ margin: "8px 0 8px 0" }}
                />
                <Skeleton width="100%" height="44px" borderRadius="12px" />
              </Box>
            ) : (
              <Box>
                <Typography>{t("actions").toUpperCase()}</Typography>
                <Box m="8px 0 8px 0">
                  <ButtonHorizontal
                    title={freezedCard ? t("unfreezeCard") : t("freezeCard")}
                    variant="small"
                    onClick={handleConfirmFreeze}
                  />
                </Box>
                {!cardClosed && (
                  <ButtonHorizontal
                    title={t("modalTexts.terminate")}
                    variant="terminate"
                    color="red"
                    onClick={handleConfirmDelete}
                  />
                )}
              </Box>
            )}

            {!loadingTransactions ? (
              <Box m="16px 0 0 0">
                <Transactions transactions={transactionItems} />
              </Box>
            ) : (
              <Box m="16px 0 0 0">
                <Skeleton
                  count={1}
                  height="21px"
                  width="151px"
                  borderRadius="12px"
                  style={{ marginBottom: "8px" }}
                />
                <Skeleton count={1} height="61px" borderRadius="12px" />
              </Box>
            )}
          </ContentWrapper>
          <MenuBlock ref={navigationRef} display="flex">
            <Button
              activeButton
              emoji="📥"
              title={t("topupButton")}
              disable={disableButton}
              onClick={() => handleRoute(topUpRoute(card_id))}
            />
          </MenuBlock>
        </Wrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
