import React from "react";
import { useTranslate } from "../../contexts";
import { Typography } from "../../UI";
import styled from "styled-components";
import { CardMenu } from "../../components";

const languages = [
  { title: "English", icon: "🇺🇸" },
  { title: "Русский", icon: "🇷🇺" },
  { title: "Español", icon: "🇪🇸" },
  { title: "Français", icon: "🇫🇷" },
  { title: "Bahasa", icon: "🇮🇩" },
  { title: "हिन्दी", icon: "🇮🇳" },
  { title: "Italiano", icon: "🇮🇹" },
];

const LanguagesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
  flex-wrap: wrap;
`;

export const LanguagePage = () => {
  const { t } = useTranslate();

  return (
    <div>
      <Typography variant="h1">{t("language")}</Typography>
      <LanguagesWrapper>
        {languages.map((el, i) => {
          return <CardMenu key={i} icon={el.icon} name={el.title} />;
        })}
      </LanguagesWrapper>
    </div>
  );
};
