import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useWallet } from "../contexts";
import { getBalanceNumber } from "../utils/formatting";

export const useGetTokenBalances = ({
  chains,
  tokens,
  chain,
  userData,
  balanceCurrentTokenId,
  tokenIds,
  deniedLoad,
  noCache,
  setReadyBalance,
}) => {
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { handleGetTokenBalances } = useWallet();
  const [tokenBalancesLoading, setTokenBalancesLoading] = useState(false);
  const [tokenBalances, setTokenBalances] = useState(null);
  const [tokenBalance, setTokenBalance] = useState(0);

  const startFetchGetTokenBalance = useRef(false);

  useEffect(() => {
    if (!tokens?.length || !tokenBalances) return;
    const currentToken = tokens.find(
      (token) => token.tokenId === balanceCurrentTokenId
    );
    const balance = tokenBalances[currentToken?.contract];

    setTokenBalance(getBalanceNumber(balance, currentToken?.decimals) || 0);
  }, [tokenBalances, balanceCurrentTokenId, tokens]);

  useEffect(() => {
    const fetch = async (chain, idsToken, deniedLoad, userData) => {
      const notLoad = deniedLoad?.some((el) => el === true);

      if (!notLoad && !startFetchGetTokenBalance.current && chain) {
        startFetchGetTokenBalance.current = true;

        let filteredTokens;
        let tokenList;

        if (userData?.chain && userData?.chain !== chain) {
          startFetchGetTokenBalance.current = false;

          return;
        }

        let info = userData;

        if (!tokens?.length && chain) {
          tokenList = userData?.tokens || [];
        } else {
          tokenList = tokens;
        }

        if (!filteredTokens) {
          startFetchGetTokenBalance.current = false;
        }

        if (idsToken?.length) {
          filteredTokens = tokenList?.filter(
            (dataToken) =>
              idsToken.includes(dataToken.tokenId) && dataToken?.tokenId
          );
        } else {
          filteredTokens = tokenList || [];
        }

        if (filteredTokens?.length) {
          setTokenBalancesLoading(true);
          handleGetTokenBalances({
            tokenList: filteredTokens,
            noCache,
            chain,
            cb: () => {
              setReadyBalance && setReadyBalance(true);
              setTokenBalancesLoading(false);
              startFetchGetTokenBalance.current = false;
            },
            userData: info,
          })
            .then((res) => {
              walletDispatch.setTokenBalances(res);
              setTokenBalances(res);
              return res;
            })
            .finally(() => {
              startFetchGetTokenBalance.current = false;
              setTokenBalancesLoading(false);
              setReadyBalance && setReadyBalance(true);
            });
        } else {
          setReadyBalance && setReadyBalance(true);

          setTokenBalancesLoading(false);
          startFetchGetTokenBalance.current = false;
        }
      }
    };

    if (chain && userData) {
      fetch(chain, tokenIds, deniedLoad, userData);
    }
  }, [chain, chains, tokenIds, tokens, deniedLoad, userData, noCache]);

  return {
    tokenBalancesLoading,
    tokenBalances,
    tokenBalance,
  };
};
