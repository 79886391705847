import React, { useMemo } from "react";
import { Accordion } from "../Accordion/Accordion";
import { Box, HINT_FIELD_NAME, Typography } from "../../UI";
import { useDispatch } from "react-redux";
import { DropdownChainWrapper, ItemChain } from "./styled";
import { useWallet } from "../../contexts";
import Skeleton from "react-loading-skeleton";
import { useTheme } from "styled-components";

export const DropdownChain = ({
  chains,
  currentChain,
  setChain,
  subTitle,
  ...props
}) => {
  const dispatch = useDispatch();
  const { colors } = useTheme();
  const { setCoinBalance } = useWallet();
  const dispatchWallet = dispatch.wallet;
  const DataComponent = React.memo(({ data, close }) => {
    const dataFiltered = data?.filter((el) => el?.id !== currentChain?.id);

    const handleSetChain = (chain) => {
      dispatchWallet.setWallet(null);
      dispatchWallet.setTokens([]);
      setCoinBalance(0);
      setChain(chain);
      close();
    };

    return (
      <>
        {dataFiltered?.map((el, i) => {
          return (
            <ItemChain
              key={i}
              variant="link"
              color="white"
              m="0 0 10px 0"
              onClick={() => {
                handleSetChain(el);
              }}
            >
              {el?.title || ""}
            </ItemChain>
          );
        })}
      </>
    );
  });

  const SelectComponent = ({ value }) => {
    return (
      <>
        <Box>
          {subTitle && (
            <Typography color={HINT_FIELD_NAME} variant="tiny">
              {subTitle}
            </Typography>
          )}
          <Typography variant="link" color="white">
            {value?.title || "..."}
          </Typography>
        </Box>
      </>
    );
  };

  const chainsFormat = useMemo(
    () =>
      chains?.map((el) => {
        return {
          ...el,
          id: el?.chain,
          title: el?.title,
        };
      }),
    [chains]
  );

  return (
    <DropdownChainWrapper>
      {currentChain?.chain ? (
        <Accordion
          {...props}
          disable={chainsFormat?.length < 2}
          value={currentChain}
          data={chainsFormat}
          ContentHeader={() => <SelectComponent value={currentChain} />}
          Content={({ close }) => (
            <DataComponent data={chainsFormat} close={close} />
          )}
        />
      ) : (
        <Skeleton
          width={subTitle ? "100%" : "251px"}
          height={subTitle ? "49px" : "40px"}
          borderRadius="12px"
        />
      )}
    </DropdownChainWrapper>
  );
};
