import React, { useState } from "react";
import {
  Box,
  ERROR_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import {
  ButtonHorizontal,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
} from "../../components";
import { VARIANT_PAY_BEP20 } from "../../config";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  convertNumberToSafe,
  declOfNum,
  numberWithCommas,
} from "../../utils/formatting";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import {
  MDAO_MAKER_PROJECT_ROUTE,
  mdaoMakerToProjectRoute,
} from "../../routes/routes";
import { updateStatus } from "../../api";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import {
  useGetMakerProject,
  useGetMakerRequirements,
  useGetMakerStakingInit,
  useGetMakerUserProjectParams,
} from "../../hooks";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { BuyTicketsSuccessPage } from "./ProcessingPages/SuccessPage";
import {
  BuyTicketsProcessingPage,
  ProcessingPage,
} from "./ProcessingPages/ProcessingPage";
import { ErrorPage } from "./ProcessingPages/ErrorPage";

export const MakerBuyTicketsPage = () => {
  const { t } = useTranslate();
  const { showError } = useSnackbar();
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const { project_id } = useParams();
  const [ticketsAmount, setTicketsAmount] = useState("");
  const [loading, setLoading] = useState(false);

  const [show, setShow] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [operationId, setOperationId] = useState(null);
  const TFA = user?.tfaStatus;

  const { project, projectLoading } = useGetMakerProject({
    projectId: project_id,
  });
  const { projectRequirements, projectRequirementsLoading } =
    useGetMakerRequirements(project_id, false);

  const { userProjectParams } = useGetMakerUserProjectParams(project_id, false);

  const { usdtBalance, tokenBalancesLoading, tickets } =
    useGetMakerStakingInit();

  const { balance: coinBalance, balanceLoading } = useGetBalance({
    chains,
    userData: user,
    chain: VARIANT_PAY_BEP20.chain,
  });

  const handleSetTickets = (value) => {
    setTicketsAmount(value);
  };
  const handlePay = (code) => {
    if (!project?.projectId) {
      showError(t("somethingWrong"));
      return;
    }

    setLoading(true);
    return makerDispatch
      .buyTickets({
        projectId: project.projectId,
        count: ticketsAmount,
        code,
      })
      .then((res) => {
        setOperationId(res.data.result?.operationId);
        setShowProcessing(true);

        return res;
      })
      .catch((e) => {
        showError(e.response.data.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayTFA = () => {
    if (TFA) {
      setShow(true);
    } else {
      handlePay();
    }
  };

  const handleUpdateStatus = () => {
    return updateStatus(
      makerDispatch.getMakerTicketStatus,
      { operationId },
      { tickets: ticketsAmount, projectId: project_id },
      null,
      "data.result"
    );
  };

  const { title } = project || {};
  const { ticketPrice, available } = projectRequirements || {};

  const notEnoughFunds = Number(ticketsAmount) > available;

  const totalAmount = ticketPrice * ticketsAmount;
  const disableButton =
    ticketsAmount > available || usdtBalance < totalAmount || !ticketsAmount;

  const globalLoading = projectLoading || loading || projectRequirementsLoading;

  const { color: rightColorBalance } = useGetColorsError({
    balance: usdtBalance,
    amount: totalAmount,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });

  const ROUTE = `${MDAO_MAKER_PROJECT_ROUTE}/${project_id}`;
  return (
    <PageLoader loading={globalLoading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handlePay}
      >
        <IFrameLayout
          processingBefore={showProcessing}
          CongratsComponent={BuyTicketsSuccessPage}
          ProcessingComponent={BuyTicketsProcessingPage}
          ErrorComponent={ErrorPage}
          processingFetch={handleUpdateStatus}
          processingRoute={ROUTE}
          continueRoute={ROUTE}
          errorRoute={ROUTE}
        >
          <Wrapper>
            <Box>
              <PageHeader
                to={mdaoMakerToProjectRoute(project_id)}
                title={t("buyProjectTickets", {
                  projectName: title || "... ",
                })}
              />
              <Box m="10px 0 0 0">
                <Input
                  onlyWholeNumbers
                  type="number"
                  onChange={handleSetTickets}
                  value={ticketsAmount}
                  placeholder={t("ticketsToBuy")}
                />
                <Box m="16px 0 0 0">
                  <LineInfo
                    leftTitle={`${t("ticketPrice")}:`}
                    rightTitle={`${Number(ticketPrice || 0).toFixed(2)} USDT`}
                  />
                  <LineInfo
                    leftTitle={`${t("availableToBuy")}:`}
                    rightTitle={available}
                    rightColor={notEnoughFunds ? "red" : "white"}
                  />
                </Box>
                <Box m="16px 0 16px 0">
                  <LineInfo
                    leftTitle={`${t("yourCurrencyBalance", {
                      currency: "USDT",
                    })}`}
                    rightColor={rightColorBalance}
                    valueLoading={tokenBalancesLoading}
                    rightTitle={`${convertNumberToSafe(usdtBalance) || 0} USDT`}
                  />
                  <LineInfo
                    leftTitle={`${t("yourCurrencyBalance", {
                      currency: VARIANT_PAY_BEP20.coinName,
                    })}`}
                    valueLoading={balanceLoading}
                    rightTitle={`${String(coinBalance)} ${
                      VARIANT_PAY_BEP20.coinName
                    }`}
                  />
                  <LineInfo
                    leftTitle={`${t("ticketsBalance")}:`}
                    rightTitle={userProjectParams?.payableTickets || 0}
                  />
                </Box>
                <Row width="100%" justifyContent="space-between">
                  <Typography variant="bold" m="0 0 16px 0">
                    {t("totalAmountDue")}
                  </Typography>
                  <Typography variant="bold" m="0 0 16px 0">
                    {`${Number(totalAmount).toFixed(2)} USDT`}
                  </Typography>
                </Row>
                <Typography variant="bold" m="0 0 16px 0">
                  {t("purchasedTicketsCannotBeUsed")}
                </Typography>
              </Box>
            </Box>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              disable={disableButton}
              onClick={handlePayTFA}
              title={t("buyTicketsForProject", {
                word: declOfNum(ticketsAmount, [
                  t("ticket1"),
                  t("ticket3"),
                  t("ticket2"),
                ]),
                amount: Number(ticketsAmount || 0)?.toFixed(2),
                projectName: title || "...",
              })}
            />
          </Wrapper>
        </IFrameLayout>
      </TFACheckContainer>
    </PageLoader>
  );
};
