import { init } from "@rematch/core";
import { wallet, dropdown, btc, cards, docs, maker } from "./store/index";
import { createLogger } from "redux-logger";

const logger = createLogger({
  collapsed: true,
});

let middleware = [];
if (process.env.REACT_APP_NODE_ENV === "development") {
  middleware = [logger];
} else {
  middleware = [];
}

const store = init({
  models: { wallet, dropdown, btc, cards, docs, maker },
  redux: {
    middlewares: middleware,
  },
});

export default store;
