import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  ERROR_FIELD_NAME,
  SUCCESS_FIELD_NAME,
  Typography,
  WHITE_FIELD_NAME,
  YELLOW_FIELD_NAME,
} from "../../UI";
import {
  ButtonHorizontal,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
} from "../../components";
import { MIN_POWDER, SUCCESS, VARIANT_PAY_BEP20 } from "../../config";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  convertNumberToSafe,
  declOfNum,
  numberWithCommas,
} from "../../utils/formatting";
import { SwitcherStyled, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { MDAO_MAKER_STAKING_ROUTE } from "../../routes/routes";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useGetMakerStakingInit, useGetRouter } from "../../hooks";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { getFutureDate } from "../../utils/utils";
import { STATUSES } from "./helpers";
import { STAKES_NAME, stakesAddLocalStorage } from "./utils";
import { AgreedTermsAndPolicy } from "../../components/AgreedTermsAndPolicy";

export const MakerTopupStakePage = () => {
  const { t } = useTranslate();
  const { showError, showErrorCode } = useSnackbar();
  const dispatch = useDispatch();
  const { handleRoute } = useGetRouter();
  const makerDispatch = dispatch.maker;
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const { rule_id, index } = useParams();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [activeAmount, setActiveAmount] = useState(null);
  const [show, setShow] = useState(false);
  const [agreed, setAgreed] = useState(false);

  const TFA = user?.tfaStatus;

  const { rules, stakings, usdtBalance, readyBalance, tokenBalancesLoading } =
    useGetMakerStakingInit();

  const currentRule = rules?.find((rule) => rule?.ruleId === Number(rule_id));
  const currentStake = stakings?.find(
    (stake) => stake?.index === Number(index)
  );

  const {
    balance: coinBalance,
    afterLoading,
    balanceLoading,
  } = useGetBalance({
    chains,
    userData: user,
    chain: VARIANT_PAY_BEP20.chain,
  });

  const handleSetAmount = (value) => {
    setAmount(value);

    const foundVariant = variantsAmount.find(
      (variant) => variant.value === Number(value)
    );

    if (foundVariant) {
      setActiveAmount(foundVariant);
    } else {
      setActiveAmount(null);
    }
  };

  const handlePay = async (code) => {
    setLoading(true);
    return makerDispatch
      .topupStake({ index: Number(index), amount, code })
      .then((res) => {
        setLoading(false);

        if (res.data.status === SUCCESS) {
          const mockStake = {
            status: STATUSES.pending,
            ruleId: currentRule.ruleId,
            amount: Number(currentStake?.amount) + Number(amount),
            prevAmount: amount,
            mock: true,
            index: Number(index),
            isTopup: true,
          };

          stakesAddLocalStorage(STAKES_NAME, mockStake);
          handleRoute(MDAO_MAKER_STAKING_ROUTE);
        }
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayTFA = () => {
    if (TFA) {
      setShow(true);
    } else {
      handlePay();
    }
  };

  useEffect(() => {
    // makerDispatch.getStakings({ cache: false });
  }, [makerDispatch]);

  const variantsAmount = useMemo(() => [
    { title: "5,000", id: 0, value: 5000 },
    { title: "10,000", id: 1, value: 10000 },
    { title: "25,000", id: 2, value: 25000 },
    { title: "50,000", id: 3, value: 50000 },
  ]);

  const handleSetActiveAmount = (value) => {
    setAmount(value?.value);
    setActiveAmount(value);
  };

  const { color: rightColorCoinBalance } = useGetColorsError({
    balance: coinBalance,
    amount: MIN_POWDER,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
    readyValue: afterLoading,
  });

  const { color: rightColorUsdtBalance } = useGetColorsError({
    balance: usdtBalance,
    amount,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
    readyValue: readyBalance,
  });

  const tickets = parseInt(Number(amount / (currentRule?.usdtPerTicket || 1)));

  const disableButton =
    !amount || amount === "0" || amount > usdtBalance || !agreed;

  const date = getFutureDate({
    period: currentRule?.period,
    format: "yyyy-MM-dd HH:mm",
  });

  const handleMaxAmount = () => {
    setAmount(convertNumberToSafe(usdtBalance, 2));
  };

  const maxButtonDisable = usdtBalance < 1;

  return (
    <PageLoader loading={loading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handlePay}
      >
        <Wrapper>
          <Box>
            <PageHeader
              to={MDAO_MAKER_STAKING_ROUTE}
              title={t("topupUsdtStake")}
            />
            <Box m="10px 0 0 0">
              <Input
                onlyWholeNumbers
                type="number"
                onChange={handleSetAmount}
                value={amount}
                placeholder={t("usdtToStake")}
                actionButtonTitle={t("max").toLowerCase()}
                actionButton={handleMaxAmount}
                actionDisable={maxButtonDisable}
              />
              <Box m="8px 0 0 0">
                <SwitcherStyled
                  autocomplete
                  variantsPay={variantsAmount}
                  activeVariant={activeAmount}
                  onChange={handleSetActiveAmount}
                />
              </Box>
              <Box m="16px 0 0 0">
                <LineInfo
                  valueLoading={tokenBalancesLoading}
                  leftTitle={`${t("yourCurrencyBalance", {
                    currency: "USDT",
                  })}`}
                  rightTitle={`${convertNumberToSafe(usdtBalance) || 0} USDT`}
                  rightColor={rightColorUsdtBalance}
                />
                <LineInfo
                  leftTitle={`${t("yourCurrencyBalance", {
                    currency: VARIANT_PAY_BEP20.coinName,
                  })}`}
                  valueLoading={balanceLoading}
                  rightTitle={`${coinBalance} ${VARIANT_PAY_BEP20.coinName}`}
                  rightColor={rightColorCoinBalance}
                />
              </Box>
              <Box m="16px 0 0 0">
                <LineInfo
                  leftTitle={`${t("currentDepositAmount", {
                    currency: "USDT",
                  })}:`}
                  rightTitle={`${
                    numberWithCommas(currentStake?.amount) || 0
                  } USDT`}
                />
                <LineInfo
                  leftTitle={`${t("depositAmountFor1Ticket")}:`}
                  rightTitle={`${currentRule?.usdtPerTicket || 0} USDT`}
                />
              </Box>
              <Box m="16px 0 16px 0">
                <LineInfo
                  leftTitle={`${t("newUnlockDate")}:`}
                  rightTitle={date}
                  leftColor={YELLOW_FIELD_NAME}
                  rightColor={YELLOW_FIELD_NAME}
                  leftVariant="bold"
                  rightVariant="bold"
                />
              </Box>
              <Box m="0 0 16px 0">
                <LineInfo
                  leftTitle={`${t("youWillReceive")}:`}
                  rightTitle={`${tickets} ${declOfNum(tickets, [
                    t("ticket1"),
                    t("ticket3"),
                    t("ticket2"),
                  ])}`}
                  rightColor={SUCCESS_FIELD_NAME}
                  leftVariant="bold"
                  rightVariant="bold"
                />
              </Box>
              <Typography m="16px 0 0 0" variant="link">
                {t("uponToppingUp")}
              </Typography>
            </Box>
          </Box>
          <Box>
            <Box m="0 0 16px 0">
              <AgreedTermsAndPolicy checked={agreed} onClick={setAgreed} />
            </Box>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              disable={disableButton}
              onClick={handlePayTFA}
              title={t("topupAmountUsdt", {
                amount: numberWithCommas(amount) || 0,
              })}
            />
          </Box>
        </Wrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
