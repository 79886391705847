import React from "react";
import { Box, Typography } from "../../UI";
import { useTranslate } from "../../contexts";

export const BlockedPage = () => {
  const { t } = useTranslate();

  return (
    <Box p="48px 0 0 0">
      <Typography fs="36px" ta="center">
        🚫
      </Typography>
      <Typography m="10px 0 0 0" variant="h1" ta="center">
        {t("accountBlocked")}
      </Typography>
    </Box>
  );
};
