import React, { createContext, useEffect, useMemo, useState } from "react";
import i18n from "./i18n";
import { useDispatch, useSelector } from "react-redux";
export const I18NContext = createContext({});

const I18NProvider = ({ children }) => {
  const { t, changeLanguage, language: languageI18n, reloadResources } = i18n;
  const { user } = useSelector(({ wallet }) => wallet);
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const [language, setLanguage] = useState(null);

  const handleSetLanguage = (lang) => {
    setLanguage(lang);
    changeLanguage(lang);
  };

  useEffect(() => {
    if (!language && user?.language) {
      handleSetLanguage(user?.language);
    }
  }, [user?.language]);

  const methods = useMemo(
    () => ({
      setLanguage: (language) => {
        walletDispatch.setLanguage({ language }).then((res) => {
          handleSetLanguage(language);
        });
      },
    }),
    [changeLanguage, walletDispatch]
  );

  return (
    <I18NContext.Provider
      value={{
        ...methods,
        t,
        changeLanguage,
        language,
        reloadResources,
      }}
    >
      {children}
    </I18NContext.Provider>
  );
};

export default I18NProvider;
