import React, { useEffect } from "react";
import { Box, Typography } from "../../UI";
import { useTranslate } from "../../contexts";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { MAIN_ROUTE } from "../../routes/routes";
import { Spinner } from "../../components/Spinner/Spinner";
import { VARIANT_PAY_RUB } from "../../config";
import { ButtonHorizontal } from "../../components";

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  flex-direction: column;
`;

const bot = window.Telegram?.WebApp;

export const SessionExpiredPage = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { session_expired } = useSelector(({ wallet }) => wallet);

  useEffect(() => {
    if (!session_expired) {
      history.push(MAIN_ROUTE);
    }
  }, [walletDispatch]);

  return (
    <Wrapper>
      <Box m="48px 0 0 0">
        <Typography fs="36px" ta="center">
          🚫
        </Typography>
        <Typography m="10px 0 0 0" variant="h1" ta="center">
          {t("sessionExpired")}
        </Typography>
      </Box>
      <Box>
        <Typography m="0 0 16px 0" variant="link" ta="center" color="hint">
          {t("youNeedToRestartTheApplication")}
        </Typography>
        <ButtonHorizontal
          variant="small"
          bottomMarginForScreen
          onClick={() => {
            if (bot.close) {
              bot.close();
            }
          }}
          title={t("closeApp")}
        />
      </Box>
    </Wrapper>
  );
};
