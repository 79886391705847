import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Box } from "../../UI";
import { PageHeader } from "../../components";

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
`;
const HeaderWrapper = styled(Box)`
  z-index: 1000;
`;

const IFrameStyled = styled.iframe`
  position: fixed;
  padding-top: 58px;
  height: calc(100vh);
  width: 100%;
  border: none;
  outline: none;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
`;

export const IFramePage = React.memo(
  ({ link, title, backTo, cb, disableRoute }) => {
    const iframeRef = useRef();

    useEffect(() => {
      if (iframeRef.current) {
        console.log(window.SecurePayThreedsUI);
        // const sp3dsConfig = {
        //   clientId: providerClientId,
        //   iframe: iframeElement,
        //   token: orderToken,
        //   simpleToken: simpleToken,
        //   threeDSSessionId: sessionId,
        // };
        //
        //
        // const securePayThreedsUI = new window.SecurePayThreedsUI();
        // window.securePayThreedsUI = securePayThreedsUI;
        // securePayThreedsUI.initThreeDS(sp3dsConfig);
      }
    }, [iframeRef]);

    return (
      <Wrapper>
        <HeaderWrapper>
          <PageHeader
            title={title}
            to={backTo}
            cb={cb}
            disableRoute={disableRoute}
          />
        </HeaderWrapper>
        <IFrameStyled src={link} ref={iframeRef}></IFrameStyled>
      </Wrapper>
    );
  }
);
