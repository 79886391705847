import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetTokenBalances } from "../useGetTokenBalances";
import { VARIANT_PAY_BEP20 } from "../../config";
import { useSnackbar } from "../../contexts";
import { CLOSES_NAME, getLocalStorageStakes } from "../../pages/Maker/utils";
import { STATUSES } from "../../pages/Maker/helpers";

export const useGetMakerTicketRequirements = () => {
  const { requirements } = useSelector(({ maker }) => maker);
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;

  useEffect(() => {
    if (!Object.keys(requirements).length) {
      makerDispatch.getTicketsRequirements();
    }
  }, [makerDispatch, requirements]);
};

export const useGetMakerStakingInit = (mockStakes = {}, updates) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { showError } = useSnackbar();
  const [closesStakingsWithPending, setClosesStakingsWithPending] = useState(
    []
  );
  const {
    stakings,
    tickets,
    subscriptions,
    totalStakesBalance,
    totalTicketsBalance,
    makerRequirements,
  } = useSelector(({ maker }) => maker);

  const { rules, bscUSDT } = makerRequirements || {};

  const { chains, user } = useSelector(({ wallet }) => wallet);
  const tokens = user?.tokens;
  const [readyBalance, setReadyBalance] = useState(false);
  const [loading, setLoading] = useState(false);

  const updatesParams = useMemo(() => updates || [], [updates]);

  const tokenId = useMemo(() => {
    return bscUSDT || 2;
  }, [bscUSDT]);

  const tokenIds = useMemo(() => {
    return [tokenId];
  }, [tokenId]);

  const { tokenBalance: usdtBalance, tokenBalancesLoading } =
    useGetTokenBalances({
      chains,
      chain: VARIANT_PAY_BEP20.chain,
      balanceCurrentTokenId: tokenId,
      tokens,
      userData: user,
      tokenIds,
    });

  useEffect(() => {
    const storageStakes = getLocalStorageStakes(CLOSES_NAME);

    setClosesStakingsWithPending(storageStakes);
  }, []);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        await makerDispatch.getStakings({ cache: false });

        if (!makerRequirements) {
          makerDispatch.getStakingMakerRequirements();
        }

        setLoading(false);
      } catch (e) {
        showError(e);
        setLoading(false);
      }
    })();
  }, [makerDispatch]);

  useEffect(() => {
    makerDispatch.getMakerUserParams({ cache: false });
  }, [makerDispatch]);

  useEffect(() => {
    if (rules?.length) {
      const hashRules = rules.reduce((acc, rule) => {
        acc[rule.ruleId] = rule;

        return acc;
      }, {});

      const mocks = mockStakes?.length ? mockStakes : [];

      const balance = [...stakings, ...mocks]?.reduce((acc, stake) => {
        if (
          !closesStakingsWithPending.includes(stake.index) &&
          stake.status === STATUSES.active &&
          !stake.saveAfterLoad &&
          !stake.mock
        ) {
          acc +=
            stake?.mock && stake.prevAmount
              ? Number(stake.prevAmount)
              : Number(stake.amount);
        }

        return acc;
      }, 0);

      const totalTickets = [...stakings, ...mocks]?.reduce((acc, stake) => {
        if (
          stake.status === STATUSES.active &&
          !closesStakingsWithPending.includes(stake.index) &&
          !stake.mock
        ) {
          const usdtPerTicket = hashRules[stake.ruleId]?.usdtPerTicket;
          acc += Number(stake.amount) / Number(usdtPerTicket);
        }

        return acc;
      }, 0);

      makerDispatch.setTotalTicketsBalance(totalTickets);

      if (balance) {
        makerDispatch.setTotalStakesBalance(balance);
        setReadyBalance(true);
      }
    }
  }, [stakings, rules, closesStakingsWithPending, ...updatesParams]);

  return {
    rules,
    stakings,
    closesStakingsWithPending,
    loading,
    usdtBalance,
    totalStakesBalance,
    readyBalance,
    totalTicketsBalance,
    tokenBalancesLoading,
    tickets,
    subscriptions,
  };
};

export const useGetFabric = ({ method, id, initCache, ignoreInitLoad }) => {
  const [data, setData] = useState(null);
  const { showError } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const currentProjectId = useRef(null);

  const handleGet = async (projectId, cache) => {
    setLoading(true);

    return await method({ projectId, cache })
      .then((res) => {
        setData(res.data.result);
        return res;
      })
      .catch(showError)
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    (async () => {
      if (id && id !== currentProjectId.current && !ignoreInitLoad) {
        await handleGet(id, initCache);
        currentProjectId.current = id;
      }
    })();
  }, [id, method, ignoreInitLoad]);

  return { data, setData, loading, method: handleGet };
};

export const useGetMakerProject = ({
  projectId,
  initCache,
  ignoreInitLoad,
}) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const {
    data: project,
    loading: projectLoading,
    method: getMakerProjectMethod,
    setData: setProject,
  } = useGetFabric({
    method: makerDispatch.getProject,
    id: projectId,
    initCache,
    ignoreInitLoad,
  });

  return { project, setProject, projectLoading, getMakerProjectMethod };
};

export const useGetMakerProjectStats = (projectId) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { data: projectStats, loading: projectStatsLoading } = useGetFabric({
    method: makerDispatch.getProjectStats,
    id: projectId,
  });
  return { projectStats, projectStatsLoading };
};

export const useGetMakerRequirements = (projectId, initCache) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { data: projectRequirements, loading: projectRequirementsLoading } =
    useGetFabric({
      method: makerDispatch.getTicketsRequirements,
      id: projectId,
      initCache,
    });
  return { projectRequirements, projectRequirementsLoading };
};
export const useGetMakerUserProjectParams = (projectId, initCache) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const {
    data: userProjectParams,
    loading: userProjectParamsLoading,
    method,
  } = useGetFabric({
    method: makerDispatch.getProjectWalletParams,
    id: projectId,
    initCache,
  });
  return { userProjectParams, userProjectParamsLoading, method };
};
