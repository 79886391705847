import React from "react";
import { Box, Paper, Row, Typography } from "../../UI";
import styled from "styled-components";
import { ReactComponent as BackArrow } from "../../assets/svg/back.svg";
import { useTranslate } from "../../contexts";

const ArrowIcon = styled(BackArrow)`
  width: 20px;
  height: 20px;
  transform: rotate(180deg);
`;

const PaperStyled = styled(Paper)`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FinishedStakes = ({ stakes, onClick }) => {
  const { t } = useTranslate();
  return (
    <Box>
      <Typography m="0 0 8px 0 ">
        {t("finishedStakes").toUpperCase()}
      </Typography>
      {stakes.map((stake, i) => {
        return (
          <PaperStyled key={i} onClick={() => onClick && onClick(stake)}>
            <Row alignItems="center" justifyContent="space-between">
              <Typography variant="link">{`${stake.amount} USDT`}</Typography>
              <ArrowIcon />
            </Row>
          </PaperStyled>
        );
      })}
    </Box>
  );
};
