import React, { useEffect, useState } from "react";
import { Box, Link, Typography } from "../../UI";
import {
  KYCBlock,
  LinkComponent,
  PageHeader,
  PassKycButton,
} from "../../components";
import { useTranslate } from "../../contexts";
import styled from "styled-components";
import { Card, CardIcon } from "../../components/Cards/Cards";
import { NavLink, useParams } from "react-router-dom";
import {
  CARDS_ROUTE,
  createCardRoute,
  payForServicesRoute,
  toCreateCardPayForServicesFromChooseCard,
} from "../../routes/routes";
import { ISSUE_CARD, KYC_STATUSES } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { ListSkeletons } from "../../components/Skeletons/Lists";

const NavLinkStyled = styled(NavLink)`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const CreateChooseCardPage = () => {
  const dispatch = useDispatch();
  const { bins, product_id } = useParams();
  const isPayServicesCreateCard = !!bins;
  const cardsDispatch = dispatch.cards;
  const { t } = useTranslate();
  const [templatesLoading, setTemplatesLoading] = useState(false);
  const [useTemplates, setUseTemplates] = useState([]);
  const { templates, requirements } = useSelector(({ cards }) => cards);

  const { isKyc } = useSelector(({ wallet }) => wallet);
  const kycNotPassed =
    isKyc === false ||
    isKyc === KYC_STATUSES.NONE ||
    isKyc === KYC_STATUSES.FAILED;
  const link =
    "https://docs.daomars.com/f.a.q/mdao-telegram-wallet/pay-for-services";

  useEffect(() => {
    setTemplatesLoading(true);
    cardsDispatch
      .getTemplates()
      .then(() => setTemplatesLoading(false))
      .catch(() => setTemplatesLoading(false));
  }, [cardsDispatch, requirements]);

  useEffect(() => {
    const binsFormat = bins?.split(",");

    if (binsFormat?.length) {
      const filteredTemplates = templates.filter((template) =>
        binsFormat.includes(String(template.binId))
      );

      setUseTemplates(filteredTemplates);
    } else {
      setUseTemplates(templates);
    }
  }, [bins, templates]);

  const backRoute = isPayServicesCreateCard
    ? payForServicesRoute()
    : CARDS_ROUTE;

  return (
    <Box>
      <PageHeader title={t("issueCard")} to={backRoute} />
      <Box m="10px 0 16px 0">
        <Typography variant="link">{t("issueVirtualCardToPay")}</Typography>
        <Box m="8px 0 0px 0">
          <LinkComponent to={link} title={t("servicePaymentGuide")} />
        </Box>
      </Box>
      <KYCBlock />
      {kycNotPassed && (
        <Box m="16px 0 0 0">
          <PassKycButton from={ISSUE_CARD} />
        </Box>
      )}
      {templatesLoading ? (
        <ListSkeletons />
      ) : (
        <Box>
          <Typography m="16px 0 8px 0" variant="h2">
            {t("availableCards").toUpperCase()}
          </Typography>
          <Box>
            {useTemplates.map((variantCard, i) => {
              const route = isPayServicesCreateCard
                ? toCreateCardPayForServicesFromChooseCard(
                    product_id,
                    variantCard.binId
                  )
                : createCardRoute(variantCard.binId);

              return (
                <NavLinkStyled to={`/${route}`}>
                  <Card
                    key={i}
                    IconComponent={CardIcon}
                    bin={variantCard.binId}
                    lastCardNumbers={variantCard.binId}
                    subTitle={`Rechargeable Debit ${variantCard.scheme}`}
                    title={
                      t("cardIssuanceCost", {
                        cost: requirements?.createCardFee,
                      }) + "$"
                    }
                  />
                </NavLinkStyled>
              );
            })}
          </Box>
        </Box>
      )}
    </Box>
  );
};
