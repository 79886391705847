import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Typography } from "../UI";
import { Button, Input, PageHeader } from "../components";
import { useSnackbar, useTranslate } from "../contexts";
import { SUCCESS, WRONG_TFA_CODE } from "../config";

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 50px);
  position: relative;
`;

export const TFACheckContainer = ({
  tfa,
  cb,
  show,
  setShow,
  children,
  onSuccess,
  loading,
}) => {
  const { t } = useTranslate();
  const { showErrorCode } = useSnackbar();
  const [code, setCode] = useState("");
  const disableButton = code?.length !== 6 || loading;

  const handleSuccess = async () => {
    await onSuccess(code)
      .then((res) => {
        const status = res?.data?.status;
        const code = res?.response?.data?.code;

        if (status === SUCCESS && !code) {
          setShow(false);
          setCode("");
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
      });

    if (cb) {
      cb();
    }
  };

  useEffect(() => {
    return () => {
      setCode("");
    };
  }, []);

  return (
    <>
      {show ? (
        <Wrapper>
          <Box>
            <PageHeader
              title={`${t("tfaSecurityCheck")} 💂`}
              disableRoute
              cb={() => {
                setCode("");
                setShow(false);
              }}
            />
            <Box m="10px 0 0 0">
              <Input
                withoutFormat
                readOnly={loading}
                type="number"
                minLength="6"
                maxLength="6"
                onChange={setCode}
                value={code}
                placeholder={t("code")}
              />
            </Box>
          </Box>
          <Button
            activeButton
            disable={disableButton}
            emoji={"✅"}
            onClick={handleSuccess}
            title={t("continue")}
          />
        </Wrapper>
      ) : (
        children
      )}
    </>
  );
};
