import React, { useEffect, useMemo, useState } from "react";
import { Box, Typography } from "../../UI";
import { PageLoader } from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { FaqContainer, PageWithInsideContent } from "../FAQ/FAQ-Container";
import { CARDS_ROUTE } from "../../routes/routes";

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
  position: relative;
`;

export const InfoAndFeesPage = () => {
  const { t } = useTranslate();
  const { showError } = useSnackbar();
  const dispatch = useDispatch();
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(false);
  const [contentTitle, setContentTitle] = useState("");
  const docsDispatch = dispatch.docs;
  const { info } = useSelector(({ docs }) => docs);

  useEffect(() => {
    (() => {
      setLoading(true);

      try {
        docsDispatch.getCardsInfo().finally(() => setLoading(false));
      } catch (e) {
        setLoading(false);
        showError(e);
      }
    })();
  }, [docsDispatch]);

  const sorted = {
    5: 3,
    3: 4,
    4: 5,
    1: 1,
    2: 2,
  };

  const sortedFee = info?.sort((a, b) => sorted[a.index] - sorted[b.index]);

  return (
    <PageLoader loading={loading}>
      <PageWithInsideContent
        pageHeaderTitleKey={"infoAndFees"}
        setContent={setContent}
        to={CARDS_ROUTE}
        contentTitle={contentTitle}
        setContentTitle={setContentTitle}
        Content={() => (
          <Box>
            <Typography m="10px 0 16px 0" variant="link">
              {t("informationalSectionTitle")}
            </Typography>
            <Typography m="0 0 8px 0" variant="h2">
              {t("readBeforeUsingCards").toUpperCase()}
            </Typography>
            <Box gap="10px" flexFlow="wrap">
              <FaqContainer
                items={sortedFee}
                setContentTitle={setContentTitle}
                setContent={setContent}
              />
            </Box>
            <Typography m="16px 0 0 0" variant="link">
              {t("notFoundAnswer")}
            </Typography>
          </Box>
        )}
        insideContent={content}
      />
    </PageLoader>
  );
};
