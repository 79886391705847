import styled from "styled-components";
import { ButtonHorizontal } from "../../components";

export const ButtonStyled = styled(ButtonHorizontal)`
  height: 44px;
  padding: ${({ p }) => p || "13px"};
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }
`;
