import React from "react";
import styled, { css, keyframes } from "styled-components";

const animationShake = keyframes`

  0% { transform: translateX(0) }
  25% { transform: translateX(4px) }
  50% { transform: translateX(-4px) }
  75% { transform: translateX(4px) }
  100% { transform: translateX(0) }
`;

const AnimationBlock = styled.div`
  animation: ${({ trigger }) =>
    trigger
      ? css`
          ${animationShake} 0.35s
        `
      : "none"};
`;

export const Shake = ({ children, trigger }) => {
  return <AnimationBlock trigger={trigger}>{children}</AnimationBlock>;
};
