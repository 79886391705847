import React from "react";
import { Box, Row, Typography, BackArrowIconStyled } from "../../UI";
import { FAWrapper } from "./2FAWrapper";
import { Input } from "../../components";
import { ButtonStyled } from "./styled";
import styled from "styled-components";
import { useTranslate } from "../../contexts";

const InputStyled = styled(Input)`
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  &[type="number"] {
    -moz-appearance: textfield;
  }
`;

export const FAConfirm = ({
  onSetCode,
  onConfirm,
  fa,
  onBack,
  stepsLength,
  activeStep,
}) => {
  const { t } = useTranslate();

  return (
    <FAWrapper
      stepsLength={stepsLength}
      activeStep={activeStep}
      title={fa ? t("tfaDisableTitle") : t("tfaSetupTitle")}
      content={
        <Box height="100%" justifyContent="center">
          <Typography variant="h1" ta="center">
            {fa ? t("disableTFASecurity") : t("confirmSetup")}
          </Typography>
          <Typography m="24px 0 24px 0" variant="link" color="hint" ta="center">
            {t("enterDigits")}
          </Typography>
          <InputStyled
            type="number"
            onChange={onSetCode}
            placeholder={t("code")}
          />
        </Box>
      }
      controls={
        <Row>
          {!fa && (
            <ButtonStyled
              variant="small"
              p="0"
              onClick={onBack}
              title={
                <div>
                  <BackArrowIconStyled /> {t("back")}
                </div>
              }
            />
          )}
          <ButtonStyled
            p="0"
            variant="small"
            onClick={onConfirm}
            title={<div>✅ {t("confirm")}</div>}
          />
        </Row>
      }
    />
  );
};
