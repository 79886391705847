import React from "react";
import { Paper, Typography } from "../../UI";
import styled from "styled-components";

const PaperElement = styled(Paper)`
  position: relative;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
`;

const TipElement = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.colors.red};
  position: absolute;
  top: 0;
  right: 0;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  transform: translate(20%, -30%);
`;

export const Tip = ({ count }) => {
  return (
    <PaperElement>
      <Typography>🔔</Typography>
      <TipElement>
        <Typography variant="notices">
          {count?.toString()?.length <= 3 ? count : "999+"}
        </Typography>
      </TipElement>
    </PaperElement>
  );
};
