import React, { useState } from "react";
import { Button, Input, PageHeader } from "../../components";
import { Box } from "../../UI";
import styled from "styled-components";

const Wrapper = styled.div`
  height: calc(100vh - 50px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const FACheckPage = () => {
  const [code, setCode] = useState("");

  const handleCheck2FA = () => {};

  return (
    <Wrapper>
      <Box>
        <PageHeader title="2FA Security check 💂" />
        <Box m="10px 0 0 0">
          <Input onChange={setCode} value={code} placeholder="Code" />
        </Box>
      </Box>
      <Button
        activeButton
        emoji={"✅"}
        onClick={handleCheck2FA}
        title={"Continue"}
      />
    </Wrapper>
  );
};
