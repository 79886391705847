import React, { useEffect, useState } from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import {
  BackArrowIconStyled,
  Box,
  Link,
  NextArrowIconStyled,
  Row,
  Typography,
} from "../../../UI";
import { useSnackbar, useTranslate } from "../../../contexts";
import { convertCardNumberSpace, shortWallet } from "../../../utils/formatting";
import { LinkComponent } from "../../../components";
import { useDispatch } from "react-redux";

export const SuccessPage = ({ margin, cb, tx, amount, balance, cardId }) => {
  const dispatch = useDispatch();
  const cardDispatch = dispatch.cards;
  const { showError } = useSnackbar();
  const { t } = useTranslate();
  const [card, setCard] = useState({});

  useEffect(() => {
    if (cardId) {
      try {
        cardDispatch.getCard({ cardId, cache: false }).then((res) => {
          setCard(res.data.result);
        });
      } catch (e) {
        showError(e);
      }
    }
  }, [cardDispatch, cardId]);

  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography ta="center" m="16px 0 0 0" variant="link">
            {t("yourVirtualCard", {
              cardNumber: convertCardNumberSpace(card?.sensitive?.number) || "",
            })}
          </Typography>
          <Row justifyContent="center">
            <LinkComponent
              to={t("guides.linkPaymentGuide")}
              title={t("paymentGuide")}
            />
          </Row>

          <Typography m="10px 0 0 0" ta="center" variant="link">
            {t("youCanTopUpYourCard", { amount: card?.balance })}
          </Typography>
        </Box>
      }
    />
  );
};
