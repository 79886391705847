import { fonts } from "./fonts";

const botTheme = window.Telegram?.WebApp?.themeParams;
console.log("bot theme:", botTheme);

const getBg2ThroughBg1 = (color, defaultColor) => {
  switch (color) {
    case "#131415":
      return "#24303F";
    case "#282828":
      return "#939393";
    case "#000000":
      return "#282828";
    case "#efeff3":
      return "#000000";
    default:
      return defaultColor;
  }
};

export const RED_COLOR = "#C74949";
export const ERROR_FIELD_NAME = "red";
export const WHITE_FIELD_NAME = "white";
export const SUCCESS_FIELD_NAME = "success";
export const ORANGE_FIELD_NAME = "orange";
export const BUTTON_FIELD_NAME = "button";
export const HINT_FIELD_NAME = "hint";
export const YELLOW_FIELD_NAME = "yellow";

const defaultThemeColors = {
  background: "black",
  background2: "#15202D",
  [BUTTON_FIELD_NAME]: "#007AFE",
  buttonText: "#FFF",
  addition: "#3C4D63",
  text: "#FFF",
  [HINT_FIELD_NAME]: "#B7C5D6",
  [ORANGE_FIELD_NAME]: "#FF5B04",
  link: "#27A4FF",
  [ERROR_FIELD_NAME]: RED_COLOR,
  subtitleText: "#FFF",
  [WHITE_FIELD_NAME]: "#FFF",
  [YELLOW_FIELD_NAME]: "#F0B90B",
  [SUCCESS_FIELD_NAME]: "#2DA581",
};

const dynamicThemeColors = {
  background: botTheme?.bg_color || "#1A222C",
  background2:
    getBg2ThroughBg1(
      botTheme?.secondary_bg_color,
      botTheme?.secondary_bg_color
    ) || "#24303F",
  addition: "#3C4D63",
  button: botTheme?.button_color || "#55A4F8",
  buttonText: botTheme?.button_text_color || "#FFF",
  text: botTheme?.text_color || "#FFF",
  [HINT_FIELD_NAME]: botTheme?.hint_color || "#B7C5D6",
  orange: "#FF5B04",
  link: botTheme?.link_color || "#79BAF4",
  [ERROR_FIELD_NAME]: RED_COLOR,
  subtitleText: botTheme?.subtitle_text_color || "#FFF",
  [WHITE_FIELD_NAME]: "#FFF",
  [YELLOW_FIELD_NAME]: "#F0B90B",
  [SUCCESS_FIELD_NAME]: "#4CA48A",
};

export const theme = {
  colors: defaultThemeColors,
  shadows: {
    shadow1: "0px 0px 50px 0px rgba(0, 0, 0, 0.25);",
    shadow2: "0px 4px 50px 0px rgba(0, 0, 0, 0.50);",
    shadow3: "0px 0px 10px 0px rgba(0, 0, 0, 0.50);",
  },
  fonts,
};
