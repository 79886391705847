import { useSnackbar, useTranslate } from "../../contexts";
import {
  Box,
  HINT_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import { copyClipboard } from "../../utils/utils";
import { CopyIcon } from "../CopyField/CopyField";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { InfoTip } from "../Tip/InfoTip";
import styled, { css } from "styled-components";
import { EllipsisText } from "../EllipsisText/Ellipsis";

const TypographyStyled = styled(Typography)`
  width: 100%;
  word-wrap: break-word;
`;

export const ParamLine = ({ titleKey, value, color, tParams }) => {
  const { t } = useTranslate();

  return (
    <Box>
      <Typography variant="small" color="hint">
        {t(titleKey, tParams)}
      </Typography>
      <TypographyStyled variant="input" color={color}>
        {value}
      </TypographyStyled>
    </Box>
  );
};

export const AllocationPurchaseFeeValue = ({
  percent1,
  percent2,
  color,
  t,
}) => {
  const calculateResult = Number(percent1 - percent2) * 100;
  return (
    <>
      {" "}
      <Typography variant="link">
        {`${Number(percent1 * 100).toFixed(2)}% - `}
      </Typography>
      &nbsp;
      <Typography variant="link" color={color}>
        {`${Number(percent2 * 100).toFixed(2)}%`}
      </Typography>
      &nbsp;
      <Typography variant="link" color={WHITE_FIELD_NAME}>
        =
      </Typography>
      &nbsp;
      <Typography variant="link" color={color}>
        {`${Number(calculateResult).toFixed(2)}% `}
      </Typography>
      &nbsp;
      <Typography variant="link">{`(${t("serviceFee")})`}</Typography>
    </>
  );
};

export const AllocationPurchaseFee = ({
  percent1,
  percent2,
  tipMessage,
  tipMessageKey,
  titleKey,
  color,
}) => {
  const { t } = useTranslate();

  const percent1Volume = (percent1 || 0) * 100;

  return (
    <InfoTip
      message={tipMessage}
      isModal
      wrapperStyles={css`
        width: 100%;
      `}
    >
      <Box>
        <Typography variant="small" color="hint">
          {t(titleKey)}
        </Typography>
        <Row>
          {percent2 ? (
            <AllocationPurchaseFeeValue
              percent2={percent2}
              percent1={percent1}
              color={color}
              t={t}
            />
          ) : (
            <>
              <Typography variant="link">
                {`${Number(percent1Volume).toFixed(2)}%`}
              </Typography>
              &nbsp;
              <Typography variant="link">{`(${t("serviceFee")})`}</Typography>
            </>
          )}
        </Row>
      </Box>
    </InfoTip>
  );
};

export const ParamVestingLine = ({
  titleKey,
  description,
  smallDescription,
}) => {
  const { t } = useTranslate();

  return (
    <Box>
      <Typography variant="small" color="hint">
        {t(titleKey)}
      </Typography>
      <Typography variant="input">{description}</Typography>
      <Typography variant="tiny" color="hint">
        {smallDescription}
      </Typography>
    </Box>
  );
};

export const ParamLineWithCopyValue = ({ titleKey, value, transformValue }) => {
  const { t } = useTranslate();
  const { showInfo, showError } = useSnackbar();

  const handleCopy = (value) => {
    if (value) {
      copyClipboard(value)
        .then(() => {
          showInfo(t("notificationTexts.copied"));
        })
        .catch((e) => showError(e));
    }
  };

  return (
    <Box>
      <Typography variant="small" color="hint">
        {t(titleKey)}
      </Typography>
      <Row alignItems="center" onClick={() => value && handleCopy(value)}>
        <Typography>
          <EllipsisText
            customEmpty={transformValue}
            value={value}
            startLength={6}
            endLength={value?.length - 4}
          />
        </Typography>
        {value ? <CopyIcon /> : null}
      </Row>
    </Box>
  );
};

export const ParamLinePlusInfo = ({
  titleKey,
  value,
  plusInfoTitle,
  plusInfoSubTitle,
  ticketPrice,
  ticketFee,
}) => {
  const { t } = useTranslate();

  const percent = ticketFee * 100;

  return (
    <Box>
      <Typography variant="small" color="hint">
        {t(titleKey)}
      </Typography>
      <Row alignItems="center">
        <Typography height="fit-content" variant="input" ws="nowrap">
          {`${
            ticketPrice
              ? `$${ticketPrice?.toFixed(2)}`
              : `$${value?.toFixed(2)}`
          } + `}
        </Typography>
        &nbsp;
        <Box>
          <Typography variant="small">{`$${(
            (ticketPrice / 100) * percent || 0
          )?.toFixed(2)}`}</Typography>
          <Typography variant="tiny">{plusInfoSubTitle}</Typography>
        </Box>
      </Row>
    </Box>
  );
};
