import React, { useEffect, useState } from "react";
import { Box, Link, NextArrowIconStyled, Row, Typography } from "../../../UI";
import { useSnackbar, useTranslate } from "../../../contexts";
import { ButtonHorizontal, LinkComponent } from "../../../components";
import styled from "styled-components";
import { convertCardNumberSpace } from "../../../utils/formatting";
import { useDispatch } from "react-redux";

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
  width: 100%;
  justify-content: flex-end;
  align-content: center;
`;

export const SuccessPage = ({ margin, cb, params, cardId }) => {
  const { t } = useTranslate();
  const { showError } = useSnackbar();
  const dispatch = useDispatch();
  const cardDispatch = dispatch.cards;
  const [card, setCard] = useState(null);
  // const { cardId } = params || {};

  useEffect(() => {
    if (cardId) {
      try {
        cardDispatch.getCard({ cardId, cache: false }).then((res) => {
          setCard(res.data.result);
        });
      } catch (e) {
        showError(e);
      }
    }
  }, [cardDispatch, cardId]);

  return (
    <Wrapper>
      <Box m={`0 0 ${margin}% 0`}>
        <Typography ta="center" fs="100px">
          ✅
        </Typography>
        <Typography ta="center" variant="h1">
          {t("congrats")}
        </Typography>
        <Typography ta="center" m="16px 0 0 0" variant="link">
          {t("yourVirtualCard", {
            cardNumber:
              convertCardNumberSpace(card?.sensitive?.number) || "loading...",
          })}
        </Typography>
        <Row justifyContent="center">
          <LinkComponent
            to={t("guides.linkPaymentGuide")}
            title={t("paymentGuide")}
          />
        </Row>

        <Typography m="10px 0 0 0" ta="center" variant="link">
          {t("youCanTopUpYourCard", { amount: card?.balance || 0 })}
        </Typography>
      </Box>
      <ButtonHorizontal
        bottomMarginForScreen
        variant="small"
        onClick={() => cb(card)}
        title={
          <div>
            {t("continue")}&nbsp;
            <NextArrowIconStyled />
          </div>
        }
      />
    </Wrapper>
  );
};
