import React from "react";
import { Box, Typography } from "../../UI";
import { CopyField, PageHeader } from "../../components";
import { useTranslate } from "../../contexts";
import { useSelector } from "react-redux";

export const InviteFriendsPage = () => {
  const { t } = useTranslate();
  const { user } = useSelector(({ wallet }) => wallet);

  return (
    <Box>
      <PageHeader title={t("friends")} />
      <Typography m="10px 0 16px 0" variant="link">
        {t("useYourReferralLinkToInviteFriends")}
      </Typography>

      <CopyField title={t("referralLink")} value={user?.referralLink} />
    </Box>
  );
};
