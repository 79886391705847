import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useParams } from "react-router-dom";
import {
  CREATE_CARD,
  ERROR,
  KYC_STATUSES,
  MIN_POWDER,
  VARIANT_PAY_BEP20,
  VARIANT_PAY_RUB,
  VARIANT_PAY_TRC20,
} from "../../config";
import {
  Box,
  ERROR_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import {
  ButtonHorizontal,
  Switcher,
  DepositItems,
  Input,
  KYCBlock,
  PageHeader,
  PassKycButton,
  PageLoader,
  LineInfo,
} from "../../components";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  CARDS_ROUTE,
  PAY_FOR_SERVICES_ROUTE,
  SELECT_OR_ISSUE_CARDS_ROUTE,
} from "../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../components/Spinner/Spinner";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { updateStatus } from "../../api";
import { SuccessPage } from "./ProcessingPages/SuccessPage";
import { ProcessingPage } from "./ProcessingPages/ProcessingPage";
import { ErrorPage } from "./ProcessingPages/ErrorPage";
import {
  convertNumberToSafe,
  fixedToSecond,
  numberFormat,
} from "../../utils/formatting";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import styled from "styled-components";

const variantsPay = [
  VARIANT_PAY_TRC20,
  VARIANT_PAY_BEP20,
  // VARIANT_PAY_RUB
];

const ContentWrapper = styled(Box)`
  overflow: scroll;
`;

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
  justify-content: space-between;
`;

export const CreateCardPage = () => {
  const dispatch = useDispatch();
  const cardDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;

  const { card_variant_id: binId, product_id } = useParams();
  const { t } = useTranslate();
  const { products, templates, cards, requirements } = useSelector(
    ({ cards }) => cards
  );
  const { user, currentChain, isKyc, chains } = useSelector(
    ({ wallet }) => wallet
  );
  const tokens = user?.tokens;

  const { showError } = useSnackbar();

  const default_chain = variantsPay[0];
  const [variantPay, setVariantPay] = useState(default_chain);
  const [amount, setAmount] = useState("");
  const [focusForm, setFocusForm] = useState(false);
  const [operationId, setOperationId] = useState(null);
  const [promocode, setPromocode] = useState("");
  const [discount, setDiscount] = useState(0);
  const [promocodeAccepted, setPromocodeAccepted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notTokenEnoughFunds, setTokenNotEnoughFunds] = useState(false);
  const [notBalanceEnoughFunds, setBalanceNotEnoughFunds] = useState(false);
  const [finalValue, setFinalValue] = useState(0);
  const [rubRate, setRubRate] = useState(0);
  const [showProcessing, setShowProcessing] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [finalValueLoading, setFinalValueLoading] = useState(false);
  const isVariantRub = variantPay.id === VARIANT_PAY_RUB.id;
  const fromPayForServices = !!product_id;
  const [show, setShow] = useState(false);

  const isKycSuccess = isKyc === KYC_STATUSES.SUCCESS;
  const isKycPending = isKyc === KYC_STATUSES.PENDING;
  const isNotPassed =
    isKyc === KYC_STATUSES.FAILED || isKyc === KYC_STATUSES.NONE;
  const isRUB = variantPay?.id === VARIANT_PAY_RUB.id;
  const depositValues = [25, 50, 75, 100, 200, 300, 400, 500];
  const activeItem = useMemo(() => {
    return (
      depositValues.find((v) => v?.toString() === amount.toString()) && amount
    );
  }, [amount]);
  const TFA = useMemo(() => user?.tfaStatus, [user]);
  const [paymentLink, setPaymentLink] = useState(null);
  const isRub = variantPay?.id === VARIANT_PAY_RUB.id;

  const formatVariantsPay = variantsPay.map((el) => ({
    ...el,
    id: el.id,
    title: el.currencyName,
    subTitle: el.chainName,
  }));

  const {
    maxCreateDepositRub,
    minCreateDepositRub,
    minCreateDeposit,
    maxCreateDeposit,
    topupCardFee,
    createCardFee,
  } = requirements || {};

  const feeActivationCard = useMemo(
    () =>
      promocodeAccepted && discount
        ? createCardFee - createCardFee * discount
        : createCardFee,
    [promocodeAccepted, discount, createCardFee]
  );

  const minCreateDepositValue = isRub ? minCreateDepositRub : minCreateDeposit;
  const maxCreateDepositValue = isRub ? maxCreateDepositRub : maxCreateDeposit;

  const currentService =
    fromPayForServices &&
    products.find(
      (service) => String(service.productId) === String(product_id)
    );

  const tokenId = useMemo(
    () => requirements?.[variantPay?.idTokenKey],
    [requirements, variantPay]
  );

  const tokenIds = useMemo(() => {
    return [requirements?.[variantPay?.idTokenKey]];
  }, [requirements, variantPay]);

  const prevChain = useRef(null);

  useEffect(() => {
    if (!chains?.length) return;
    const chain = chains.find((chain) => chain.chain === default_chain?.chain);

    if (chain) {
      prevChain.current = chain.chain;
      walletDispatch.setCurrentChain({
        ...chain,
        id: chain.chain,
        title: chain.title,
      });
    }
  }, [chains]);

  const { tokenBalance } = useGetTokenBalances({
    chains,
    noCache: true,
    chain: currentChain?.chain,
    balanceCurrentTokenId: tokenId,
    tokens,
    userData: user,
    tokenIds,
  });

  const { balance, balanceLoading, balanceReady } = useGetBalance({
    chains,
    userData: user,
    chain: variantPay.chain,
  });

  const validateAmount = (value) => {
    if (
      value &&
      (value < minCreateDepositValue || value > maxCreateDepositValue) &&
      !focusForm
    ) {
      showError(
        t("checkAmountCorrect", {
          min: minCreateDepositValue,
          max: maxCreateDepositValue,
        })
      );
      return false;
    }

    return true;
  };

  const handleChangeVariantPay = useCallback(
    (value) => {
      setVariantPay(value);

      const chain = chains.find((chain) => chain.chain === value.chain);

      if (chain) {
        prevChain.current = chain.chain;
        walletDispatch.setCurrentChain({
          ...chain,
          id: chain.chain,
          title: chain.title,
        });
      }
    },
    [chains]
  );

  const handleChangeAmount = useCallback((value) => {
    setAmount(value);
  }, []);

  const handleChangePromocode = useCallback((value) => {
    setPromocode(value);
  }, []);

  const handleSetAmount = useCallback((value) => {
    setAmount(value);
  }, []);

  const validateForm = () => {
    let error = null;

    if (!amount) {
      error = t("notificationTexts.allFieldsMustBeCompleted");
    }

    if (!variantPay?.paymentType) {
      error = t("notificationTexts.allFieldsMustBeCompleted");
    }

    if (error) {
      showError(error);
    }

    return !!error;
  };

  const handlePay = (code) => {
    const validate = validateForm();

    if (validate) {
      return;
    }

    setLoading(true);
    try {
      const hasPromocode = promocode ? { promoCode: promocode } : {};

      return cardDispatch
        .createCard({
          binId,
          deposit: amount,
          paymentType: variantPay.paymentType,
          code,
          ...hasPromocode,
        })
        .then((res) => {
          if (res.data.status === ERROR) {
            showError(res.data.message);
          }

          setOperationId(res.data.result.operationId);
          if (res.data.result.paymentUrl) {
            setRefreshStatus(true);
            setPaymentLink(res.data.result.paymentUrl);
          } else {
            setShowProcessing(true);
          }

          setLoading(false);
          return res;
        })
        .catch((e) => {
          showError(e?.response?.data?.message || e);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      showError(e?.response?.data?.message || e);
      setLoading(false);
    }
  };

  const handleUpdateStatus = () => {
    return updateStatus(cardDispatch.getCardOperation, { operationId });
  };

  const handlePayWithTFA = () => {
    const validate = validateForm();

    if (validate) {
      return;
    }

    setShow(true);
    return handlePay;
  };

  const handle2FACallback = () => {
    setLoading(false);
  };

  const amountPrev = useRef(null);

  useEffect(() => {
    (async () => {
      if (
        (feeActivationCard || feeActivationCard === 0) &&
        !isRUB &&
        variantPay?.chain
      ) {
        if (Number(balance) < MIN_POWDER) {
          setBalanceNotEnoughFunds(true);
        } else {
          setBalanceNotEnoughFunds(false);
        }

        const sum =
          Number(tokenBalance || 0) <=
          (Number(amount) || 0) + feeActivationCard;

        if (sum) {
          setTokenNotEnoughFunds(true);
        } else {
          setTokenNotEnoughFunds(false);
        }
      }
    })();
  }, [feeActivationCard, tokenBalance, balance, amount, variantPay?.id]);

  useEffect(() => {
    const valueCorrect = validateAmount(amount);

    if (amount && Number(amount) && !focusForm) {
      setFinalValue(amount);
    }

    if (!valueCorrect) return;

    if (amount && variantPay?.paymentType && binId && !focusForm) {
      const hasPromocode = promocode ? { promoCode: promocode.trim() } : {};
      amountPrev.current = amount;

      setFinalValueLoading(true);
      cardDispatch
        .getCreateCardPrice({
          binId: binId,
          deposit: Number(amount),
          paymentType: variantPay.paymentType,
          ...hasPromocode,
        })
        .then((res) => {
          setFinalValue(res.data.result.sum);
          setRubRate(res.data.result.rate);
          setPromocodeAccepted(res.data.result.promoCode);
          setDiscount(res.data.result.discount);

          if (!res.data.result.promoCode && promocode) {
            showError(t("promoCodeInvalid"));
          }

          setFinalValueLoading(false);
        })
        .catch((e) => {
          showError(e.response.data.message || e);
          setFinalValueLoading(false);
        });
    }
  }, [cardDispatch, amount, focusForm, variantPay, promocode]);

  useEffect(() => {
    if (!templates?.length) {
      cardDispatch.getTemplates();
    }
  }, [templates, cardDispatch, binId]);

  const title = fromPayForServices
    ? t("payForSpecificService", { serviceName: currentService?.title })
    : t("issueCard");

  const processingRoute = fromPayForServices
    ? PAY_FOR_SERVICES_ROUTE
    : CARDS_ROUTE;

  const failRoute = fromPayForServices ? PAY_FOR_SERVICES_ROUTE : CARDS_ROUTE;
  // const successRoute = (data) => cardInfoRoute(data?.cardId);
  const successRoute = (data) => CARDS_ROUTE;

  const balanceNotEnough = isRUB ? true : finalValue <= tokenBalance;

  const validAmount =
    amount &&
    amount <= maxCreateDepositValue &&
    amount >= minCreateDepositValue &&
    balanceNotEnough;

  const submitMethod = TFA ? handlePayWithTFA : handlePay;

  const { color: rightColorBalance, hasError } = useGetColorsError({
    balance: tokenBalance || 0,
    amount: finalValue,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });

  const disablePayButton =
    !validAmount ||
    !variantPay?.id ||
    finalValueLoading ||
    (!isRUB && (notBalanceEnoughFunds || notTokenEnoughFunds)) ||
    (!isRUB && hasError) ||
    focusForm;

  const contentRef = useRef(null);
  const navigationRef = useRef(null);

  const { contentHeight } = useGetMainContentHeight(contentRef, navigationRef);

  return (
    <PageLoader loading={loading} noArrow={false}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        cb={handle2FACallback}
        onSuccess={handlePay}
      >
        <IFrameLayout
          view={!!paymentLink}
          updatingWhenIFrameOpened={refreshStatus}
          processingBefore={showProcessing}
          CongratsComponent={SuccessPage}
          ProcessingComponent={ProcessingPage}
          ErrorComponent={ErrorPage}
          link={paymentLink}
          title={t("backToApp")}
          processingRoute={processingRoute}
          continueRoute={successRoute}
          processingFetch={handleUpdateStatus}
          errorRoute={failRoute}
          cb={() => {
            setRefreshStatus(false);
            setPaymentLink(null);
            setShowProcessing(true);
          }}
        >
          <Wrapper>
            <Box>
              <PageHeader title={title} to={SELECT_OR_ISSUE_CARDS_ROUTE} />
              <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
                <Box m="10px 0 0 0">
                  <Switcher
                    variantsPay={formatVariantsPay}
                    activeVariant={variantPay}
                    onChange={handleChangeVariantPay}
                  />
                </Box>
                <Box m="16px 0 8px 0">
                  <Input
                    type="number"
                    onlyWholeNumbers
                    onFocus={() => setFocusForm(true)}
                    onBlur={() => setFocusForm(false)}
                    onChange={handleSetAmount}
                    value={amount}
                    placeholder={t("amount")}
                  />
                </Box>
                <DepositItems
                  minHeight="100px"
                  onChange={handleChangeAmount}
                  items={depositValues}
                  transform={(value) => `$${value}`}
                  activeItem={activeItem}
                />

                {isRUB && (isNotPassed || isKycPending) ? null : (
                  <Box m="16px 0 8px 0">
                    <Input
                      onFocus={() => setFocusForm(true)}
                      onBlur={() => setFocusForm(false)}
                      onChange={handleChangePromocode}
                      value={promocode}
                      placeholder={t("promoCode")}
                    />

                    {promocodeAccepted && (
                      <Box m="8px 0 0 0">
                        <Typography variant="link" color="success">
                          {t("promoCodeAccepted")}
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
                {isVariantRub && !isKycSuccess ? (
                  <Box m="16px 0 0 0">
                    <KYCBlock />
                  </Box>
                ) : (
                  <Box m="16px 0 0 0">
                    {!isRUB ? (
                      <Box>
                        <LineInfo
                          leftTitle={t("yourBalanceCurrency", {
                            chainName: variantPay.name,
                          })}
                          rightTitle={`${convertNumberToSafe(
                            tokenBalance || 0
                          )} ${variantPay.currencyName}`}
                          rightColor={rightColorBalance}
                        />
                        <Box m="4px 0 0px 0">
                          <LineInfo
                            leftTitle={t("yourCurrencyBalance", {
                              currency: variantPay.coinName,
                            })}
                            rightTitle={`${
                              balanceLoading
                                ? 0
                                : convertNumberToSafe(balance) || 0
                            } ${variantPay.coinName}`}
                            rightColor={
                              notBalanceEnoughFunds && balanceReady
                                ? "red"
                                : "white"
                            }
                          />
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <LineInfo
                          leftTitle={t("usdExchangeRate")}
                          rightTitle={`~ ${fixedToSecond(rubRate || 0)} RUB`}
                        />
                      </Box>
                    )}
                    <Row m="16px 0 0 0">
                      <LineInfo
                        leftTitle={t("cardActivationFee")}
                        rightColor={promocodeAccepted ? "success" : ""}
                        rightTitle={`$${fixedToSecond(feeActivationCard) || 0}`}
                      />
                    </Row>
                    <LineInfo
                      leftTitle={t("cardTopUpFee")}
                      rightTitle={`${fixedToSecond(topupCardFee * 100) || 0}%`}
                    />
                    <Box m="16px 0 0 0">
                      <Row width="100%" justifyContent="space-between">
                        <Typography variant="bold" m="0 0 16px 0">
                          {t("totalAmountDue")}
                        </Typography>
                        <Typography variant="bold" m="0 0 16px 0">
                          {`${Number(finalValue)?.toFixed(2)} ${
                            variantPay.currencyName
                          }`}
                        </Typography>
                      </Row>
                    </Box>
                    <Typography variant="link" m="0 0 0 0">
                      {t("allFeesIncluded")}
                    </Typography>
                  </Box>
                )}
              </ContentWrapper>
            </Box>
            <Box ref={navigationRef} p="16px 0 0 0">
              {isKycPending ? null : isVariantRub && isNotPassed ? (
                <PassKycButton from={CREATE_CARD} />
              ) : (
                <ButtonHorizontal
                  bottomMarginForScreen
                  variant="small"
                  disable={disablePayButton}
                  onClick={submitMethod}
                  title={
                    finalValueLoading ? (
                      <Spinner />
                    ) : (
                      t("payAmount", {
                        amount: numberFormat(finalValue) || 0,
                        currencyName: variantPay.currencyName,
                      })
                    )
                  }
                />
              )}
            </Box>
          </Wrapper>
        </IFrameLayout>
      </TFACheckContainer>
    </PageLoader>
  );
};
