import React from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import { BackArrowIconStyled, Box, Typography } from "../../../UI";
import { useTranslate } from "../../../contexts";
import {
	CREATE_CARD, FROM_MAKER_PROJECT,
	FROM_PAGE,
	FROM_PAY_FOR_SERVICES,
	FROM_TOP_UP_CARD,
	ISSUE_CARD,
	TOP_UP_FROM_PAY_FOR_SERVICES,
} from "../../../config";

export const ProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();

  const fromPage = localStorage.getItem(FROM_PAGE);

  const buttonName =
    {
      [FROM_TOP_UP_CARD]: "backToCard",
      [FROM_PAY_FOR_SERVICES]: "backToServices",
      [TOP_UP_FROM_PAY_FOR_SERVICES]: "backToServices",
      [ISSUE_CARD]: "backToIssueCard",
      [CREATE_CARD]: "backToCreateCard",
      [FROM_MAKER_PROJECT]: "backToMakerProject",
    }[fromPage] || "back";

  const handleCallback = () => {
    localStorage.removeItem(FROM_PAGE);
    if (cb) {
      cb();
    }
  };

  return (
    <ContentWrapper
      cb={handleCallback}
      button={
        <>
          <BackArrowIconStyled /> {t(buttonName)}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            🔄
          </Typography>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("pleaseWaitFrom")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("ifYouHaveTroubles")}
          </Typography>
        </Box>
      }
    />
  );
};
