import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  ERROR_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
  YELLOW_FIELD_NAME,
} from "../../UI";
import {
  ButtonHorizontal,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
} from "../../components";
import { MIN_POWDER, SUCCESS, VARIANT_PAY_BEP20 } from "../../config";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  convertNumberToSafe,
  declOfNum,
  numberWithCommas,
} from "../../utils/formatting";
import { SwitcherStyled, Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { MDAO_MAKER_STAKING_ROUTE } from "../../routes/routes";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useGetMakerStakingInit, useGetRouter } from "../../hooks";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { STAKES_NAME, stakesAddLocalStorage } from "./utils";
import { STATUSES } from "./helpers";
import { AgreedTermsAndPolicy } from "../../components/AgreedTermsAndPolicy";
import Skeleton from "react-loading-skeleton";

export const StakeMdaoPage = () => {
  const { t } = useTranslate();
  const { showError, showSuccess, showErrorCode } = useSnackbar();
  const dispatch = useDispatch();
  const { handleRoute } = useGetRouter();
  const makerDispatch = dispatch.maker;
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const { project_id } = useParams();
  const [amount, setAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [activeAmount, setActiveAmount] = useState(null);
  const [activeTerm, setActiveTerm] = useState(null);
  const [requirements, setRequirements] = useState(null);
  const [show, setShow] = useState(false);
  const [focusForm, setFocusForm] = useState(false);

  const TFA = user?.tfaStatus;

  const { rules, usdtBalance, tokenBalancesLoading } = useGetMakerStakingInit();

  const {
    balance: coinBalance,
    afterLoading,
    balanceLoading,
  } = useGetBalance({
    chains,
    userData: user,
    chain: VARIANT_PAY_BEP20.chain,
  });

  const handlePay = (code) => {
    setLoading(true);
    return makerDispatch
      .addStake({ ruleId: currentRule.ruleId, amount, code })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          showSuccess(t("successfullyStaked"));
          const mockStake = {
            status: STATUSES.pending,
            ruleId: currentRule.ruleId,
            amount,
            mock: true,
            index: Number(res.data.result),
          };

          stakesAddLocalStorage(STAKES_NAME, mockStake);
          handleRoute(MDAO_MAKER_STAKING_ROUTE);
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayTFA = () => {
    if (TFA) {
      setShow(true);
    } else {
      handlePay();
    }
  };

  useEffect(() => {
    // makerDispatch.getStakings({ cache: false });
  }, [makerDispatch]);

  const handleGetProject = async (projectId) => {};

  const handleGetTicketReuirements = async (projectId) => {
    setLoading(true);
    await makerDispatch
      .getTicketsRequirements({ projectId })
      .then((res) => {
        setRequirements(res?.data?.result);
      })
      .catch((e) => {
        showError(e);
        return e;
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (project_id) {
      (async () => {
        await handleGetProject(project_id);
        await handleGetTicketReuirements(project_id);
      })();
    }
  }, [makerDispatch, project_id]);

  const variantsAmount = useMemo(() => [
    { title: "5,000", id: 0, value: 5000 },
    { title: "10,000", id: 1, value: 10000 },
    { title: "25,000", id: 2, value: 25000 },
    { title: "50,000", id: 3, value: 50000 },
  ]);

  const variantsTerm = useMemo(() => {
    return rules?.map((rule) => {
      const periodValue = rule.period[Object.keys(rule.period)[0]];
      const periodName = Object.keys(rule?.period || {})[0];

      const periodTrans = declOfNum(periodValue, [
        t(`${periodName}1`),
        t(`${periodName}3`),
        t(`${periodName}2`),
      ]);

      return {
        title: `${periodValue} ${periodTrans}`,
        id: rule.ruleId,
      };
    });
  }, [rules]);

  useEffect(() => {
    if (variantsTerm?.length && !activeTerm) {
      setActiveTerm(variantsTerm[0]);
    }
  }, [activeTerm, variantsTerm]);

  const currentRule = rules?.find((rule) => rule?.ruleId === activeTerm?.id);

  const handleSetAmountVariant = (value) => {
    const foundVariant = variantsAmount.find(
      (variant) => variant.value === Number(value)
    );
    if (foundVariant) {
      setActiveAmount(foundVariant);
    } else {
      setActiveAmount(null);
    }
  };

  const handleSetAmount = (value) => {
    setAmount(value);
    handleSetAmountVariant(value);
  };

  const handleMaxAmount = () => {
    if (!tokenBalancesLoading) {
      handleSetAmountVariant(usdtBalance);
      setAmount(convertNumberToSafe(usdtBalance, 2));
    }
  };

  const handleSetActiveAmount = (value) => {
    setActiveAmount(value);
    setAmount(value?.value);
  };
  const handleSetActiveTerm = (value) => {
    setActiveTerm(value);
  };

  const { color: rightColorCoinBalance, hasError: coinBalanceError } =
    useGetColorsError({
      balance: coinBalance,
      amount: MIN_POWDER,
      successColor: WHITE_FIELD_NAME,
      errorColor: ERROR_FIELD_NAME,
      readyValue: afterLoading,
    });

  const { color: rightColorUsdtBalance, hasError: usdtBalanceError } =
    useGetColorsError({
      balance: usdtBalance,
      amount: amount,
      successColor: WHITE_FIELD_NAME,
      errorColor: ERROR_FIELD_NAME,
      readyValue: !tokenBalancesLoading,
    });

  const { color: rightColorMinDeposit, hasError: usdtMinDepositError } =
    useGetColorsError({
      balance: amount,
      amount: currentRule?.minDeposit,
      successColor: WHITE_FIELD_NAME,
      errorColor: YELLOW_FIELD_NAME,
      readyValue: !!amount && !focusForm,
    });

  const disableButton =
    usdtBalanceError ||
    coinBalanceError ||
    !amount ||
    usdtMinDepositError ||
    !agreed;

  const maxButtonDisable = tokenBalancesLoading || usdtBalance < 1;

  const ticketsAmount = amount / (currentRule?.usdtPerTicket || 1);

  return (
    <PageLoader loading={loading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handlePay}
      >
        <Wrapper>
          <Box>
            <PageHeader
              title={t("stakingUsdt")}
              to={MDAO_MAKER_STAKING_ROUTE}
            />
            <Box m="10px 0 0 0">
              <Input
                onlyWholeNumbers
                type="number"
                onChange={handleSetAmount}
                value={amount}
                placeholder={t("usdtToStake")}
                onFocus={() => setFocusForm(true)}
                onBlur={() => setFocusForm(false)}
                actionButtonTitle={t("max").toLowerCase()}
                actionButton={handleMaxAmount}
                actionDisable={maxButtonDisable}
              />
              <Box m="8px 0 16px 0">
                <SwitcherStyled
                  autocomplete
                  variantsPay={variantsAmount}
                  activeVariant={activeAmount}
                  onChange={handleSetActiveAmount}
                />
              </Box>
              {!variantsTerm?.length ? (
                <Skeleton
                  count={1}
                  width="100%"
                  height="48px"
                  borderRadius="12px"
                />
              ) : (
                <SwitcherStyled
                  autocomplete
                  variantsPay={variantsTerm}
                  activeVariant={activeTerm}
                  onChange={handleSetActiveTerm}
                />
              )}

              <Box m="16px 0 0 0">
                <LineInfo
                  valueLoading={tokenBalancesLoading}
                  leftTitle={`${t("yourCurrencyBalance", {
                    currency: "USDT",
                  })}`}
                  rightTitle={`${convertNumberToSafe(usdtBalance) || 0} USDT`}
                  rightColor={rightColorUsdtBalance}
                />
                <LineInfo
                  leftTitle={`${t("yourCurrencyBalance", {
                    currency: VARIANT_PAY_BEP20.coinName,
                  })}`}
                  valueLoading={balanceLoading}
                  rightTitle={`${coinBalance} ${VARIANT_PAY_BEP20.coinName}`}
                  rightColor={rightColorCoinBalance}
                />
              </Box>
              {currentRule && (
                <Box m="16px 0 16px 0">
                  <LineInfo
                    rightErrorColor={YELLOW_FIELD_NAME}
                    rightColor={rightColorMinDeposit}
                    leftTitle={`${t("minToDeposit")}:`}
                    rightTitle={`${currentRule?.minDeposit} USDT`}
                  />
                  <LineInfo
                    leftTitle={`${t("depositAmountForOneTicket")}:`}
                    rightTitle={`${currentRule?.usdtPerTicket || 0} USDT`}
                  />

                  <Row
                    width="100%"
                    justifyContent="space-between"
                    m="16px 0 0 0"
                  >
                    <Typography variant="bold" m="0 0 16px 0">
                      {t("youWillReceive")}:
                    </Typography>
                    <Typography variant="bold" m="0 0 16px 0">
                      {`${Math.floor(Number(ticketsAmount))} ${declOfNum(
                        ticketsAmount,
                        [t("ticket1"), t("ticket3"), t("ticket2")]
                      )}`}
                    </Typography>
                  </Row>
                </Box>
              )}
            </Box>
          </Box>
          <Box>
            <Box m="0 0 16px 0">
              <AgreedTermsAndPolicy checked={agreed} onClick={setAgreed} />
            </Box>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              disable={disableButton}
              onClick={handlePayTFA}
              title={t("stakeUsdtAmount", {
                amount: numberWithCommas(amount) || 0,
              })}
            />
          </Box>
        </Wrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
