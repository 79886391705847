import styled from "styled-components";
import { Paper } from "../../UI";

export const PaperStyled = styled(Paper)`
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;
