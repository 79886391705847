import React, { useMemo } from "react";
import { Accordion } from "../Accordion/Accordion";
import { Box, Typography } from "../../UI";
import { DropdownChainWrapper, ItemChain } from "./styled";

export const Dropdown = ({
  items,
  currentValue,
  setValue,
  subTitle,
  ...props
}) => {
  const DataComponent = useMemo(
    () =>
      ({ data, close }) => {
        const dataFiltered = data?.filter((el) => el?.id !== currentValue?.id);

        const handleSetChain = (value) => {
          setValue(value);
          close();
        };

        return (
          <>
            {dataFiltered?.map((el, i) => {
              return (
                <ItemChain
                  key={i}
                  variant="link"
                  m="0 0 10px 0"
                  onClick={() => {
                    handleSetChain(el);
                  }}
                >
                  {el?.title || ""}
                </ItemChain>
              );
            })}
          </>
        );
      },
    [currentValue, setValue]
  );

  const SelectComponent = ({ value }) => {
    return (
      <Box>
        {subTitle && (
          <Typography color="hint" variant="tiny">
            {subTitle}
          </Typography>
        )}
        <Typography variant="link">{value?.title || "..."}</Typography>
      </Box>
    );
  };

  const itemsFormat = useMemo(
    () =>
      items.map((el) => {
        return {
          id: el?.id,
          title: el?.title,
        };
      }),
    [items]
  );

  return (
    <DropdownChainWrapper>
      <Accordion
        {...props}
        value={currentValue}
        data={itemsFormat}
        ContentHeader={SelectComponent}
        Content={DataComponent}
      />
    </DropdownChainWrapper>
  );
};
