import React from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import {
  BackArrowIconStyled,
  Box,
  Link,
  NextArrowIconStyled,
  Row,
  Typography,
} from "../../../UI";
import { useTranslate } from "../../../contexts";
import { VARIANT_PAY_BEP20 } from "../../../config";

export const SuccessPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("youHaveSuccessfullyPassed")}
          </Typography>
        </Box>
      }
    />
  );
};
