import React, { memo } from "react";
import { Box, Link } from "../../UI";
import styled from "styled-components";
import { ReactComponent as CustomIcon } from "../../assets/svg/project-icons/custom.svg";
import { ReactComponent as DiscordIcon } from "../../assets/svg/project-icons/discord.svg";
import { ReactComponent as GithubIcon } from "../../assets/svg/project-icons/github.svg";
import { ReactComponent as GlobalIcon } from "../../assets/svg/project-icons/global.svg";
import { ReactComponent as InstagramIcon } from "../../assets/svg/project-icons/instagram.svg";
import { ReactComponent as MediumIcon } from "../../assets/svg/project-icons/medium.svg";
import { ReactComponent as RedditIcon } from "../../assets/svg/project-icons/reddit.svg";
import { ReactComponent as TelegramIcon } from "../../assets/svg/project-icons/telegram.svg";
import { ReactComponent as TwitterIcon } from "../../assets/svg/project-icons/twitter.svg";
import { ReactComponent as WhitepaperIcon } from "../../assets/svg/project-icons/whitepaper.svg";
import { ReactComponent as YoutubeIcon } from "../../assets/svg/project-icons/youtube.svg";
import { LinkComponent } from "../Link/Link";

const WrapperIcons = styled.div`
  margin-bottom: 16px;
`;

const IconsAssset = {
  telegram: TelegramIcon,
  twitter: TwitterIcon,
  whitepaper: WhitepaperIcon,
  reddit: RedditIcon,
  discord: DiscordIcon,

  instagram: InstagramIcon,
  github: GithubIcon,
  youtube: YoutubeIcon,
  medium: MediumIcon,
  web: GlobalIcon,
};

const IconWrapper = styled.div`
  height: 40px;
  width: fit-content;
  display: flex;
  justify-content: center;
  &:last-child {
    margin-right: 0;
    justify-content: end;
  }

  &:first-child {
    justify-content: start;
  }
`;

const RowStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const splitArray = (arr, num) => {
  const result = [];
  for (let i = 0; i < arr?.length; i += num) {
    result.push(arr.slice(i, i + num));
  }
  return result;
};

export const Social = memo(({ links }) => {
  const iconsArr = splitArray(Object.entries(links || {}), 5);

  return (
    <Box>
      <WrapperIcons>
        {iconsArr.map((row) => {
          return (
            <RowStyled>
              {row.map(([icon, link]) => {
                const Icon = IconsAssset[icon] || CustomIcon;
                return (
                  <IconWrapper>
                    <LinkComponent to={link}>
                      <Icon />
                    </LinkComponent>
                  </IconWrapper>
                );
              })}
            </RowStyled>
          );
        })}
      </WrapperIcons>
    </Box>
  );
});
