import React, { useRef } from "react";
import { Box, setStyle } from "../../UI";
import styled from "styled-components";
import debounce from "lodash.debounce";

const Link = styled(Box)`
  font-family: Roboto, sans-serif;
  color: ${({ theme, color }) =>
    color ? theme?.colors?.[color] : theme.colors.text};
  text-align: ${setStyle("ta", "left")};
  ${({ theme, variant }) => theme.fonts[variant] || theme.fonts.h2};
  font-size: ${({ variant }) => (variant ? null : setStyle("fs", "16px"))};
  line-height: ${({ variant }) => (variant ? null : setStyle("lh", "normal"))};
  ${({ theme, variant }) => theme.fonts[variant] || theme.fonts.link};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.link};
  cursor: pointer;
`;

const BoxStyled = styled(Box)`
  cursor: pointer;
`;

const bot = window.Telegram?.WebApp;

export const LinkComponent = ({
  to,
  title,
  children,
  inBotLink,
  isTelegramLink,
  callback,
}) => {
  const canLink = useRef(false);

  const handleOpenLink = () => {
    if (isTelegramLink && to) {
      bot?.openTelegramLink(to);
      callback && callback();
      return;
    }

    if (to && inBotLink) {
      window.location.href = to;
      return;
    }

    if (!canLink.current && bot?.openLink && to) {
      canLink.current = true;
      if (inBotLink) {
        bot?.openTelegramLink(to);
      } else {
        bot?.openLink(to);
      }

      setTimeout(() => {
        canLink.current = false;
      }, 1000);
    }
  };
  return title ? (
    <Link onClick={handleOpenLink}>{title}</Link>
  ) : (
    <BoxStyled onClick={handleOpenLink}>{children}</BoxStyled>
  );
};
