import React, { useMemo, useState } from "react";
import { Box, Row, Typography } from "../../UI";
import {
  ButtonHorizontal,
  Input,
  LineInfo,
  PageHeader,
} from "../../components";
import { VARIANT_PAY_BEP20 } from "../../config";
import { useTranslate } from "../../contexts";
import { numberWithCommas } from "../../utils/formatting";
import { Wrapper } from "./styled";
import { useParams } from "react-router-dom";
import { mdaoMakerToProjectRoute } from "../../routes/routes";
import { useGetMakerProject, useGetMakerRequirements } from "../../hooks";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useSelector } from "react-redux";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";

export const MakerBuyTokensPage = () => {
  const { t } = useTranslate();
  const [tokensAmount, setTokenAmount] = useState("");
  const { project_id } = useParams();
  const { user, chains, tokens } = useSelector(({ wallet }) => wallet);
  const { requirements: cardsRequirements } = useSelector(({ cards }) => cards);

  const { project } = useGetMakerProject(project_id);

  const { balance: bnbBalance, balanceLoading: bnbBalanceLoading } =
    useGetBalance({
      chains,
      userData: user,
      chain: VARIANT_PAY_BEP20.chain,
    });

  const tokenId = useMemo(
    () => cardsRequirements?.[VARIANT_PAY_BEP20.idTokenKey],
    [cardsRequirements]
  );
  const tokenIds = useMemo(() => [tokenId], [tokenId]);

  const {
    tokenBalance: usdtBalance,
    tokenBalancesLoading: usdtBalanceLoading,
  } = useGetTokenBalances({
    chains,
    chain: VARIANT_PAY_BEP20.chain,
    balanceCurrentTokenId: tokenId,
    tokens,
    tokenIds,
  });

  const handleSetTokens = (value) => setTokenAmount(value);
  const handleMaxAmount = () => setTokenAmount(1000000);
  const handlePay = () => {};

  const disableButton = !tokens;

  const ticketPrice = 100;
  const notEnoughFunds = ticketPrice * tokens > usdtBalance;
  return (
    <Wrapper>
      <Box>
        <PageHeader
          title={t("buyTokens", { tokenName: project?.token })}
          to={mdaoMakerToProjectRoute(project_id)}
        />
        <Box m="10px 0 0 0">
          <Input
            onlyWholeNumbers
            type="number"
            onChange={handleSetTokens}
            value={tokensAmount}
            placeholder={t("ticketsToUse")}
            actionButtonTitle={t("max").toLowerCase()}
            actionButton={handleMaxAmount}
            // actionDisable={maxButtonDisable}
          />
          <Box m="16px 0 16px 0">
            <LineInfo leftTitle={`${t("ticketsBalance")}:`} rightTitle={500} />
            <LineInfo leftTitle={t("ticketsToPayLeft")} rightTitle={500} />
          </Box>
          <Box>
            <LineInfo
              leftTitle={`${t("tokensPerTicket")}:`}
              rightTitle={`${"125".toLocaleString("en-US")} ${project?.token}`}
            />
            <LineInfo
              leftTitle={`${t("ticketPrice")}:`}
              rightTitle={`${Number(ticketPrice).toFixed(2)} ${
                VARIANT_PAY_BEP20.currencyName
              }`}
            />
            <LineInfo
              leftTitle={`${t("serviceFee")}`}
              rightTitle={`${Number(25).toFixed(2)} %`}
            />
          </Box>
          <Box m="16px 0 16px 0">
            <LineInfo
              leftTitle={`${t("yourCurrencyBalance", {
                currency: VARIANT_PAY_BEP20.name,
              })}`}
              valueLoading={usdtBalanceLoading}
              rightTitle={`${String(usdtBalance || 0).toLocaleString(
                "en-US"
              )} ${VARIANT_PAY_BEP20.currencyName}`}
            />
            <LineInfo
              leftTitle={`${t("yourCurrencyBalance", {
                currency: VARIANT_PAY_BEP20.coinName,
              })}`}
              valueLoading={bnbBalanceLoading}
              rightTitle={`${String(bnbBalance).toLocaleString("en-US")} ${
                VARIANT_PAY_BEP20.coinName
              }`}
              rightColor={notEnoughFunds ? "red" : "white"}
            />
          </Box>
          <Row width="100%" justifyContent="space-between">
            <Typography variant="bold" m="0 0 16px 0">
              {t("totalAmountDue")}
            </Typography>
            <Typography variant="bold" m="0 0 16px 0">
              {`${Number(400).toFixed(2)} ${
                VARIANT_PAY_BEP20.currencyName
              } + ${Number(100).toFixed(2)} ${
                VARIANT_PAY_BEP20.currencyName
              } (fee)`}
            </Typography>
          </Row>
          <Row width="100%" justifyContent="space-between" m="16px 0 0 0">
            <Typography variant="bold" m="0 0 16px 0">
              {`${t("tokensAmount")}:`}
            </Typography>
            <Typography variant="bold" m="0 0 16px 0">
              {`${numberWithCommas(500000)} PLN`}
            </Typography>
          </Row>
        </Box>
      </Box>
      <ButtonHorizontal
        variant="small"
        disable={disableButton}
        bottomMarginForScreen
        onClick={handlePay}
        title={t("payAmount", {
          amount: Number(tokensAmount || 0)?.toFixed(2),
          currencyName: VARIANT_PAY_BEP20.name,
        })}
      />
    </Wrapper>
  );
};
