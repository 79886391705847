import styled, { ThemeProvider } from "styled-components";
import { theme } from "./UI";
import { Layout } from "./components";
import I18NProvider from "./contexts/i18n/I18nProvider";
import { Provider } from "react-redux";
import store from "./store";
import { Web3Provider } from "./contexts/web3";
import { NotistackProvider, WalletProvider } from "./contexts";
import { ModalProvider } from "./contexts";
import { SkeletonTheme } from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const AllProviders = ({ children }) => {
  return (
    <Provider store={store}>
      <Web3Provider>
        <I18NProvider>
          <ThemeProvider theme={theme}>
            <NotistackProvider>
              <WalletProvider>
                <ModalProvider>
                  <SkeletonTheme
                    baseColor={theme.colors.background2}
                    highlightColor={theme.colors.background}
                  >
                    <Layout>{children}</Layout>
                  </SkeletonTheme>
                </ModalProvider>
              </WalletProvider>
            </NotistackProvider>
          </ThemeProvider>
        </I18NProvider>
      </Web3Provider>
    </Provider>
  );
};

export default AllProviders;
