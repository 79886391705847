import React from "react";
import { ERROR_FIELD_NAME, Row, Typography } from "../../UI";
import { Shake } from "../Animation/Shake";
import Skeleton from "react-loading-skeleton";

export const LineInfo = ({
  leftTitle,
  rightTitle,
  leftColor,
  leftVariant,
  rightVariant,
  rightColor,
  valueLoading,
  rightErrorColor,
}) => {
  const trigger = rightColor === (rightErrorColor || ERROR_FIELD_NAME);
  return (
    <Row width="100%" justifyContent="space-between">
      <Typography
        variant={leftVariant || "link"}
        m="0 0 4px 0"
        color={leftColor || "hint"}
      >
        {leftTitle}
      </Typography>
      <Shake trigger={trigger}>
        {valueLoading ? (
          <Skeleton width="80px" height="16px" borderRadius="8px" />
        ) : (
          <Typography variant={rightVariant || "link"} color={rightColor}>
            {rightTitle}
          </Typography>
        )}
      </Shake>
    </Row>
  );
};
