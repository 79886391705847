import React, { createContext, useCallback, useState } from "react";
import { OptimaContexts } from "../OptimazeContexts";
import { createModal } from "../../components";
import { useSnackbar } from "../notistack";
import styled from "styled-components";
import { Modals } from "../../components/Modals";
import { AnimatePresence, motion } from "framer-motion";

const Overlay = styled.div`
  background: rgba(26, 34, 44, 0.95);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
  z-index: 55555;
`;
const ModalWrapper = styled.div`
  position: fixed;
  z-index: 1000000;
`;
const ModalInner = styled.div`
  position: relative;
  z-index: 10000001;
`;

export const ModalContext = createContext(null);

export const ModalProvider = ({ children }) => {
  const [modalId, setModalId] = useState(null);
  const [props, setProps] = useState(null);
  const { showError } = useSnackbar();

  const close = useCallback(() => {
    setModalId(null);
    setProps(null);
  }, []);

  const open = useCallback((nameModal, props) => {
    if (Modals[nameModal]) {
      setProps(props);
      setModalId(nameModal);
    } else {
      showError("Modal not found");
    }
  }, []);

  const ModalComponent = createModal(Modals[modalId]);

  const animation = {
    initial: {
      opacity: 0,
    },
    animate: {
      opacity: 1,
    },
    exit: {
      opacity: 0,
    },
  };

  const animationOverlay = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    exit: { opacity: 0 },
  };

  return (
    <ModalContext.Provider value={{ close, open }}>
      <ModalWrapper>
        <ModalInner>
          <AnimatePresence>
            {modalId && ModalComponent && (
              <motion.div
                key="modal"
                animate="animate"
                exit="exit"
                initial="initial"
                transition={{ duration: 0.3, ease: "easeInOut" }}
                variants={animation}
              >
                <ModalComponent {...props} close={close} />
              </motion.div>
            )}
          </AnimatePresence>
        </ModalInner>
        <AnimatePresence mode="popLayout">
          {modalId && (
            <motion.div
              key="overlay"
              animate="animate"
              exit="exit"
              initial="initial"
              transition={{ duration: 0.3 }}
              variants={animationOverlay}
            >
              <Overlay onClick={close} />
            </motion.div>
          )}
        </AnimatePresence>
      </ModalWrapper>

      <OptimaContexts>{children}</OptimaContexts>
    </ModalContext.Provider>
  );
};
