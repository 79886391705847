import React from "react";
import { PaperStyled } from "./styled";
import { Box, Row, Typography } from "../../UI";
import styled from "styled-components";

const DepositItemsWrapper = styled(Row)`
  flex-flow: wrap;
  width: 100%;
`;

const DepositItemsStyled = styled(Box)`
  padding: 16px 0;
  border-right: ${({ theme }) => `1px solid ${theme.colors.background}`};
  border-bottom: ${({ theme }) => `1px solid ${theme.colors.background}`};
  background-color: ${({ theme, active }) =>
    active ? theme.colors.button : "transparent"};
  &:last-child {
    border-right: none;
  }
`;

export const DepositItems = ({
  items,
  activeItem,
  minHeight,
  onChange,
  currency,
  transform,
}) => {
  const widthItem = 100 / 4;
  return (
    <Box minHeight={minHeight}>
      <PaperStyled>
        <DepositItemsWrapper>
          {items?.map((item, i) => {
            return (
              <DepositItemsStyled
                key={i}
                onClick={() => onChange(item)}
                width={widthItem + "%"}
                active={activeItem?.toString() === item?.toString()}
              >
                <Typography ta="center" variant="link">
                  {currency}
                  {transform ? transform(item) : item}
                </Typography>
              </DepositItemsStyled>
            );
          })}
        </DepositItemsWrapper>
      </PaperStyled>
    </Box>
  );
};
