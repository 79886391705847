import { useMemo, createContext, useCallback, useEffect } from "react";
import { OptimaContexts } from "../OptimazeContexts";
import { useWeb3 } from "../../hooks";

export const Web3Context = createContext(null);

export const Web3Provider = ({ children }) => {
  const { web3, web3Interface } = useWeb3();

  const web3Methods = useCallback(
    async (newChain) => {
      const interfaceWeb3 = await web3Interface(newChain);

      if (!interfaceWeb3) {
        return {};
      }
      return {
        getBalance: ({ account, balances }) => {
          return interfaceWeb3.getBalance({ account, balances });
        },
        getGasPrice: async (data) => {
          const method = interfaceWeb3.getGasPrice;
          return await method(data);
        },
        getTokenGasPrice: async (data) => {
          return await interfaceWeb3.getTokenGasPrice(data);
        },
        getGasPriceToken: (data) => {
          return interfaceWeb3.getGasPriceToken(data);
        },
        getContract: ({ abi, address, contractOptions }) => {
          return interfaceWeb3.getContract({ abi, address, contractOptions });
        },
        checkValidAddress: (adr) => interfaceWeb3.checkValidAddress(adr),
      };
    },
    [web3, web3Interface]
  );

  return (
    <Web3Context.Provider value={{ web3Methods, web3 }}>
      <OptimaContexts>{children}</OptimaContexts>
    </Web3Context.Provider>
  );
};
