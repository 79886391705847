import { useTranslate } from "../../contexts";
import { ParamLine } from "../../components";
import { Box, Row, Typography } from "../../UI";
import React, { memo } from "react";
import styled from "styled-components";
import Skeleton from "react-loading-skeleton";
import get from "lodash.get";

const ParamRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
`;

const skeletonLayoutRow = [0, 1];

const ParamLineWrapper = styled.div`
  //margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const IndentBox = styled(Box)`
  margin-bottom: 12px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Params = React.memo(
  ({ paramsSchema, project, title, loading, key, ticketPrice, ticketFee }) => {
    const { t, language } = useTranslate();

    const renderParam = (param, project, loading) => {
      const value = get(project, param.paramName);
      const formattedValue = param.formatting
        ? param.formatting(value, project, t)
        : value;
      const CustomComponent = param.CustomComponent;
      const notView = param.notViewIf ? param.notViewIf(project) : false;
      const props = param.propsToComponent
        ? param.propsToComponent(project, { t, language })
        : {};

      if (CustomComponent) {
        return (
          <ParamLineWrapper>
            <CustomComponent
              {...props}
              loading={loading}
              ticketFee={ticketFee}
              ticketPrice={ticketPrice ? ticketPrice : null}
            />
          </ParamLineWrapper>
        );
      } else {
        return notView ? null : (
          <ParamLineWrapper>
            <ParamLine
              {...props}
              titleKey={param.titleKey}
              tParams={param.tParams && param.tParams(project)}
              value={formattedValue}
              loading={loading}
            />
          </ParamLineWrapper>
        );
      }
    };

    return (
      <Box key={key}>
        <Typography variant="bold">{title}</Typography>
        <Box m={title ? "12px 0 0 0" : "0"}>
          {!loading ? (
            paramsSchema.map((el) => {
              if (el.length > 1) {
                return (
                  <ParamRow
                    justifyContent="space-between"
                    // alignItems="center"
                    m="0 0 12px 0"
                  >
                    {el.map((param) => renderParam(param, project, loading))}
                  </ParamRow>
                );
              } else {
                return (
                  <IndentBox>
                    {el?.map((param) => renderParam(param, project))}
                  </IndentBox>
                );
              }
            })
          ) : (
            <Box>
              {skeletonLayoutRow.map((el) => (
                <Row
                  key={el}
                  m="0 0 8px 0"
                  justifyContent="space-between"
                  // alignItems="center"
                >
                  <Box width="50%">
                    <Skeleton width="40%" height="14px" />
                    <Skeleton width="90%" height="18px" />
                  </Box>
                  <Box width="50%">
                    <Skeleton width="40%" height="14px" />
                    <Skeleton width="90%" height="18px" />
                  </Box>
                </Row>
              ))}
            </Box>
          )}
        </Box>
      </Box>
    );
  }
);
