import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Box } from "../../UI";

export const Image = styled.img`
  width: 100%;
  height: 155px;
  object-fit: cover;
  border-radius: 12px;
`;

export const BalanceBlock = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 28px;
`;

export const MenuBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
`;

export const NavLinkComponents = styled(NavLink)`
  text-decoration: none;
  color: inherit;
  cursor: pointer;
`;

export const Wrapper = styled(Box)`
  //overflow: scroll;
  //overflow-y: scroll;
  //height: 100%;
`;
