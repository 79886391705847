export const STATUS_COLORS = {
  expired: "red",
  failed: "red",
  paid: "yellow",
  waiting: "yellow",
  pending: "yellow",
  success: "success",
  finished: "hint",
};

export const CARD_COLORS = [
  "#4CA48A",
  "#FF7518",
  "#082567",
  "#674C47",
  "#79A0C1",
];
