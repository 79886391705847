import React from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import { BackArrowIconStyled, Box, Row, Typography } from "../../../UI";
import { useTranslate } from "../../../contexts";
import { SpinnerStyled } from "../../IFrame/styled";

export const ProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          <BackArrowIconStyled /> {t("back")}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Row justifyContent="center" m="0 0 18px 0">
            <SpinnerStyled />
          </Row>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("applicationForBuyingTicketsSuccessfully")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("ifYouHaveTroubles")}
          </Typography>
        </Box>
      }
    />
  );
};

export const BuyAllocationProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          <BackArrowIconStyled /> {t("back")}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Row justifyContent="center" m="0 0 18px 0">
            <SpinnerStyled />
          </Row>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("yourTokenPurchaseApplicationHasBeenSuccessfully")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("ifYouEncounterAnyIssuesDontHesitate")}
          </Typography>
        </Box>
      }
    />
  );
};

export const FreeCheckAMLProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          <BackArrowIconStyled /> {t("backToProject")}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Row justifyContent="center" m="0 0 18px 0">
            <SpinnerStyled />
          </Row>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("applicationForAMLCheck")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("ifYouHaveTroubles")}
          </Typography>
        </Box>
      }
    />
  );
};

export const BuyTicketsProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          <BackArrowIconStyled /> {t("back")}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Row justifyContent="center" m="0 0 18px 0">
            <SpinnerStyled />
          </Row>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("yourTicketsPurchaseApplicationHasBeenSuccessfully")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("ifYouEncounterAnyIssues")}
          </Typography>
        </Box>
      }
    />
  );
};
