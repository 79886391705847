import React from "react";
import { declOfNum, shortWallet } from "../../utils/formatting";
import {
  AllocationPurchaseFee,
  ParamLine,
  ParamLinePlusInfo,
  ParamLineWithCopyValue,
  ParamVestingLine,
} from "../../components";
import { FAILED, PENDING, SUCCESS, VARIANTS_PAY } from "../../config";
import {
  ERROR_FIELD_NAME,
  SUCCESS_FIELD_NAME,
  YELLOW_FIELD_NAME,
} from "../../UI";
import { getSafeTranslate } from "../../utils/utils";

export const NA = "N/A";

export const STATUSES = {
  pending: "pending",
  finished: "finished",
  active: "active",
};

const transformToDollar = (value) =>
  value ? `$${value?.toLocaleString("en-US")}` : 0;

const PURCHASED_PLN = {
  titleKey: "purchasedTokens",
  tParams: (project) => ({ tokenName: project?.token }),
  paramName: "userProjectParamsData.purchasedPLN",
  formatting: (value, project) => "not api",
};

const MDAO_STAKING_TICKETS = {
  titleKey: "usdtStakingTickets",
  paramName: "stakingTickets",
  formatting: (value, project) => {
    return value || 0;
  },
};

const MDAO_STAKING_TICKETS_STATS = {
  titleKey: "usdtStakingTickets",
  paramName: "userProjectParamsData.usdtStakedTickets",
  formatting: (value, project) => value || 0,
};

const TICKETS_LEFT_TO_PURCHASE_PRIVATE_SALE = {
  titleKey: "ticketLefToPurchase",
  paramName: "ticketLefToPurchase",
  formatting: (value, project) => {
    const volume =
      (project?.privateRoundLeft || 0) / (project?.ticketPrice || 0);
    return volume?.toLocaleString("en-US") || "--";
  },
};

const STATUS = {
  CustomComponent: ParamLine,
  propsToComponent: (project, { t }) => {
    return {
      titleKey: "status",
      value: project?.userProjectParamsData?.subscribed
        ? t("subscribed")
        : t("notSubscribed"),
      color: project?.userProjectParamsData?.subscribed
        ? SUCCESS_FIELD_NAME
        : ERROR_FIELD_NAME,
    };
  },
};

const YOUR_ALLOCATION_PURCHASE_FEE = {
  CustomComponent: AllocationPurchaseFee,
  propsToComponent: (project, { t }) => {
    return {
      percent1: project?.ticketFee || 0,
      percent2: project?.userProjectParamsData?.discount || 0,
      tipMessage: t("yourAllocationPurchaseFeeMessage"),
      color: SUCCESS_FIELD_NAME,
      titleKey: "yourAllocationPurchaseFee",
    };
  },
};

const PAYABLE_TICKETS = {
  titleKey: "payableTickets",
  paramName: "userProjectParamsData.payableTickets",
  formatting: (value) => {
    return value || 0;
  },
};

const MAX_PAYABLE_TICKETS = {
  titleKey: "maxPayableTickets",
  paramName: "maxPayableTickets",
  formatting: (value) => {
    return value || 0;
  },
};

const AML_STATUS = {
  titleKey: "yourAMLStatus",
  paramName: "aml",
  notViewIf: (project) => !project?.amlRequired,
  formatting: (value, project, t) => {
    return project.aml
      ? t("passed")?.toUpperCase()
      : t("notPassed")?.toUpperCase();
  },
  propsToComponent: (project) => {
    return {
      color: project?.aml ? SUCCESS_FIELD_NAME : ERROR_FIELD_NAME,
    };
  },
};
const KYC_STATUS = {
  titleKey: "yourKYCStatusAML",
  paramName: "aml",
  notViewIf: (project) => !project?.kycRequired,
  formatting: (value, project, t) => {
    const success = project?.kyc === SUCCESS;
    const pending = project?.kyc === PENDING;
    return success
      ? t("passed")?.toUpperCase()
      : pending
      ? t("pending")?.toUpperCase()
      : t("notPassed")?.toUpperCase();
  },
  propsToComponent: (project) => {
    const success = project?.kyc === SUCCESS;
    const pending = project?.kyc === PENDING;
    return {
      color: success
        ? SUCCESS_FIELD_NAME
        : pending
        ? YELLOW_FIELD_NAME
        : ERROR_FIELD_NAME,
    };
  },
};

const PAYABLE_TICKETS_PREPARATION = {
  titleKey: "payableTickets",
  paramName: "userProjectParamsData.payableTickets",
  formatting: (value, project) => {
    return value ?? 0;
  },
};

const PAYABLE_TICKETS_ALLOCATION = {
  titleKey: "payableTickets",
  paramName: "payableTickets",
  formatting: (value, project) => {
    return (value ?? 0).toLocaleString("en-US");
  },
};

const SUBSCRIBED = {
  titleKey: "subscribed",
  paramName: "userProjectParamsData.subscribed",
  formatting: (value, project, t) => {
    return value ? t("yes").toUpperCase() : t("no").toUpperCase();
  },
};

const ALLOCATION_PURCHASED = {
  titleKey: "allocationPurchased",
  paramName: "userProjectParamsData.allocationPurchased",
  formatting: (value) => {
    return Number(value)?.toFixed(2) ?? 0;
  },
};
const DROP_WALLET = {
  titleKey: "dropWallet",
  paramName: "currentTicket.dropWallet",
  formatting: (value) => {
    return value ?? "--";
  },
};

const ALLOCATION_RECEIVED = {
  titleKey: "allocationClaimed",
  paramName: "userProjectParamsData.allocationClaimed",
  formatting: (value) => {
    return value ?? 0;
  },
};

const MDAO_HELD_TICKETS_STATS = {
  titleKey: "usdtHeldTickets",
  paramName: "userProjectParamsData.usdtHeldTickets",
  formatting: (value, project) => value || 0,
};

const MDAO_HELD_TICKETS = {
  titleKey: "usdtHeldTickets",
  paramName: "userProjectParamsData.heldUsed",
  formatting: (value, project) => value || 0,
};

const MDAO_HELD_TICKETS_ALLOCATION = {
  titleKey: "usdtHeldTickets",
  paramName: "heldTickets",
  formatting: (value, project) => value || 0,
};

const HELD_MDAO = {
  titleKey: "heldUsdt",
  paramName: "userProjectParamsData.held",
};

const MDAO_HELD = {
  titleKey: "usdtHeld",
  paramName: "userProjectParamsData.usdtHeld",
  formatting: (value) => {
    return `${(value || 0)?.toLocaleString("en-US")} USDT`;
  },
};

const MDAO_HELD_ALLOCATION = {
  titleKey: "usdtHeld",
  paramName: "usdtHeld",
  formatting: (value) => {
    return `${(value || 0)?.toLocaleString("en-US")} USDT`;
  },
};

const ATH = {
  titleKey: "ath",
  paramName: "ath",
  formatting: (value) => {
    return value ? `x${value || 0}` : "--";
  },
};

const CLAIMED_PLH = {
  titleKey: "claimedTokens",
  tParams: (project) => ({ tokenName: project?.token }),
  paramName: "userProjectParamsData.claimedPLN",
  formatting: (value) => "not api",
};

const PARTICIPANTS = {
  titleKey: "participants",
  paramName: "participant",
  formatting: (value) => "not api",
};

const PRIVATE_MEMBERS = {
  titleKey: "privateSaleMembers",
  paramName: "privateSaleMembers",
  formatting: (value) => value?.toLocaleString("en-US") || 0,
};

const MDAO_STAKED = (notExactly) => ({
  titleKey: "usdtStaked",
  paramName: "userProjectParamsData.usdtStaked",
  formatting: (value, project, t) => {
    return `${(value || 0)?.toLocaleString("en-US")} (${
      notExactly && project?.userProjectParamsData?.usdtStakedTickets ? "~" : ""
    }${project?.userProjectParamsData?.usdtStakedTickets} ${declOfNum(
      project?.userProjectParamsData?.usdtStakedTickets,
      [t("ticket1"), t("ticket3"), t("ticket2")]
    )})`;
  },
});

const MDAO_STAKED_ALLOCATION = (notExactly) => ({
  titleKey: "usdtStaked",
  paramName: "usdtStaked",
  formatting: (value, project, t) => {
    return `${(value || 0)?.toLocaleString("en-US")} (${
      notExactly && project?.stakingTickets ? "~" : ""
    }${project?.stakingTickets || 0} ${declOfNum(project?.stakingTickets, [
      t("ticket1"),
      t("ticket3"),
      t("ticket2"),
    ])}) `;
  },
});

const NETWORK = {
  titleKey: "network",
  paramName: "chain",
  formatting: (value) => value || "--",
};

const TOKEN = { titleKey: "token", paramName: "token" };

const PRICE_AT_LAUNCH = {
  titleKey: "priceAtLaunch",
  paramName: "launchPrice",
  formatting: transformToDollar,
};

const ALLOCATION = {
  titleKey: "allocation",
  paramName: "allocation",
  formatting: transformToDollar,
};

const MDAO_PER_HELD_TICKET = {
  titleKey: "usdtPerHeldTicket",
  paramName: "usdtPerHeldTicket",
  formatting: (value) => String(value).toLocaleString("en-US") || 0,
};

const TOKENS_PER_TICKET = {
  titleKey: "tokensPerTicket",
  paramName: "tokensPerTicket",
};

const TICKET_PRICE = {
  formatting: transformToDollar,
  CustomComponent: ParamLinePlusInfo,
  propsToComponent: (project, { t }) => {
    const percent = project?.ticketFee * 100;

    return {
      titleKey: "ticketPrice",
      value: `$${project?.ticketPrice?.toFixed(2)}`,
      plusInfoTitle: `$${((project?.ticketPrice / 100) * percent || 0)?.toFixed(
        2
      )}`,
      plusInfoSubTitle: `${t("serviceFee")} ${percent}%`,
    };
  },
};

const TGE = {
  titleKey: "tge",
  paramName: "tge",
  formatting: (value) => value,
};

const TOKEN_CONTRACT = {
  titleKey: "tokenContract",
  paramName: "contract",
  CustomComponent: ParamLineWithCopyValue,
  propsToComponent: (project) => {
    return {
      titleKey: "tokenContract",
      value: project?.contract,
      transformValue: shortWallet(
        project?.contract,
        6,
        project?.contract?.length - 4,
        "--"
      ),
    };
  },
};

const VESTING = {
  CustomComponent: ParamVestingLine,
  propsToComponent: (project, { language, t }) => {
    return {
      titleKey: "vesting",
      description: getSafeTranslate(project?.vesting, language),
      // smallDescription: project?.description?.[language],
      smallDescription: t("vestingDescription"),
    };
  },
};
export const projectParamsSchema = [
  [NETWORK, TOKEN],
  [PRICE_AT_LAUNCH, ALLOCATION],
  [TOKENS_PER_TICKET, TICKET_PRICE],
  [TGE, TOKEN_CONTRACT],
  [VESTING],
];

export const projectParamsPublicHoldSchema = [
  [NETWORK, TOKEN],
  [PRICE_AT_LAUNCH, ALLOCATION],
  [TOKENS_PER_TICKET, TICKET_PRICE],
  [TGE, TOKEN_CONTRACT],
  [MDAO_PER_HELD_TICKET],
  [VESTING],
];

export const projectParamsPublicSaleSchema = [
  [NETWORK, TOKEN],
  [PRICE_AT_LAUNCH, ALLOCATION],
  [TOKENS_PER_TICKET, TICKET_PRICE],
  [TGE, TOKEN_CONTRACT],
  [MDAO_PER_HELD_TICKET],
  [VESTING],
];

export const projectParamsPublicTicketDistributionSchema = [
  [NETWORK, TOKEN],
  [PRICE_AT_LAUNCH, ALLOCATION],
  [TOKENS_PER_TICKET, TICKET_PRICE],
  [TGE, TOKEN_CONTRACT],
  [MDAO_PER_HELD_TICKET],
  [VESTING],
];

export const projectParamsPublicSubscriptionSchema = [
  [NETWORK, TOKEN],
  [PRICE_AT_LAUNCH, ALLOCATION],
  [TOKENS_PER_TICKET, TICKET_PRICE],
  [TGE, TOKEN_CONTRACT],
  [MDAO_PER_HELD_TICKET],
  [VESTING],
];

export const participantParamsSchema = [
  [
    {
      titleKey: "privateRound",
      paramName: "privateRoundVolume",
      formatting: transformToDollar,
    },
    {
      titleKey: "publicRound",
      paramName: "publicRoundVolume",
      formatting: transformToDollar,
    },
  ],
  [
    MAX_PAYABLE_TICKETS,
    {
      titleKey: "paymentOptions",
      paramName: "paymentOptions",
      formatting: (value) => {
        return (
          VARIANTS_PAY.find((variant) => variant.paymentType === value?.[0])
            ?.name || "--"
        );
      },
    },
  ],
  [
    {
      titleKey: "KYC",
      paramName: "kycRequired",
      formatting: (value, project, t) => {
        return value ? t("required") : t("na");
      },
    },
  ],
];

export const participantParamsSchema_v1 = [
  [
    {
      titleKey: "publicRound",
      paramName: "publicRoundVolume",
      formatting: transformToDollar,
    },
    {
      titleKey: "paymentOptions",
      paramName: "paymentOptions",
      formatting: (value) => {
        return (
          VARIANTS_PAY.find((variant) => variant.paymentType === value?.[0])
            ?.name || "--"
        );
      },
    },
  ],
  [
    {
      titleKey: "KYC",
      paramName: "kycRequired",
      formatting: (value, project, t) => {
        return value ? t("required") : t("na");
      },
    },
  ],
];

const PUBLIC_MEMBERS = {
  titleKey: "publicSaleMembers",
  paramName: "publicSaleMembers",
  formatting: (value) => value?.toLocaleString("en-US") || 0,
};

export const allocationParams = [
  [MDAO_STAKING_TICKETS, PAYABLE_TICKETS_ALLOCATION],
  [PRIVATE_MEMBERS, PUBLIC_MEMBERS],
];

export const allocationHoldParams = [
  [MDAO_STAKING_TICKETS, PAYABLE_TICKETS_ALLOCATION],
  [PRIVATE_MEMBERS, PUBLIC_MEMBERS],
  [MDAO_HELD_ALLOCATION],
];

export const allocationHoldParams_v1 = [[PUBLIC_MEMBERS]];

export const allocationParams_v1 = [[PUBLIC_MEMBERS]];

export const allocationPublicTicketDistributionParams = [
  [MDAO_STAKING_TICKETS, PAYABLE_TICKETS_ALLOCATION],
  [PRIVATE_MEMBERS, PUBLIC_MEMBERS],
  [MDAO_HELD_ALLOCATION, MDAO_HELD_TICKETS_ALLOCATION],
];
export const allocationPublicTicketDistributionParams_v1 = [
  [PUBLIC_MEMBERS, MDAO_HELD_ALLOCATION],
  [MDAO_HELD_TICKETS_ALLOCATION],
];

export const allocationTokenDistributionParams = [
  [MDAO_STAKING_TICKETS, PAYABLE_TICKETS_ALLOCATION],
  [PRIVATE_MEMBERS, PUBLIC_MEMBERS],
  [MDAO_HELD, MDAO_HELD_TICKETS_ALLOCATION],
  [ATH],
];
export const allocationTokenDistributionParams_v1 = [
  [PUBLIC_MEMBERS, MDAO_HELD_ALLOCATION],
  [MDAO_HELD_TICKETS_ALLOCATION, ATH],
];
export const allocationPrivateTicketsDistributionParams = [
  [PRIVATE_MEMBERS, PAYABLE_TICKETS_ALLOCATION],
  [MDAO_STAKED_ALLOCATION(true)],
];

export const allocationPrivateTicketsDistributionParams_v1 = [
  [MDAO_STAKED_ALLOCATION(true)],
];

// TODO
export const allocationPreparationParams = [
  [PRIVATE_MEMBERS, PAYABLE_TICKETS_ALLOCATION],
  [MDAO_STAKED_ALLOCATION(true)],
];

export const allocationPreparationParams_v1 = [[MDAO_STAKED_ALLOCATION(true)]];

export const allocationPrivateSaleParams = [
  [MDAO_STAKING_TICKETS, PAYABLE_TICKETS_ALLOCATION],
  [PRIVATE_MEMBERS, TICKETS_LEFT_TO_PURCHASE_PRIVATE_SALE],
];
export const allocationPrivateSaleParams_v1 = [
  [TICKETS_LEFT_TO_PURCHASE_PRIVATE_SALE],
];

export const myParamsSchemaPreparation = [
  [MDAO_STAKED(true), PAYABLE_TICKETS_PREPARATION],
  [KYC_STATUS],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];

export const myParamsSchemaPrivateSale = [
  [MDAO_STAKING_TICKETS_STATS, PAYABLE_TICKETS_PREPARATION],
  [KYC_STATUS, ALLOCATION_PURCHASED],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];

export const myParamsSchemaHolding = [
  [MDAO_STAKING_TICKETS_STATS, PAYABLE_TICKETS_PREPARATION],
  [KYC_STATUS],
  [ALLOCATION_PURCHASED, SUBSCRIBED],
  [MDAO_HELD],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];

export const myParamsSchemaPrivateTicketsDistribution = [
  [MDAO_STAKED(), PAYABLE_TICKETS_PREPARATION],
  [KYC_STATUS],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];

export const myParamsSchemaSubscription = [
  [MDAO_STAKING_TICKETS_STATS, PAYABLE_TICKETS],
  [KYC_STATUS],
  [ALLOCATION_PURCHASED, SUBSCRIBED],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];
export const myParamsSchemaTicketDistribution = [
  [MDAO_STAKING_TICKETS_STATS, PAYABLE_TICKETS],
  [ALLOCATION_PURCHASED, SUBSCRIBED],
  [MDAO_HELD, MDAO_HELD_TICKETS_STATS],
  [KYC_STATUS],
  [YOUR_ALLOCATION_PURCHASE_FEE],
];
export const myParamsSchemaDistribution = [
  [STATUS, MDAO_STAKING_TICKETS],
  [KYC_STATUS],
  [PAYABLE_TICKETS, MDAO_HELD_TICKETS],
  [PURCHASED_PLN, CLAIMED_PLH],
];

export const myParamsSchemaTokenDistribution = [
  [ALLOCATION_PURCHASED, ALLOCATION_RECEIVED],
  [DROP_WALLET],
];

export const myParamsSchemaPublic = [
  [STATUS, MDAO_STAKING_TICKETS],
  [KYC_STATUS],

  [PAYABLE_TICKETS, MDAO_HELD_TICKETS],
  [PURCHASED_PLN],
];
export const myParamsSchemaLottery = [
  [STATUS, MDAO_STAKING_TICKETS],
  [KYC_STATUS],

  [PAYABLE_TICKETS, HELD_MDAO],
  [PURCHASED_PLN],
];

export const allocationPublicParams = [
  [PARTICIPANTS, MDAO_STAKING_TICKETS],
  [PAYABLE_TICKETS_ALLOCATION, MDAO_HELD_TICKETS],
];
