import { useDrag } from "react-use-gesture";
import { useMemo } from "react";

export const useSwipe = (
  actions = {
    onUp: () => {},
    onDown: () => {},
    onLeft: () => {},
    onRight: () => {},
  },
  threshold = 0.3
) => {
  const bind = useDrag(({ last, vxvy: [vx, vy] }) => {
    if (Math.abs(vx) > Math.abs(vy)) {
      if (vx < -threshold && last && actions?.onLeft) {
        actions.onLeft();
      } else if (vx > threshold && last && actions?.onRight) {
        actions.onRight();
      }
    } else {
      if (vy < -threshold && last && actions?.onUp) {
        actions.onUp();
      } else if (vy > threshold && last && actions?.onDown) {
        actions.onDown();
      }
    }
  });

  return { bind };
};
