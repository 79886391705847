const UPDATE_CARDS_LIST = "updateCardList";
const FROM_ROUTE = "fromRoute";

export const setUpdateCardsList = () =>
  localStorage.setItem(UPDATE_CARDS_LIST, "true");
export const getUpdateCardsList = () => localStorage.getItem(UPDATE_CARDS_LIST);
export const removeUpdateCardsList = () =>
  localStorage.removeItem(UPDATE_CARDS_LIST);

export const setFromRouteUrl = (url) => localStorage.setItem(FROM_ROUTE, url);
export const getFromRouteUrl = () => localStorage.getItem(FROM_ROUTE);
export const clearFromRouteUrl = () => localStorage.removeItem(FROM_ROUTE);
