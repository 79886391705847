import React, { useState } from "react";
import { PageHeader } from "../../components";
import { Row, Typography } from "../../UI";
import styled from "styled-components";

const ContentBlock = styled.div`
  display: ${({ open }) => (open ? "display" : "none")};
  margin-top: 15px;
`;

const ColorItem = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ color }) => color};
`;

const Wrapper = styled.div`
  overflow: hidden;
`;
const botTheme = window.Telegram?.WebApp?.themeParams;

export const ComingSoonPage = (title) => {
  return () => {
    // eslint-disable-next-line
    const [open, setOpen] = useState(false);

    const formatColors = Object.entries(botTheme || {}).map(
      ([name, color]) => ({
        name,
        color,
      })
    );
    return (
      <Wrapper>
        <PageHeader title={title} />
        <Typography
          onClick={() => setOpen(!open)}
          ta="center"
          variant="h2"
          m="10px 0 0 0"
          width="100%"
        >
          Coming soon
        </Typography>
        <ContentBlock open={open}>
          {formatColors.length
            ? formatColors.map((el) => {
                return (
                  <Row m="0 0 10px 0">
                    <Typography>{el.name}:</Typography>
                    <Typography m="0 10px 0 10px">{el.color}</Typography>
                    <ColorItem color={el.color} />
                  </Row>
                );
              })
            : null}
        </ContentBlock>
      </Wrapper>
    );
  };
};
