import React, { useEffect, useState } from "react";
import { Box, Typography } from "../../UI";
import styled from "styled-components";

const Label = styled.label`
  display: flex;
  align-items: center;
`;

const InputHide = styled.input`
  //display: none;
  width: 0;
  height: 0;
  opacity: 0;
  z-index: -1;
`;

const CheckboxStyled = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.button};
  background-color: ${({ checked, theme }) =>
    checked ? theme.colors.button : "transparent"};
  transition: 0.1s;
  position: relative;
`;

const Arrow = styled.div`
  width: 8px;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.background};
  border-radius: 5px;
  position: relative;

  &:after {
    content: "";
    background-color: ${({ theme }) => theme.colors.background};
    position: absolute;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    width: 2px;
    height: 5px;
  }
`;

const ArrowBlock = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-30%, -50%);

  & > div {
    transform: rotate(-45deg);
  }
`;

export const Checkbox = ({ title, value, onChange }) => {
  const handleChecked = () => {
    if (onChange) {
      onChange((prev) => !prev);
    }
  };

  return (
    <Box>
      <Label>
        <InputHide type="checkbox" onChange={handleChecked} />
        <CheckboxStyled checked={value}>
          <ArrowBlock>
            <Arrow />
          </ArrowBlock>
        </CheckboxStyled>
        <Typography m="0 0 0 8px" variant="link">
          {title}
        </Typography>
      </Label>
    </Box>
  );
};
