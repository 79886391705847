import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ButtonHorizontal,
  Switcher,
  DepositItems,
  Input,
  KYCBlock,
  PageHeader,
  PageLoader,
  LineInfo,
} from "../../components";
import {
  Box,
  ERROR_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import { useSnackbar, useTranslate, useWallet } from "../../contexts";
import {
  FROM_TOP_UP_CARD,
  KYC_STATUSES,
  MIN_POWDER,
  SUCCESS,
  TOP_UP_FROM_PAY_FOR_SERVICES,
  VARIANT_PAY_BEP20,
  VARIANT_PAY_RUB,
  VARIANT_PAY_TRC20,
} from "../../config";
import { Wrapper } from "./styled";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import { useParams } from "react-router-dom";
import {
  cardInfoRoute,
  CARDS_ROUTE,
  chooseCardFromServicesRoute,
  ISSUE_CARD_CHOOSE_ROUTE,
  PAY_FOR_SERVICES_ROUTE,
  payForServicesRoute,
} from "../../routes/routes";
import { SuccessPage } from "./ProcessingPages/SuccessPage";
import { ProcessingPage } from "./ProcessingPages/ProcessingPage";
import { ErrorPage } from "./ProcessingPages/ErrorPage";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../components/Spinner/Spinner";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { updateStatus } from "../../api";
import {
  convertNumberToSafe,
  lastFourCardNumbers,
  numberFormat,
} from "../../utils/formatting";
import Skeleton from "react-loading-skeleton";
import { useGetBalance } from "../../hooks/useGetBalance";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { getCardBins, getCardForProduct } from "../../utils/dataFiltering";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import styled from "styled-components";

const variantsPay = [
  VARIANT_PAY_TRC20,
  VARIANT_PAY_BEP20,
  // VARIANT_PAY_RUB
];

const ContentWrapper = styled(Box)`
  overflow: scroll;
`;

export const TopUpPage = () => {
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;
  const default_chain = variantsPay[0];

  const { showErrorCode, showError } = useSnackbar();
  const { card_id, product_id } = useParams();
  const { cards, products, requirements, templates } = useSelector(
    ({ cards }) => cards
  );
  const { user, chains, currentChain } = useSelector(({ wallet }) => wallet);
  const tokens = user?.tokens;
  const [finalValue, setFinalValue] = useState(0);
  const [finalValueLoading, setFinalValueLoading] = useState(false);
  const [focusForm, setFocusForm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [rate, setRate] = useState(0);
  const [operationId, setOperationId] = useState(null);
  const [variantPay, setVariantPay] = useState(default_chain);
  const [paymentLink, setPaymentLink] = useState(null);
  const [showProcessing, setShowProcessing] = useState(false);
  const [amount, setAmount] = useState("");
  const [coinBalance, setCoinBalance] = useState(0);

  useEffect(() => {
    if (variantPay?.chain) {
      // walletDispatch.getInfo({ chain: variantPay?.chain });
    }
  }, [walletDispatch, variantPay]);

  const { tokensPriceLoading, balanceCoin } = useWallet();
  const { t } = useTranslate();

  const depositValues = [25, 50, 75, 100, 200, 300, 400, 500];
  const activeItem = useMemo(() => {
    return (
      depositValues.find((v) => v?.toString() === amount.toString()) && amount
    );
  }, [amount]);
  const currentCard = cards?.find(
    (card) => String(card.cardId) === String(card_id)
  );
  const fromPayServices = !!product_id;
  const isVariantRub = variantPay.id === VARIANT_PAY_RUB.id;

  const { isKyc } = useSelector(({ wallet }) => wallet);
  const isKycSuccess = isKyc === KYC_STATUSES.SUCCESS;
  const isKycPending = isKyc === KYC_STATUSES.PENDING;
  const isKycNotPassed =
    isKyc === KYC_STATUSES.FAILED || isKyc === KYC_STATUSES.NONE;
  const TFA = user?.tfaStatus;

  const isRub = variantPay?.id === VARIANT_PAY_RUB.id;
  const {
    minTopupDeposit,
    maxTopupDeposit,
    minTopupDepositRub,
    maxTopupDepositRub,
    topupCardFee,
  } = requirements || {};

  const minTopupDepositValue = isRub ? minTopupDepositRub : minTopupDeposit;
  const maxTopupDepositValue = isRub ? maxTopupDepositRub : maxTopupDeposit;

  const currentProduct = products?.find(
    (product) => String(product.productId) === product_id
  );

  const cardBins = getCardBins(cards);
  const cardsForProduct = getCardForProduct(cardBins, currentProduct);

  const handleChangeVariantPay = async (value) => {
    walletDispatch.setTokens([]);
    setCoinBalance(0);
    setVariantPay(value);

    const chain = chains.find((chain) => chain.chain === value.chain);

    if (chain) {
      prevChain.current = chain.chain;
      walletDispatch.setCurrentChain({
        ...chain,
        id: chain.chain,
        title: chain.title,
      });
    }
  };

  const tokenId = useMemo(
    () => requirements?.[variantPay?.idTokenKey],
    [requirements, variantPay]
  );

  const tokenIds = useMemo(() => {
    return [requirements?.[variantPay?.idTokenKey]];
  }, [requirements, variantPay]);

  const { balance, balanceLoading } = useGetBalance({
    chains,
    userData: user,
    chain: variantPay?.chain,
  });

  useEffect(() => {
    setCoinBalance(balance);
  }, [balance]);

  const { tokenBalancesLoading, tokenBalance } = useGetTokenBalances({
    chains,
    chain: variantPay?.chain,
    balanceCurrentTokenId: tokenId,
    tokens,
    userData: user,
    tokenIds,
    noCache: true,
  });

  const handleSetAmount = useCallback((value) => {
    setAmount(value);
  }, []);

  const validateAmount = (value) => {
    if (
      value &&
      (value < minTopupDepositValue || value > maxTopupDepositValue) &&
      !focusForm
    ) {
      showError(
        t("checkAmountCorrect", {
          min: minTopupDepositValue,
          max: maxTopupDepositValue,
        })
      );
      setFinalValue(0);
      return false;
    }

    return true;
  };
  const prevChain = useRef(null);

  useEffect(() => {
    if (!chains?.length) return;
    const chain = chains.find((chain) => chain.chain === default_chain?.chain);

    if (chain) {
      prevChain.current = chain.chain;
      walletDispatch.setCurrentChain({
        ...chain,
        id: chain.chain,
        title: chain.title,
      });
    }
  }, [chains]);

  useEffect(() => {
    const valueIsCorrect = validateAmount(amount);

    if (amount && Number(amount) && !focusForm) {
      setFinalValue(amount);
    }

    if (
      amount &&
      variantPay?.paymentType &&
      card_id &&
      !focusForm &&
      valueIsCorrect
    ) {
      setFinalValueLoading(true);
      cardsDispatch
        .getTopupPrice({
          cardId: card_id,
          deposit: amount,
          paymentType: variantPay.paymentType,
        })
        .then((res) => {
          setFinalValueLoading(false);
          setFinalValue(res.data.result.sum);

          setRate(res.data.result.rate);
        })
        .catch((e) => showError(e));
    }
  }, [cardsDispatch, amount, focusForm, variantPay]);

  const handlePay = (code) => {
    setLoading(true);
    return cardsDispatch
      .topup({
        cardId: card_id,
        deposit: amount,
        paymentType: variantPay.paymentType,
        code,
      })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          if (res.data.result.paymentUrl) {
            setPaymentLink(res.data.result.paymentUrl);
            setRefreshStatus(true);
          } else {
            setShowProcessing(true);
          }

          setOperationId(res.data.result.operationId);
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateStatus = () => {
    return updateStatus(
      cardsDispatch.getCardOperationStatus,
      { operationId },
      { amount, balance: currentCard?.balance + amount },
      null,
      "data.result"
    );
  };

  const handlePayWithTFA = () => {
    setShow(true);

    return handlePay;
  };

  const title = fromPayServices
    ? t("payForSpecificService", { serviceName: currentProduct?.title })
    : t("topUpCard", { card: lastFourCardNumbers(currentCard?.maskNumber) });

  const successRoute = cardInfoRoute(card_id);

  const processingRoute = fromPayServices
    ? payForServicesRoute()
    : cardInfoRoute(card_id);

  const chooseRoute =
    cardsForProduct?.length > 1
      ? chooseCardFromServicesRoute(product_id)
      : payForServicesRoute();

  const route = fromPayServices ? chooseRoute : cardInfoRoute(card_id);

  const fromPage = fromPayServices
    ? TOP_UP_FROM_PAY_FOR_SERVICES
    : FROM_TOP_UP_CARD;

  const errorRoute = fromPayServices
    ? PAY_FOR_SERVICES_ROUTE
    : cardInfoRoute(card_id);

  const formatVariantsPay = variantsPay.map((el) => ({
    ...el,
    id: el.id,
    title: el.currencyName,
    subTitle: el.chainName,
  }));

  const submitMethod = TFA ? handlePayWithTFA : handlePay;

  const loadingPage =
    loading || tokensPriceLoading || balanceLoading || tokenBalancesLoading;

  const { color: rightColorCoinBalance } = useGetColorsError({
    balance,
    amount: MIN_POWDER,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });

  const { color: rightColorTokenBalance, hasError } = useGetColorsError({
    balance: Number(tokenBalance),
    amount: Number(finalValue),
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });

  const wrongLimited =
    amount < minTopupDepositValue || amount > maxTopupDepositValue;

  const buttonDisable =
    (!isRub && hasError) ||
    loadingPage ||
    !amount ||
    wrongLimited ||
    Number(amount) === 0 ||
    focusForm ||
    finalValueLoading;

  const contentRef = useRef(null);
  const navigationRef = useRef(null);

  const { contentHeight } = useGetMainContentHeight(contentRef, navigationRef);

  return (
    <PageLoader loading={loadingPage}>
      <TFACheckContainer
        show={show}
        loading={loadingPage}
        setShow={setShow}
        onSuccess={handlePay}
      >
        <IFrameLayout
          processingBefore={showProcessing}
          updatingWhenIFrameOpened={refreshStatus}
          view={!!paymentLink}
          link={paymentLink}
          CongratsComponent={SuccessPage}
          ProcessingComponent={ProcessingPage}
          ErrorComponent={ErrorPage}
          processingFetch={handleUpdateStatus}
          fromPage={fromPage}
          title={title}
          arrowRoute={CARDS_ROUTE}
          processingRoute={processingRoute}
          continueRoute={successRoute}
          errorRoute={errorRoute}
          cb={() => {
            setRefreshStatus(false);
            setPaymentLink(null);
          }}
        >
          <Wrapper>
            <Box>
              <PageHeader title={title} />
              <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
                {/*<PageHeader title={title} to={route} />*/}
                <Box m="10px 0 0 0">
                  <Switcher
                    disabled={loading}
                    variantsPay={formatVariantsPay}
                    activeVariant={variantPay}
                    onChange={handleChangeVariantPay}
                  />
                </Box>
                <Box m="16px 0 8px 0">
                  <Input
                    onlyWholeNumbers
                    onFocus={() => setFocusForm(true)}
                    onBlur={() => {
                      setTimeout(() => {
                        setFocusForm(false);
                      }, 10);
                    }}
                    type="number"
                    onChange={handleSetAmount}
                    value={amount}
                    placeholder={t("amount")}
                  />
                </Box>
                <DepositItems
                  currency="$"
                  minHeight="100px"
                  onChange={handleSetAmount}
                  items={depositValues}
                  activeItem={activeItem}
                />
                {isVariantRub && !isKycSuccess ? (
                  <Box m="16px 0 0 0">
                    <KYCBlock />
                  </Box>
                ) : (
                  <Box>
                    {!rate && !isRub ? (
                      tokensPriceLoading ? (
                        <Box m="0 0 12px 0">
                          <Skeleton
                            style={{ marginBottom: "4px", marginTop: "16px" }}
                            width="100%"
                          />
                          <Skeleton width="100%" />
                        </Box>
                      ) : (
                        <Box m="16px 0 6px 0">
                          <LineInfo
                            leftTitle={t("yourBalanceCurrency", {
                              chainName: variantPay.name,
                            })}
                            rightTitle={`${
                              convertNumberToSafe(tokenBalance) || 0
                            } ${variantPay.currencyName}`}
                            rightColor={rightColorTokenBalance}
                          />
                          <Box m="4px 0 0px 0">
                            <LineInfo
                              leftTitle={t("yourCurrencyBalance", {
                                currency: variantPay.coinName,
                              })}
                              rightTitle={`${
                                convertNumberToSafe(coinBalance) ||
                                balanceCoin?.convertedBalance ||
                                0
                              } ${variantPay.coinName}`}
                              rightColor={rightColorCoinBalance}
                            />
                          </Box>
                        </Box>
                      )
                    ) : null}
                    {isRub && (
                      <Row
                        width="100%"
                        justifyContent="space-between"
                        m="16px 0 0 0"
                      >
                        <LineInfo
                          leftTitle={t("usdExchangeRate")}
                          rightTitle={`~ ${Number(rate || 0).toFixed(2)} RUB`}
                        />
                      </Row>
                    )}

                    <Box m="16px 0 0 0">
                      <LineInfo
                        leftTitle={t("cardTopUpFee")}
                        rightTitle={`${Number(topupCardFee * 100).toFixed(2)}%`}
                      />
                    </Box>

                    <Row width="100%" justifyContent="space-between">
                      <Typography variant="bold" m="16px 0 16px 0">
                        {t("totalAmountDue")}
                      </Typography>
                      <Typography variant="bold" m="16px 0 16px 0">
                        {`${convertNumberToSafe(finalValue) || 0} ${
                          variantPay.currencyName
                        }`}
                      </Typography>
                    </Row>

                    <Typography variant="link" m="0 0 0 0">
                      {t("allFeesIncluded")}
                    </Typography>
                  </Box>
                )}
              </ContentWrapper>
            </Box>

            <Box ref={navigationRef} p="16px 0 0 0">
              {isVariantRub && (isKycNotPassed || isKycPending) ? null : (
                <ButtonHorizontal
                  bottomMarginForScreen
                  variant="small"
                  disable={buttonDisable}
                  onClick={submitMethod}
                  title={
                    finalValueLoading ? (
                      <Spinner />
                    ) : (
                      t("payAmount", {
                        amount: convertNumberToSafe(finalValue) || 0,
                        currencyName: variantPay.currencyName,
                      })
                    )
                  }
                />
              )}
            </Box>
          </Wrapper>
        </IFrameLayout>
      </TFACheckContainer>
    </PageLoader>
  );
};
