import styled from "styled-components";
import { Box } from "../../UI";
import { Input, Switcher } from "../../components";
import Skeleton from "react-loading-skeleton";

export const ContentWrapper = styled(Box)`
  overflow: scroll;
  margin-top: 10px;
`;

export const ProjectContentWrapper = styled(Box)`
  overflow: scroll;
  margin-top: 10px;
  //max-height: 74vh;
`;
export const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
  //position: relative;
  justify-content: space-between;
`;

export const WrapperContent = styled(Box)`
  height: 100%;
`;

export const MenuBlock = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px 10px;
`;

export const SwitcherStyled = styled(Switcher)`
  & > div {
    min-height: 48px;
  }
`;

export const ProjectSkeletonCard = styled(Skeleton)`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const InputStyled = styled(Input)`
  height: 44px;
  padding: ${({ value }) =>
    value ? `20px 12px 10px 12px` : `20px 12px 20px 12px`};

  input {
    padding: 0 0 0 2px;
  }
`;

export const ProjectIcon = styled.img`
  min-width: 30%;
  height: 60px;
  object-fit: contain;
  margin-right: 20px;
`;

export const StatusButton = styled(Box)`
  width: 100%;
  margin-top: 8px;
  margin-bottom: 12px;
  min-height: 60px;
  border-radius: 12px;
  padding: 0 16px 0 16px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, complete }) =>
    complete ? theme.colors.button : theme.colors.orange};
`;

export const AthButton = styled(StatusButton)`
  background-color: ${({ theme }) => theme.colors.button};
`;

export const ProjectsWrapper = styled(Box)`
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-appearance: none;
  position: relative;
  -webkit-transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1px;
  transform: translateZ(0);
  //transform: translate3d(0, 0, 0);
`;
