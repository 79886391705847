import React, { useEffect, useState } from "react";
import { PageLoader } from "../../components";
import { Box, Typography } from "../../UI";
import { useTranslate } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { FaqContainer, PageWithInsideContent } from "./FAQ-Container";

export const FaqCardsPage = () => {
  const dispatch = useDispatch();
  const docsDispatch = dispatch.docs;
  const { faq } = useSelector(({ docs }) => docs);
  const { t } = useTranslate();
  const [loading, setLoading] = useState(false);
  const [content, setContent] = useState("");
  const [contentTitle, setContentTitle] = useState("");

  useEffect(() => {
    (async () => {
      setLoading(true);
      await docsDispatch.getCardsFaq();
      setLoading(false);
    })();
  }, [docsDispatch]);

  return (
    <PageLoader loading={loading}>
      <PageWithInsideContent
        pageHeaderTitleKey={"faq"}
        setContent={setContent}
        contentTitle={contentTitle}
        setContentTitle={setContentTitle}
        Content={() => (
          <Box>
            <Typography m="10px 0 16px 0" variant="link">
              {t("frequentlyAskedCards")}
            </Typography>
            <FaqContainer
              items={faq}
              setContentTitle={setContentTitle}
              setContent={setContent}
            />
          </Box>
        )}
        insideContent={content}
      />
    </PageLoader>
  );
};
