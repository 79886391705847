import styled, { css } from "styled-components";

export const setStyle = (nameProps, defaultValue) => (props) =>
  props?.[nameProps] || defaultValue;

const Sizes = css`
  width: ${setStyle("width", "auto")};
  min-height: ${setStyle("minHeight", "auto")};
  min-width: ${setStyle("minWidth", "auto")};
  height: ${setStyle("height", "auto")};
`;

const Indents = css`
  margin: ${setStyle("m", "0")};
  padding: ${setStyle("p", "0")};
`;

export const CenterStyles = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Typography = styled.div`
  white-space: ${setStyle("ws", "pre-line")};
  margin: ${setStyle("m", "0")};
  text-align: ${setStyle("ta", "left")};
  font-family: Roboto, sans-serif;
  color: ${({ theme, color }) =>
    color ? theme?.colors?.[color] : theme.colors.text};
  ${({ theme, variant }) => theme.fonts[variant] || theme.fonts.h2};
  font-weight: ${({ variant, fw, theme }) =>
    fw ? fw : variant ? theme.fonts[variant]?.fontWeight : "500"};
  font-size: ${({ variant }) => (variant ? null : setStyle("fs", "16px"))};
  line-height: ${({ variant, lh }) =>
    lh ? lh : variant ? null : setStyle("lh", "normal")};
  ${Sizes};
`;

export const Container = styled.div`
  //max-width: 480px;
  //min-height: 100vh;

  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  padding: 16px 16px 34px 16px;
`;

export const Paper = styled.div`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.background2};
  border-radius: 12px;
  margin: ${setStyle("m", "0")};
  padding: ${setStyle("p", "12px 16px")};
`;

export const Box = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: ${setStyle("flexGrow", "0")};
  flex-flow: ${setStyle("flexFlow", "normal")};
  overflow: ${setStyle("overflow", "visible")};
  align-items: ${setStyle("alignItems", "normal")};
  justify-content: ${setStyle("justifyContent", "normal")};
  gap: ${setStyle("gap", "0")};
  ${Sizes}
  ${Indents}
`;

export const Row = styled.div`
  display: flex;
  align-items: ${setStyle("alignItems", "normal")};
  justify-content: ${setStyle("justifyContent", "normal")};
  flex-wrap: ${setStyle("fw", "auto")};
  gap: ${setStyle("gap", "0")};
  ${Sizes}
  ${Indents}
`;

export const Link = styled.a`
  font-family: Roboto, sans-serif;
  color: ${({ theme, color }) =>
    color ? theme?.colors?.[color] : theme.colors.text};
  text-align: ${setStyle("ta", "left")};
  ${({ theme, variant }) => theme.fonts[variant] || theme.fonts.h2};
  font-size: ${({ variant }) => (variant ? null : setStyle("fs", "16px"))};
  line-height: ${({ variant }) => (variant ? null : setStyle("lh", "normal"))};
  ${({ theme, variant }) => theme.fonts[variant] || theme.fonts.link};
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.link};
  ${Indents};
  ${Sizes};
`;
