import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, STATUS_COLORS } from "../../UI";
import { Operations, PageHeader, PageLoader } from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat, lastFourCardNumbers } from "../../utils/formatting";
import { ContentWrapper, Wrapper } from "./styled";
import { getStatusTrans, VARIANTS_PAY } from "../../config";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";

const STATUSES = {
  waiting: "pending",
};

export const TransactionsHistoryPage = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const { cards } = useSelector(({ cards }) => cards);
  const [operationsList, setOperationsList] = useState([]);
  const [inited, setInited] = useState(false);
  const [loadingOperations, setLoadingOperations] = useState(false);

  const { showError } = useSnackbar();

  const cardsHash = useMemo(
    () =>
      cards?.reduce((acc, card) => {
        acc[card.cardId] = card;
        return acc;
      }, {}),
    [cards]
  );

  const handleGetOperations = useCallback(
    (offset) => {
      try {
        setLoadingOperations(true);

        return cardsDispatch
          .getOperations({ offset })
          .then((res) => {
            setOperationsList((prev) => [...prev, ...res.data.result]);
            if (!inited) {
              setInited(true);
            }
          })
          .finally(() => setLoadingOperations(false));
      } catch (e) {
        showError(e);
        setLoadingOperations(false);
      }
    },
    [cardsDispatch]
  );

  const getTitlesTypeOperation = useCallback(
    (operation) => {
      return {
        createCard: t("createCardBIN", { bin: operation?.binId }),
        terminateCard: t("terminateCardNumber", {
          number:
            lastFourCardNumbers(cardsHash?.[operation?.cardId]?.maskNumber) ||
            t("none"),
        }),
        topup: t("topupCardNumber", {
          number:
            lastFourCardNumbers(cardsHash?.[operation?.cardId]?.maskNumber) ||
            t("none"),
        }),
        freezeCard: t("freezeCardWithNumber", {
          cardNumber:
            lastFourCardNumbers(cardsHash?.[operation?.cardId]?.maskNumber) ||
            t("none"),
        }),
        unfreezeCard: t("unfreezeCardWithNumber", {
          cardNumber:
            lastFourCardNumbers(cardsHash?.[operation?.cardId]?.maskNumber) ||
            t("none"),
        }),
        closeCard: t("terminateCardNumber", {
          number:
            lastFourCardNumbers(cardsHash?.[operation?.cardId]?.maskNumber) ||
            t("none"),
        }),
      };
    },
    [t, cardsHash]
  );

  const operationsListFormatted = operationsList?.map((operation) => {
    const deposit = operation?.deposit?.toFixed(2);
    const currency = VARIANTS_PAY.find(
      (variant) => variant.paymentType === operation.currency
    );
    return {
      colors: {
        subValueColor: STATUS_COLORS[operation.status],
      },
      value: deposit ? `${deposit} USD` : "--",
      subValue: getStatusTrans(operation?.status, t) || operation?.status,
      title:
        getTitlesTypeOperation(operation)?.[operation?.type] || "none title",
      subTitle: dateFormat(operation.timestamp, "yyyy-MM-dd HH:mm"),
    };
  });

  const operationsRef = useRef(null);
  const navigationRef = useRef();
  const contentRef = useRef();
  const [operationsHeight, setOperationsHeight] = useState(0);

  const optionsToContentHeight = useMemo(() => {
    return {
      minusIndent: 0,
    };
  }, [inited]);

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    optionsToContentHeight
  );

  useEffect(() => {
    if (contentHeight && operationsRef.current) {
      const operationSizes = operationsRef.current.getBoundingClientRect();

      if (!operationsHeight) {
        setOperationsHeight(contentHeight - operationSizes.top + 54);
      }
    }
  }, [contentHeight]);

  const height =
    loadingOperations && !operationsListFormatted?.length
      ? "80vh"
      : `${contentHeight}px`;

  return (
    <Wrapper>
      <PageHeader title={t("transactionHistory")} />
      <ContentWrapper m="10px 0 0 0" ref={contentRef} height={"100vh"}>
        <PageLoader loading={loadingOperations} overlayOff>
          <Operations
            withRef={operationsRef}
            loading={loadingOperations}
            height={height}
            schemeStatuses={getTitlesTypeOperation}
            operationsList={operationsListFormatted}
            fetch={handleGetOperations}
            cardsHash={cardsHash}
          />
        </PageLoader>
      </ContentWrapper>
      <Box ref={navigationRef}></Box>
    </Wrapper>
  );
};
