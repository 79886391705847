import styled from "styled-components";
import { Accordion } from "../Accordion/Accordion";
import { CardToken } from "../Cards/Card-Token";
import { Typography } from "../../UI";

export const DropdownTokenWrapper = styled.div`
  border-radius: 12px;
  height: fit-content;
  min-height: 60px;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
`;
export const AccordionStyled = styled(Accordion)`
  & > div {
    padding: 12px 12px 12px 16px;
  }
`;

export const ActiveCardTokenStyled = styled(CardToken)`
  padding: 0 20px 0 0;
`;

export const CardTokenStyled = styled(CardToken)`
  padding: 0 20px 0 0;
  margin-bottom: 15px;
`;

export const DropdownChainWrapper = styled.div`
  height: fit-content;
  min-height: 40px;
`;

export const ItemChain = styled(Typography)`
  cursor: pointer;
  &:last-child {
    margin: 0;
  }
`;
