export const BNB = {
  chain: "BSC",
  decimals: 18,
  image: "https://cryptologos.cc/logos/bnb-bnb-logo.png",
  name: "BNB",
  symbol: "BNB",
  gasFixed: 4,
};

export const POL = {
  chain: "POL",
  decimals: 18,
  image: "https://altcoinsbox.com/wp-content/uploads/2023/03/matic-logo.png",
  name: "POL",
  symbol: "POL",
};

export const ETH = {
  chain: "ETH",
  decimals: 18,
  image:
    "https://icons.iconarchive.com/icons/cjdowner/cryptocurrency-flat/512/Ethereum-ETH-icon.png",
  name: "ETH",
  symbol: "ETH",
};

export const TRX = {
  chain: "ETH",
  decimals: 6,
  image:
    "https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/tron-trx-icon.png",
  name: "TRX",
  symbol: "TRX",
};

export const BTC = {
  chain: "BTC",
  decimals: 18,
  image: "https://cdn-icons-png.flaticon.com/512/5968/5968260.png",
  name: "BTC",
  symbol: "BTC",
};

export const defaultTokensConfig = {
  ETH: {
    gasFixed: 15,
  },
  BSC: {
    gasFixed: 4,
  },
};

export const mainCoins = {
  BSC: BNB,
  ETH: ETH,
  TRX: TRX,
  BTC: BTC,
  POL: POL,
};
