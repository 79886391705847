import styled from "styled-components";
import { Box, Typography } from "../../UI";
import { Switcher } from "../../components";

export const CardsWrapper = styled.div`
  position: relative;
  height: calc(100vh - 50px);
  //max-width: 480px;
`;

export const DetailsWrapper = styled(Box)`
  overflow: hidden;
`;

export const DetailsContentScroll = styled(Box)`
  overflow: scroll;
`;

export const ContentWrapper = styled(Box)`
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const MenuBlock = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 22px);
`;

export const MenuBlockRow = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-top: 8px;
  grid-gap: 10px 10px;
`;

export const SwitcherStyled = styled(Switcher)`
  & > div {
    padding: 12px 0;
    min-height: 43px;
  }
`;

export const ChooseByServicesWrapper = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const TypographyListStyled = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 4px;

  &:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    margin-right: 4px;
  }
`;
