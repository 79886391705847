import React from "react";
import { Box, Paper, Typography } from "../../UI";
import styled from "styled-components";
import { LinkComponent } from "../Link/Link";
import { NavLinkComponents } from "../../pages/Menu/styled";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const PaperComponent = styled(Paper)`
  width: 100%;
  height: 100%;
  //margin-right: 10px;
  //margin-top: 10px;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  opacity: ${({ blocked }) => (blocked ? "0.3" : "1")};

  background-color: ${({ theme, actionButton }) =>
    actionButton ? theme.colors.button : theme.colors.background2};

  &:nth-child(even) {
    margin-right: 0;
  }
`;

const TypographyStyled = styled(Typography)`
  color: ${({ theme, activeButton }) =>
    activeButton ? theme.colors.white : theme.colors.buttonText};
`;

const bot = window.Telegram?.WebApp;

const StyledBox = styled(Box)`
  cursor: pointer;
`;

export const CardMenu = ({
  icon,
  name,
  actionButton,
  link,
  path,
  blocked,
  inBotLink,
  screenName,
  isTelegramLink,
  callback,
}) => {
  const offScreens = process.env.REACT_APP_OFF_SCREENS?.split(",");
  const offScreen = offScreens?.includes(screenName) || blocked;
  const linkString = link && link();

  const Element = offScreen
    ? StyledBox
    : link
    ? LinkComponent
    : NavLinkComponents;

  return (
    <Element
      to={link ? linkString : path}
      key={path}
      inBotLink={inBotLink}
      callback={callback}
      isTelegramLink={isTelegramLink}
    >
      <PaperComponent actionButton={actionButton} blocked={offScreen}>
        <Typography>{icon}</Typography>
        <TypographyStyled
          actionButton={!!actionButton}
          m="8px 0 0 0"
          variant="h2"
        >
          {name}
        </TypographyStyled>
      </PaperComponent>
    </Element>
  );
};
