import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSnackbar, useTranslate } from "../../contexts";
import { useDispatch, useSelector } from "react-redux";
import { dateFormat, lastFourCardNumbers } from "../../utils/formatting";
import { getStatusTrans, VARIANTS_PAY } from "../../config";
import { Box, STATUS_COLORS } from "../../UI";
import { Operations, PageLoader } from "../../components";
import styled from "styled-components";

export const Wrapper = styled.div`
  height: calc(100vh - 50px);
  overflow-y: hidden;
  display: flex;
  flex-direction: column;
`;

export const ContentWrapper = styled(Box)``;

export const OperationsSection = React.memo(
  ({
    withRef,
    loadingOperations,
    trigerFetch,
    height,
    infinityOff,
    handleGetOperations,
    minHeight,
    operationsList,
  }) => {
    const { t } = useTranslate();
    const { cards } = useSelector(({ cards }) => cards);

    const cardsHash = useMemo(
      () =>
        cards?.reduce((acc, card) => {
          acc[card.cardId] = card;
          return acc;
        }, {}),
      [cards]
    );

    const getTitlesTypeOperation = useMemo(() => {
      return {
        createCard: t("createCardBIN"),
        terminateCard: t("terminateCardNumber"),
        topup: t("topupCardNumber"),
        freezeCard: t("freezeCardWithNumber"),
        unfreezeCard: t("unfreezeCardWithNumber"),
        closeCard: t("terminateCardNumber"),
      };
    }, [t, cardsHash]);

    const operationsListFormatted = operationsList?.map((operation) => {
      const deposit = (operation?.deposit || 0)?.toFixed(2);

      return {
        colors: {
          subValueColor: STATUS_COLORS[operation.status],
        },
        value: deposit > 0 ? `$${deposit}` : "--",
        subValue: getStatusTrans(operation?.status, t) || operation?.status,
        title: getTitlesTypeOperation?.[operation?.type] || "none title",
        subTitle: dateFormat(operation.timestamp, "yyyy-MM-dd HH:mm"),
      };
    });

    const [operationLoading, setOperationLoading] = useState(false);

    return (
      <Operations
        withLoader
        overlayOff
        loading={operationLoading}
        setLoading={setOperationLoading}
        trigerFetch={trigerFetch}
        infinityOff={infinityOff}
        withRef={withRef}
        height={height}
        minHeight={minHeight}
        schemeStatuses={getTitlesTypeOperation}
        operationsList={operationsListFormatted}
        fetch={handleGetOperations}
        cardsHash={cardsHash}
      />
    );
  }
);
