import { useEffect, useRef, useState } from "react";

export const useGetMainContentHeight = (
  topRef,
  bottomRef,
  options,
  update = []
) => {
  const [contentHeight, setContentHeight] = useState(0);

  const prevHeight = useRef(0);
  useEffect(() => {
    if (topRef.current && bottomRef.current) {
      const topRect = topRef.current.getBoundingClientRect();
      const bottomRect = bottomRef.current.getBoundingClientRect();
      const indent = options?.minusIndent || 0;
      const height = bottomRect.top - topRect.top - indent;

      const bottomLowerScreen =
        bottomRect.bottom > document.body.clientHeight // why?
          ? bottomRect.bottom - document.body.clientHeight // why?
          : 0;

      const bottomLowerScreenOptimized = bottomLowerScreen > height ? 0 : 0; // why?

      if (prevHeight.current !== height) {
        prevHeight.current = height;

        setTimeout(() => {
          setContentHeight(height - bottomLowerScreenOptimized);
        }, 0);
      }
    }
  }, [
    topRef,
    bottomRef,
    options,
    ...update,
    document?.activeElement,
    document.body.clientHeight,
  ]);

  return { contentHeight };
};
