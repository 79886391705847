import { ReactComponent as NextArrowIcon } from "../assets/svg/next-arrow.svg";
import styled from "styled-components";

export const NextArrowIconStyled = styled(NextArrowIcon)`
  margin-left: 10px;
`;

export const BackArrowIconStyled = styled(NextArrowIcon)`
  margin-right: 10px;
  fill: white;
  transform: rotate(180deg);
`;
