import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Link, STATUS_COLORS, Typography } from "../../UI";
import {
  Button,
  KYCBlock,
  LinkComponent,
  Operations,
  PageHeader,
  PageLoader,
  PassKycButton,
} from "../../components";
import { useTranslate } from "../../contexts";
import { BUY_USDT_BUY_ROUTE, MAIN_ROUTE } from "../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  FAILED,
  getStatusTrans,
  NONE,
  PROCESSING,
  SUCCESS,
  VARIANT_PAY_RUB,
} from "../../config";
import { useSnackbar } from "notistack";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { ContentWrapper, MenuBlockStyled, Wrapper } from "./styled";
import { useGetRouter } from "../../hooks";
import { dateFormat } from "../../utils/formatting";
import styled from "styled-components";
import useCheckKeyStatus from "../../hooks/useCheckKeyStatus";

const ButtonWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 16px 36px 16px;
  //max-width: 480px;
`;

export const BuyUSDTKYCPage = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { handleRoute } = useGetRouter();
  const cardsDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;

  const { isKyc } = useSelector(({ wallet }) => wallet);
  const isKycSuccess = isKyc === SUCCESS;
  const kycNotPassed = isKyc === NONE || isKyc === false || isKyc === FAILED;
  const kycWaiting = isKyc === PROCESSING;
  const [operations, setOperations] = useState([]);
  const [loadingOperations, setLoadingOperations] = useState(false);
  const [operationsHeight, setOperationsHeight] = useState(0);
  const operationsRef = useRef(null);
  const { showError } = useSnackbar();
  const navigationRef = useRef();
  const contentRef = useRef();

  useEffect(() => {
    walletDispatch.getKycStatus();
  }, []);

  useCheckKeyStatus();

  const optionsToContentHeight = useMemo(() => {
    return {
      minusIndent: 0,
    };
  }, [operations, isKyc]);

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    optionsToContentHeight
  );

  const handleGetOperations = useCallback(
    (offset) => {
      setLoadingOperations(true);
      return cardsDispatch
        .getBuyUsdtOperations({ offset })
        .then((res) => {
          setOperations((prev) => [...prev, ...res.data.result]);
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => setLoadingOperations(false));
    },
    [cardsDispatch]
  );

  const getTitlesTypeOperation = useCallback(
    (operation) => ({
      buyUSDT: t("buyUsdtAmount", { amount: operation?.usdtAmount }),
    }),
    [t]
  );

  const operationsListFormatted = useMemo(
    () =>
      operations.map((operation) => {
        const deposit = operation?.rubAmount?.toFixed(2);

        return {
          ...operation,
          colors: {
            subValueColor: STATUS_COLORS[operation.status],
          },
          title: getTitlesTypeOperation(operation)?.buyUSDT,
          subTitle: dateFormat(operation.timestamp, "yyyy-MM-dd HH:mm"),
          value: deposit ? `${deposit} ${VARIANT_PAY_RUB.name}` : "--",
          subValue: getStatusTrans([operation?.status], t) || operation?.status,
        };
      }),
    [operations]
  );

  useEffect(() => {
    if (contentHeight && operationsRef.current) {
      const operationSizes = operationsRef.current.getBoundingClientRect();

      if (!operationsHeight) {
        setOperationsHeight(contentHeight - operationSizes.top + 54);
      }
    }
  }, [contentHeight, operationsListFormatted]);

  return (
    <Wrapper>
      <Box>
        <PageHeader title={t("buyUsdt")} to={MAIN_ROUTE} />
        <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
          <Box>
            <Typography variant="link" m="10px 0 8px 0">
              {t("affiliateServiceToBuy")}
            </Typography>
            <LinkComponent
              to={t("guides.linkBuyUsdtGuide")}
              title={t("kycComplianceGuideUsdtPurchase")}
            />
            <Box m="16px 0 0 0">
              <KYCBlock onlyTitle />
            </Box>
          </Box>
          {isKycSuccess && (
            <Box m="16px 0 0 0">
              <Typography m="0 0 8px 0" variant="h2">
                {t("operationsHistory").toUpperCase()}
              </Typography>
              <PageLoader loading={loadingOperations} overlayOff>
                <Operations
                  withRef={operationsRef}
                  height={`${operationsHeight}px`}
                  operationsList={operationsListFormatted}
                  fetch={handleGetOperations}
                  schemeStatuses={getTitlesTypeOperation}
                />
              </PageLoader>
            </Box>
          )}
        </ContentWrapper>
      </Box>
      {kycNotPassed ? (
        <Box m="8px 0 0 0" ref={navigationRef}>
          {" "}
          <PassKycButton title={t("passKycButtonToBuyUsdt")} />
        </Box>
      ) : kycWaiting ? (
        <Box ref={navigationRef}></Box>
      ) : (
        <ButtonWrapper ref={navigationRef} display="flex">
          <Button
            activeButton
            emoji="📥"
            title={t("buyUsdtButton")}
            onClick={() => handleRoute(BUY_USDT_BUY_ROUTE)}
          />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};
