import "normalize.css";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import {
  blockedRoute,
  MAIN_ROUTE,
  MAINTENANCE_ROUTE,
  routes,
  SESSION_EXPIRED_ROUTE,
} from "./routes/routes";
import "./contexts/i18n/i18n";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Buffer } from "buffer";
import { useScreen } from "./hooks";
import { MAINTENANCE_ERROR } from "./config";
import { useGetRouter } from "./hooks";
import "clipboard-polyfill/overwrite-globals";
import { axiosInstance } from "./rest/axios";
import { useTheme } from "styled-components";
import { useSnackbar } from "./contexts";

const bot = window.Telegram?.WebApp;

window.Buffer = Buffer;

function App() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleRoute } = useGetRouter();
  const walletDispatch = dispatch.wallet;

  const { botToken, session_expired, user, isAuth } = useSelector(
    ({ wallet }) => wallet
  );

  useEffect(() => {
    if (session_expired) {
      handleRoute(SESSION_EXPIRED_ROUTE);
    }
  }, [session_expired]);

  const { colors } = useTheme();

  const { windowSize } = useScreen();

  useEffect(() => {
    if (
      !bot.initData &&
      process.env.REACT_APP_NODE_ENV === "production" &&
      process.env.REACT_APP_BOT_URL
    ) {
      window.location?.replace(process.env.REACT_APP_BOT_URL);
    }
  }, [bot]);

  useEffect(() => {
    axiosInstance.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response?.data?.code === MAINTENANCE_ERROR.code) {
          handleRoute(MAINTENANCE_ROUTE);
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    walletDispatch
      .getProfile({ cache: false })
      .then((res) => {
        if (res?.data?.status === "success" && localStorage.getItem("SE")) {
          localStorage.removeItem("SE");
          walletDispatch.setSessionExpired(false);
        }
      })
      .catch((e) => {
        const data = e.response?.data;
        if (data?.code === 74106) {
          walletDispatch.setSessionExpired(true);
        }

        if (data?.code === MAINTENANCE_ERROR.code) {
          handleRoute(MAINTENANCE_ROUTE);
        }

        if (data?.code === 74107) {
          history.replace(blockedRoute());
        }

        return e;
      });
  }, [walletDispatch]);

  useEffect(() => {
    if (!isAuth) return;

    if (!bot?.isExpanded) {
      bot?.expand();
    }

    bot?.disableVerticalSwipes?.();

    if (bot) {
      setTimeout(() => {
        document.body.style.backgroundColor = colors.background;
        document.body.style.height = `${windowSize?.height}px`;
      }, 100);
    }

    if (bot.initData && bot.initData !== botToken) {
      walletDispatch.setToken(bot.initData);
      walletDispatch.setUser({ ...bot.initDataUnsafe?.user });
    }
  }, [bot, walletDispatch, user?.wallet, isAuth, colors, windowSize]);

  const { showInfo } = useSnackbar();

  return (
    <Switch>
      {routes.map((route, index) => {
        return (
          <Route
            key={index}
            path={`/${route.path}`}
            exact
            component={route.component}
          />
        );
      })}
      <Route path="*" render={() => <Redirect to={MAIN_ROUTE} />} />
    </Switch>
  );
}

export default App;
