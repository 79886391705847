import React, { useEffect, useMemo, useRef, useState } from "react";
import { Input, PageHeader, PageLoader } from "../../components";
import { Box } from "../../UI";
import { useAsyncWeb3Methods, useGetTokenInfo } from "../../contexts/web3";
import { Button } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { shortWallet } from "../../utils/formatting";
import { useGetRouter, useScreen } from "../../hooks";
import useSWR from "swr";
import { swrKeys } from "../../rest/swr";
import { useSnackbar, useTranslate, useWallet } from "../../contexts";
import { InputWrapper } from "./styled";
import styled from "styled-components";
import BigNumber from "bignumber.js";
import { useParams } from "react-router-dom";
import { CHAINS } from "../../config";
import { WALLET_ROUTE } from "../../routes/routes";

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
`;

export const AddTokenPage = () => {
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { windowSize } = useScreen();
  const { handleRoute } = useGetRouter();
  const { chain } = useParams();
  const { t } = useTranslate();
  const { currentChain, chains, user } = useSelector(({ wallet }) => wallet);
  const tokens = user?.tokens;
  const [tokenData, setTokenData] = useState({});
  const [loading, setLoading] = useState(false);
  const { showSuccess, showError } = useSnackbar();
  const [contractAddress, setContractAddress] = useState("");
  const { checkValidAddress } = useAsyncWeb3Methods(currentChain?.chain);
  const { handleSetTokenInCacheNeedUpdate } = useWallet();

  const validContractAddress = useMemo(
    () => checkValidAddress && checkValidAddress(contractAddress),
    [contractAddress]
  );

  const [tokenLoaded, setTokenLoaded] = useState(false);

  const prevChain = useRef(null);
  useEffect(() => {
    if (
      chain &&
      chains?.length &&
      currentChain?.chain &&
      prevChain.current !== currentChain.chain
    ) {
      const chainElement = chains.find((chainEl) => chainEl.chain === chain);
      if (chainElement) {
        walletDispatch.setCurrentChain({
          ...chainElement,
          id: chainElement.chain,
          title: chainElement.title,
        });
        prevChain.current = chainElement.chain;
      }
    }
  }, [chain, chains, walletDispatch, currentChain]);

  const fetch = useGetTokenInfo(currentChain?.chain, contractAddress, {
    onComplete: (data) => {
      if (data.name && data.symbol && data.decimals !== undefined) {
        setTokenLoaded(true);
      }
      setTokenData((prev) => ({
        ...prev,
        ...data,
        decimals: Number(new BigNumber(data?.decimals)),
      }));
    },
  });

  const { mutate } = useSWR(
    swrKeys.getTokenInfo,
    validContractAddress && fetch,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const handleChange = (name) => (value) => {
    setTokenData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (mutate) {
      mutate();
    }
  }, [validContractAddress]);

  const handleAddToken = async () => {
    const alreadyHasToken = tokens?.find(
      (token) => token.contract === contractAddress
    );

    if (!validContractAddress) {
      showError(t("addressInvalid"));
      return;
    }

    if (alreadyHasToken) {
      showError(t("tokenAlreadyExist"));
      return;
    }

    if (currentChain?.id) {
      setLoading(true);
      walletDispatch
        .addToken({
          chain: currentChain.id,
          contract: contractAddress,
        })
        .then(async (res) => {
          const { status, message, result } = res?.data || {};
          if (status === "error") {
            showError(message);
          }

          if (status === "success") {
            showSuccess(t("tokenAdded"));
            setTokenData({});
            setContractAddress("");
            walletDispatch.addTokenToUser(result);
            if (result.contract) {
              handleSetTokenInCacheNeedUpdate(
                result.contract,
                currentChain?.id
              );
            }
            handleRoute(WALLET_ROUTE);
          }
        })
        .catch((e) => {
          showError(e.message);
        })
        .finally(() => setLoading(false));
    }
  };

  const handleSetContractAddress = (value) => {
    if (tokenLoaded) {
      setTokenLoaded(false);
      setTokenData((prev) => ({ ...prev, symbol: "", decimals: "" }));
    }

    setContractAddress(value);
  };

  const disabledButton =
    (validContractAddress && tokenData.symbol && tokenData.decimals) || loading;
  const lengthFirstPartShortWallet =
    windowSize.width < 390 ? windowSize.width / 12 : null;

  return (
    <PageLoader loading={loading}>
      <Wrapper height="100%" justifyContent="space-between">
        <Box>
          <PageHeader title={t("addToken")} to={WALLET_ROUTE} />
          <InputWrapper>
            <Input
              placeholder={t("tokenContractAddress")}
              onChange={handleSetContractAddress}
              value={
                validContractAddress && lengthFirstPartShortWallet
                  ? shortWallet(contractAddress, lengthFirstPartShortWallet)
                  : contractAddress
              }
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              readOnly={tokenLoaded}
              placeholder={t("tokenSymbol")}
              onChange={handleChange("symbol")}
              value={tokenData.symbol || ""}
            />
          </InputWrapper>
          <InputWrapper>
            {" "}
            <Input
              readOnly={tokenLoaded}
              placeholder={t("tokenDecimals")}
              onChange={handleChange("decimals")}
              value={tokenData.decimals || ""}
            />
          </InputWrapper>
        </Box>
        <Button
          activeButton
          disable={!disabledButton}
          emoji="➕"
          title={t("addToken")}
          onClick={() => handleAddToken()}
          // onClick={() => showError("123")}
        />
      </Wrapper>
    </PageLoader>
  );
};
