export const Actions = {
  SET_BALANCE_COIN: "SET_BALANCE_COIN",
  SET_BALANCE_IN_USDT: "SET_BALANCE_IN_USDT",
};

export const setBalanceCoinAction = (payload) => ({
  type: Actions.SET_BALANCE_COIN,
  payload,
});

export const setBalanceInUsdtAction = (payload) => ({
  type: Actions.SET_BALANCE_IN_USDT,
  payload,
});
