import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useWallet } from "../contexts";

export const useGetBalance = ({ chains, chain, userData }) => {
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { fetchBalance } = useWallet();
  const [balanceLoading, setBalanceLoading] = useState(false);
  const [afterLoading, setAfterLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const [balanceReady, setBalanceReady] = useState(false);

  useEffect(() => {
    setBalanceLoading(true);

    if (chain && chains?.length && userData && userData?.chain === chain) {
      const foundChain = chains.find((elChain) => {
        return chain === elChain.chain;
      });

      walletDispatch.setCurrentChain({
        ...foundChain,
        id: foundChain?.chain,
        title: foundChain?.title,
      });

      fetchBalance(chain, userData)
        .then((res) => {
          setBalance(res.convertedBalance || 0);
          setBalanceReady(true);
        })
        .finally(() => {
          setBalanceLoading(false);
          setAfterLoading(true);
        });
    }
  }, [chain, chains, userData]);

  return { balanceLoading, balance, afterLoading, balanceReady };
};
