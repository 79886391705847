import { CHAINS } from "./constants";

export const VARIANT_PAY_RUB = {
  currencyName: "RUB",
  name: "RUB",
  paymentType: "rub",
  id: 3,
};

export const VARIANT_PAY_BEP20 = {
  currencyName: "USDT",
  chainName: "BEP-20",
  coinName: "BNB",
  name: "USDT BEP-20",
  paymentType: "usdt-bsc",
  idTokenKey: "bscUSDT",
  chain: CHAINS.BSC,
  id: 2,
};

export const VARIANT_PAY_TRC20 = {
  currencyName: "USDT",
  coinName: "TRX",
  chainName: "TRC-20",
  name: "USDT TRC-20",
  paymentType: "usdt-trx",
  idTokenKey: "trxUSDT",
  chain: CHAINS.TRX,
  id: 1,
};

export const VARIANTS_PAY = [
  VARIANT_PAY_RUB,
  VARIANT_PAY_TRC20,
  VARIANT_PAY_BEP20,
];
