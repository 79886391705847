import React from "react";
import { Box, Typography } from "../../UI";
import { PageHeader } from "../../components";
import { useTranslate } from "../../contexts";

export const NeedToKnowPage = () => {
  const { t } = useTranslate();
  return (
    <Box>
      <PageHeader title={t("needToKnow")} />
      <Typography m="10px 0 0 0" variant="link">
        ❗️{t("currencyConversionFrom")}
      </Typography>
      <Typography m="16px 0 16px 0" variant="link">
        ❗️{t("pleaseUseAVPN")}
      </Typography>
      <Typography variant="link">
        ❗️{t("cardsCanBeUsedExclusively")}
      </Typography>
    </Box>
  );
};
