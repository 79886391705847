import styled from "styled-components";
import { ReactComponent as BackArrow } from "../../assets/svg/back.svg";
import { Box } from "../../UI";

export const DropdownArrow = styled(BackArrow)`
  width: 20px;
`;

export const Wrapper = styled(Box)`
  position: relative;
  z-index: ${({ z }) => z || 1};
`;

export const AccordionHeader = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AccordionWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.background2};
  border-radius: 12px;
  box-shadow: ${({ theme }) => theme.shadows.shadow};
  padding: ${({ open }) =>
    open ? "12px 12px 12px 20px" : "12px 12px 12px 20px"};
`;

export const ArrowBlock = styled.div`
  width: 28px;
  height: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowAnimation = styled.div`
  display: flex;
  transform: ${({ open }) => (open ? "rotate(90deg)" : "rotate(-90deg)")};
  transition: 0.3s transform ease-in-out;
  -moz-transition: 0.3s transform ease-in-out; /* Firefox 4 */
  -webkit-transition: 0.3s transform ease-in-out; /* Safari and Chrome */
  -o-transition: 0.3s transform ease-in-out; /* Opera */
  -ms-transition: 0.3s transform ease-in-out; /* Explorer 10 */
`;

export const AccordionContent = styled.div`
  position: ${({ noAbsolute }) => (noAbsolute ? "relative" : "absolute")};
  background-color: ${({ theme }) => theme.colors.background2};
  height: ${({ height, open }) => (open ? `${height + 8}px` : "0px")};
  transition: 0.3s;
  top: 81%;
  left: 0;
  right: 0;
  overflow: hidden;
  box-sizing: content-box;
  border-radius: 0 0 12px 12px;
`;

export const AccordionContentWrapper = styled.div`
  padding: ${({ open }) =>
    open ? "16px 20px 12px 16px" : "12px 20px 12px 16px"};
  transition: 0.2s;
`;
