import styled, { keyframes } from "styled-components";
import { ReactComponent as InfoTipIcon } from "../../assets/svg/info-tip.svg";

const PulseAnimation = keyframes`
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	  z-index: 400;
  }

  70% {
    transform: scale(1);
    z-index: 400;

    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95);
    z-index: 400;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }`;

export const StageCircle = styled.div`
  width: 12px;
  height: 12px;
  background-color: ${({ theme, active, passed, finalStage }) =>
    passed
      ? theme.colors.button
      : active
      ? finalStage
        ? theme.colors.button
        : theme.colors.orange
      : theme.colors.background};
  border-radius: 50%;
  margin-right: 12px;
  //box-shadow: ${({ theme }) => theme.shadows.shadow2};
  box-shadow: 0px 0px 3px 4px rgba(0, 0, 0, 0.15);
  position: absolute;

  &:before {
    content: "";
    width: 3px;
    height: 18px;
    background-color: ${({ theme, activeLineTop, passed, finalStage }) =>
      passed
        ? theme.colors.button
        : activeLineTop
        ? finalStage
          ? theme.colors.button
          : theme.colors.orange
        : theme.colors.background};
    display: ${({ notNext }) => (notNext ? "none" : "block")};
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    top: 50%;
    z-index: -1;
  }
  &:after {
    content: "";
    width: 3px;
    height: 18px;
    display: ${({ notPrev }) => (notPrev ? "none" : "block")};
    background-color: ${({ theme, activeLineBottom, passed, finalStage }) =>
      passed
        ? theme.colors.button
        : activeLineBottom
        ? finalStage
          ? theme.colors.button
          : theme.colors.orange
        : theme.colors.background};
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: 50%;
    z-index: -1;
  }
`;

export const Info = styled.div`
  width: 20px;
  height: 20px;
  background-color: ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  margin-right: 20px;
`;

export const InfoIcon = styled(InfoTipIcon)`
  width: 16px;
  height: 16px;
`;

export const MarkWrapper = styled.div`
  position: relative;
  align-items: center;
  display: flex;
  width: 12px;
  height: 12px;
  margin-right: 12px;
  z-index: 100;
  border-radius: 50%;
  animation-name: ${PulseAnimation};
  animation-duration: ${({ currentActive, passed }) =>
    currentActive && !passed ? "2s" : "0s"};
  animation-iteration-count: infinite;
`;
