import React from "react";
import {
  Box,
  ERROR_FIELD_NAME,
  HINT_FIELD_NAME,
  Paper,
  Row,
  SUCCESS_FIELD_NAME,
  Typography,
  YELLOW_FIELD_NAME,
} from "../../UI";
import styled from "styled-components";
import { useTranslate } from "../../contexts";
import { SecondButton } from "../Button/Button";
import { dateFormat } from "../../utils/formatting";

const AmountValue = styled(Typography)``;

const PaperStyled = styled(Paper)`
  padding: 12px 16px;
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const STATUSES_KEYS = {
  DECLINED: "declined",
  SUCCESS: "success",
  PENDING: "pending",
  REVERSED: "reversed",
};

const STATUSES_COLORS = {
  DECLINED: ERROR_FIELD_NAME,
  SUCCESS: SUCCESS_FIELD_NAME,
  PENDING: YELLOW_FIELD_NAME,
  REVERSED: HINT_FIELD_NAME,
};

const Transaction = ({ date, value, status }) => {
  const { t } = useTranslate();
  const buy = value < 0;
  const zero = value === 0;
  const amountSign = zero ? "" : buy ? "-" : "+";

  const typeName = zero ? "" : buy ? "Credit" : "Debit";
  return (
    <PaperStyled>
      <Row justifyContent="space-between" alignItems="center">
        <Box>
          <Typography m="0 0 4px 0" variant="link">
            {dateFormat(date, "MM-dd-yyyy HH:mm")}
          </Typography>
          <Typography color="hint" variant="link">
            {typeName}
          </Typography>
        </Box>
        <Box>
          <AmountValue
            ta="right"
            color={zero ? "white" : buy ? "red" : "success"}
            vaiant="link"
          >{`${amountSign}$${Math.abs(value)}`}</AmountValue>
          <Typography variant="link" color={STATUSES_COLORS[status] || "white"}>
            {status}
          </Typography>
        </Box>
      </Row>
    </PaperStyled>
  );
};

const TransactionsBlock = styled(Box)``;

export const Transactions = React.memo(({ transactions, fetchMore }) => {
  const { t } = useTranslate();
  return (
    <Box>
      {/*<Typography m="0 0 8px 0" variant="h2">*/}
      {/*  {t("transactions").toUpperCase()}*/}
      {/*</Typography>*/}
      <TransactionsBlock m="0 0 8px 0">
        {transactions?.map((transaction, index) => (
          <Transaction
            key={index}
            status={transaction.status}
            date={transaction.timestamp}
            actionName={transaction.type}
            value={transaction.amount}
          />
        ))}
      </TransactionsBlock>
      {/*{transactions?.length ? (*/}
      {/*  <Box>*/}
      {/*    <SecondButton*/}
      {/*      minHeight="44px"*/}
      {/*      title={t("loadMore")}*/}
      {/*      titleVariant={"link"}*/}
      {/*      onClick={() => fetchMore && fetchMore(transactions?.length || 0)}*/}
      {/*    />*/}
      {/*  </Box>*/}
      {/*) : null}*/}
      {!transactions?.length ? (
        <Typography m="0 0 8px 0" variant="link" color="hint" ta="center">
          {t("listEmpty")}
        </Typography>
      ) : null}
    </Box>
  );
});
