import React, { useEffect, useState } from "react";
import { Wrapper, WrapperContent } from "./styled";
import {
  ButtonHorizontal,
  Input,
  PageHeader,
  PageLoader,
} from "../../components";
import {
  MAKER_ROUTE,
  MDAO_MAKER_PROJECT_ADDRESS,
  MDAO_MAKER_PROJECT_ROUTE,
} from "../../routes/routes";
import { Box, Link, Row, Typography } from "../../UI";
import { STAGES } from "./constants";
import { CHAINS, DAOMARS_EMAIL } from "../../config";
import { useModal, useSnackbar, useTranslate } from "../../contexts";
import { useParams } from "react-router-dom";
import { Trans } from "react-i18next";
import {
  useGetMakerProject,
  useGetMakerUserProjectParams,
  useGetRouter,
} from "../../hooks";
import { CONFIRM_MODAL } from "../../components/Modals";
import { useDispatch } from "react-redux";
import { useGetWeb3Context } from "../../contexts/web3";
import {
  clearFromRouteUrl,
  getFromRouteUrl,
} from "../../utils/local-storage-utils";

const SetProjectAddressFabric = ({ content, title, backRoute, actions }) => {
  return (
    <>
      <WrapperContent>
        <PageHeader
          title={title}
          to={backRoute}
          cb={() => clearFromRouteUrl()}
        />
        {content}
      </WrapperContent>
      <Box>{actions}</Box>
    </>
  );
};

const SetProjectAddress = ({ backRoute, ...props }) => {
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { handleRoute } = useGetRouter();
  const { showError, showSuccess } = useSnackbar();
  const { t } = useTranslate();
  const [value, setValue] = useState();
  const { open } = useModal();
  const { project } = props || {};
  const { web3Methods } = useGetWeb3Context();
  // const { method: getUserProjectParamsMethod } = useGetMakerUserProjectParams(
  //   project?.projectId
  // );

  const handleSetWallet = async () => {
    let valid = false;
    const hasValidCheck = CHAINS[project?.chain];

    if (!value || !project?.projectId) {
      showError(t("notificationTexts.somethingWrong"));
      return;
    }

    if (hasValidCheck) {
      const { checkValidAddress } = await web3Methods(project?.chain);

      valid = await checkValidAddress(value);
    }

    if (hasValidCheck && !valid) {
      showError(t("walletInvalid"));
      return;
    }

    return makerDispatch
      .setDropWallet({
        projectId: project.projectId,
        wallet: value,
      })
      .then(async () => {
        const hasLocationRoute = getFromRouteUrl();
        await makerDispatch.getMakerUserParams({ cache: false });
        showSuccess(t("notificationTexts.successfully"));

        handleRoute(
          hasLocationRoute || `${MDAO_MAKER_PROJECT_ROUTE}/${project.projectId}`
        );
      })
      .catch((e) => {
        showError(e.response.data?.message);
      });
  };

  const handleConfirmSave = () => {
    open(CONFIRM_MODAL, {
      description: ` ${t("areYouSureSetAddress")}️`,
      buttons: [
        {
          title: t("save"),
          color: "success",
          isClose: true,
          onClick: () => {
            handleSetWallet();
          },
        },
        {
          title: t("modalTexts.cancelWill"),
          isClose: true,
          color: "red",
        },
      ],
    });
  };

  return (
    <SetProjectAddressFabric
      backRoute={backRoute}
      title={t("setProjectAddress", { projectName: project?.title || "..." })}
      actions={
        <Box>
          <ButtonHorizontal
            bottomMarginForScreen
            variant="small"
            onClick={handleConfirmSave}
            checkTFA
            title={t("save")}
          />
        </Box>
      }
      content={
        <Box>
          <Input
            textarea
            onChange={setValue}
            value={value}
            placeholder={t("nearWalletPlaceholder", {
              chain: project?.chain || "...",
            })}
          />
          <Typography m="16px 0 16px 0" variant="link">
            <Trans
              i18nKey={"setYourAddressNear"}
              values={{
                nameLink: DAOMARS_EMAIL,
              }}
              components={{
                CustomLink: (
                  <Link
                    variant="link"
                    target="_blank"
                    m="0 0 0 0"
                    href={`mailto:${DAOMARS_EMAIL}`}
                  />
                ),
              }}
            />
          </Typography>
          <Link
            variant="link"
            m="0 0 0 0"
            href={t("guides.howToCreateWallet")}
            target="_blank"
          >
            {t("howToCreateANearChainWallet", {
              projectName: project?.title || "...",
            })}
          </Link>
        </Box>
      }
      {...props}
    />
  );
};

const Empty = () => <></>;

const getStagesComponent = (stage) => {
  return {
    [STAGES.PREPARATION]: Empty,
    [STAGES.PRIVATE_SALE]: Empty,
    [STAGES.PUBLIC_TICKET_DISTRIBUTION]: Empty,
    [STAGES.SUBSCRIPTIONS]: Empty,
    [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: Empty,
    [STAGES.PUBLIC_SALE]: Empty,
    [STAGES.HOLDING]: Empty,
    [STAGES.LOTTERY]: Empty,
    [STAGES.TOKEN_DISTRIBUTION]: SetProjectAddress,
    [STAGES.PROJECT_COMPLETE]: Empty,
  }[stage];
};

export const NearAddressPage = () => {
  const { t } = useTranslate();
  const { project_id } = useParams();
  const {
    project,
    projects,
    projectLoading,
    setProject,
    getMakerProjectMethod,
  } = useGetMakerProject({
    projectId: project_id,
    initCache: false,
    ignoreInitLoad: true,
  });

  const Component = project?.stage ? getStagesComponent(project?.stage) : Empty;

  const hasLocationRoute = getFromRouteUrl();
  const backRoute = hasLocationRoute
    ? hasLocationRoute
    : project_id
    ? `${MDAO_MAKER_PROJECT_ROUTE}/${project_id}`
    : MAKER_ROUTE;

  useEffect(() => {
    if (project_id) {
      if (projects?.length) {
        const currentProject = projects.find(
          (projectEl) => projectEl.projectId === Number(project_id)
        );

        if (currentProject) {
          if (!project || project?.projectId !== currentProject?.projectId) {
            setProject(currentProject);
          }
        } else {
          getMakerProjectMethod(project_id, false);
        }
      } else {
        getMakerProjectMethod(project_id, false);
      }
    }
  }, [projects, project_id]);

  return (
    <PageLoader loading={projectLoading}>
      <Wrapper>
        <Component project={project} backRoute={backRoute} />
      </Wrapper>
    </PageLoader>
  );
};
