import styled from "styled-components";
import { createContext, forwardRef, useCallback, useMemo } from "react";
import React from "react";
import Grow from "./Grow";
import { SnackbarProvider, enqueueSnackbar, closeSnackbar } from "notistack";
import { Box, Row, theme } from "../../UI";
import { useScreen } from "../../hooks";
import { findErrorCode } from "../../config";
import { useTranslate } from "../i18n";

const ErrorColors = {
  error: theme.colors.red,
  success: theme.colors.button,
  info: theme.colors.hint,
};

const ErrorMessage = styled.div`
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  word-break: normal;
  background-color: ${({ type }) => ErrorColors[type] || ErrorColors.error};
  padding: 20px;
  font-family: Roboto, sans-serif;
  box-sizing: border-box;
  box-shadow: ${({ theme }) => theme.shadows.shadow2};

  ${({ theme }) => theme.fonts.link};
  color: white;
`;

const MySnackbar = forwardRef((props, ref) => {
  return (
    <ErrorMessage
      {...props}
      ref={ref}
      type={props.variant}
      onClick={() => {
        closeSnackbar(props.id);
      }}
    >
      <Row width="100%" justifyContent="space-between">
        {props?.message}
      </Row>
    </ErrorMessage>
  );
});

export const NotistackContext = createContext(null);

const NOTISTACK_DURATION = 1500;

export const NotistackProvider = ({ children }) => {
  const { windowSize } = useScreen();
  const { t } = useTranslate();

  const handleShowNotification = useCallback(
    (msg, type) =>
      enqueueSnackbar(msg, {
        variant: type,
        autoHideDuration: NOTISTACK_DURATION,
      }),
    [enqueueSnackbar]
  );

  const methods = useMemo(
    () => ({
      showError: (msg) => {
        msg && handleShowNotification(msg?.message || msg, "error");
      },
      showErrorCode: (error) =>
        findErrorCode(error, (keyMessage) =>
          handleShowNotification(
            keyMessage
              ? t(keyMessage)
              : error?.response?.data?.message ||
                  error?.message ||
                  t("notificationTexts.somethingWrong"),
            "error"
          )
        ),
      showSuccess: (msg) => handleShowNotification(msg, "success"),
      showWarning: (msg) => handleShowNotification(msg, "warning"),
      showInfo: (msg) => handleShowNotification(msg, "info"),
    }),
    []
  );

  return (
    <NotistackContext.Provider value={{ ...methods }}>
      <SnackbarProvider
        maxSnack={3}
        TransitionComponent={Grow}
        Components={{
          error: MySnackbar,
          success: MySnackbar,
          info: MySnackbar,
        }}
      >
        {children}
      </SnackbarProvider>
    </NotistackContext.Provider>
  );
};
