import React from "react";
import { Box, Row, Typography } from "../../UI";
import { useSnackbar, useTranslate } from "../../contexts";
import { InfoIcon, MarkWrapper, StageCircle } from "./styled";
import { InfoTipsTexts, getStageTitles } from "../../pages/Maker/constants";
import { InfoTip } from "../Tip/InfoTip";
import { allBigFirstChar } from "../../utils/formatting";

export const Stages = ({ stages, passed, finalStage }) => {
  const { t } = useTranslate();

  const sortedStages = stages?.sort((a, b) => a.index - b.index);

  const indexComplete = sortedStages?.reduce((acc, el, i) => {
    if (el.complete && i >= acc) {
      acc = i + 1;
    }

    return acc;
  }, 0);

  return (
    <Box>
      {sortedStages?.map((stage, i, arr) => {
        const nextStage = arr[i + 1];
        const prevStage = arr[i - 1];

        const active = passed || i <= indexComplete;
        const activeLineTop = passed || indexComplete > i;
        const activeLineBottom = passed || indexComplete >= i;
        const currentActive = passed || indexComplete === i;

        return (
          <InfoTip
            key={i}
            message={t(InfoTipsTexts(stage.stage))}
            isModal
            positionMargin={{ name: "right", value: "-6px" }}
          >
            <Row alignItems="center" height="32px" p="0 12px ">
              <MarkWrapper currentActive={currentActive} passed={passed}>
                <StageCircle
                  active={active}
                  activeLineTop={activeLineTop}
                  activeLineBottom={activeLineBottom}
                  notPrev={!prevStage}
                  notNext={!nextStage}
                  passed={passed}
                  finalStage={finalStage}
                />
              </MarkWrapper>
              <Typography m="0 8px 0 0 " variant="link">
                {allBigFirstChar(getStageTitles(t, stage?.stage))}
              </Typography>
            </Row>
          </InfoTip>
        );
      })}
    </Box>
  );
};
