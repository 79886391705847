import React, { useEffect } from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import { Box, Link, NextArrowIconStyled, Row, Typography } from "../../../UI";
import { useTranslate } from "../../../contexts";
import {
  declOfNum,
  numberWithCommas,
  shortWallet,
} from "../../../utils/formatting";
import { useGetMakerUserProjectParams } from "../../../hooks";

export const SuccessPage = ({ margin, cb, params, amount }) => {
  const { t } = useTranslate();

  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("youveSuccessfullyBuyTickets", {
              amount,
              word: declOfNum(amount, [
                t("ticket1"),
                t("ticket3"),
                t("ticket2"),
              ]),
            })}{" "}
            <br />
          </Typography>
          <Typography ta="center" m="16px 0 0 0" variant="link">
            {t("youCanUseItDuringAllocation")}
          </Typography>
        </Box>
      }
    />
  );
};

export const BuyAllocationSuccessPage = ({ margin, cb, token, count }) => {
  const { t } = useTranslate();

  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography ta="center" m="16px 0 0 0" variant="link">
            {t("youveSuccessfullyBuyTokenAllocation", {
              count: numberWithCommas(Number(count)) || 0,
              tokenName: token,
              word: declOfNum(count, [
                t("tokens1"),
                t("tokens3"),
                t("tokens2"),
              ]),
            })}{" "}
          </Typography>
        </Box>
      }
    />
  );
};

export const BuyTicketsSuccessPage = ({ margin, cb, tickets, projectId }) => {
  const { t } = useTranslate();

  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography m="16px 0 0 0" ta="center" variant="link">
            {t("youveSuccessfullyBoughtCountTickets", {
              count: tickets,
              word: declOfNum(tickets, [
                t("ticket1"),
                t("ticket3"),
                t("ticket2"),
              ]),
            })}
          </Typography>
          <Typography m="16px 0 0 0" ta="center" variant="link">
            {t("youCanUseThemDuringThePrivate")}
          </Typography>
        </Box>
      }
    />
  );
};
