import React, { useMemo } from "react";
import {
  AccordionStyled,
  ActiveCardTokenStyled,
  CardTokenStyled,
  DropdownTokenWrapper,
} from "./styled";
import { useSelector } from "react-redux";
import { sortTokens } from "../../utils/dataFiltering";

export const DropdownToken = React.memo(
  ({ tokens, currentToken, setToken }) => {
    const { tokenBalances } = useSelector(({ wallet }) => wallet);
    const DataComponent = React.memo(({ data, close }) => {
      const handleSetToken = (token) => {
        setToken(token);
        close();
      };

      return (
        <>
          {data?.map((token, i) => {
            return (
              <CardTokenStyled key={i} data={token} setValue={handleSetToken} />
            );
          })}
        </>
      );
    });

    const SelectComponent = React.memo(({ value }) => {
      return <ActiveCardTokenStyled data={value} />;
    });

    const tokensWithoutSelect = useMemo(
      () => tokens?.filter((token) => token?.name !== currentToken?.name),
      [tokens, currentToken]
    );

    const tokensSorted = tokensWithoutSelect?.sort(sortTokens(tokenBalances));

    return (
      <DropdownTokenWrapper>
        <AccordionStyled
          disable={!tokensSorted?.length}
          value={currentToken}
          data={tokensSorted}
          ContentHeader={() => <SelectComponent value={currentToken} />}
          Content={({ close }) => (
            <DataComponent data={tokensSorted} close={close} />
          )}
        />
      </DropdownTokenWrapper>
    );
  }
);
