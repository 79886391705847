import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AllProviders from "./providers";
import { BrowserRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <AllProviders>
      <Suspense>
        <App />
      </Suspense>
    </AllProviders>
  </BrowserRouter>
);
