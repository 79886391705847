import { AxiosGet, AxiosPost } from "../rest/axios";
import { URLS } from "../api/index";
import { mainCoins, NONE } from "../config";

const moduleState = {
  state: {
    user: null,
    chains: [],
    faChecked: false,
    isKyc: NONE,
    isAuth: false,
    currentChain: null,
    botToken: null,
    balance: null,
    tokenBalances: {},
    tokenPrices: [],
    session_expired: false,
    mainTokenIds: [],
  },
  reducers: {
    setUser(state, payload) {
      return {
        ...state,
        isAuth: true,
        user: { ...state.user, ...payload },
      };
    },
    setWallet(state, payload) {
      return {
        ...state,
        user: { ...state.user, wallet: payload },
      };
    },

    setMainTokenIds(state, payload) {
      return {
        ...state,
        mainTokenIds: payload,
      };
    },
    setKyc(state, payload) {
      return {
        ...state,
        isKyc: payload,
      };
    },
    setFA(state, payload) {
      return { ...state, user: { ...state.user, tfaStatus: payload } };
    },
    setToken(state, payload) {
      return { ...state, botToken: payload };
    },
    addTokenToUser(state, payload) {
      return {
        ...state,
        user: {
          ...state.user,
          tokens: [...(state?.user?.tokens || []), payload],
        },
      };
    },
    setSessionExpired(state, payload) {
      return { ...state, session_expired: payload };
    },
    setBalance(state, payload) {
      return { ...state, balance: payload };
    },
    setChains(state, payload) {
      return { ...state, chains: payload };
    },
    setTokens(state, payload) {
      return { ...state, tokens: payload };
    },
    setCurrentChain(state, payload) {
      return { ...state, currentChain: payload };
    },
    setTokenBalance(state, payload) {
      return {
        ...state,
        tokenBalances: {
          ...state.tokenBalances,
          [payload.contract]: payload.balance,
        },
      };
    },
    clearTokenBalances(state, payload) {
      return {
        ...state,
        tokenBalances: {},
      };
    },
    setTokenBalances(state, payload) {
      return {
        ...state,
        tokenBalances: {
          ...state.tokenBalances,
          ...payload,
        },
      };
    },
  },
  effects: {
    getInfo(data) {
      const params = { chain: data?.chain || "BSC" };
      return AxiosGet({
        url: URLS.getInfo,
        params,
        cache: data?.cache,
      }).then((res) => {
        if (!data?.withoutSave) {
          this.setUser(res?.data.result);
        }

        return res?.data.result;
      });
    },
    getChains() {
      return AxiosGet({
        url: URLS.getChains,
      })
        .then((res) => {
          this.setChains(res?.data?.result);
          return res;
        })
        .catch((e) => console.error(e.message));
    },
    addToken(data) {
      return AxiosPost({
        url: URLS.addToken,
        data,
      });
    },
    withdraw(data) {
      return AxiosPost({
        url: URLS.withdraw,
        data,
      });
    },

    withdrawToken(data) {
      return AxiosPost({
        url: URLS.withdrawToken,
        data,
      });
    },
    getWithdrawFee(params) {
      return AxiosGet({
        url: URLS.withdrawalFee,
        params,
        cache: false,
      });
    },
    getCoinPrice(params) {
      return fetch(
        `https://api.binance.com/api/v3/ticker/price?symbol=${params.coinName}USDT`
      ).then((res) => res.json());
    },
    getWithdrawTokenFee(params) {
      return AxiosGet({
        url: URLS.withdrawTokenFee,
        params,
      });
    },
    getProfile(data) {
      return AxiosGet({
        url: URLS.getProfile,
        cache: data?.cache,
      }).then((res) => {
        this.setUser({ ...res?.data?.result });
        return res;
      });
    },
    setLanguage(data) {
      return AxiosPost({
        url: URLS.setLanguage,
        data,
      });
    },
    get2FA(data) {
      return AxiosPost({
        url: URLS.get2FA,
        data,
      });
    },
    enable2FA(data) {
      return AxiosPost({
        url: URLS.enable2FA,
        data,
      });
    },
    disable2FA(data) {
      return AxiosPost({
        url: URLS.disable2FA,
        data,
      });
    },
    getKycStatus(data) {
      return AxiosGet({
        url: URLS.getKycStatus,
        data,
        cache: false,
      })
        .then(({ data }) => {
          this.setKyc(data?.result);
          return data;
        })
        .catch((e) => console.error(e.message));
    },
    startKyc() {
      return AxiosPost({
        url: URLS.startKyc,
      });
    },
  },
};

export default moduleState;
