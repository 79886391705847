import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, CARD_COLORS, Row, Typography } from "../../UI";
import { useSwipe } from "../../hooks/useSwipe";
import { useGetRouter, useScreen } from "../../hooks";
import { ReactComponent as MastercardIcon } from "../../assets/svg/mastercard.svg";
import { ReactComponent as VisaIcon } from "../../assets/svg/visa.svg";
import { ReactComponent as UKIcon } from "../../assets/svg/uk.svg";
import { useTranslate } from "../../contexts";
import { SELECT_OR_ISSUE_CARDS_ROUTE } from "../../routes/routes";
import { AnimatePresence, motion, usePresence } from "framer-motion";
import { CARD_STATUTES } from "../../config";
import {
  ActionButtonElement,
  BalanceWrapper,
  CardBoxWrapper,
  CardCreateWrapper,
  CardCreditsBlock,
  CardWrapper,
  CardWrapperPaper,
  ControlsBlock,
  Dot,
  DotsBlock,
  HideWrapper,
  HongKongIconStyled,
  MasterCard,
  MoveWrapper,
  PlusIconElement,
  UKIconStyled,
  Wrapper,
  WrapperBox,
  WrapperComponent,
} from "./styled";
import styled, { useTheme } from "styled-components";
import Skeleton from "react-loading-skeleton";

const DURATION = 0.5;

const WrapperAnimation = styled.div`
  position: relative;
  height: 40px;
  min-width: 150px;
  margin: 16px auto 25px auto;
`;

const WrapperAnimationItem = styled.div`
  display: flex;
  position: absolute;
  width: 300px;
  height: 40px;
`;

const WrapperAnimationControlItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
`;

const CarNumberTitleStyled = styled(Typography)`
  font-size: ${({ fs }) => fs + "px"} !important;
`;

const CardComponent = React.memo(
  ({
    data,
    color,
    schemes,
    setShowDetails,
    showDetails,
    isActiveCard,
    fontSizeCardNumber,
    cardFrozen,
  }) => {
    const { scheme, country } = schemes[data?.binId] || {};
    const { colors } = useTheme();
    const { t } = useTranslate();
    const { cvv, expiryMonth, expiryYear, number } = data?.sensitive || {};
    const cardNumber = data?.maskNumber;
    const lastFourNumbers = cardNumber
      ?.toString()
      ?.slice(cardNumber?.toString().length - 4, cardNumber?.toString().length);

    const hideCardNumber = `**** **** **** ${lastFourNumbers}`;
    const formatCardNumber = (number || cardNumber)
      ?.toString()
      ?.replace(/\d{4}(?=.)/g, "$& ");

    const cardNumberShowed = showDetails ? formatCardNumber : hideCardNumber;

    const CardTypeIcon =
      {
        MasterCard: MastercardIcon,
        Visa: VisaIcon,
      }[scheme] || MasterCard;

    const Flag =
      {
        UK: UKIconStyled,
        ["Hong Kong"]: HongKongIconStyled,
      }[country] || UKIcon;

    const cardLoading = showDetails && !number;

    return (
      <CardBoxWrapper>
        <Row width="100%" justifyContent="space-between" alignItems="center">
          {cardLoading && isActiveCard ? (
            <Skeleton
              width="140px"
              height="21px"
              borderRadius="6px"
              baseColor={color}
              highlightColor={colors.white}
            />
          ) : (
            <CarNumberTitleStyled variant="h2" fs={fontSizeCardNumber}>
              {cardNumberShowed}
            </CarNumberTitleStyled>
          )}

          <Box>
            {cardFrozen && (
              <Typography lh="18px" variant="h2">
                ❄️
              </Typography>
            )}
          </Box>
        </Row>
        {cardLoading && isActiveCard ? (
          <Box>
            <Skeleton
              width="80px"
              height="14px"
              borderRadius="6px"
              baseColor={color}
              highlightColor={colors.white}
              style={{ marginTop: "8px" }}
            />
            <Skeleton
              width="36px"
              height="14px"
              borderRadius="6px"
              baseColor={color}
              highlightColor={colors.white}
              style={{ marginTop: "2px" }}
            />
            <Skeleton
              width="50px"
              height="14px"
              borderRadius="6px"
              baseColor={color}
              highlightColor={colors.white}
              style={{ marginTop: "2px" }}
            />
          </Box>
        ) : (
          <Box>
            <Typography m="8px 0 0 0" variant="small" lh="12px">
              {data?.binId ? `BIN: ${data?.binId}` : "..."}
            </Typography>
            {showDetails && (
              <Box>
                <Typography m="8px 0 0 0" variant="small" lh="12px">
                  {expiryMonth && expiryYear
                    ? `${expiryMonth}/${expiryYear}`
                    : "..."}
                </Typography>{" "}
                <Typography m="8px 0 0 0" variant="small" lh="12px">
                  {cvv ? `CVV: ${cvv || "..."}` : "..."}
                </Typography>
              </Box>
            )}
          </Box>
        )}
        <CardCreditsBlock
          onClick={(e) => {
            e.stopPropagation();
            setShowDetails && isActiveCard && setShowDetails((prev) => !prev);
          }}
        >
          <Flag />
          <Typography variant="small" ta="center">
            {showDetails ? t("tapToHide") : t("tapToShow")}
          </Typography>
          <CardTypeIcon />
        </CardCreditsBlock>
      </CardBoxWrapper>
    );
  }
);

const CardCreate = (props) => {
  const { t } = useTranslate();
  return (
    <CardCreateWrapper {...props}>
      <Row width="100%" justifyContent="center">
        <PlusIconElement />
      </Row>
      <Typography ta="center" variant="h2">
        {t("tapToCreateANewCard")}
      </Typography>
    </CardCreateWrapper>
  );
};

const ActionButtonStyled = styled(Typography)`
  font-weight: 700;
  font-size: ${({ fs }) => `${fs}px`} !important;
`;

const ActionButton = ({
  emoji,
  title,
  action,
  currentCardId,
  disable,
  fontSize,
}) => {
  return (
    <ActionButtonElement
      disable={disable}
      onClick={() => action && !disable && action(currentCardId)}
    >
      <Typography ta="center" variant="h1" align>
        {emoji}
      </Typography>
      <ActionButtonStyled ta="center" fs={fontSize ? `${fontSize}` : null}>
        {title}
      </ActionButtonStyled>
    </ActionButtonElement>
  );
};

const DotPositionWrapper = styled(Box)`
  position: absolute;
  left: ${({ left }) => `${left}`};
  top: ${({ bottom }) => `${bottom}`};
  transform: translate(-50%, 0);
`;

const Controls = ({
  actions,
  activeCard,
  isCreate,
  fontSizeActionButton,
  cards,
}) => {
  const [isPresent, safeToRemoval] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemoval, 500);
  }, [isPresent]);

  return (
    <WrapperAnimationControlItem>
      {actions?.map((el, i) => {
        const currentCardId = cards?.find(
          (card) => Number(card.id) === Number(activeCard)
        )?.cardId;

        return (
          <ActionButton
            key={i}
            disable={isCreate || el.disable}
            currentCardId={currentCardId}
            emoji={el.emoji}
            fontSize={fontSizeActionButton}
            title={el.title}
            action={el.action}
          />
        );
      })}
    </WrapperAnimationControlItem>
  );
};

const DotPagination = ({
  cards,
  cardsLoaded,
  dotView,
  windowSize,
  activeCard,
  isCreate,
  handleSetActiveCard,
  isSwipping,
}) => {
  const dotBlockAnimation = useMemo(
    () => ({
      initial: {
        x: 0,
        opacity: 0,
      },
      animate: { x: 0, opacity: 1 },
      exit: {
        opacity: 0,
      },
    }),
    []
  );

  return (
    <Box height={"8px"} m="8px auto 0 auto">
      {cards?.length && cardsLoaded && dotView ? (
        <motion.div
          variants={dotBlockAnimation}
          key="box"
          initial={{ opacity: 0 }}
          animate="animate"
          exit="exit"
          transition={{ duration: DURATION / 2 }}
        >
          <DotsBlock
            width={
              ((windowSize.width > 480 ? 480 : windowSize.width) / 100) * 65 +
              "px"
            }
          >
            {[...(cards || []), { id: -1 }].map((el, i) => {
              return (
                <Dot
                  active={!isCreate ? i === activeCard : el.id === -1}
                  onClick={() => !isSwipping && handleSetActiveCard(el.id)}
                />
              );
            })}
          </DotsBlock>
        </motion.div>
      ) : null}
    </Box>
  );
};

const Balance = ({ variants, currentCard, activeCardData, t }) => {
  const [isPresent, safeToRemoval] = usePresence();

  useEffect(() => {
    !isPresent && setTimeout(safeToRemoval, 400);
  }, [isPresent]);

  return (
    <motion.div
      variants={variants}
      key="box"
      initial="initial"
      animate="animate"
      exit="exit"
    >
      <WrapperAnimationItem key="box1">
        <BalanceWrapper>
          <Typography m="8px 0 0 0" variant="h2">
            {t("cardBalance", {
              balance: `$${(
                currentCard?.balance ??
                activeCardData?.balance ??
                0
              ).toFixed(2)}`,
            })}
          </Typography>
        </BalanceWrapper>
      </WrapperAnimationItem>
    </motion.div>
  );
};

export const CardsSlider = React.memo(
  ({
    actions,
    cards,
    cardsLoaded,
    showTFA,
    setActiveCardId,
    loadingCard,
    setShowDetails,
    activeCardId,
    schemes,
    activeCardData,
    showDetails,
    setIsCreateMode,
    cardsInited,
  }) => {
    const { t } = useTranslate();
    const { handleRoute } = useGetRouter();
    const [activeCard, setActiveCard] = useState(0);
    const [isCreate, setIsCreate] = useState(false);
    const { windowSize } = useScreen();
    const [sliderWidth, setSliderWidth] = useState(0);

    const [leftAnimation, setLeftAnimation] = useState(null);
    const [rightAnimation, setRightAnimation] = useState(false);

    const [balanceBlock, setBalanceBlock] = useState(null);
    const [cardBlock, setCardBlock] = useState("initial");
    const [controlsBlockAnimation, setControlsBlockAnimation] = useState(null);

    const init = useRef(false);
    const sliderRef = useRef();
    const cardRef = useRef(null);
    const lastCardRef = useRef(null);

    const CARD_WIDTH = ((sliderWidth - 32) / 100) * 69;

    const left = CARD_WIDTH * (cards?.length - activeCard || 0) + 86;

    const widthSlider = (windowSize.width / 100) * 60;
    const widthSliderMax = widthSlider > 420 ? 420 : widthSlider;

    const currentCard = useMemo(
      () => cards?.find((card) => Number(card.id) === Number(activeCard)),
      [cards, activeCard]
    );

    const balanceBlockAnimation = useMemo(
      () => ({
        initial:
          leftAnimation === null
            ? {
                x: 0,
                opacity: 0,
                scale: 0.8,
                transition: { duration: 0.4 },
              }
            : leftAnimation
            ? {
                x: "145%",
                opacity: 0,
                scale: 1,
                transition: { duration: 0.5 },
              }
            : {
                x: -60,
                opacity: 0,
                scale: 0.6,
                transition: { duration: 0.4 },
              },
        animate: {
          x: 0,
          y: 0,
          opacity: 1,
          scale: 1,
          transition: { duration: leftAnimation ? 0.5 : 0.4 },
        },
        exit: leftAnimation
          ? {
              x: -30,
              opacity: 0,
              scale: 0.8,
              transition: { duration: 0.3 },
            }
          : {
              x: "60%",
              opacity: 0,
              scale: 1,
              transition: { duration: 0.3 },
            },
      }),
      [leftAnimation, rightAnimation]
    );

    const cardsBlockAnimation = useMemo(
      () => ({
        open: { opacity: 1, x: "0%", scale: 1 },
        closed: { opacity: 0, x: "00%", scale: 0.7 },
      }),
      []
    );

    const textCreateCard = useMemo(
      () => ({
        open: { opacity: 1, x: "0%", y: 0 },
        closed: { opacity: 0, x: "70%", y: "-70%" },
      }),
      []
    );

    const isSwipping = useRef(false);

    const handleStartSwipe = () => {
      isSwipping.current = true;

      setTimeout(() => {
        isSwipping.current = false;
      }, DURATION * 1000);
    };

    const handleSetCard = (index) => {
      setActiveCard(index);
      const cardId = cards?.find(
        (card) => Number(card.id) === Number(index)
      )?.cardId;

      if (cardId) {
        setActiveCardId(cardId);
      }
    };
    const swipeActions = useMemo(() => {
      return {
        onLeft: () => {
          if (isSwipping.current) return;

          setLeftAnimation(true);
          setRightAnimation(false);
          handleStartSwipe();
          let index = null;

          if (isCreate) return;

          if (activeCard < cards?.length - 1) {
            index = activeCard + 1;
          } else {
            setIsCreate(true);
            setIsCreateMode(true);
            index = -1;
          }

          handleSetCard(index);
        },
        onRight: () => {
          if (isSwipping.current) return;
          setLeftAnimation(false);
          setRightAnimation(true);
          handleStartSwipe();
          const createPageBuyHasCards = activeCard === -1;
          let index = null;
          if (activeCard > 0 || createPageBuyHasCards) {
            index =
              activeCard > 0
                ? activeCard - 1
                : createPageBuyHasCards
                ? cards?.length - 1
                : activeCard;

            handleSetCard(index);
          }
        },
      };
    }, [activeCard, cards]);

    const { bind } = useSwipe(swipeActions, 0.4);
    const swipeMethods = bind();

    const handleSetActiveCard = (index) => {
      handleSetCard(index);
      setShowDetails(false);
    };

    useEffect(() => {
      if (cardsLoaded && !activeCardId) {
        handleSetCard(0);
      }
    }, [cardsLoaded]);

    useEffect(() => {
      const item = localStorage.getItem("saveCardPosition");

      if (item) {
        setActiveCardId(item);
        localStorage.removeItem("saveCardPosition");
      }
    }, [activeCard, cards, cardsLoaded]);

    useEffect(() => {
      if (sliderRef.current) {
        const rect = sliderRef.current.getBoundingClientRect();
        setSliderWidth(rect.width);
      }
    }, [sliderRef, windowSize, cardsInited]);

    useEffect(() => {
      if (activeCardId && cards?.length) {
        let index = null;
        cards.forEach((card, i) => {
          if (!index && card.cardId === activeCardId) {
            index = i;
          }
        });

        if (index > -1 && !isCreate) {
          setActiveCard(index);
        }
      }
    }, [activeCardId, cards, isCreate]);

    useEffect(() => {
      if (cards && !cards?.length && cardsLoaded) {
        handleSetActiveCard(-1);
        setIsCreate(true);
        setIsCreateMode(true);
      }
    }, [cards, cardsLoaded]);

    useEffect(() => {
      if (activeCard < 0) {
        setIsCreate(true);
        setIsCreateMode(true);
      } else {
        setIsCreate(false);
        setIsCreateMode(false);
      }
    }, [cards, activeCard]);

    useEffect(() => {
      if (!isCreate && activeCard !== null && init.current) {
        setBalanceBlock((prev) => !prev);
        setControlsBlockAnimation((prev) => !prev);
      }
    }, [activeCard]);

    useEffect(() => {
      if (activeCardId && !init.current) {
        init.current = true;
      }
    }, [activeCardId]);

    useEffect(() => {
      setCardBlock("open");
    }, []);

    const fontSizeActionButton =
      windowSize.width > 479
        ? 12
        : windowSize.width < 450
        ? Math.floor(windowSize.width / 35)
        : Math.floor(windowSize.width / 37);

    const [dotPosition, setDotPosition] = useState({});
    const [dotView, setDotView] = useState(false);
    const cardBlockRef = useRef(null);
    const createCardRef = useRef(null);
    const controlsBlockRef = useRef(null);

    useEffect(() => {
      if (cardBlockRef.current && controlsBlockRef.current) {
        if (isCreate) {
          setDotView(false);

          setTimeout(() => {
            const rect =
              createCardRef.current?.firstChild?.getBoundingClientRect();
            setDotPosition({
              left: rect?.left - 16,
              bottom: rect?.bottom - 34,
            });
            setDotView(true);
          }, DURATION * 1000);
        } else {
          setTimeout(() => {
            const rect = cardBlockRef?.current?.getBoundingClientRect();
            setDotPosition({
              left: rect?.left - 16,
              bottom: rect?.bottom - 54,
            });
          }, DURATION * 1000);
        }
      }

      if (!dotView) {
        setTimeout(() => {
          setDotView(true);
        }, 500);
      }
    }, [
      cardBlockRef,
      showTFA,
      controlsBlockRef,
      cardsLoaded,
      cards,
      cardsInited,
      windowSize.width,
    ]);

    return (
      <Box ref={sliderRef}>
        <WrapperComponent>
          <HideWrapper hide={isCreate}>
            <AnimatePresence>
              <WrapperBox {...swipeMethods}>
                <motion.div
                  ref={cardBlockRef}
                  variants={cardsBlockAnimation}
                  animate={isCreate ? "closed" : "open"}
                  initial={{ x: "0%", scale: 0.9 }}
                  transition={{ duration: DURATION }}
                >
                  <Wrapper
                    needPadding={activeCard > 0}
                    width={`${widthSliderMax}px`}
                  >
                    <MoveWrapper>
                      {(!cardsLoaded || !cardsInited) && !isCreate ? (
                        <Row>
                          <Skeleton
                            count={1}
                            height="154px"
                            width={CARD_WIDTH + "px"}
                            borderRadius={"12px"}
                            style={{ marginRight: "8px" }}
                          />
                          <Skeleton
                            count={1}
                            height="154px"
                            width={CARD_WIDTH + "px"}
                            borderRadius={"12px"}
                          />
                        </Row>
                      ) : (
                        cards?.map((el) => {
                          const notActive = el.id < activeCard;
                          const needMoveLeft = activeCard >= el.id;
                          const lastCard = el.id === cards.length - 1;
                          const indentLeft =
                            (el.id - activeCard) * CARD_WIDTH +
                            (activeCard <= el.id ? 10 : 0);
                          const rule = notActive
                            ? (widthSliderMax / 100) * 7 * -1
                            : needMoveLeft
                            ? 0
                            : indentLeft;
                          const left = `${isCreate ? 0 : rule}px`;

                          const advancedData = isSwipping.current
                            ? {}
                            : activeCardData;
                          const cardData = { ...el, ...advancedData };
                          const { status } = el || {};
                          const cardFrozen = status === CARD_STATUTES.INACTIVE;
                          const isActiveCard = Number(activeCard) === el.id;
                          const medium = windowSize.width < 365;
                          const small = windowSize.width < 340;

                          const fontIfFrozen = cardFrozen ? 16 : 18;
                          const fontSizeCardNumber = small
                            ? 12
                            : medium
                            ? 14
                            : fontIfFrozen;

                          const color = CARD_COLORS[el.color];

                          return (
                            <CardWrapper
                              onClick={(e) => {
                                e.stopPropagation();
                                if (
                                  el.id !== activeCard &&
                                  !isSwipping.current &&
                                  !isCreate
                                ) {
                                  handleSetActiveCard(el.id);
                                }
                              }}
                              inited={cardsInited}
                              cardWidth={CARD_WIDTH + "px"}
                              hide={lastCard}
                              notActive={notActive}
                              left={left}
                              cardsInited={cardsInited}
                              ref={lastCard ? lastCardRef : cardRef}
                            >
                              <CardWrapperPaper
                                color={color}
                                cardsInited={cardsInited}
                              >
                                <CardComponent
                                  color={color}
                                  cardFrozen={cardFrozen}
                                  fontSizeCardNumber={fontSizeCardNumber}
                                  data={cardData}
                                  loadingCard={loadingCard}
                                  isActiveCard={isActiveCard}
                                  schemes={schemes}
                                  setShowDetails={setShowDetails}
                                  showDetails={
                                    el.id === activeCard ? showDetails : false
                                  }
                                />
                              </CardWrapperPaper>
                            </CardWrapper>
                          );
                        })
                      )}
                    </MoveWrapper>
                  </Wrapper>
                </motion.div>
                <AnimatePresence>
                  {!isCreate && (
                    <DotPagination
                      isCreate={isCreate}
                      cards={cards}
                      dotView={dotView}
                      activeCard={activeCard}
                      cardsLoaded={cardsLoaded}
                      windowSize={windowSize}
                      isSwipping={isSwipping.current}
                      handleSetActiveCard={handleSetActiveCard}
                    />
                  )}
                </AnimatePresence>
                {!isCreate && (
                  <WrapperAnimation key="animationWrapper2">
                    <AnimatePresence>
                      {balanceBlock ? (
                        <Balance
                          t={t}
                          variants={balanceBlockAnimation}
                          currentCard={currentCard}
                          activeCardData={activeCardData}
                          key="box"
                        />
                      ) : (
                        <Balance
                          t={t}
                          variants={balanceBlockAnimation}
                          currentCard={currentCard}
                          activeCardData={activeCardData}
                          key="box2"
                        />
                      )}
                    </AnimatePresence>
                  </WrapperAnimation>
                )}
              </WrapperBox>
            </AnimatePresence>
            {!isCreate && (
              <Box height="64px" m="0px 0 16px 0">
                <Controls
                  cards={cards}
                  activeCard={activeCard}
                  fontSizeActionButton={fontSizeActionButton}
                  actions={actions}
                  isCreate={isCreate}
                />
              </Box>
            )}
          </HideWrapper>

          {(cardsLoaded && cardsInited) || isCreate ? (
            <motion.div
              animate={
                !cards?.length
                  ? { opacity: 1 }
                  : {
                      opacity: [0, 1],
                      x: [20, 0],
                    }
              }
              initial={{ opacity: 0 }}
              transition={{ easy: "linear", duration: DURATION }}
            >
              <Box {...swipeMethods}>
                <Box ref={createCardRef}>
                  <CardCreate
                    cardWidth={CARD_WIDTH + "px"}
                    onClick={() => {
                      if (!isSwipping.current) {
                        if (isCreate) {
                          handleRoute(SELECT_OR_ISSUE_CARDS_ROUTE);
                        }
                      }
                    }}
                    top={isCreate ? 30 : 0}
                    isCreate={isCreate}
                    notCards={!cards?.length}
                    left={isCreate ? 50 + "%" : left + "px"}
                  />
                </Box>
              </Box>
            </motion.div>
          ) : null}
        </WrapperComponent>
        {cardsLoaded && (
          <AnimatePresence>
            {isCreate && cardsLoaded && (
              <motion.div
                {...swipeMethods}
                variants={textCreateCard}
                animate={"open"}
                initial={
                  !cards.length || !cardsInited
                    ? { opacity: 0 }
                    : { x: "100%", y: "-70%", opacity: 1 }
                }
                transition={{ easy: "linear", duration: DURATION - 0.04 }}
              >
                <Typography ta="center" variant="link">
                  {t("issueAVirtualMasterCardVisa")}
                </Typography>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </Box>
    );
  }
);
