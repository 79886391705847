import React, { useEffect, useState } from "react";
import { useSnackbar, useTranslate } from "../../../contexts";
import { Wrapper } from "../styled";
import {
  ButtonHorizontal,
  LinkComponent,
  PageHeader,
  PageLoader,
} from "../../../components";
import { Box, Typography } from "../../../UI";
import { firstCharUpper, shortWallet } from "../../../utils/formatting";
import { useDispatch } from "react-redux";

const risks = {
  low: "low",
  medium: "medium",
  high: "high",
};

const getRiskValue = (risk) => {
  const percent = risk * 100;
  if (percent > 60) {
    return risks.high;
  }

  if (percent >= 20 && percent <= 60) {
    return risks.medium;
  }

  if (percent < 20) {
    return risks.low;
  }
};

export const AMLCheckResultPage = ({
  operationId,
  Button,
  description,
  cb,
  disableRoute,
  data,
}) => {
  const { t } = useTranslate();
  const { showError } = useSnackbar();
  const dispatch = useDispatch();
  const amlDispatch = dispatch.aml;
  const [state, setState] = useState(null);
  const [loading, setLoading] = useState(null);

  useEffect(() => {
    if (operationId || operationId === 0) {
      setLoading(true);
      amlDispatch
        .getAmlOperation({ operationId, cache: false })
        .then((res) => {
          setState(res.data.result);
        })
        .catch((e) => {
          showError(e);
        })
        .finally(() => setLoading(false));
    }
  }, [operationId, amlDispatch]);

  const { score, chain, address, type, link } = data || state || {};

  const percent = Number(score ? score * 100 : 0).toFixed(2);
  const riskValue = getRiskValue(score);

  const emojiRisk = {
    [risks.low]: "🟢",
    [risks.medium]: "🟠",
    [risks.high]: "🔴",
  }[riskValue];

  const titleRisk = {
    [risks.low]: t("low"),
    [risks.medium]: t("medium"),
    [risks.high]: t("high"),
  }[riskValue];

  return (
    <PageLoader loading={loading}>
      <Wrapper>
        <Box>
          <PageHeader
            title={t("amlCheckResult")}
            disableRoute={disableRoute}
            cb={cb}
          />
          <Box m="10px 0 0 0">
            <Typography variant="link">
              {t("amlCheckResultWallet", {
                wallet: shortWallet(address, 24, address?.length - 4) || "...",
              })}
            </Typography>
            <Typography variant="link">
              {t("amlCheckResultChain", { chain: chain || "..." })}
            </Typography>
            <Typography variant="link">
              {t("amlCheckResultType", { type: type ? t(type) : "..." })}
            </Typography>
            <Typography variant="h2" m="16px 0 16px 0">
              {t("amlCheckResultRiskLevel", {
                percent: percent || 0,
                emojiRisk: emojiRisk,
              })}
            </Typography>
            <Typography variant="link">
              {description
                ? description(titleRisk)
                : t("amlCheckResultRiskIs", {
                    type: titleRisk || "...",
                  })}
            </Typography>
          </Box>
        </Box>
        {Button ? (
          <Button onClick={cb} />
        ) : link ? (
          <LinkComponent to={link}>
            <ButtonHorizontal
              variant="small"
              title={t("viewReport")}
              bottomMarginForScreen
            />
          </LinkComponent>
        ) : (
          <ButtonHorizontal
            variant="small"
            disable
            title={t("viewReport")}
            bottomMarginForScreen
          />
        )}
      </Wrapper>
    </PageLoader>
  );
};
