import styled from "styled-components";
import { Box } from "../../UI";
import { CopyField, PaperStyled } from "../../components";

export const Wrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: calc(100vh - 50px);
  position: relative;
`;

export const ContentBox = styled(Box)`
  gap: 10px;
`;

export const CopyFieldStyled = styled(CopyField)`
  padding: 10px 10px 10px 20px;
`;
