import { AxiosGet, AxiosPost } from "../rest/axios";
import { URLS } from "../api/index";
import { CHAINS } from "../config";

export default {
  state: { addresses: [] },
  reducers: {
    setAddresses(state, payload) {
      return {
        ...state,
        addresses: payload,
      };
    },
  },
  effects: {
    getAddresses(data) {
      const params = { chain: "BTC" };
      return AxiosGet({
        url: URLS.getAddresses,
        params,
      }).then((res) => {
        this.setAddresses(res?.data.result);

        return res?.data.result;
      });
    },
    getAddressDeposit(data) {
      const params = { chain: "BTC" };
      return AxiosGet({
        url: URLS.getAddressDeposit,
        params,
      });
    },
    getSatFees(params) {
      return AxiosGet({
        url: URLS.getNetworkFee,
        params,
      });
    },
    getMaxAmount({ satB, address }) {
      const params = { chain: CHAINS.BTC, satB, address };
      return AxiosGet({
        url: URLS.getBtcMaxAmount,
        params,
      });
    },
  },
};
