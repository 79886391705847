import React from "react";
import { Box, Link, Typography } from "../../UI";
import { PageHeader } from "../../components";
import { useTranslate } from "../../contexts";
import { DAOMARS_EMAIL } from "../../config";
import { Trans } from "react-i18next";

export const SupportPage = () => {
  const { t } = useTranslate();

  return (
    <Box>
      <PageHeader title={t("support")} />

      <Typography m="16px 0 16px 0" variant="link">
        <Trans
          i18nKey={"ifICauseYouAnyTrouble"}
          values={{
            nameLink: DAOMARS_EMAIL,
          }}
          components={{
            CustomLink: (
              <Link
                variant="link"
                target="_blank"
                m="0 0 0 0"
                href={`mailto:${DAOMARS_EMAIL}`}
              />
            ),
          }}
        />
      </Typography>
      {/*<Typography m="10px 0 0 0" variant="link">*/}
      {/*  {t("ifICauseYouAnyTrouble")}*/}
      {/*</Typography>*/}
      {/*<Link href={` mailto:${DAOMARS_EMAIL}`} target="_blank">*/}
      {/*  <Typography variant="link" color="link">*/}
      {/*    {DAOMARS_EMAIL}*/}
      {/*  </Typography>*/}
      {/*</Link>*/}
    </Box>
  );
};
