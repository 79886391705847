import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Row, Typography } from "../../UI";
import { Button, Checkbox, PageHeader, PageLoader } from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";
import { ReactComponent as SearchIcon } from "../../assets/svg/search.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross.svg";
import { MakerProjectCard } from "../../components/Cards/MakerProjectCard";
import { useDispatch, useSelector } from "react-redux";
import {
  MAIN_ROUTE,
  MAKER_ROUTE,
  MDAO_MAKER_PROJECT_ADDRESS,
  MDAO_MAKER_PROJECT_ROUTE,
  MDAO_MAKER_STAKING_ROUTE,
} from "../../routes/routes";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { FINALS_PROJECTS_STAGES, STAGES } from "./constants";
import { useGetMakerStakingInit, useGetRouter } from "../../hooks";
import {
  ContentWrapper,
  InputStyled,
  ProjectSkeletonCard,
  ProjectsWrapper,
} from "./styled";
import styled from "styled-components";
import {
  clearFromRouteUrl,
  setFromRouteUrl,
} from "../../utils/local-storage-utils";
import useCheckKeyStatus from "../../hooks/useCheckKeyStatus";

const bot = window.Telegram?.WebApp;

const Wrapper = styled(Box)`
  height: ${({ height }) => height - 50 + "px"};

  position: relative;
  overflow: hidden;
  justify-content: space-between;
`;

const ButtonStyled = styled(Button)`
  height: 100%;
`;

const WrapperContent = styled(Box)``;

const ButtonWrapperForMany = styled(Box)`
  position: fixed;

  bottom: 0;
  height: fit-content;
  left: 0;
  right: 0;
  padding: ${({ notView }) =>
    notView ? "0 16px 0 16px" : "16px 16px 34px 16px"};
`;

const ButtonWrapper = styled(Box)`
  display: ${({ isEng }) => (isEng ? "grid" : "flex")};
  grid-template-columns: ${({ isEng, both }) =>
    isEng ? (both ? "1fr 1fr" : "1fr") : "0"};
  grid-gap: ${({ isEng, both }) => (isEng ? (both ? "8px" : "0") : "0")};
`;

const PageHeaderStyled = styled(PageHeader)`
  //padding-bottom: 16px;
  padding: 0;
`;

const SearchIconStyled = styled(SearchIcon)``;
const CrossIconStyled = styled(CrossIcon)`
  height: 14px;
  width: 14px;
`;
const HeaderSearchIconStyled = styled(SearchIcon)`
  height: 25px;
  width: 25px;
`;

const POSITION_SCROLL_STORAGE_NAME = "makerProjectScrollPosition";

const ProjectsComponent = React.memo(
  ({ title, tickets, loading, projects, detailed, onClickCb, particulars }) => {
    const { handleRoute } = useGetRouter();
    const { t } = useTranslate();

    return (
      <Box m="0px 0 0 0 ">
        <Typography m="0 0 8px 0" variant="bold" color="hint">
          {title}
        </Typography>

        {loading ? (
          <ProjectSkeletonCard
            width="100%"
            height="67px"
            borderRadius="12px"
            count={3}
          />
        ) : (
          projects?.map(
            ({
              allocation,
              stage,
              token,
              usdtBalance,
              projectId,
              tokenPrice,
              subscribed,
              tge,
              icon,
              ath,
              title,
              ...rest
            }) => {
              const ticket = tickets.find(
                (tick) => tick.projectId === Number(projectId)
              );

              const isPart = particulars.includes(projectId);
              const subscribedFromTicket = ticket?.subscribed;
              const isComplete = FINALS_PROJECTS_STAGES.includes(stage);

              return (
                <MakerProjectCard
                  {...rest}
                  projectId={projectId}
                  subscribed={subscribedFromTicket}
                  usdtBalance={usdtBalance}
                  ath={ath}
                  ticket={ticket}
                  complete={isComplete}
                  onClick={() => {
                    handleRoute(`${MDAO_MAKER_PROJECT_ROUTE}/${projectId}`);
                    if (onClickCb) {
                      onClickCb();
                    }
                  }}
                  stage={stage}
                  projectName={title}
                  token={token}
                  tokenPrice={tokenPrice}
                  tge={tge}
                  isPart={isPart}
                  detailed={detailed}
                  allocation={allocation}
                  icon={icon}
                />
              );
            }
          )
        )}
        {!loading && !projects?.length ? (
          <Typography m="8px 0 8px 0" ta="center" variant="link" color="hint">
            {t("projectsNotFound")}
          </Typography>
        ) : null}
      </Box>
    );
  }
);

export const MakerMainPage = () => {
  const { t, language } = useTranslate();
  const dispatch = useDispatch();
  const { showError } = useSnackbar();
  const makerDispatch = dispatch.maker;
  const walletDispatch = dispatch.wallet;

  const { handleRoute } = useGetRouter();
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [detailed, setDetailed] = useState(true);
  const [projects, setProjects] = useState(null);
  const [isSearch, setIsSearch] = useState(false);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [rerender, setRerender] = useState(false);
  const { subscriptions, tickets } = useSelector(({ maker }) => maker);
  const navigationRef = useRef();
  const contentRef = useRef();
  const scroll = useRef(false);

  useEffect(() => {
    walletDispatch.getKycStatus();
  }, []);

  useCheckKeyStatus();

  const isEng = language === "en";

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    {},
    [projects]
  );

  useGetMakerStakingInit();

  const handleSetSearch = (value) => {
    setSearch(value);
  };

  useEffect(() => {
    clearFromRouteUrl();

    return () => {
      // setProjects([]);
    };
  }, []);

  const handleGetProjects = async () => {
    setLoading(true);
    try {
      return makerDispatch
        .getProjects({ cache: false })
        .then((res) => {
          setProjects(
            res.data.result.map((el) => {
              const subscribed = subscriptions?.includes(el.projectId);
              return { ...el, subscribed: !!subscribed };
            })
          );
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (e) {
      showError(e);
    }
  };

  useEffect(() => {
    (async () => {
      await handleGetProjects();
    })();
  }, [makerDispatch]);

  useEffect(() => {
    if (!rerender) return;

    setTimeout(() => {
      projectRef.current?.scrollTo(0, Number(0));
      projectRef.current?.scrollTo(0, Number(2));
      setRerender(false);
    }, 20);
  }, [filteredProjects, contentHeight, rerender]);

  const subscriptionInited = useRef(false);
  useEffect(() => {
    if (!projects?.length || subscriptionInited.current) return;

    setProjects(
      projects?.map((el) => {
        const subscribed = subscriptions?.includes(el.projectId);
        return { ...el, subscribed: !!subscribed };
      })
    );
    subscriptionInited.current = true;
  }, [subscriptions, projects]);

  useEffect(() => {
    if (search) {
      const filtered = projects?.filter((project) =>
        project.title?.toLowerCase().includes(search.toLowerCase())
      );
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [search, projects]);

  const activeProjects = useMemo(
    () =>
      filteredProjects?.filter(
        (project) => project.stage !== STAGES.PROJECT_COMPLETE
      ),
    [filteredProjects]
  );

  const completedProjects = useMemo(
    () =>
      filteredProjects?.filter(
        (project) => project.stage === STAGES.PROJECT_COMPLETE
      ),
    [filteredProjects]
  );

  const projectRef = useRef(null);
  const init = useRef(null);

  useEffect(() => {
    if (!projectRef.current && init.current) return;

    const scrollPosition = localStorage.getItem(POSITION_SCROLL_STORAGE_NAME);

    if (Number(scrollPosition) > 0) {
      setTimeout(() => {
        projectRef.current?.scrollTo(0, Number(scrollPosition));
        localStorage.removeItem(POSITION_SCROLL_STORAGE_NAME);
        scroll.current = true;
      }, 0);
    } else {
      setRerender(true);
    }
  }, [projectRef]);

  useEffect(() => {
    if (!projectRef.current) return;

    projectRef.current.addEventListener("scroll", (event) => {
      scroll.current = projectRef.current?.scrollTop;
    });
  }, [projectRef.current]);

  const usdtStakingButton =
    process.env.REACT_APP_USDT_STAKING_BUTTON === "true";

  const searchInputRef = useRef(null);

  const handleSetScrollPosition = useCallback(() => {
    localStorage.setItem(
      POSITION_SCROLL_STORAGE_NAME,
      scroll.current > 5 ? scroll.current : 0
    );

    if (searchInputRef.current) {
      searchInputRef.current?.blur();
    }
  }, [searchInputRef.current]);

  const ticketsForMyProjects = tickets.reduce((acc, el) => {
    acc[el.projectId] = (el.held || 0) + (el.staking || 0) + (el.payable || 0);
    return acc;
  }, {});

  const hasParticular = activeProjects
    ?.filter(
      (el) => el.subscribed || (ticketsForMyProjects?.[el.projectId] || 0) > 0
    )
    .map((el) => el.projectId);

  const activeProjectsFiltered = useMemo(() => {
    return activeProjects;
  }, [activeProjects]);

  const completedProjectsFiltered = useMemo(() => {
    return completedProjects;
  }, [completedProjects]);

  const projectWithDropWallet = tickets?.reduce((acc, el) => {
    if (el?.dropWallet === null && el?.allocationPurchased > 0) {
      acc[el.projectId] = true;
    }
    return acc;
  }, {});

  const firstProjectWithDropWalletRequired = projects?.find(
    (project) =>
      project?.dropWalletRequired &&
      projectWithDropWallet[project?.projectId] &&
      project.stage === STAGES.TOKEN_DISTRIBUTION
  );

  const viewSetAddresses = firstProjectWithDropWalletRequired;

  return (
    <PageLoader loading={loading}>
      <Wrapper height={window.innerHeight}>
        <WrapperContent>
          {isSearch ? (
            <Box width="100%">
              <InputStyled
                ref={searchInputRef}
                LeftIcon={SearchIconStyled}
                RightIcon={CrossIconStyled}
                onChange={handleSetSearch}
                rightIconAction={() => setIsSearch(false)}
                value={search}
                placeholder={t("search")}
              />
            </Box>
          ) : (
            <Row
              p="0 0 16px 0 "
              justifyContent="space-between"
              alignItems="center"
            >
              <PageHeaderStyled title={t("antipad")} to={MAIN_ROUTE} />
              <HeaderSearchIconStyled onClick={() => setIsSearch(true)} />
            </Row>
          )}

          <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
            <ProjectsWrapper ref={projectRef} key={"vxcklvjsdf"}>
              <ProjectsComponent
                particulars={hasParticular}
                onClickCb={handleSetScrollPosition}
                tickets={tickets}
                loading={loading}
                detailed={detailed}
                projects={activeProjectsFiltered}
                title={t("activeAllocation").toUpperCase()}
              />
              <Box m="16px 0 0 0">
                <ProjectsComponent
                  particulars={hasParticular}
                  onClickCb={handleSetScrollPosition}
                  tickets={tickets}
                  loading={loading}
                  detailed={detailed}
                  projects={completedProjectsFiltered}
                  title={t("closedAllocation").toUpperCase()}
                />
              </Box>
            </ProjectsWrapper>
          </ContentWrapper>
        </WrapperContent>

        <ButtonWrapperForMany
          notView={!usdtStakingButton && !viewSetAddresses}
          ref={navigationRef}
          height="fit-content"
        >
          <ButtonWrapper
            isEng={isEng}
            both={usdtStakingButton && viewSetAddresses}
          >
            {usdtStakingButton ? (
              <Box
                m={
                  usdtStakingButton && viewSetAddresses && !isEng
                    ? "0 0 8px 0"
                    : "0"
                }
              >
                <ButtonStyled
                  activeButton
                  emoji="💼️"
                  title={t("stakeUsdt")}
                  onClick={() => {
                    handleRoute(MDAO_MAKER_STAKING_ROUTE);
                  }}
                />
              </Box>
            ) : null}
            {viewSetAddresses ? (
              <Button
                activeButton
                emoji="💎️"
                title={t("setProjectAddressButton")}
                onClick={() => {
                  setFromRouteUrl(MAKER_ROUTE);
                  handleRoute(
                    `${MDAO_MAKER_PROJECT_ADDRESS}/${firstProjectWithDropWalletRequired?.projectId}`
                  );
                }}
              />
            ) : null}
          </ButtonWrapper>
        </ButtonWrapperForMany>
      </Wrapper>
    </PageLoader>
  );
};
