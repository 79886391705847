import { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NONE, PROCESSING, REFRESH_TIME_KYC_STATUS_MS } from "../config";

const useCheckKeyStatus = () => {
  const dispatch = useDispatch();

  const kysSuccess = useRef(false);

  const walletDispatch = dispatch.wallet;

  const { isKyc } = useSelector(({ wallet }) => wallet);

  useEffect(() => {
    let loop;

    // if (isKyc === NONE && !kysSuccess.current) {
    //   walletDispatch.getKycStatus();
    //   return;
    // }

    if (isKyc === PROCESSING && !kysSuccess.current) {
      loop = setInterval(() => {
        walletDispatch.getKycStatus();
      }, REFRESH_TIME_KYC_STATUS_MS);
    }

    if (isKyc !== PROCESSING) {
      kysSuccess.current = true;
      clearInterval(loop);
    }

    return () => {
      clearInterval(loop);
    };
  }, [isKyc, walletDispatch]);
};

export default useCheckKeyStatus;
