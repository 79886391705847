import React, { useEffect, useMemo, useState } from "react";
import { Box } from "../../UI";
import { FAAppPage } from "./2FA-App-Page";
import { FASetCode } from "./2FASetCode";
import { FAConfirm } from "./2FA-Confirm";
import { useDispatch, useSelector } from "react-redux";
import { SETTINGS_ROUTE } from "../../routes/routes";
import { useSnackbar, useTranslate } from "../../contexts";
import { PageLoader } from "../../components";
import { useGetRouter } from "../../hooks";

const StepComponents = {
  0: FAAppPage,
  1: FASetCode,
  2: FAConfirm,
};

export const FaScreen = () => {
  const [secret, setSecret] = useState("");
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");
  const dispatch = useDispatch();
  const { showInfo, showSuccess, showError, showErrorCode } = useSnackbar();
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();
  const { user } = useSelector(({ wallet }) => wallet);
  const FA = useMemo(() => user?.tfaStatus, [user]);
  const [step, setStep] = useState(FA ? 2 : 0);

  const handleNextStep = () => setStep((prev) => prev + 1);

  const handleSetCode = (value) => {
    setCode(value);
  };

  const handleBack = () => {
    if (step > 0) {
      setStep((prev) => prev - 1);
    }
  };

  useEffect(() => {
    setStep(FA ? 2 : 0);
  }, [FA]);

  const handleEnableConfirm = () => {
    if (!code || code?.length < 6 || code?.length > 6) {
      showError(t("notificationTexts.validationError"));
      return;
    }
    setLoading(true);

    walletDispatch
      .enable2FA({ code })
      .then((res) => {
        if (res.data.result) {
          walletDispatch.setFA(true);
          showSuccess(t("notificationTexts.successTFAEnable"));
          setLoading(false);

          handleRoute(SETTINGS_ROUTE);
        }
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleDisableConfirm = () => {
    setLoading(true);
    walletDispatch
      .disable2FA({ code })
      .then((res) => {
        if (res.data.result) {
          walletDispatch.setFA(false);
          showSuccess(t("notificationTexts.successTFADisable"));
          setLoading(false);
          handleRoute(SETTINGS_ROUTE);
        }
      })
      .catch((e) => {
        showErrorCode(e);
        setLoading(false);
      });
  };

  const walletDispatch = dispatch.wallet;

  useEffect(() => {
    if (FA === false && step === 1) {
      walletDispatch.get2FA().then((res) => {
        setSecret(res?.data?.result);
      });
    }
  }, [FA, step]);

  const action = FA ? handleDisableConfirm : handleEnableConfirm;

  const StepComponent = StepComponents[step] || StepComponents[0];
  return (
    <PageLoader loading={loading || !user}>
      {user ? (
        <StepComponent
          onBack={handleBack}
          onNext={handleNextStep}
          onSetCode={handleSetCode}
          onConfirm={action}
          fa={FA}
          stepsLength={3}
          activeStep={step}
          code={secret}
        />
      ) : (
        <Box height="90vh"></Box>
      )}
    </PageLoader>
  );
};
