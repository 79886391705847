import styled from "styled-components";

export const Spinner = styled.div`
  border: ${({ theme }) => `5px solid ${theme.colors.hint}`};
  border-top: ${({ theme }) => `5px solid ${theme.colors.link} `};
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
