import React from "react";
import { Box, Link, Row, Typography } from "../UI";
import { useTranslate } from "../contexts";
import { useDispatch, useSelector } from "react-redux";
import { LinkComponent } from "./Link/Link";

export const KYCBlock = ({ onlyTitle }) => {
  const { t } = useTranslate();
  const { isKyc } = useSelector(({ wallet }) => wallet);
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;

  const kycTranslateStatus =
    {
      processing: t("pending"),
      success: t("passed"),
      none: t("notPassed"),
    }[isKyc] || t("notPassed");

  const colorsKycStatus =
    {
      processing: "yellow",
      success: "success",
    }[isKyc] || "red";

  const kycIndex = {
    success: 1,
    processing: 2,
    none: 3,
  };

  const indexKyc = {
    1: "success",
    2: "processing",
    3: "none",
  };

  // const handleChangeKyc = () => {
  //   const index = kycIndex[isKyc] || 1;
  //   walletDispatch.setKyc(indexKyc[index + 1] || indexKyc[1]);
  // };

  return (
    <Box width="100%">
      <Row
        width="100%"
        justifyContent="space-between"
        // onClick={() => handleChangeKyc()}
      >
        <Typography variant={"h2"}>
          {t("yourKYCStatus").toUpperCase()}
        </Typography>
        <Typography variant={"h2"} color={colorsKycStatus}>
          &nbsp;{kycTranslateStatus.toUpperCase()}
        </Typography>
      </Row>
      {!onlyTitle && isKyc !== "success" && (
        <Box>
          <Typography m="16px 0 8px 0 " variant="link">
            {t("checkPayInRub")}
          </Typography>
          <LinkComponent
            to={t("guides.linkReadGuide")}
            title={t("kycComplianceGuide")}
          />
        </Box>
      )}
    </Box>
  );
};
