import {
  MenuPage,
  WalletPage,
  ComingSoonPage,
  DepositPage,
  AddTokenPage,
  WithdrawPage,
  SettingsPage,
  BlockedPage,
  SessionExpiredPage,
  FaScreen,
  CardsPageNew,
  CardInfoPage,
  InfoAndFeesPage,
  MakerMainPage,
  BuyUSDTKYCPage,
  BuyUSDTPage,
  PassKycPage,
  MakerBuyTokensPage,
  MakerBuyTicketsPage,
  StakeMdaoPage,
  MdaoStakingPage,
  MakerTopupStakePage,
  SelectOrIssueCardPage,
  SelectCardsPage,
  FaqCardsPage,
  SupportPage,
  InviteFriendsPage,
  NearAddressPage,
} from "../pages";
import { FACheckPage } from "../pages/2FA/2FA-Check-Page";
import { TopUpPage } from "../pages/Topup/Topup-Page";
import { CreateChooseCardPage, CreateCardPage } from "../pages";
import { NeedToKnowPage } from "../pages/NeedToKnow/NeedToKnow-Page";
import { FaqPage } from "../pages/FAQ/FAQ-Page";
import { TransactionsHistoryPage } from "../pages/TransactionHistory/TransactionsHistory";
import { MakerProjectPage } from "../pages/Maker/MakerProject-Page";
import { MakerBuyAllocationPage } from "../pages/Maker/MakerBuyAllocation-Page";
import { MaintenancePage } from "../pages";

const bot = window.Telegram?.WebApp;

export const MAIN_ROUTE = "/";
export const SESSION_EXPIRED_ROUTE = "session-expired";
export const BLOCKED_ROUTE = "blocked";
export const FA_APP_ROUTE = "2fa";
export const SETTINGS_ROUTE = "settings";
export const FA_CHECK_ROUTE = "2fa-check";
export const PAY_FOR_SERVICES_ROUTE = "pay-for-services";
export const BUY_USDT_ROUTE = "buy-usdt";

export const CARDS_ROUTE = "cards";
export const WALLET_ROUTE = "wallet";
export const MAKER_ROUTE = "launchpad";
export const MAINTENANCE_ROUTE = "maintenance";
export const AML_ROUTE = "aml";
export const ZAVOD_BUY_ITEM_ROUTE = "zavod_operations";
export const TOP_UP_PATH = "cards/card/top-up";
export const CARDS_ROUTE_WITH_PARAM = `${CARDS_ROUTE}/:card_id?`;
export const BUY_USDT_KYC_ROUTE = `${BUY_USDT_ROUTE}/kyc`;
export const BUY_USDT_BUY_ROUTE = `${BUY_USDT_ROUTE}/buy`;

export const blockedRoute = () => `/${BLOCKED_ROUTE}`;
export const payForServicesRoute = () => `/${PAY_FOR_SERVICES_ROUTE}`;

const namespaces = {
  wallet: WALLET_ROUTE,
  payForServices: PAY_FOR_SERVICES_ROUTE,
  buyUsdt: BUY_USDT_KYC_ROUTE,
  cards: CARDS_ROUTE_WITH_PARAM,
  AML: AML_ROUTE,
  mdaoMaker: MAKER_ROUTE,
};

export const FAQ_ROUTE = `${CARDS_ROUTE}/null/faq`;
export const WITHDRAW_ROUTE = `${namespaces.wallet}/withdraw`;
export const ZAVOD_BUY_ITEM_ROUTE_WITH_PARAM = `${ZAVOD_BUY_ITEM_ROUTE}/:itemId`;
export const ISSUE_CARD_CHOOSE_ROUTE = `${CARDS_ROUTE}/null/issue-card/choose`;
export const SELECT_OR_ISSUE_CARDS_ROUTE = `${CARDS_ROUTE}/null/select-or-issue`;
export const SELECT_OR_ISSUE_CHOOSE_CARDS_ROUTE_WITH_PARAMS = `${CARDS_ROUTE}/null/select-or-issue/choose/:bins/:active_products_ids?`;
export const SELECT_OR_ISSUE_CHOOSE_CARDS_ROUTE = `${CARDS_ROUTE}/null/select-or-issue/choose`;
export const ISSUE_CARD_CHOOSE_ROUTE_WITH_PARAM = `${CARDS_ROUTE}/null/issue-card/choose/:bins?/:product_id?`;
export const ISSUE_CARD_CREATE_ROUTE_WITH_PARAM = `${CARDS_ROUTE}/null/issue-card/create/:card_variant_id/:product_id?`;
export const ISSUE_CARD_CREATE_ROUTE = `${CARDS_ROUTE}/null/issue-card/create`;
export const INFO_AND_FEELS_ROUTE = `${CARDS_ROUTE}/null/info-and-feels`;
export const OPERATIONS_ROUTE = `${CARDS_ROUTE}/null/operations`;
export const DEPOSIT_ROUTE = `${namespaces.wallet}/deposit`;
export const DEPOSIT_ROUTE_WITH_PARAM = `${namespaces.wallet}/deposit/:from?`;
export const MDAO_MAKER_PROJECT_ROUTE_WITH_PARAM = `${namespaces.mdaoMaker}/project/:project_id`;
export const MDAO_MAKER_PROJECT_ROUTE = `${namespaces.mdaoMaker}/project`;
export const MDAO_MAKER_BUY_TOKENS_ROUTE_WITH_PARAM = `${namespaces.mdaoMaker}/buy-tokens/:project_id`;
export const MDAO_MAKER_BUY_TOKENS_ROUTE = `${namespaces.mdaoMaker}/buy-tokens`;
export const MDAO_MAKER_STAKE_ROUTE = `${namespaces.mdaoMaker}/stake-mdao`;
export const MDAO_MAKER_TOPUP_STAKE_ROUTE_WITH_PARAM = `${namespaces.mdaoMaker}/topup-stake/:index/:rule_id`;
export const MDAO_MAKER_TOPUP_STAKE_ROUTE = `${namespaces.mdaoMaker}/topup-stake`;
export const MDAO_MAKER_BUY_TICKETS_ROUTE = `${namespaces.mdaoMaker}/buy-tickets/:project_id`;
export const MDAO_MAKER_STAKING_ROUTE = `${namespaces.mdaoMaker}/staking`;
export const MDAO_MAKER_STAKING_ROUTE_WITH_PARAM = `${namespaces.mdaoMaker}/staking/:project_id?`;
export const MDAO_MAKER_BUY_ALLOCATION_ROUTE = `${namespaces.mdaoMaker}/buy-allocation`;
export const MDAO_MAKER_PROJECT_ADDRESS = `${namespaces.mdaoMaker}/set-address`;
export const MDAO_MAKER_PROJECT_ADDRESS_WITH_PARAMS = `${namespaces.mdaoMaker}/set-address/:project_id?`;
export const MDAO_MAKER_BUY_ALLOCATION_ROUTE_WITH_PARAM = `${namespaces.mdaoMaker}/buy-allocation/:project_id`;
export const CARDS_PAY_FOR_SERVICES_CHOOSE_CARD_ROUTE = `pay-for-services/choose-card`;
export const CARDS_PAY_FOR_SERVICES_CHOOSE_CARD_WITH_PARAM_ROUTE = `${CARDS_PAY_FOR_SERVICES_CHOOSE_CARD_ROUTE}/:product_id`;
export const CARDS_PAY_FOR_SERVICES_TOP_UP_ROUTE = `pay-for-services/top-up`;
export const CARDS_PAY_FOR_SERVICES_TOP_UP_WITH_PARAM_ROUTE = `${CARDS_PAY_FOR_SERVICES_TOP_UP_ROUTE}/:product_id/:card_id`;
export const CARD_INFO_WITH_PARAM_ROUTE = `${CARDS_ROUTE}/card/:card_id`;
export const CARD_INFO_ROUTE = `${CARDS_ROUTE}/card`;
export const NEED_TO_KNOW_ROUTE = `${INFO_AND_FEELS_ROUTE}/need-to-know`;
export const CARDS_PAY_FOR_SERVICES_CREATE_CARD_FROM_CHOOSE_CARD_WITH_PARAM_ROUTE = `pay-for-services/create-card/:product_id?/:card_variant_id?`;
export const CARDS_PAY_FOR_SERVICES_CREATE_CARD_ROUTE = `pay-for-services/create-card`;
export const PASS_KYC_ROUTE = `pass-kyc`;
export const BUY_AML_CREDITS_ROUTE = `${AML_ROUTE}/buy-credits`;
export const CHECK_AML_RESULT_ROUTE = `${AML_ROUTE}/result/:operation_id`;
export const CHECK_AML_ROUTE = `${AML_ROUTE}/check`;

export const toCreateCardPayForServicesFromChooseCard = (
  product_id,
  card_variant_id
) => `pay-for-services/create-card/${product_id}/${card_variant_id}`;
export const chooseCardFromServicesRoute = (productId) =>
  `${CARDS_PAY_FOR_SERVICES_CHOOSE_CARD_ROUTE}/${productId}`;
export const toCardInfo = (cardId) => `${CARD_INFO_ROUTE}/${cardId}`;
export const topUpFromServicesRoute = (productId, cardId) =>
  `${CARDS_PAY_FOR_SERVICES_TOP_UP_ROUTE}/${productId}/${cardId}`;
export const createCardFromServiceRoute = (productId) =>
  `${CARDS_PAY_FOR_SERVICES_CREATE_CARD_ROUTE}/${productId}`;
export const cardInfoRoute = (cardId) => `/${CARD_INFO_ROUTE}/${cardId}`;
export const topUpRoute = (cardId) => `/${TOP_UP_PATH}/${cardId}`;
export const createCardRoute = (variantCardId) =>
  `${ISSUE_CARD_CREATE_ROUTE}/${variantCardId}`;
export const mdaoMakerBuyTicketsRoute = (projectId) =>
  `${namespaces.mdaoMaker}/buy-tickets/${projectId}`;
export const mdaoMakerBuyTokensRoute = (projectId) =>
  `${MDAO_MAKER_BUY_TOKENS_ROUTE}/${projectId}`;
export const mdaoMakerToProjectRoute = (projectId) =>
  `${MDAO_MAKER_PROJECT_ROUTE}/${projectId}`;

export const linkRoutes = {
  addToken: `${namespaces.wallet}/add-token`,
  addTokenWithParam: `${namespaces.wallet}/add-token/:chain`,
};

export const userControls = [
  {
    titleKey: "wallet",
    icon: "💎",
    path: namespaces.wallet,
    component: WalletPage,
    screenName: "wallet",
  },
  {
    titleKey: "buyUsdt",
    icon: "💸",
    screenName: "buyUsdt",
    path: namespaces.buyUsdt,
    component: BuyUSDTKYCPage,
  },
  {
    titleKey: "virtualCardsAndServices",
    icon: "💳",
    screenName: "cards",
    path: namespaces.cards,
    pathRoute: CARDS_ROUTE,
    component: CardsPageNew,
  },
];

export const ZAVOD_ROUTE_COMPONENT = {
  titleKey: "capitalist",
  icon: "💰",
  path: "zavod",
  screenName: "capitalist",
  isTelegramLink: true,
  link: () => process.env.REACT_APP_GAME_LINK,
  callback: () => {
    bot?.close();
  },
};

export const mainControls = [
  ZAVOD_ROUTE_COMPONENT,
  {
    titleKey: "launchpad",
    icon: "🚀",
    screenName: "launchpad",
    // blocked: true,
    path: namespaces.mdaoMaker,
    component: MakerMainPage,
  },
  {
    titleKey: "inviteFriends",
    icon: "🕺",
    screenName: "friends",
    path: "invite-friends",
    component: InviteFriendsPage,
  },
  {
    titleKey: "faq",
    icon: "💡",
    screenName: "faq",
    path: "faq",
    component: FaqPage,
  },
  {
    titleKey: "support",
    icon: "💬",
    screenName: "support",

    path: "support",
    component: SupportPage,
  },
  {
    titleKey: "settings",
    icon: "⚙️",
    screenName: "settings",

    path: SETTINGS_ROUTE,
    component: SettingsPage,
  },
];

export const faqControls = [
  {
    title: "faq",
    icon: "💡",
    path: "faq",
    component: ComingSoonPage("F.A.Q."),
  },
  {
    titleKey: "support",
    icon: "💬",
    path: "support",
    component: ComingSoonPage("Support"),
  },
];

export const settings = {
  titleKey: "settings",
  icon: "⚙️",
  path: SETTINGS_ROUTE,
  component: SettingsPage,
};

const FAQCards = {
  path: FAQ_ROUTE,
  component: FaqCardsPage,
};

export const walletControls = [
  {
    actionButton: true,
    titleKey: "deposit",
    icon: "📥",
    path: DEPOSIT_ROUTE_WITH_PARAM,
    pathRoute: DEPOSIT_ROUTE,
    component: DepositPage,
  },
  {
    actionButton: true,
    titleKey: "withdraw",
    icon: "📤",
    path: WITHDRAW_ROUTE,
    component: WithdrawPage,
  },
];

export const CreateChooseCard = {
  path: ISSUE_CARD_CHOOSE_ROUTE_WITH_PARAM,
  component: CreateChooseCardPage,
};

const CardInfo = {
  path: CARD_INFO_WITH_PARAM_ROUTE,
  component: CardInfoPage,
};

const TopUp = {
  path: `${TOP_UP_PATH}/:card_id/:product_id?`,
  component: TopUpPage,
};

const BuyUsdt = {
  path: BUY_USDT_BUY_ROUTE,
  component: BuyUSDTPage,
};

const CreateCard = {
  path: ISSUE_CARD_CREATE_ROUTE_WITH_PARAM,
  component: CreateCardPage,
};

const InfoAndFees = {
  path: INFO_AND_FEELS_ROUTE,
  component: InfoAndFeesPage,
};

const Maintenance = {
  path: MAINTENANCE_ROUTE,
  component: MaintenancePage,
};

const Operations = {
  path: OPERATIONS_ROUTE,
  component: TransactionsHistoryPage,
};

const MdaoMakerProjectPage = {
  path: MDAO_MAKER_PROJECT_ROUTE_WITH_PARAM,
  component: MakerProjectPage,
};
const MdaoMakerBuyTokensPage = {
  path: MDAO_MAKER_BUY_TOKENS_ROUTE_WITH_PARAM,
  component: MakerBuyTokensPage,
};

const MdaoMakerStakePage = {
  path: MDAO_MAKER_STAKE_ROUTE,
  component: StakeMdaoPage,
};

const MdaoMakerTopupStakePage = {
  path: MDAO_MAKER_TOPUP_STAKE_ROUTE_WITH_PARAM,
  component: MakerTopupStakePage,
};
const MdaoMakerBuyTicketsPage = {
  path: MDAO_MAKER_BUY_TICKETS_ROUTE,
  component: MakerBuyTicketsPage,
};
const MdaoMakerStakingPage = {
  path: MDAO_MAKER_STAKING_ROUTE_WITH_PARAM,
  component: MdaoStakingPage,
};
const MdaoMakerBuyAllocationPage = {
  path: MDAO_MAKER_BUY_ALLOCATION_ROUTE_WITH_PARAM,
  component: MakerBuyAllocationPage,
};

const SelectOrIssueCardPageComponent = {
  path: SELECT_OR_ISSUE_CARDS_ROUTE,
  component: SelectOrIssueCardPage,
};

const SelectOrIssueChooseCardPageComponent = {
  path: SELECT_OR_ISSUE_CHOOSE_CARDS_ROUTE_WITH_PARAMS,
  component: SelectCardsPage,
};

const PayForServicesTopUp = {
  path: CARDS_PAY_FOR_SERVICES_TOP_UP_WITH_PARAM_ROUTE,
  component: TopUpPage,
};

const CreateCardRoute = {
  path: CARDS_PAY_FOR_SERVICES_CREATE_CARD_FROM_CHOOSE_CARD_WITH_PARAM_ROUTE,
  component: CreateCardPage,
};

const PassKycRoute = {
  path: PASS_KYC_ROUTE,
  component: PassKycPage,
};

const NeedToKnow = {
  path: NEED_TO_KNOW_ROUTE,
  component: NeedToKnowPage,
};
const NearAddressRoute = {
  path: MDAO_MAKER_PROJECT_ADDRESS_WITH_PARAMS,
  component: NearAddressPage,
};

export const routes = [
  ...userControls,
  ...mainControls,
  ...faqControls,
  ...walletControls,
  settings,
  Operations,
  CardInfo,
  TopUp,
  Maintenance,
  CreateCard,
  InfoAndFees,
  NeedToKnow,
  FAQCards,
  NearAddressRoute,
  MdaoMakerProjectPage,
  MdaoMakerBuyTokensPage,
  SelectOrIssueChooseCardPageComponent,
  PayForServicesTopUp,
  MdaoMakerStakePage,
  MdaoMakerBuyTicketsPage,
  MdaoMakerStakingPage,
  MdaoMakerTopupStakePage,
  MdaoMakerBuyAllocationPage,
  SelectOrIssueCardPageComponent,
  CreateCardRoute,
  BuyUsdt,
  PassKycRoute,
  CreateChooseCard,
  { path: BLOCKED_ROUTE, component: BlockedPage },
  { path: FA_APP_ROUTE, component: FaScreen },
  { path: FA_CHECK_ROUTE, component: FACheckPage },
  { path: SESSION_EXPIRED_ROUTE, component: SessionExpiredPage },
  { path: linkRoutes.addTokenWithParam, component: AddTokenPage },
  { path: "/", exact: true, component: MenuPage },
];
