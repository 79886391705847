import React from "react";
import { Box, Paper, Row, Typography } from "../../UI";
import { ReactComponent as UKIcon } from "../../assets/svg/uk.svg";
import { ReactComponent as HongKongIcon } from "../../assets/svg/flag.svg";
import { ReactComponent as MastercardIcon } from "../../assets/svg/mastercard.svg";
import { ReactComponent as VisaIcon } from "../../assets/svg/visa.svg";
import { useTranslate } from "../../contexts";
import styled from "styled-components";
import { NavLink, useLocation } from "react-router-dom";
import { OPERATIONS_ROUTE, toCardInfo } from "../../routes/routes";
import { useSelector } from "react-redux";
import { lastFourCardNumbers } from "../../utils/formatting";
import { CARD_STATUTES } from "../../config";

const PaperCardStyled = styled(Paper)`
  border-radius: 8px;
  width: 79px;
  height: 38px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const CardIcon = ({ bin, country, scheme }) => {
  const { templates } = useSelector(({ cards }) => cards);

  const CardTypeIcon =
    {
      MasterCard: MastercardIcon,
      Visa: VisaIcon,
    }[scheme] || MastercardIcon;

  const Flag =
    {
      UK: UKIcon,
      ["Hong Kong"]: HongKongIcon,
    }[country] || UKIcon;

  return (
    <PaperCardStyled bgColor="success" p="2px 8px">
      <Typography variant="link">{bin}</Typography>
      <Row justifyContent="space-between">
        <Flag />
        <Box m="0 0 0 8px">
          <CardTypeIcon />
        </Box>
      </Row>
    </PaperCardStyled>
  );
};

const PaperStyled = styled(Paper)`
  box-sizing: border-box;
  border: ${({ closed, theme }) =>
    closed ? `1px solid ${theme.colors.red}` : "none"};
`;

const LinkStyled = styled(NavLink)`
  margin-bottom: 8px;
  &:last-child {
    margin-bottom: 0;
  }
`;

const FrozenBlock = styled(Box)`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;

const CardInfoBlock = styled(Box)`
  position: relative;
  width: 100%;
`;

const EmojiFrozen = styled(Typography)`
  font-size: 24px;
`;
export const Card = ({
  className,
  closed,
  title,
  subTitle,
  IconComponent,
  bin,
  cardNumber,
  frozen,
}) => {
  const { schemes } = useSelector(({ cards }) => cards);
  const { scheme, country } = schemes[bin] || {};

  return (
    <PaperStyled className={className} closed={closed}>
      <Row alignItems="center">
        <IconComponent
          bin={bin}
          scheme={scheme}
          country={country}
          lastCardNumbers={cardNumber?.toString()?.slice(12, 16)}
        />
        <CardInfoBlock m="0 0 0 8px">
          <Typography variant="link">{title}</Typography>
          <Typography m="4px 0 0 0" variant="link" color="hint">
            {subTitle && typeof subTitle === "function"
              ? subTitle(scheme)
              : subTitle}
          </Typography>
          {frozen && (
            <FrozenBlock>
              <EmojiFrozen variant="h2">❄️</EmojiFrozen>
            </FrozenBlock>
          )}
        </CardInfoBlock>
      </Row>
    </PaperStyled>
  );
};

const Wrapper = styled(Box)`
  overflow-y: scroll;
`;

export const Cards = ({ cards, withRef, height }) => {
  const { t } = useTranslate();
  const { pathname } = useLocation();

  const filteredCards = cards?.filter(
    (el) => el.status !== CARD_STATUTES.CLOSED
  );

  return (
    <Box ref={withRef}>
      <Row justifyContent="space-between">
        <Typography m="0 0 8px 0" variant="h2">
          {t("myCards").toUpperCase()}
        </Typography>
        <NavLink to={OPERATIONS_ROUTE}>
          <Typography m="0 0 8px 0" variant="link" color="link">
            {t("operationsHistory")}
          </Typography>
        </NavLink>
      </Row>
      <Wrapper height={`${height}px`}>
        <Box>
          {filteredCards?.length ? (
            filteredCards?.map((card, i) => {
              const cardFrozen = card.status === CARD_STATUTES.INACTIVE;
              return (
                <LinkStyled
                  key={i}
                  to={toCardInfo(card.cardId)}
                  pathname={pathname}
                >
                  <Card
                    key={i}
                    frozen={cardFrozen}
                    cardNumber={card.cardNumber}
                    bin={card.binId}
                    IconComponent={CardIcon}
                    subTitle={(scheme) =>
                      `Debit ${scheme} ... ${lastFourCardNumbers(
                        card.maskNumber
                      )}`
                    }
                    title={`$${card.balance}`}
                  />
                </LinkStyled>
              );
            })
          ) : (
            <Typography ta="center">{t("noCard")}</Typography>
          )}
        </Box>
      </Wrapper>
    </Box>
  );
};
