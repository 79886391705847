import { motion } from "framer-motion";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Skeleton from "react-loading-skeleton";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Box, CARD_COLORS, Row, Typography, WHITE_FIELD_NAME } from "../../UI";
import { Button, CopyField, PageHeader, PageLoader } from "../../components";
import { CardsSlider } from "../../components/CardsSlider/CardsSlider";
import { CONFIRM_MODAL } from "../../components/Modals";
import { CARD_STATUTES, SUCCESS } from "../../config";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { useModal, useSnackbar, useTranslate, useWallet } from "../../contexts";
import { useGetRouter } from "../../hooks";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import {
  FAQ_ROUTE,
  INFO_AND_FEELS_ROUTE,
  MAIN_ROUTE,
  topUpRoute,
} from "../../routes/routes";
import { convertCardNumberSpace } from "../../utils/formatting";
import { setUpdateCardsList } from "../../utils/local-storage-utils";
import { getProductsByBin } from "../SelectOrIssueCard/helpers";
import { MenuBlock } from "../Wallet/styled";
import { OperationsSection } from "./Operations";
import { TransactionsSection } from "./Transactions";
import {
  CardsWrapper,
  ChooseByServicesWrapper,
  ContentWrapper,
  DetailsContentScroll,
  DetailsWrapper,
  SwitcherStyled,
  TypographyListStyled,
} from "./styled";

const EmptyComponent = () => {
  return <></>;
};

const TRANSACTIONS_SECTION_NAME = "transactions";
const OPERATIONS_SECTION_NAME = "operations";

const variantsOperation = (t) => [
  { id: 0, title: t("operations"), sectionName: OPERATIONS_SECTION_NAME },
  { id: 1, title: t("transactions"), sectionName: TRANSACTIONS_SECTION_NAME },
];

export const CardsPageNew = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const { handleRoute } = useGetRouter();

  const cardsDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;

  const { schemes, products, cards } = useSelector(({ cards }) => cards);
  const { user } = useSelector(({ wallet }) => wallet);
  const { open } = useModal();
  const { card_id: queryCardId } = useParams();
  const { cardsLoaded } = useWallet();
  const [showDetails, setShowDetails] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);

  const [transactionItems, setTransactionItems] = useState([]);
  const [operationsList, setOperationsList] = useState([]);
  const [layerShowDetails, setLayerShowDetails] = useState(false);
  const [loadingOperations, setLoadingOperations] = useState(false);
  const [contentBlock, setContentBlock] = useState("closed");
  const [switcherBlock, setSwitcherBlock] = useState("closed");
  const { showError, showSuccess } = useSnackbar();
  const [activeCardId, setActiveCardId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingCard, setLoadingCard] = useState(false);
  const [cardsLoading, setCardsLoading] = useState(false);

  const [activeOperation, setActiveOperation] = useState(
    variantsOperation(t)?.[0]
  );
  const [cardInfo, setCardInfo] = useState(null);

  const TFA = user?.tfaStatus;
  const [handleTFAFunction, setHandleTFAFunction] = useState(null);
  const [showTFA, setShowTFA] = useState(false);
  const contentRef = useRef();
  const prevCardId = useRef(null);
  const navigationRef = useRef();
  const { contentHeight } = useGetMainContentHeight(contentRef, navigationRef, {
    minusIndent: 0,
  });
  const [errorLoadCard, setErrorLoadCard] = useState(false);

  useEffect(() => {
    walletDispatch.getKycStatus();
  }, []);

  const currentCard = cards?.find((card) => card.cardId === activeCardId);

  const { status } = currentCard || {};
  const { cardId, binId } = cardInfo || currentCard || {};

  const cardFrozen = status === CARD_STATUTES.INACTIVE;
  const cardClosed = status === CARD_STATUTES.CLOSED;
  const freezedCard = status === CARD_STATUTES.INACTIVE;
  const { billingAddress, active } = schemes?.[binId] || {};
  const productsByBin = useMemo(() => getProductsByBin(products), [products]);
  const currentProductByBin = productsByBin[binId];

  const handleRefetchOperations = () => {
    setOperationsList([]);
    setTransactionItems([]);
  };

  const handleSetActiveCardId = (cardId) => {
    handleRefetchOperations();
    setCardInfo(null);
    setShowDetails(false);
    setActiveCardId(cardId);
  };

  useEffect(() => {
    if (queryCardId) {
      setActiveCardId(queryCardId);
    }
  }, [queryCardId]);

  const handleGetCard = async (cardId) => {
    await cardsDispatch
      .getCard({
        cardId: cardId,
        cache: false,
      })
      .then((res) => {
        setCardInfo(res.data.result);

        setErrorLoadCard(false);
      })
      .catch((e) => {
        handleRefetchOperations();
        showError(e.response.data.message);
        setErrorLoadCard(true);
      })
      .finally(() => {
        prevCardId.current = activeCardId;
        setLoading(false);
        setLoadingCard(false);
      });
  };

  useEffect(() => {
    (async () => {
      if (activeCardId && showDetails) {
        setLoading(true);
        setLoadingCard(true);
        setCardInfo(null);

        handleGetCard(activeCardId);
      }
    })();

    if (!showDetails && cardInfo) {
      setCardInfo(null);
    }
  }, [cardsDispatch, activeCardId, showDetails]);

  const handleCloseCard = async (code) => {
    setLoading(true);
    return cardsDispatch
      .closeCard({ cardId, code })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          showSuccess(t("cardClosed"));
          setCardInfo((prev) => ({ ...prev, ...res.data.result }));
          cardsDispatch.setCards(
            cards?.map((el) => {
              if (String(el.cardId) === String(cardId)) {
                return res.data.result;
              }

              return el;
            })
          );
          setUpdateCardsList();
        }
        setLoading(false);
        return res;
      })
      .catch((e) => showError(e.response.data.message))
      .finally(() => setLoading(false));
  };

  const handleFreezeCard = async (code) => {
    setLoading(true);
    if (freezedCard) {
      return cardsDispatch
        .unfreezeCard({ cardId, code })
        .then((res) => {
          if (res?.data.status === SUCCESS) {
            setCardInfo((prev) => ({ ...prev, ...res.data?.result }));
            cardsDispatch.setCards(
              cards?.map((el) => {
                if (String(el.cardId) === String(cardId)) {
                  return res.data.result;
                }

                return el;
              })
            );
            showSuccess(t("cardSuccessfullyUnFrozen"));
            handleGetOperations(0, false);
            setUpdateCardsList();
          }
          return res;
        })
        .catch((e) => showError(e.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    } else {
      return cardsDispatch
        .freezeCard({ cardId, code })
        .then((res) => {
          if (res?.data.status === SUCCESS) {
            setCardInfo((prev) => ({
              ...prev,
              ...res.data?.result,
            }));
            showSuccess(t("cardSuccessfullyFrozen"));
            handleGetOperations(0, false);
            setUpdateCardsList();
            return res;
          }
        })
        .catch((e) => showError(e.response.data.message))
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const handleConfirmDelete = () => {
    open(CONFIRM_MODAL, {
      title: `🗑  ${t("modalTexts.terminateCardTitle")}️`,
      description: t("modalTexts.terminateCardDescription"),
      buttons: [
        {
          title: t("modalTexts.terminate"),
          color: "red",
          isClose: true,
          onClick: () => {
            handleConfirmTFA(handleCloseCard);
          },
        },
        {
          title: t("modalTexts.cancel"),
          isClose: true,
          color: "success",
        },
      ],
    });
  };

  const handleConfirmTFA = async (cb) => {
    if (TFA) {
      setHandleTFAFunction(() => cb);
      handleRefetchOperations();
      setShowTFA(true);
    } else {
      cb();
    }
  };

  const handleGetTransactions = (card_id) => {
    setLoadingOperations(true);
    cardsDispatch
      .getCardTransactions({ cardId: card_id })
      .then((res) => {
        setTransactionItems(res?.data?.result);
      })
      .catch((e) => showError(e))
      .finally(() => setLoadingOperations(false));
  };

  const handleGetOperations = useCallback(
    (offset, cache = true) => {
      if (!cardId) return Promise.resolve();

      setLoadingOperations(true);
      return cardsDispatch
        .getOperations({ offset, cardId, cache: cache })
        .then((res) => {
          setOperationsList((prev) => [...prev, ...res.data.result]);
        })
        .catch((e) => {
          showError(e?.response?.data?.message);
        })
        .finally(() => {
          setLoadingOperations(false);
        });
    },
    [cardsDispatch, cardId]
  );

  const handleConfirmFreeze = () => {
    open(CONFIRM_MODAL, {
      title: `${cardFrozen ? "☀️" : "❄️"}${
        cardFrozen ? t("unfreezeCard") : t("freezeCard")
      }️`,
      description: t("modalTexts.areYouSure"),
      buttons: [
        {
          title: cardFrozen ? t("modalTexts.unfreeze") : t("modalTexts.freeze"),
          color: "red",
          isClose: true,
          onClick: async () => {
            // const card = cards.find( // FOR DEBUGG FREEZING
            //   (el) => String(el.cardId) === String(cardId)
            // );
            // card.status = CARD_STATUTES.INACTIVE;
            //
            // setCardInfo((prev) => ({
            //   ...prev,
            //   ...card,
            // }));
            // cardsDispatch.setCards(
            //   cards?.map((el) => {
            //     if (String(el.cardId) === String(cardId)) {
            //       return card;
            //     }
            //
            //     return el;
            //   })
            // );

            await handleConfirmTFA(handleFreezeCard);
            // await handleGetCard(cardId);
          },
        },
        {
          title: t("modalTexts.cancel"),
          isClose: true,
          color: "success",
        },
      ],
    });
  };

  const handleSetActiveOperation = (value) => {
    setActiveOperation(value);
  };

  useEffect(() => {
    const item = localStorage.getItem("saveCardPosition");

    if (item) {
      setActiveCardId(item);
      localStorage.removeItem("saveCardPosition");
    }
  }, []);

  const actions = useMemo(
    () => [
      {
        title: t("topUp"),
        emoji: "📥",
        disable: !active || errorLoadCard || !cardId,
        action: (cardId) => {
          localStorage.setItem("saveCardPosition", cardId);
          handleRoute(topUpRoute(cardId));
        },
      },
      {
        title: cardFrozen ? t("modalTexts.unfreeze") : t("modalTexts.freeze"),
        emoji: cardFrozen ? "☀️" : "❄️",
        action: handleConfirmFreeze,
        disable: errorLoadCard || !cardId,
      },
      {
        title: t("modalTexts.terminate"),
        emoji: "❌",
        action: handleConfirmDelete,
        disable: errorLoadCard || !cardId,
      },
    ],
    [cardId, active, errorLoadCard, cardFrozen]
  );

  const contentBlockAnimation = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: [1, 0], y: "10%" },
  };

  useEffect(() => {
    setCardsLoading(true);
    cardsDispatch.getCards({ cache: false }).finally(() => {
      setCardsLoading(false);
    });
  }, [cardsDispatch]);

  useEffect(() => {
    setSwitcherBlock("open");
  }, []);

  useEffect(() => {
    if (showDetails) {
      setSwitcherBlock("closed");

      setTimeout(() => {
        setContentBlock("open");
      }, 400);
    } else {
      setContentBlock("closed");
      setTimeout(() => {
        setSwitcherBlock("open");
      }, 400);
    }
  }, [showDetails]);

  useEffect(() => {
    if (showDetails) {
      setTimeout(() => {
        setLayerShowDetails(true);
      }, 400);
    } else {
      setTimeout(() => {
        setLayerShowDetails(false);
      }, 400);
    }
  }, [showDetails]);

  useEffect(() => {
    if (cardId) {
      if (activeOperation.sectionName === TRANSACTIONS_SECTION_NAME) {
        handleGetTransactions(cardId);
      }
    }
  }, [cardId, activeOperation]);

  const SectionComponent =
    {
      [TRANSACTIONS_SECTION_NAME]: TransactionsSection,
      [OPERATIONS_SECTION_NAME]: OperationsSection,
    }[activeOperation.sectionName] || EmptyComponent;

  const operationsRef = useRef(null);

  const [operationsHeight, setOperationsHeight] = useState(0);

  useEffect(() => {
    setTimeout(() => {
      if (contentHeight && operationsRef.current) {
        const operationSizes = operationsRef.current.getBoundingClientRect();

        setOperationsHeight(contentHeight - operationSizes.top + 54);
      }
    }, 400);
  }, [
    contentHeight,
    layerShowDetails,
    showDetails,
    loadingOperations,
    operationsRef.current,
  ]);

  let color = null;

  const sorted = (cards ? [...cards] : [])?.sort(
    (a, b) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime()
  );

  const activeCards = useMemo(
    () =>
      sorted
        ?.filter((card) =>
          process.env.REACT_APP_NODE_ENV === "development"
            ? true
            : card.status !== CARD_STATUTES.CLOSED
        )
        ?.map((card, i) => {
          if (color < CARD_COLORS?.length - 1) {
            if (color === null) {
              color = 0;
            } else {
              color++;
            }
          } else {
            color = 0;
          }

          return { ...card, id: i, color };
        }),
    [sorted]
  );

  const contentTitle = useMemo(() => {
    return activeOperation.sectionName === TRANSACTIONS_SECTION_NAME
      ? t("transactionsListEmpty")
      : t("operationsListEmpty");
  }, [activeOperation, t]);

  const minHeightContentOperation = useMemo(() => "125px", []);

  const heightContentOperation = useMemo(
    () => `${operationsHeight}px`,
    [operationsHeight]
  );
  const [cardsInited, setCardsInited] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setCardsInited(true);
    }, 450);
  }, []);

  const sliderBlock = useRef(null);

  return (
    <PageLoader loading={loading}>
      <TFACheckContainer
        show={showTFA}
        loading={loading}
        setShow={setShowTFA}
        onSuccess={handleTFAFunction}
      >
        <CardsWrapper>
          <Box>
            <PageHeader
              title={t("cards")}
              to={MAIN_ROUTE}
              disableRoute={showDetails}
              cb={() => {
                if (showDetails) {
                  setShowDetails(false);
                }
              }}
            />
            <ContentWrapper
              m="15px 0 0 0"
              ref={contentRef}
              height={contentHeight + "px"}
            >
              <Box ref={sliderBlock}>
                <CardsSlider
                  cardsInited={cardsInited}
                  cardsLoaded={cardsLoaded && !cardsLoading}
                  activeCardId={activeCardId}
                  showDetails={showDetails}
                  setShowDetails={setShowDetails}
                  showTFA={showTFA}
                  activeCardData={cardInfo}
                  actions={actions}
                  loadingCard={loadingCard}
                  schemes={schemes}
                  setActiveCardId={handleSetActiveCardId}
                  cards={activeCards}
                  setIsCreateMode={setIsCreateMode}
                />
              </Box>
              {isCreateMode || !cardId ? null : layerShowDetails ? (
                <motion.div
                  transition={{ easy: "linear" }}
                  variants={contentBlockAnimation}
                  initial={{ y: "10%" }}
                  animate={contentBlock}
                >
                  <DetailsWrapper
                    height={
                      contentHeight - sliderBlock.current?.offsetHeight + "px"
                    }
                  >
                    <DetailsContentScroll m="0px 0 0 0">
                      <Box>
                        {cardInfo ? (
                          <CopyField
                            variant="small"
                            title={t("cardNumber")}
                            notTransform
                            value={
                              convertCardNumberSpace(
                                cardInfo?.sensitive?.number
                              ) || "..."
                            }
                            copyValue={cardInfo?.sensitive?.number}
                          />
                        ) : (
                          <Skeleton
                            count={1}
                            height="54px"
                            borderRadius="12px"
                          />
                        )}
                        <Row m="8px 0 8px 0">
                          <Box m="0 8px 0 0" width="50%">
                            {cardInfo ? (
                              <CopyField
                                variant="small"
                                title={t("expirationDate")}
                                notTransform
                                copyValue={`${cardInfo?.sensitive?.expiryMonth}/${cardInfo?.sensitive?.expiryYear}`}
                                value={
                                  `${cardInfo?.sensitive?.expiryMonth}/${cardInfo?.sensitive?.expiryYear}` ||
                                  "..."
                                }
                              />
                            ) : (
                              <Skeleton
                                count={1}
                                height="54px"
                                borderRadius="12px"
                              />
                            )}
                          </Box>
                          <Box width="50%">
                            {cardInfo ? (
                              <CopyField
                                variant="small"
                                title="CVV"
                                notTransform
                                copyValue={cardInfo?.sensitive?.cvv}
                                value={cardInfo?.sensitive?.cvv}
                              />
                            ) : (
                              <Skeleton
                                count={1}
                                height="54px"
                                borderRadius="12px"
                              />
                            )}
                          </Box>
                        </Row>
                      </Box>
                      <Box m="0 0 16px 0">
                        {cardInfo ? (
                          <CopyField
                            widthContent="90%"
                            variant="small"
                            title={t("billingAddress")}
                            notTransform
                            value={billingAddress || "..."}
                          />
                        ) : (
                          <Skeleton
                            count={1}
                            height="88px"
                            borderRadius="12px"
                          />
                        )}
                      </Box>
                      <Typography variant="h2" m="0px 0 8px 0">
                        {t("supportedServices")}
                      </Typography>
                      <ChooseByServicesWrapper>
                        {currentProductByBin?.map((product, i) => {
                          return (
                            <TypographyListStyled
                              key={i}
                              variant="small"
                              fw="400"
                              color={WHITE_FIELD_NAME}
                            >
                              {product.title}
                            </TypographyListStyled>
                          );
                        })}
                      </ChooseByServicesWrapper>
                    </DetailsContentScroll>
                  </DetailsWrapper>
                </motion.div>
              ) : (
                <motion.div
                  transition={{ easy: "linear", duration: 0.3 }}
                  variants={contentBlockAnimation}
                  initial={{ y: "60%" }}
                  animate={switcherBlock}
                >
                  <Box m="0px 0 0 0">
                    <SwitcherStyled
                      variantsPay={variantsOperation(t)}
                      activeVariant={activeOperation}
                      onChange={handleSetActiveOperation}
                    />
                  </Box>
                  <Box m="8px 0 0 0">
                    <SectionComponent
                      infinityOff
                      handleGetOperations={handleGetOperations}
                      operationsItems={operationsList}
                      height={heightContentOperation}
                      trigerFetch={activeCardId}
                      setOperationsList={setOperationsList}
                      operationsList={operationsList}
                      setLoadingOperations={setLoadingOperations}
                      withRef={operationsRef}
                      minHeight={minHeightContentOperation}
                      loadingOperations={loadingOperations}
                      setTransactionItems={setTransactionItems}
                      transactionItems={transactionItems}
                      tKey={contentTitle}
                      cardId={cardId}
                    />
                  </Box>
                </motion.div>
              )}
            </ContentWrapper>
          </Box>

          <MenuBlock ref={navigationRef} display="flex">
            <Button
              activeButton
              emoji={"ℹ️"}
              onClick={() => handleRoute(INFO_AND_FEELS_ROUTE)}
              title={t("infoAndFees")}
            />
            <Button
              activeButton
              emoji={"💡"}
              onClick={() => handleRoute(FAQ_ROUTE)}
              title={t("faq")}
            />
          </MenuBlock>
        </CardsWrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
