import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import translationRU from "../../dictionary/ru.json";
import translationEN from "../../dictionary/en.json";
import translationHindi from "../../dictionary/hi.json";
import translationItalia from "../../dictionary/it.json";
import translationBahasa from "../../dictionary/id.json";
import translationEspan from "../../dictionary/es.json";
import translationFranc from "../../dictionary/fr.json";
import translationVie from "../../dictionary/vi.json";

const resources = {
  en: {
    translation: translationEN,
  },
  ru: {
    translation: translationRU,
  },
  es: {
    translation: translationEspan,
  },
  fr: {
    translation: translationFranc,
  },
  hi: {
    translation: translationHindi,
  },
  it: {
    translation: translationItalia,
  },
  vi: {
    translation: translationVie,
  },
  id: {
    translation: translationBahasa,
  },
};

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en",
    debug: true,

    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
