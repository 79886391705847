import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Link, Row, Typography } from "../../UI";
import {
  CardToken,
  DropdownChain,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
  SelectedLink,
  Switcher,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "../../components";
import {
  abi,
  useAsyncWeb3Methods,
  useGetWeb3Context,
} from "../../contexts/web3";
import { useSnackbar, useTranslate, useWallet } from "../../contexts";
import {
  CHAINS_COIN,
  mainCoins,
  defaultTokensConfig,
  TRANSACTIONS_FEE,
  NOT_ENOUGH_FUNDS,
  CHAINS,
  MIN_POWDER,
  ETH_NETWORK_FEE_DELTA,
} from "../../config";
import { copyClipboard } from "../../utils/utils";
import {
  getBalanceNumber,
  toSatoshi,
  fromSatoshi,
  convertNumberToSafe,
} from "../../utils/formatting";
import { useHistory } from "react-router-dom";
import { DropdownToken } from "../../components/Dropdown/DropdownToken";
import { ContentBox, CopyFieldStyled, Wrapper } from "./styled";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import Skeleton from "react-loading-skeleton";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { MAX_AMOUNT_POWDER_LENGTH } from "../../config";
import { WALLET_ROUTE } from "../../routes/routes";
import { sortTokens } from "../../utils/dataFiltering";
import bigInt from "big-integer";

const getFeeTranslate = (key) => {
  return {
    slow: "slowFee",
    medium: "mediumFee",
    fast: "fastFee",
  }[key];
};

const formatBTCFeeResponse = (result, t) => {
  return Object.entries(result || {})?.map(([name, fee]) => ({
    id: name,
    title: `${fee < 0 ? 0 : fee} sat/b`,
    subTitle: t(getFeeTranslate(name))?.toUpperCase(),
    amount: fee,
    disable: fee < 0,
  }));
};

export const WithdrawPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const btcDispatch = dispatch.btc;
  const {
    balanceCoin,
    setRefreshBalance,
    fetchBalance,
    handleSetTokenInCacheNeedUpdate,
  } = useWallet();
  const { t } = useTranslate();
  const { showSuccess, showError, showInfo, showErrorCode } = useSnackbar();
  const { chains, currentChain, user, tokenBalances } = useSelector(
    ({ wallet }) => wallet
  );
  const tokens = user?.tokens || [];
  const { addresses } = useSelector(({ btc }) => btc);
  const [currentToken, setCurrentToken] = useState(null);
  const [amount, setAmount] = useState("");
  const [wallet, setWallet] = useState("");
  const [maxAmountSum, setMaxAmountSum] = useState(0);
  const [success, setSuccess] = useState(null);
  const [focusForm, setFocusForm] = useState(false);
  const [transactionFeeLoading, setTransactionFeeLoading] = useState(false);
  const [btcFeeVariants, setBtcFeeVariants] = useState(null);
  const [transactionFee, setTransactionFee] = useState(null);
  const [notFundsEnoughtForMaxButton, setNotFundsEnoughtForMaxButton] =
    useState(false);
  const [fundsEnoughActive, setFundsEnoughActive] = useState(false);
  const [amountError, setAmountError] = useState(false);
  const [amountErrorForDisableButton, setAmountErrorForDisableButton] =
    useState(false);
  const [fundsEnoughCommissionActive, setFundsEnoughCommissionActive] =
    useState(false);
  const [loading, setLoading] = useState(false);
  const [activeBTCFee, setActiveBTCFee] = useState(null);
  const [show, setShow] = useState(false);
  const [readyFormat, setReadyFormat] = useState(false);
  const prevChain = useRef();
  const prevAmount = useRef(null);
  const afterPressMaxButton = useRef(false);
  const { checkValidAddress } = useAsyncWeb3Methods(currentChain?.chain);
  const TFA = user?.tfaStatus;
  const validAddress = checkValidAddress && wallet && checkValidAddress(wallet);
  const amountIsNaN = readyFormat && Number.isNaN(Number(amount));

  const chainName = currentChain?.id;
  const isTRX = chainName === CHAINS.TRX;
  const isMATIC = chainName === CHAINS.POL;
  const isBSC = chainName === CHAINS.BSC;
  const isETH = chainName === CHAINS.ETH;
  const isBTC = chainName === CHAINS.BTC;

  const isToken = !!currentToken?.tokenId;

  let delta = {
    [CHAINS.BSC]: ETH_NETWORK_FEE_DELTA,
    [CHAINS.ETH]: ETH_NETWORK_FEE_DELTA,
    [CHAINS.POL]: ETH_NETWORK_FEE_DELTA,
    [CHAINS.TRX]: 0,
  }[chainName];

  const { web3Methods } = useGetWeb3Context();

  const deniedLoad = useMemo(() => {
    return [afterPressMaxButton.current];
  }, [afterPressMaxButton.current]);

  const { balancesLoading } = useGetTokenBalances({
    chains,
    tokens,
    userData: user,
    chain: currentChain?.chain,
    deniedLoad: deniedLoad,
  });

  const balance = useMemo(
    () => Number(balanceCoin?.convertedBalance),
    [currentChain, balanceCoin]
  );

  const balanceToken = tokenBalances?.[currentToken?.contract];

  const balanceTokenConverted = convertNumberToSafe(
    getBalanceNumber(balanceToken, currentToken?.decimals)
  );

  const name = CHAINS_COIN[currentChain?.id];
  const tokenConfig = defaultTokensConfig[currentChain?.id];

  const mainToken = useMemo(
    () => ({
      ...mainCoins[currentChain?.chain],
      balance: success?.token?.balance ?? balance,
    }),
    [currentChain?.chain, balance, success]
  );

  const gasFactor = currentToken?.tokenId
    ? TRANSACTIONS_FEE.TOKEN
    : TRANSACTIONS_FEE.COIN;

  const feeData = useMemo(
    () => ({
      value: gasFactor,
      amount: Number(amount) || 0,
      address: wallet,
      round: currentChain?.round || tokenConfig?.gasFixed,
      chain: currentChain?.chain,
      tokenId: currentToken?.tokenId,
    }),
    [currentToken, amount, wallet, currentChain, tokenConfig]
  );

  const nameCurrency = currentToken?.tokenId
    ? currentToken?.symbol
    : name || "";
  const tokensOfUser = useMemo(
    () => [{ ...mainCoins[currentChain?.id], balance }, ...tokens],
    [currentChain, tokens, balance]
  );

  const handleSetChain = (chain) => {
    setWallet("");
    setFundsEnoughActive(false);
    setFundsEnoughCommissionActive(false);
    handleSetAmount("");
    setTransactionFee({});
    setAmountError(false);
    setNotFundsEnoughtForMaxButton(false);
    walletDispatch.setCurrentChain(chain);
    setFundsEnoughActive(false);
    prevAmount.current = null;

    if (afterPressMaxButton.current) {
      afterPressMaxButton.current = false;
    }
  };

  const handleSetToken = useCallback((data) => {
    setAmount("");
    setWallet("");
    setNotFundsEnoughtForMaxButton(false);
    setTransactionFee({});
    setAmountError(false);
    setFundsEnoughActive(false);
    setFundsEnoughCommissionActive(false);
    setCurrentToken(data);
    prevAmount.current = null;
  }, []);

  const currentChainDropdown = useMemo(
    () => (success ? success?.chain : currentChain),
    [currentChain, success]
  );

  useEffect(() => {
    const fetchBTC = async () => {
      if (isBTC && !focusForm && !btcFeeVariants?.length) {
        const fetchWithdrawFee = async () => {
          setLoading(true);
          return btcDispatch
            .getSatFees({ chain: currentChain.chain })
            .then((res) => {
              const result = res?.data?.result;
              const formatVariants = formatBTCFeeResponse(result, t);
              setBtcFeeVariants(formatVariants);
              setActiveBTCFee(null);
            })
            .catch((e) => {
              if (e.response?.data?.code === NOT_ENOUGH_FUNDS) {
                setFundsEnoughActive(true);
              }
            })
            .finally(() => setLoading(false));
        };
        await fetchWithdrawFee();
        // prevAmount.current = amount;
      }
    };

    if (!afterPressMaxButton.current) {
      fetchBTC();
    }
  }, [btcDispatch, isBTC, wallet, validAddress, addresses, focusForm]);

  const handleFetchFee = async (data) => {
    const { getGasPrice } = await web3Methods(currentChain?.chain);
    setTransactionFeeLoading(true);
    try {
      const result = await getGasPrice(data);
      setTransactionFee(result);
      setFundsEnoughCommissionActive(false);
      setTransactionFeeLoading(false);
      return result;
    } catch (e) {
      showErrorCode(e);
      setTransactionFeeLoading(false);
    }
    setTransactionFeeLoading(false);
  };

  const handleCheckTokenAmount = async () => {
    const { getContract, getGasPrice } = await web3Methods(currentChain?.chain);

    const tokenContract = await getContract({
      abi,
      address: currentToken?.contract,
    });

    const tokenBalance = await tokenContract?.methods
      ?.balanceOf(user?.wallet)
      .call();
    const gas = Number(await getGasPrice(feeData));

    const convertedBalance = getBalanceNumber(
      Number(tokenBalance),
      currentToken?.decimals
    );

    return {
      checked: Number(amount) > convertedBalance - gas?.convertedValue,
      tokenBalance: convertedBalance,
      tokenContract,
    };
  };

  const handleWithdraw = async (code) => {
    let result;

    setLoading(true);
    const ifHasCode = code ? { code } : {};

    if (currentToken?.tokenId && currentToken?.contract) {
      const convertedTokenBalance = getBalanceNumber(
        Number(tokenBalances[currentToken.contract]),
        currentToken?.decimals
      );
      // const safeAmount = Number(amount)?.toFixed(SAFE_AMOUNT_LENGTH);
      const safeAmount = convertNumberToSafe(amount);

      try {
        result = await walletDispatch
          .withdrawToken({
            chain: currentChain?.id,
            address: wallet,
            amount: safeAmount,
            tokenId: currentToken?.tokenId,
            ...ifHasCode,
          })
          .then((res) => {
            const newBalance = convertedTokenBalance - Number(amount);
            const balanceValue = newBalance < MIN_POWDER ? 0 : newBalance;

            setSuccess({
              hash: res.data.result,
              wallet,
              amount: Number(amount) || 0,
              chain: currentChain,
              token: {
                ...currentToken,
                balance: Number(convertNumberToSafe(balanceValue)) || 0,
              },
            });
            handleSetTokenInCacheNeedUpdate(
              currentToken?.contract,
              currentChain?.id
            );
            // prevAmount.current = null;

            showSuccess(t("notificationTexts.successTransaction"));
            return res;
          });
      } catch (e) {
        showErrorCode(e);
        setLoading(false);
        return e;
      }
      setLoading(false);
    } else {
      const btcFee = isBTC ? { satB: activeBTCFee?.amount } : {};

      if (amountIsNaN) {
        showError(t("notificationTexts.invalidValue"));
        setLoading(false);
        return;
      }

      const sendAmount = isBTC ? toSatoshi(amount) : Number(amount);

      try {
        result = await walletDispatch
          .withdraw({
            chain: currentChain?.id,
            address: wallet,
            amount: sendAmount,
            ...btcFee,
            ...ifHasCode,
          })
          .then(async (res) => {
            const newBalance =
              currentToken?.balance -
              (Number(amount) + Number(transactionFee?.convertedValue || 0));

            const balance = await fetchBalance(chainName, user);

            setSuccess({
              hash: res.data.result,
              wallet,
              amount: Number(amount) || 0,
              chain: currentChain,
              token: {
                ...currentToken,
                balance: balance?.convertedBalance || 0,
              },
            });

            showSuccess(t("notificationTexts.successTransaction"));
            return res;
          });
      } catch (e) {
        showErrorCode(e);
        setLoading(false);
        return e;
      }
    }

    setRefreshBalance();
    setLoading(false);
    return result;
  };

  const handleCopy = (hash) => {
    copyClipboard(hash);
    showInfo(t("notificationTexts.copied"));
  };

  const handleValidate = async (onSuccess) => {
    let error;

    if (amountIsNaN) {
      showError(t("notificationTexts.invalidValue"));
      return;
    }

    if (!wallet || !amount) {
      error = t("notificationTexts.allFieldsMustBeCompleted");
    }

    if (!activeBTCFee?.id && isBTC) {
      error = t("btcFeeVariantNotSelect");
    }

    if (error) {
      showError(error);
      setLoading(false);
      return;
    }

    setLoading(false);
    onSuccess();
  };

  const handleGetTokenGas = async (wallet, token, chain, maxAmount) => {
    const { getTokenGasPrice } = await web3Methods(chain);

    const chainAmount =
      {
        [CHAINS.TRX]: amount,
      }[chain] || bigInt(amount * `1e${currentToken?.decimals}`).value;

    const chainValue = maxAmount ? maxAmount : chainAmount;

    try {
      const fee = await getTokenGasPrice({
        wallet,
        chain,
        address: wallet,
        tokenId: currentToken.tokenId,
        amount: chainValue,
        contract: token.contract,
      });

      if (balanceTokenConverted < getBalanceNumber(fee)) {
        showError(t("notificationTexts.notEnoughFunds"));
        setTransactionFeeLoading(false);
        return;
      }

      const feeTypeConvertedValue = {
        [CHAINS.TRX]: Number(convertNumberToSafe(fee)),
        [CHAINS.BSC]: Number(convertNumberToSafe(getBalanceNumber(fee))),
        [CHAINS.ETH]: Number(convertNumberToSafe(getBalanceNumber(fee))),
        [CHAINS.POL]: Number(convertNumberToSafe(getBalanceNumber(fee))),
      }?.[chain];

      return {
        convertedValue: feeTypeConvertedValue,
        unconvertedValue: fee,
      };
    } catch (e) {
      console.error(e);
      showError(e.message || e);
    }
  };

  const startFetchTokenFee = useRef(false);

  const handleMaxAmount = async () => {
    const balance = isToken
      ? Number(balanceToken)
      : Number(balanceCoin.convertedBalance);

    if (balance <= 0) {
      showError(t("notificationTexts.notEnoughFunds"));
      return;
    }

    setFundsEnoughActive(false);
    setFundsEnoughCommissionActive(false);
    setTransactionFeeLoading(true);
    const { getGasPrice } = await web3Methods(currentChain?.chain);

    if ((isBSC || isETH || isTRX || isMATIC) && !isToken) {
      if (!validAddress && isTRX) {
        showError(t("walletInvalid"));
        setTransactionFeeLoading(false);
        return;
      }

      try {
        if (isTRX) {
          feeData.amount = balanceCoin.convertedBalance;
        }

        const response = await getGasPrice(feeData);

        let maxAmount;

        maxAmount = convertNumberToSafe(
          balanceCoin.convertedBalance - response?.convertedValue - delta,
          MAX_AMOUNT_POWDER_LENGTH[currentChain?.id] ||
            response?.convertedValue.toString().length
        );

        if (response.convertedValue === 0) {
          maxAmount = balanceCoin.convertedBalance;
        }

        setTransactionFee(response);

        if (maxAmount < 0) {
          setNotFundsEnoughtForMaxButton(true);
          setFundsEnoughCommissionActive(true);
          setTransactionFeeLoading(false);

          return;
        }

        if (Number(balanceCoin.convertedBalance) < Number(maxAmount)) {
          showError(t("notificationTexts.notEnoughFunds"));
          setTransactionFeeLoading(false);
          setNotFundsEnoughtForMaxButton(true);
          return;
        }

        if (maxAmount < 0) {
          setTransactionFeeLoading(false);
          return;
        }

        if (
          Number(balanceCoin?.convertedBalance) <
          Number(transactionFee?.convertedValue)
        ) {
          setFundsEnoughCommissionActive(true);
        }

        setAmountErrorForDisableButton(false);
        setMaxAmountSum(maxAmount);
        setAmount(maxAmount);
        afterPressMaxButton.current = true;
      } catch (e) {
        setAmountErrorForDisableButton(false);
        showError(t("notificationTexts.somethingWrong"));
      }
    }

    if (isBTC) {
      if (!activeBTCFee?.id) {
        showError(t("chooseComissionOption"));
        setTransactionFeeLoading(false);
        return;
      }

      await btcDispatch
        .getMaxAmount({
          satB: activeBTCFee?.amount,
          address: wallet,
        })
        .then((res) => {
          const maxAmount = fromSatoshi(res.data.result.amount);
          const fee = fromSatoshi(res.data.result.fee);

          setMaxAmountSum(maxAmount);
          setAmount(maxAmount);

          setTransactionFee({
            convertedValue: fee,
            unconvertedValue: res.data.result.fee,
            onlyView: true,
          });

          afterPressMaxButton.current = true;
          setTransactionFeeLoading(false);
        })
        .catch((e) => {
          if (e.response.data.code === NOT_ENOUGH_FUNDS.code) {
            setFundsEnoughActive(true);
            if (activeBTCFee?.id) {
              setNotFundsEnoughtForMaxButton(true);
            }
          } else {
            showError(e);
          }
          return e;
        })
        .finally(() => {
          setTransactionFeeLoading(false);
        });
    }

    if (isToken) {
      try {
        setTransactionFeeLoading(true);

        if (!validAddress) {
          showError(t("walletInvalid"));
          setTransactionFeeLoading(false);
          return;
        }

        startFetchTokenFee.current = true;

        const chainBalanceType = {
          [CHAINS.TRX]: balanceTokenConverted,
        }[chainName];

        const fee = await handleGetTokenGas(
          wallet,
          currentToken,
          currentChain?.chain,
          chainBalanceType || balanceToken
        );

        if (balanceCoin?.convertedBalance < fee?.convertedValue) {
          setFundsEnoughCommissionActive(true);
          setNotFundsEnoughtForMaxButton(true);
        }

        prevAmount.current = balanceTokenConverted;

        setAmount(balanceTokenConverted);
        setMaxAmountSum(balanceTokenConverted);
        setTransactionFee(fee);
        setTransactionFeeLoading(false);
        setAmountError(false);
        startFetchTokenFee.current = false;
      } catch (e) {
        showError(e.message);
        setTransactionFeeLoading(false);
        startFetchTokenFee.current = false;
      }
    }

    setTransactionFeeLoading(false);
  };

  const handleSetAmount = (value) => {
    if (fundsEnoughActive) {
      setFundsEnoughActive(false);
    }
    setAmount(value);
    if (amountError) {
      setAmountError(false);
    }
    if (amountError) {
      setAmountError(false);
    }
    if (afterPressMaxButton.current) {
      afterPressMaxButton.current = false;
    }
  };

  const handleAmountFocus = () => {
    setFocusForm(true);
    setFundsEnoughActive(false);
  };

  const handleBTCFeeChange = async (value) => {
    if (transactionFeeLoading) {
      return;
    }

    setActiveBTCFee(value);
    setTransactionFee({});

    if (!validAddress && wallet) {
      showError(t("notificationTexts.invalidAddress"));
      return;
    }

    if (wallet && amount && validAddress) {
      setTransactionFeeLoading(true);
      await handleFetchFee({
        ...feeData,
        satB: value?.amount,
      });
    }
  };

  const handleSetWallet = (value) => {
    setWallet(value);

    if (afterPressMaxButton.current) {
      afterPressMaxButton.current = false;
    }
  };

  useEffect(() => {
    setCurrentToken(mainToken);
  }, [mainToken]);

  useEffect(() => {
    if (!prevChain.current && currentChain?.chain) {
      prevChain.current = currentChain?.chain;
    }
  }, [currentChain]);

  useEffect(() => {
    // HERE WE SET BALANCE'S PARAMS ERRORS WHEN DO BLUR FORM
    if (focusForm || success) {
      setFundsEnoughActive(false);
      setFundsEnoughCommissionActive(false);
      setAmountError(false);
      return;
    }

    if (
      !transactionFee?.convertedValue &&
      transactionFee?.convertedValue !== 0
    ) {
      setAmountErrorForDisableButton(true);
      return;
    }

    if (
      transactionFee?.convertedValue ||
      (transactionFee?.convertedValue === 0 && amount && amount > 0)
    ) {
      const balance = isToken
        ? balanceTokenConverted
        : balanceCoin?.convertedBalance;

      const fee = transactionFee?.onlyView
        ? 0
        : Number(transactionFee?.convertedValue);

      const amountWithFee = isToken ? Number(amount) : Number(amount) + fee;

      const enoughAmountForBalance = Number(amount) <= balance;

      const notEnoughFeeForAmount = Number(amount) + fee > balance;

      if (enoughAmountForBalance && notEnoughFeeForAmount && !isToken) {
        setFundsEnoughCommissionActive(true);
        setAmountErrorForDisableButton(true);
        return;
      }

      if (isToken) {
        const notEnoughBalanceForFee =
          balanceCoin?.convertedBalance <
          Number(transactionFee?.convertedValue);

        if (balance < amountWithFee) {
          setFundsEnoughActive(true);
          setAmountError(true);
          setAmountErrorForDisableButton(true);
          return;
        }

        if (notEnoughBalanceForFee) {
          setFundsEnoughCommissionActive(true);
          setFundsEnoughActive(false);
          setAmountErrorForDisableButton(true);
          setAmountError(true);
          return;
        }
      }

      if (amountWithFee > Number(balance) && !success) {
        setFundsEnoughActive(true);
        setAmountErrorForDisableButton(true);
        setFundsEnoughCommissionActive(false);
      } else if (fundsEnoughActive) {
        setAmountError(false);
        setAmountErrorForDisableButton(false);
        setFundsEnoughActive(false);
      }
    } else {
      setFundsEnoughActive(false);
      setAmountErrorForDisableButton(false);
    }
  }, [amount, isToken, focusForm, transactionFee]);

  useEffect(() => {
    // HERE WE SHOW ERRORS BASED ON A PARAMETERS
    if (!focusForm && fundsEnoughActive && !success) {
      showError(t("notificationTexts.notEnoughFunds"));
    }

    if (!focusForm && amountIsNaN) {
      showError(t("notificationTexts.invalidValue"));
    }
  }, [focusForm, fundsEnoughActive, amountIsNaN, success]);

  useEffect(() => {
    const rule =
      currentChain?.chain &&
      Number(amount) &&
      wallet &&
      !focusForm &&
      !afterPressMaxButton.current &&
      prevAmount.current !== amount &&
      !success;

    if (!validAddress && wallet && !focusForm) {
      showError(t("notificationTexts.invalidAddress"));
      return;
    }

    if (!isToken && rule) {
      if (isBTC && activeBTCFee?.id) {
        handleFetchFee({ ...feeData, satB: activeBTCFee?.amount });
      }
      if (!isBTC) {
        handleFetchFee(feeData);
      }
      prevAmount.current = amount;
    }

    if (isToken && rule && !startFetchTokenFee.current) {
      prevAmount.current = amount;

      const fetchFee = async () => {
        try {
          setTransactionFeeLoading(true);

          if (Number(balanceTokenConverted) < amount) {
            setTransactionFeeLoading(false);
            setFundsEnoughActive(true);
            return;
          }

          const fee = await handleGetTokenGas(
            wallet,
            currentToken,
            currentChain?.chain
          );

          setTransactionFee(fee);
          setTransactionFeeLoading(false);
        } catch (e) {
          showError(e.message || e);
          setTransactionFeeLoading(false);
        }
      };

      fetchFee();
    }
  }, [
    currentToken,
    prevAmount.current,
    currentChain,
    balanceTokenConverted,
    balanceToken,
    wallet,
    amount,
    validAddress,
    activeBTCFee,
    focusForm,
    checkValidAddress,
  ]);

  useEffect(() => {
    // HERE WE DISABLE PAY BUTTON WHEN DO CHANGE AMOUNT
    if (
      amount &&
      (transactionFee?.convertedValue || transactionFee?.convertedValue === 0)
    ) {
      const balance = isToken
        ? balanceTokenConverted
        : balanceCoin?.convertedBalance;

      const fee = transactionFee?.onlyView
        ? 0
        : Number(transactionFee?.convertedValue);

      const amountWithFee = isToken ? Number(amount) : Number(amount) + fee;

      const notEnoughtFee = balanceCoin?.convertedBalance < fee;

      if (balance < amountWithFee || notEnoughtFee) {
        setAmountErrorForDisableButton(true);
      } else {
        setAmountErrorForDisableButton(false);
      }
    }
  }, [amount, transactionFee, isToken]);

  const hash = success?.hash?.transactionHash || success?.hash;

  const maxButtonAmountDisable = currentToken?.tokenId
    ? balanceToken <= 0
    : Number(balanceCoin?.convertedBalance) <= 0;

  const maxButtonDisable =
    maxButtonAmountDisable ||
    notFundsEnoughtForMaxButton ||
    (isBTC && (!validAddress || !wallet || notFundsEnoughtForMaxButton));

  const disableButton =
    !amount || !validAddress || loading || amountIsNaN || amount == 0;

  const globalLoading = loading || transactionFeeLoading;
  const btcDisableButton = isBTC
    ? disableButton ||
      !activeBTCFee?.id ||
      fundsEnoughActive ||
      amountErrorForDisableButton
    : disableButton ||
      amountErrorForDisableButton ||
      globalLoading ||
      (!transactionFee?.convertedValue && transactionFee?.convertedValue !== 0);

  const maxBalanceBeforeMax = isToken
    ? balanceTokenConverted
    : balanceCoin?.convertedBalance;

  const error = fundsEnoughActive || fundsEnoughCommissionActive || amountError;

  const buttonTitle = success
    ? t("wallet")
    : `${t("withdrawButton")} ${Number(amount) || 0} ${
        nameCurrency.toUpperCase() || ""
      }`;

  const buttonOnClick = success
    ? () => {
        prevAmount.current = null;
        history.goBack();
      }
    : TFA
    ? () => handleValidate(() => setShow(true))
    : () => handleValidate(handleWithdraw);

  return (
    <PageLoader loading={globalLoading} noArrow={success}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handleWithdraw}
      >
        <Wrapper>
          <ContentBox>
            <Box>
              <PageHeader
                noArrow={success}
                title={success ? `${t("withdrawSuccess")} 🎉` : t("withdraw")}
                to={WALLET_ROUTE}
              />
              <Box>
                <DropdownChain
                  subTitle={t("chain")}
                  noExpand={success || loading}
                  chains={success ? [success?.chain] : chains}
                  currentChain={currentChainDropdown}
                  setChain={handleSetChain}
                  z={2}
                />
              </Box>
            </Box>
            {success ? (
              <CardToken data={success?.token} />
            ) : balancesLoading ? (
              <Skeleton width="100%" height="60px" borderRadius="12px" />
            ) : (
              <DropdownToken
                sortFunc={sortTokens}
                tokens={tokensOfUser}
                currentToken={currentToken}
                setToken={handleSetToken}
              />
            )}
            {!success && (
              <Input
                onFocus={() => setFocusForm(true)}
                onBlur={() => setFocusForm(false)}
                readOnly={loading}
                placeholder={t("wallet")}
                onChange={handleSetWallet}
                value={wallet || ""}
              />
            )}
            {!success && (
              <Row gap="8px">
                <Box width={"100%"}>
                  <Input
                    type="number"
                    setReadyFormat={setReadyFormat}
                    error={error}
                    onFocus={handleAmountFocus}
                    onBlur={() => setFocusForm(false)}
                    readOnly={success || loading}
                    actionButtonTitle={t("max").toLowerCase()}
                    actionButton={!success && handleMaxAmount}
                    actionDisable={maxButtonDisable}
                    placeholder={
                      success
                        ? t("amount")
                        : t("amountMax", {
                            maxAmount:
                              maxAmountSum < 0
                                ? 0
                                : Number(maxAmountSum) ||
                                  Number(maxBalanceBeforeMax) ||
                                  0,
                            nameCurrency,
                          })
                    }
                    onChange={handleSetAmount}
                    value={success?.amount || amount || ""}
                  />
                </Box>
              </Row>
            )}
            {fundsEnoughCommissionActive && !fundsEnoughActive ? (
              <Typography m="0px 0 0 0" variant="link" color="red">
                {t("notEnoughFundsToPayFee")}
              </Typography>
            ) : null}
            {!success && isBTC && (
              <Box>
                {btcFeeVariants?.length && (
                  <Box>
                    <Typography m="0 0 8px 0" variant="link" color="hint">
                      {t("transactionFeeRegular")}
                    </Typography>
                    <Switcher
                      autocomplete
                      variantsPay={btcFeeVariants}
                      activeVariant={activeBTCFee}
                      onChange={handleBTCFeeChange}
                    />
                    {!fundsEnoughActive &&
                      amount &&
                      validAddress &&
                      transactionFee?.convertedValue > 0 && (
                        <Row
                          width="100%"
                          justifyContent="space-between"
                          textAlign="center"
                          m="10px 0 0 0"
                        >
                          <LineInfo
                            leftTitle={t("transactionFee", {
                              fee: "",
                              name: "",
                            })}
                            rightTitle={`${
                              transactionFee?.convertedValue || 0
                            } ${name}`}
                          />
                        </Row>
                      )}
                  </Box>
                )}
              </Box>
            )}
            {success ? (
              <>
                <CopyFieldStyled
                  variant="small"
                  title={t("wallet")}
                  value={success.wallet}
                  onClick={() => handleCopy(success.wallet)}
                />
                <Input
                  type="number"
                  readOnly
                  placeholder={t("amount")}
                  value={success?.amount || amount || ""}
                />
                <CopyFieldStyled
                  variant="small"
                  title={t("hash")}
                  value={hash}
                  onClick={() => handleCopy(hash)}
                />
                <SelectedLink
                  txLink={currentChain?.provider?.tx}
                  type="tx"
                  link={hash}
                >
                  <Link m="10px 0 0 0">{t("viewTransaction")}</Link>
                </SelectedLink>
              </>
            ) : (
              <>
                {!isBTC && (
                  <>
                    <Row
                      width="100%"
                      justifyContent="space-between"
                      textAlign="center"
                      m="10px 0 0 0"
                    >
                      <LineInfo
                        leftTitle={t("transactionFee", {
                          fee: "",
                          name: "",
                        })}
                        rightTitle={`${
                          transactionFee?.convertedValue || 0
                        } ${name}`}
                      />
                    </Row>
                    <Row
                      width="100%"
                      justifyContent="space-between"
                      m="-6px 0 0 0"
                      textAlign="center"
                    >
                      <Typography color="hint" variant="link">
                        {t("balance", {
                          balance: "",
                          currency: nameCurrency,
                        })}
                      </Typography>
                      <Typography variant="link">
                        {`${
                          isToken ? balanceTokenConverted || 0 : balance || 0
                        } ${nameCurrency}`}
                      </Typography>
                    </Row>
                  </>
                )}
              </>
            )}
          </ContentBox>
          <Button
            activeButton
            disable={success ? false : btcDisableButton}
            emoji={success ? "💎" : "📤"}
            onClick={buttonOnClick}
            title={buttonTitle}
          />
        </Wrapper>
      </TFACheckContainer>
    </PageLoader>
  );
};
