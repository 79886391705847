import styled from "styled-components";
import { CenterStyles, Typography } from "../../UI";

export const Wrapper = styled.div`
  position: relative;
  height: calc(100vh - 50px);
`;

export const PlusButton = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.button};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: ${({ theme }) => theme.shadows.shadow2};
  ${CenterStyles};

  &:after {
    content: "➕";
    height: 20px;
    width: 23px;
    font-size: 20px;
    overflow: hidden;
  }
`;

export const MenuBlock = styled.div`
  display: ${({ display }) => display || "grid"};
  grid-template-columns: 1fr 1fr;
  padding: 16px 16px 36px 16px;
  position: fixed;
  //max-width: 480px;

  grid-gap: 10px 10px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10000;
`;

export const NavigationBlock = styled.div`
  //position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 16px 16px 36px 16px;
  position: fixed;
  //max-width: 480px;
`;

export const PendingBTCBalance = styled(Typography)`
  color: ${({ theme }) => theme.colors.yellow};
  text-align: center;
  margin-bottom: 4px;
`;
