import React from "react";
import { useTranslate } from "../../contexts";
import { Box, Link, Row, Typography } from "../../UI";
import styled from "styled-components";
import { LinkComponent } from "../Link/Link";

const GrayCheckbox = styled.div`
  font-size: 15px;
  line-height: 13px;
  height: 15px;
  width: 15px;
  transition: 0.2s;
  border-radius: 4px;
  z-index: 5555;
  background-color: ${({ theme }) => theme.colors.background2};
  position: relative;

  &:before {
    content: "";
    opacity: ${({ checked }) => (checked ? 0 : 1)};
    position: absolute;
    transition: 0.2s;
    left: 0;
    bottom: 0;
  }

  &:after {
    content: "✅";
    transition: 0.2s;

    opacity: ${({ checked }) => (checked ? 1 : 0)};
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 5555;
  }
`;

export const AgreedTermsAndPolicy = ({ checked, onClick }) => {
  const { t } = useTranslate();
  return (
    <Row>
      <Box justifyContent="center" alignItems="center" m="0 16px 0 0">
        <GrayCheckbox
          onClick={() => onClick && onClick(!checked)}
          checked={!!checked}
        ></GrayCheckbox>
      </Box>
      <Row fw="wrap" alignItems="center">
        <Typography variant="small">{t("haveReadAndAgreed")}</Typography>&nbsp;
        <LinkComponent
          to={t("guides.termsOfUse")}
          title={t("termsOfUseAgreed")}
        />
        ,&nbsp;
        <LinkComponent
          to={t("guides.privacyPolicy")}
          title={t("privacyPolicyAgreed")}
        />
        ,&nbsp;
        <LinkComponent
          to={t("guides.amlKycPolicy")}
          title={t("amlKycPolicyAgreed")}
        />
        &nbsp;<Typography variant="small">{t("and")}</Typography>&nbsp;
        <LinkComponent
          to={t("guides.generalRulesOfIwo")}
          title={t("generalRulesOfIWOAgreed")}
        />
      </Row>
    </Row>
  );
};
