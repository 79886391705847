import { AxiosGet, AxiosPost } from "../rest/axios";
import { URLS } from "../api/index";
import { SUCCESS } from "../config";

export default {
  state: {
    subscriptions: [],
    tickets: [],
    projects: [],
    usdtStaking: 0,
    stakings: null,
    stakingRequirements: null,
    totalStakesBalance: 0,
    makerUserParamsLoaded: false,
    makerRequirements: null,
    totalTicketsBalance: 0,
    aml: false,
    kyc: null,
  },
  reducers: {
    setSubscriptions(state, payload) {
      return {
        ...state,
        subscriptions: payload,
      };
    },
    setTickets(state, payload) {
      return {
        ...state,
        tickets: payload,
      };
    },
    setTotalTicketsBalance(state, payload) {
      return {
        ...state,
        totalTicketsBalance: payload,
      };
    },
    setMakerUserParamsLoaded(state) {
      return {
        ...state,
        makerUserParamsLoaded: true,
      };
    },
    setTotalStakesBalance(state, payload) {
      return {
        ...state,
        totalStakesBalance: payload,
      };
    },
    setStakings(state, payload) {
      return {
        ...state,
        stakings: payload,
      };
    },
    setStaking(state, payload) {
      return {
        ...state,
        stakings: [...state.stakings, payload],
      };
    },
    setAml(state, payload) {
      return {
        ...state,
        aml: payload,
      };
    },
    setKyc(state, payload) {
      return {
        ...state,
        kyc: payload,
      };
    },

    setUsdtStaking(state, payload) {
      return {
        ...state,
        usdtStaking: payload,
      };
    },
    setRestStakings(state, payload) {
      return {
        ...state,
        stakings: [...state.stakings, ...payload],
      };
    },
    setStakingRequirements(state, payload) {
      return {
        ...state,
        stakingRequirements: payload,
      };
    },
    setMakerRequirements(state, payload) {
      return {
        ...state,
        makerRequirements: payload,
      };
    },
    setProjects(state, payload) {
      return {
        ...state,
        projects: payload,
      };
    },
  },
  effects: {
    getProjects({ cache }) {
      return AxiosGet({
        url: URLS.getMakerProjects,
        cache,
      }).then((res) => {
        this.setProjects(res.data.result);

        return res;
      });
    },
    getProject({ projectId, cache }) {
      return AxiosGet({
        url: URLS.getMakerProject,
        params: { projectId },
        cache,
      });
    },
    getSubscriptions() {
      return AxiosGet({
        url: URLS.getMakerSubscriptions,
      }).then((res) => {
        this.setSubscriptions(res.data.result);
      });
    },
    getSubscription({ projectId }) {
      return AxiosGet({
        url: URLS.getMakerSubscription,
        params: { projectId },
      });
    },
    getTicketsRequirements({ projectId, cache }) {
      return AxiosGet({
        url: URLS.getMakerTicketsRequirements,
        params: { projectId },
        cache,
      });
    },
    getAllocationRequirements({ projectId, cache }) {
      return AxiosGet({
        url: URLS.getAllocationRequirements,
        params: { projectId },
        cache,
      });
    },
    getMakerOperations() {
      return AxiosGet({
        url: URLS.getMakerOperations,
      });
    },
    getMakerOperation({ operationId }) {
      return AxiosGet({
        url: URLS.getMakerOperation,
        params: { operationId },
        cache: false,
      });
    },
    getProjectStats({ projectId }) {
      return AxiosGet({
        url: URLS.getMakerProjectStats,
        params: { projectId },
      });
    },
    getProjectWalletParams({ projectId, cache }) {
      return AxiosGet({
        url: URLS.getMakerProjectParams,
        params: { projectId },
        cache,
      });
    },
    getMakerUserParams({ cache } = {}) {
      return AxiosGet({
        url: URLS.getMakerUserParams,
        cache: cache !== undefined ? cache : true,
      }).then((res) => {
        if (res.data.status === SUCCESS) {
          this.setMakerUserParamsLoaded();
          this.setTickets(res.data.result.tickets);
          this.setAml(res.data.result.aml);
          this.setKyc(res.data.result.kyc);
          this.setSubscriptions(
            res.data.result.tickets
              ?.filter((el) => el.subscribed)
              .map((el) => el.projectId)
          );
          this.setUsdtStaking(res.data.result.usdtStaking);
        }
      });
    },
    getStakingRequirements() {
      return AxiosGet({
        url: URLS.getStakingRequirements,
      }).then((res) => {
        this.setStakingRequirements(res.data.result);
      });
    },
    getStakingMakerRequirements() {
      return AxiosGet({
        url: URLS.getStakingMakerRequirements,
      }).then((res) => {
        this.setMakerRequirements(res.data.result);
      });
    },
    getStakings({ cache }) {
      return AxiosGet({
        url: URLS.getStakins,
        cache,
      }).then((res) => {
        this.setStakings(res.data.result);
      });
    },
    buyTickets({ projectId, count, code }) {
      return AxiosPost({
        url: URLS.buyMakerTickets,
        data: { projectId, count, code },
      });
    },
    addStake({ ruleId, amount, code }) {
      return AxiosPost({
        url: URLS.addStake,
        data: { ruleId, amount, code },
      });
    },
    topupStake({ index, amount, code }) {
      return AxiosPost({
        url: URLS.topupStake,
        data: { index, amount, code },
      });
    },
    prolongStake({ index, code }) {
      return AxiosPost({
        url: URLS.prolongStake,
        data: { index, code },
      });
    },
    closeStake({ index }) {
      return AxiosPost({
        url: URLS.closeStake,
        data: { index },
      });
    },
    subscribe({ projectId }) {
      return AxiosPost({
        url: URLS.makerSubscribe,
        data: { projectId },
      });
    },
    buyAllocation({ projectId, tickets, code }) {
      return AxiosPost({
        url: URLS.buyAllocation,
        data: { projectId, tickets, code },
      });
    },
    freeAmlCheck({ projectId }) {
      return AxiosPost({
        url: URLS.freeAmlCheck,
        data: { projectId },
      });
    },
    getAllocationOperation({ operationId }) {
      return AxiosGet({
        url: URLS.getAllocationOperation,
        params: { operationId },
        cache: false,
      });
    },
    getTicketOperation({ operationId }) {
      return AxiosGet({
        url: URLS.getTicketOperation,
        params: { operationId },
        cache: false,
      });
    },

    getMakerAllocationStatus({ operationId }) {
      return AxiosGet({
        url: URLS.getMakerAllocationStatus,
        params: { operationId },
        cache: false,
      });
    },
    getMakerTicketStatus({ operationId }) {
      return AxiosGet({
        url: URLS.getMakerTicketStatus,
        params: { operationId },
        cache: false,
      });
    },
    setDropWallet({ projectId, wallet }) {
      return AxiosPost({
        url: URLS.setDropWallet,
        data: { projectId, wallet },
      });
    },
  },
};
