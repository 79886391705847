import React, { useEffect, useMemo, useState } from "react";
import { PageHeader } from "../../components";
import { Box, Typography } from "../../UI";
import { useTranslate } from "../../contexts";
import { ChooseCardElement } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { CARD_STATUTES } from "../../config";
import { lastFourCardNumbers } from "../../utils/formatting";
import { createCardRoute, toCardInfo } from "../../routes/routes";
import { useGetRouter } from "../../hooks";
import { useParams } from "react-router-dom";
import { getProductsByBin } from "./helpers";

export const SelectCardsPage = ({ showCards, setShowCards }) => {
  const { t } = useTranslate();
  const { bins, active_products_ids } = useParams();

  const { handleRoute } = useGetRouter();
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const [activeBins, setActiveBins] = useState([]);
  const [activeProducts, setActiveProducts] = useState([]);
  const { cards, templates, requirements, products } = useSelector(
    ({ cards }) => cards
  );

  const productsByBin = useMemo(() => getProductsByBin(products), [products]);

  const cardFiltered = cards?.filter(
    (card) =>
      card?.status === CARD_STATUTES.ACTIVE && activeBins?.includes(card?.binId)
  );

  const templateFiltered = templates?.filter((template) =>
    activeBins?.includes(String(template?.binId))
  );

  useEffect(() => {
    if (bins) {
      const convertedBins = bins.split(",");

      if (convertedBins?.length) {
        setActiveBins(convertedBins);
      }
    }
  }, [bins]);

  useEffect(() => {
    if (active_products_ids) {
      const convertedProducts = active_products_ids.split(",");

      if (convertedProducts?.length) {
        setActiveProducts(convertedProducts);
      }
    }
  }, [active_products_ids]);

  useEffect(() => {
    cardsDispatch.getCards({ cache: false });
  }, [cardsDispatch]);

  return (
    <Box>
      <PageHeader
        title={t("selectOrIssueCard")}
        disableRoute={showCards}
        cb={() => setShowCards && setShowCards(false)}
      />
      {cardFiltered?.length ? (
        <Box>
          <Typography m="10px 0 0 0" variant="h2">
            {t("myCards").toUpperCase()}
          </Typography>
          <Typography m="10px 0 0 0" variant="link">
            {t("youAlreadyHaveCard")}
          </Typography>
          <Box m="8px 0 0 0">
            {cardFiltered?.map(
              ({ balance, maskNumber, binId, cardId, ...rest }, index) => {
                const products = productsByBin?.[binId];
                const route = toCardInfo(cardId);
                return (
                  <ChooseCardElement
                    balance={balance}
                    activeProducts={activeProducts}
                    key={index}
                    onClick={() => handleRoute(route)}
                    maskNumber={maskNumber}
                    bin={binId}
                    products={products}
                    title={`$${balance}`}
                    subTitle={`Debit MasterCard ... ${lastFourCardNumbers(
                      maskNumber
                    )}`}
                  />
                );
              }
            )}
          </Box>
        </Box>
      ) : null}

      <Box m={!cardFiltered?.length ? "10px 0 0 0" : "16px 0 0 0"}>
        <Typography m="10px 0 0 0" variant="h2">
          {t("createNewCard").toUpperCase()}
        </Typography>
        <Box m="8px 0 0 0">
          {templateFiltered?.map(({ binId, scheme, country }, index) => {
            const products = productsByBin[binId];

            const createRoute = createCardRoute(binId);
            return (
              <ChooseCardElement
                key={index}
                bin={binId}
                activeProducts={activeProducts}
                onClick={() => handleRoute(createRoute)}
                products={products}
                title={`${t("cardIssuanceCost", {
                  cost: requirements?.createCardFee,
                })} USDT`}
                subTitle={(scheme) =>
                  `${t("rechargeableDebitMasterCard", { scheme })}`
                }
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
