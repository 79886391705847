import React, { useEffect, useRef, useState } from "react";
import { IFramePage } from "./IFrame";
import { PROCESSING_STATUSES, ProcessingPage } from "./Processing-Page";
import { INTERVAL_FETCH_MS, SUCCESS } from "../../config";

export const IFrameLayout = React.memo(
  ({
    children,
    link,
    title,
    continueRoute,
    processingBefore,
    updatingWhenIFrameOpened,
    processingDataBeforeRequest,
    finishedFetch,
    fetchingWithInitStatus,
    errorRoute,
    processingRoute,
    view,
    arrowRoute,
    cb,
    cbRoute,
    initStatus,
    fromPage,
    CongratsComponent,
    ErrorComponent,
    processingFetch,
    ProcessingComponent,
  }) => {
    const [processing, setProcessing] = useState(false);
    const [status, setStatus] = useState(null);
    const [props, setProps] = useState(null);

    useEffect(() => {
      if (initStatus && initStatus !== status) {
        setStatus(initStatus);
      }
    }, [initStatus]);

    useEffect(() => {
      if (!processing && processingBefore) {
        setProcessing(true);
      }
    }, [processingBefore]);

    const interval = useRef(null);

    useEffect(() => {
      if (
        updatingWhenIFrameOpened &&
        processingFetch &&
        INTERVAL_FETCH_MS > 1000
      ) {
        interval.current = setInterval(() => {
          processingFetch()?.then(async (res) => {
            if (
              res.status === PROCESSING_STATUSES.success ||
              res.status === PROCESSING_STATUSES.error
            ) {
              setProcessing(true);
              setStatus(SUCCESS);

              let finishedFetchData;

              if (finishedFetch) {
                finishedFetchData = await finishedFetch();
              }
              const finishedData = finishedFetchData?.data?.result || {};

              const data = { ...res.data, ...finishedData };

              if (data) {
                setProps(data);
              }

              if (cb) {
                cb();
              }
            }
          });
        }, INTERVAL_FETCH_MS);

        return () => {
          clearInterval(interval.current);
        };
      } else {
        clearInterval(interval.current);
      }
    }, [updatingWhenIFrameOpened, processingFetch]);

    const handleCallback = () => {
      if (cb) {
        cb();
      }
    };

    const handleProcessingCb = () => {
      setProcessing(false);
      if (cb) {
        cb();
      }
    };

    return (
      <>
        {processing || processingBefore ? (
          <ProcessingPage
            processingDataBeforeRequest={processingDataBeforeRequest}
            fromPage={fromPage}
            cbRoute={cbRoute}
            fetchingWithInitStatus={fetchingWithInitStatus}
            initStatus={status}
            initProps={props}
            fetch={processingFetch}
            finishedFetch={finishedFetch}
            ErrorComponent={ErrorComponent}
            CongratsComponent={CongratsComponent}
            ProcessingComponent={ProcessingComponent}
            continueRoute={continueRoute}
            errorRoute={errorRoute}
            processingRoute={processingRoute}
            cb={handleProcessingCb}
          />
        ) : view ? (
          <IFramePage
            backTo={arrowRoute}
            link={link}
            title={title}
            cb={handleCallback}
          />
        ) : (
          children
        )}
      </>
    );
  }
);
