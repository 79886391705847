import styled from "styled-components";
import { Box, Paper, Row } from "../../UI";
import { ReactComponent as PlusIcon } from "../../assets/svg/plus-icon.svg";
import { ReactComponent as MastercardIcon } from "../../assets/svg/mastercard.svg";
import { ReactComponent as UKIcon } from "../../assets/svg/uk.svg";
import { ReactComponent as HongKongIcon } from "../../assets/svg/flag.svg";

export const ActionButtonElement = styled(Paper)`
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 8px 8px;
  min-width: 64px;
  width: 33%;
  margin-right: 10px;

  opacity: ${({ disable }) => (disable ? "0.4" : "1")};

  transition: 0.4s opacity;

  &:last-child {
    margin-right: 0;
  }
`;

export const CardWrapper = styled.div`
  width: ${({ cardWidth }) => `${cardWidth}`};
  height: 154px;
  opacity: ${({ hide, isCreate, notActive }) =>
    (hide || isCreate) && notActive ? 0 : 1};
  position: absolute;
  left: ${({ left }) => left};
  transform: ${({ notActive }) => (notActive ? "scale(0.9)" : "scale(1)")};
  z-index: ${({ notActive }) => (notActive ? 0 : 5)};
  transition: ${({ cardsInited }) => (cardsInited ? "0.5s" : "none")};
  transition-property: left, transform, opacity;
  transition-timing-function: ease-in-out;
`;

export const CardCreateWrapper = styled(CardWrapper)`
  border-radius: 12px;
  width: ${({ cardWidth }) => `${cardWidth}`};
  background-color: ${({ theme }) => theme.colors.background2};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  transform: ${({ isCreate }) =>
    isCreate ? "translate(-50%, 0)" : "translate(0, 0)"};

  left: ${({ left, isCreate }) => (isCreate ? "50%" : left)};
  top: ${({ top }) => top + "px"};
  padding: 16px;
  transition: ${({ notCards }) => (notCards ? "none" : ".5s")};
`;
export const PlusIconElement = styled(PlusIcon)`
  width: 48px;
  height: 48px;
`;

export const ControlsBlock = styled(Box)`
  width: 100px;
  //height: 212px;
  margin-right: 24px;
`;

export const BalanceWrapper = styled(Box)`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: 0.3s;
`;
export const HideWrapper = styled(Box)`
  //opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: 0.3s;
  position: relative;
  width: 100%;
`;

export const CardWrapperPaper = styled.div`
  background-color: ${({ color }) => color};
  padding: 16px;
  border-radius: 12px;
  opacity: ${({ cardsInited }) => (cardsInited ? 1 : 0)};
  //transition: 0.2s;
`;

export const MoveWrapper = styled.div`
  display: flex;
  transition: 0.5s;
  position: ${({ isCreate }) => (isCreate ? "static" : "relative")};
`;

export const Wrapper = styled.div`
  height: 154px;
  padding-left: 17%;

  margin-bottom: 8px;
  width: ${({ width }) => width};
  //padding: ${({ needPadding }) => (needPadding ? "0 0 0 10px" : 0)};
  display: flex;
`;

export const WrapperBox = styled(Box)`
  opacity: ${({ hide }) => (hide ? 0 : 1)};
  transition: 0.4s;
`;

export const CardCreditsBlock = styled(Row)`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  //padding-bottom: 16px;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CardBoxWrapper = styled(Box)`
  position: relative;
  height: 122px;
`;
export const MasterCard = styled(MastercardIcon)`
  width: 42px;
  height: 24px;
`;

export const createSizeIcon = (icon) =>
  styled(icon)`
    width: 24px;
    height: 24px;
  `;

export const DotsBlock = styled(Row)`
  justify-content: center;
`;

export const Dot = styled(Row)`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin-right: 8px;

  &:last-child {
    margin: 0;
  }
`;

export const WrapperComponent = styled(Row)`
  min-height: 228px;
  position: relative;
`;

export const UKIconStyled = styled(UKIcon)`
  width: 24px;
  height: 24px;
`;
export const HongKongIconStyled = styled(HongKongIcon)`
  width: 24px;
  height: 24px;
`;
