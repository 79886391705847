export const SUCCESS = "success";
export const PENDING = "pending";
export const WAITING = "waiting";
export const ERROR = "error";
export const PAID = "paid";
export const FAILED = "failed";
export const PROCESSING = "processing";
export const NONE = "none";

export const KYC_STATUSES = {
  PENDING: "processing",
  FAILED: "failed",
  SUCCESS: "success",
  NONE: "none",
};

export const getStatusTrans = (name, t) => {
  return (
    {
      [WAITING]: t("pending"),
      [PENDING]: t("pending"),
      [SUCCESS]: t("success"),
      [FAILED]: t("failed"),
      [PAID]: t("paid"),
    }[name] || t("notFoundKey")
  );
};
