import { useHistory } from "react-router-dom";
import { useCallback } from "react";

export const useGetRouter = () => {
  const history = useHistory();

  const handleRoute = (route) => {
    const routeFirstChart = route?.charAt(0);
    const startSlash = routeFirstChart === "/";
    const parseRoute = startSlash ? route : `/${route}`;

    history.push(parseRoute);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  return { handleRoute, handleGoBack };
};
