import React, { useEffect } from "react";
import { Typography } from "../../UI";
import { ChainItem, PaperStyled } from "./styled";
import { useTranslate } from "../../contexts";

const SwitchItem = ({
  title,
  subTitle,
  width,
  activeVariant,
  onClick,
  disabled,
}) => {
  return (
    <ChainItem
      disabled={disabled}
      width={width + "%"}
      active={activeVariant}
      onClick={!disabled ? onClick : () => {}}
    >
      <Typography ta="center" variant="link">
        {title}
      </Typography>
      {subTitle && (
        <Typography ta="center" variant="link">
          {subTitle}
        </Typography>
      )}
    </ChainItem>
  );
};

export const Switcher = ({
  variantsPay,
  activeVariant,
  onChange,
  disabled,
  autocomplete,
  className,
}) => {
  const { t } = useTranslate();
  const itemWidth = 100 / variantsPay?.length;

  useEffect(() => {
    if (autocomplete) {
      const activeVariants = variantsPay?.filter((variant) => !variant.disable);

      if (activeVariants?.length === 1) {
        onChange(activeVariants[0]);
      }
    }
  }, [autocomplete, variantsPay, onChange]);
  return (
    <PaperStyled className={className}>
      {variantsPay?.map((item, i) => {
        return (
          <SwitchItem
            key={i}
            disabled={item.disable}
            onClick={() =>
              !disabled && activeVariant?.id !== item?.id && onChange(item)
            }
            activeVariant={activeVariant?.id === item.id}
            title={item.titleKey ? t(item.titleKey) : item.title}
            subTitle={item?.subTitle}
            width={itemWidth}
          />
        );
      })}
    </PaperStyled>
  );
};
