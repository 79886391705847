import React, { useEffect, useState } from "react";
import { Box, Typography } from "../../UI";
import styled from "styled-components";
import {
  shortWallet,
  fromSatoshi,
  firstNotZeroFormat,
} from "../../utils/formatting";
import { useSnackbar, useTranslate } from "../../contexts";
import { copyClipboard } from "../../utils/utils";
import { Ellipsis, EllipsisText } from "../EllipsisText/Ellipsis";

const Wrapper = styled(Box)`
  overflow-y: scroll;
  margin-top: 20px;
`;

const AddressItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 12px;
  background-color: ${({ theme }) => theme.colors.background2};
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const MBTCAmount = styled(Typography)`
  color: ${({ theme, active }) =>
    active ? theme.colors.yellow : theme.colors.text};
`;

const Address = ({ address, value, pending, mBTCFormat }) => {
  const [mbtc, setMbtc] = useState(false);
  const { showInfo } = useSnackbar();
  const { t } = useTranslate();

  useEffect(() => {
    if (mbtc !== mBTCFormat) {
      setMbtc(mBTCFormat);
    }
  }, [mBTCFormat]);

  const handleCopy = (address) => {
    copyClipboard(address);
    showInfo(t("notificationTexts.copied"));
  };

  const mBTCValue = mbtc ? firstNotZeroFormat(Number(value * 1000)) : value;
  return (
    <AddressItem>
      <Typography onClick={() => handleCopy(address)} variant="link">
        {/*{shortWallet(address, 9, -10)}*/}
        <EllipsisText value={address} startLength={9} endLength={52} />
      </Typography>
      <MBTCAmount variant="link" active={pending}>
        {`${mBTCValue} ${mbtc ? "mBTC" : "BTC"}`}
      </MBTCAmount>
    </AddressItem>
  );
};

export const Addresses = React.memo(
  ({ addresses, withRef, tokensHeight, mBTCFormat, formatting }) => {
    const { t } = useTranslate();
    const sorted = addresses.sort(
      (a, b) => Number(b.pending) - Number(a.pending)
    );

    return (
      <Wrapper ref={withRef} height={`${tokensHeight}px`}>
        <Typography m="0 0 10px 0" varint="h1">
          {t("addresses")}
        </Typography>
        {sorted?.map((address, i) => {
          const pending =
            Number(address.balance) !== Number(address.unconfirmedBalance);

          const value = fromSatoshi(Number(address.unconfirmedBalance));

          return (
            <Address
              key={i}
              noArrow
              mBTCFormat={mBTCFormat}
              address={address.wallet}
              value={value || 0}
              pending={pending}
            />
          );
        })}
      </Wrapper>
    );
  }
);
