import { STATUSES } from "./helpers";

export const STAKES_NAME = "stakes";
export const CLOSES_NAME = "closes";

export const stakesUpdateLocalStorage = (name, realStakes, cb) => {
  const storageStakes = localStorage.getItem(STAKES_NAME);

  if (storageStakes) {
    const parsedStorageStakes = JSON.parse(storageStakes);

    const realStakesActiveIndexes = realStakes?.map(
      (realStake) => realStake.index
    );

    const realStakesActiveHash = realStakes?.reduce((acc, realStake) => {
      acc[realStake.index] = realStake;
      return acc;
    }, {});

    const filteredStorageStakes = parsedStorageStakes?.filter(
      (parsedStorageStake) => {
        return (
          !realStakesActiveIndexes?.includes(parsedStorageStake?.index) ||
          (parsedStorageStake.isTopup &&
            realStakesActiveHash[parsedStorageStake?.index] &&
            Number(realStakesActiveHash[parsedStorageStake?.index].amount) !==
              Number(parsedStorageStake.amount)) ||
          (realStakesActiveHash[parsedStorageStake?.index].status ===
            STATUSES.finished &&
            parsedStorageStake.prolong &&
            parsedStorageStake.prevTo ===
              realStakesActiveHash[parsedStorageStake?.index].to)
        );
      }
    );

    const stringifiedStorageStakes = JSON.stringify(filteredStorageStakes);

    localStorage.setItem(name, stringifiedStorageStakes);

    if (cb) {
      cb(filteredStorageStakes);
    }
  }
};

export const stakesUpdateLocalStorageClosesStakes = (name, realStakes, cb) => {
  const storageClosesStakes = localStorage.getItem(name);
  const realStakesIndexes = realStakes?.map((realStake) => realStake.index);

  if (storageClosesStakes) {
    const parsedStorageStakes = JSON.parse(storageClosesStakes);

    const filteredStorageStakes = parsedStorageStakes?.filter(
      (closeStakeIndex) => realStakesIndexes?.includes(closeStakeIndex)
    );

    const stringifiedStorageStakes = JSON.stringify(filteredStorageStakes);

    localStorage.setItem(name, stringifiedStorageStakes);
  }
};

export const getLocalStorageStakes = (name) => {
  const storageStakes = localStorage.getItem(name);
  if (storageStakes) {
    return JSON.parse(storageStakes);
  }
  return [];
};

export const removeStakeFromLocalStorage = (name, indexes) => {
  const storageStakes = localStorage.getItem(name);
  if (storageStakes) {
    const parsed = JSON.parse(storageStakes);

    const filtered = parsed.filter(
      (parsedStake) => !indexes?.includes(parsedStake.index)
    );

    const stringifiedStorageStakes = JSON.stringify(filtered);

    localStorage.setItem(name, stringifiedStorageStakes);
  }
};

export const stakesAddLocalStorage = (name, newPendingStake) => {
  const storageStakes = localStorage.getItem(name);

  let toLocalStorage;

  if (!storageStakes) {
    const newParsedStorageStakes = [newPendingStake];
    toLocalStorage = JSON.stringify(newParsedStorageStakes);
  } else {
    const parsedStorageStakes = JSON.parse(storageStakes);

    const newParsedStorageStakes = [...parsedStorageStakes, newPendingStake];

    toLocalStorage = JSON.stringify(newParsedStorageStakes);
  }

  localStorage.setItem(name, toLocalStorage);
};
