import { CARD_STATUTES } from "../config";
import { convertNumberToSafe, getBalanceNumber } from "./formatting";

export const getCardBins = (cards) =>
  cards?.reduce((acc, card) => {
    if (card.status === CARD_STATUTES.ACTIVE) {
      acc.push({
        cardId: card.cardId,
        bin: card.binId,
      });
    }
    return acc;
  }, []);

export const getCardForProduct = (cardBins, product) =>
  cardBins?.filter((card) => product?.bins?.includes(card?.bin));

export const sortTokens = (tokenBalances) => (a, b) => {
  return (
    convertNumberToSafe(
      getBalanceNumber(tokenBalances?.[b.contract], b?.decimals)
    ) -
    convertNumberToSafe(
      getBalanceNumber(tokenBalances?.[a.contract], a?.decimals)
    )
  );
};
