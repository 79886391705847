import React, { useEffect, useState } from "react";
import { InfinityScroller } from "../InfinityScroller";
import { useTranslate } from "../../contexts";
import { Box, Typography } from "../../UI";
import { dateFormat } from "../../utils/formatting";
import { Operation } from "./Operation";
import { PageLoader } from "../PageLoader/PageLoader";

const OperationItem = ({ operation, onClick }) => {
  const { value, subValue, title, subTitle, colors } = operation || {};

  const { subValueColor, subTitleColor, titleColor, valueColor } = colors || {};

  return (
    <Operation
      onClick={onClick}
      title={title}
      subTitle={subTitle}
      value={value}
      subValue={subValue}
      subValueColor={subValueColor}
      subTitleColor={subTitleColor}
      titleColor={titleColor}
      valueColor={valueColor}
    />
  );
};

export const Operations = ({
  operationsList,
  heightFitContent,
  withLoader,
  minHeight,
  overlayOff,
  fetch,
  height,
  trigerFetch,
  cardsHash,
  withRef,
  schemeStatuses,
  onClick,
  loading,
  setLoading,
  emptyTitle,
}) => {
  const { t } = useTranslate();

  const handleClick = (operation) => {
    if (onClick) {
      onClick(operation);
    }
  };

  const Wrapper = withLoader ? PageLoader : Box;

  return (
    <Box ref={withRef}>
      <Wrapper loading={loading} overlayOff={overlayOff}>
        <InfinityScroller
          trigerFetch={trigerFetch}
          setLoading={setLoading}
          list={operationsList}
          fetch={fetch}
          minHeight={minHeight}
          customHeight={heightFitContent ? "fit-content" : height}
        >
          {!operationsList?.length && !loading && (
            <Typography
              m="16px auto 0 auto"
              ta="center"
              variant="link"
              color="hint"
              width="80%"
            >
              {emptyTitle || t("operationsListEmpty")}
            </Typography>
          )}
          {operationsList?.map((operation, i) => {
            return (
              <OperationItem
                onClick={() => handleClick(operation)}
                schemeStatuses={schemeStatuses}
                key={i}
                operation={operation}
                cardsHash={cardsHash}
              />
            );
          })}
        </InfinityScroller>
      </Wrapper>
    </Box>
  );
};
