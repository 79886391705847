import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box, Row, Typography } from "../../UI";
import { DropdownChain, PageHeader, Tokens } from "../../components";
import { NavLink } from "react-router-dom";
import {
  firstNotZeroFormat,
  getFirstGreaterZero,
  numberWithCommas,
  fromSatoshi,
  convertNumberToSafe,
} from "../../utils/formatting";
import { Navigation } from "../Menu/Menu-Page";
import { linkRoutes, MAIN_ROUTE, walletControls } from "../../routes/routes";
import { useDispatch, useSelector } from "react-redux";
import {
  NavigationBlock,
  PendingBTCBalance,
  PlusButton,
  Wrapper,
} from "./styled";
import { useTranslate, useWallet } from "../../contexts";
import { CHAINS, CHAINS_COIN } from "../../config";
import { Addresses } from "../../components/Addresses/Addresses";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { CardSkeleton } from "../../components/Skeletons/Lists";
import Skeleton from "react-loading-skeleton";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { sortTokens } from "../../utils/dataFiltering";

const formattingAddressValue = (value) =>
  firstNotZeroFormat(fromSatoshi(value));

export const WalletPage = () => {
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const btcDispatch = dispatch.btc;
  const navigationRef = useRef();
  const tokensRef = useRef();
  const { t } = useTranslate();
  const { chains, currentChain, user } = useSelector(({ wallet }) => wallet);
  const tokens = user?.tokens;

  const { addresses } = useSelector(({ btc }) => btc);

  const {
    balanceCoin,
    balanceUsdt,
    isLoadingCoinPrice,
    setRefreshBalance,
    isLoadingBalance,
    setRefreshTokenBalance,
    balanceLoaded,
  } = useWallet();

  const [mBTCFormat, setMBTCFormat] = useState(false);
  const [balanceReady, setReadyBalance] = useState(false);

  const chainCoin = useMemo(
    () => CHAINS_COIN[currentChain?.chain],
    [currentChain?.chain]
  );

  const handleSetMBTCFormat = () => {
    if (!isBTC) return;
    setMBTCFormat((prev) => !prev);
  };

  const handleRefreshToken = useCallback(
    () => setRefreshTokenBalance(),
    [setRefreshTokenBalance]
  );

  // TODO TEST THIS STROKE MB THIS DEPRECATED
  useEffect(() => {
    handleRefreshToken();
  }, []);

  useEffect(() => {
    if (currentChain?.id) {
      setRefreshBalance();
    }
  }, [currentChain?.id]);

  const { tokenBalanceReady, tokenBalancesLoading } = useGetTokenBalances({
    chains,
    tokens,
    userData: user,
    chain: currentChain?.chain,
    setReadyBalance: setReadyBalance,
  });

  useEffect(() => {
    if (currentChain?.id === CHAINS.BTC) {
      btcDispatch.getAddresses();
    }
  }, [btcDispatch, currentChain]);

  const handleSetChain = useCallback(
    (chain) => {
      setReadyBalance(false);
      walletDispatch.setCurrentChain(chain);
    },
    [walletDispatch]
  );

  const Options = useMemo(() => {
    return {
      minusIndent: 0,
    };
  }, [tokens, balanceReady]);

  const { contentHeight } = useGetMainContentHeight(
    tokensRef,
    navigationRef,
    Options
  );

  const balanceString = balanceCoin?.convertedBalance?.toString();

  const firstNotZero = getFirstGreaterZero(balanceString);

  // const balanceFormat =
  //   firstNotZero < 3
  //     ? Number(balanceCoin?.convertedBalance)?.toFixed(3)
  //     : firstNotZeroFormat(balanceString); 121.920001

  const balanceFormat = Number(convertNumberToSafe(balanceString)) || 0;

  const mBTCValue = mBTCFormat
    ? Number(balanceFormat * 1000)?.toFixed(2)
    : balanceFormat;

  const pendingValue = addresses?.reduce((acc, address) => {
    const pending =
      Number(address.balance) !== Number(address.unconfirmedBalance);
    if (pending) {
      acc += fromSatoshi(Number(address.unconfirmedBalance));
    }
    return acc;
  }, 0);

  const mbtcPendingValue = mBTCFormat ? pendingValue * 1000 : pendingValue;

  const addressesSorted = addresses?.sort((a, b) => {
    const aConfirmed = Number(a.balance) !== Number(a.unconfirmedBalance);
    const bConfirmed = Number(b.balance) !== Number(b.unconfirmedBalance);

    return aConfirmed === bConfirmed ? 0 : aConfirmed ? -1 : 1;
  });

  const isBTC = currentChain?.id === CHAINS.BTC;

  return (
    <Wrapper>
      <PageHeader title={t("wallet")} to={MAIN_ROUTE} />
      <Box m="10px auto 20px auto" width="251px">
        <DropdownChain
          chains={chains}
          setChain={handleSetChain}
          currentChain={currentChain}
        />
      </Box>
      {!balanceLoaded ? (
        <Row justifyContent="center">
          <Skeleton
            count={1}
            height="33px"
            width="154px"
            borderRadius="12px"
            style={{ marginBottom: "4px" }}
          />
        </Row>
      ) : (
        <Typography
          ta="center"
          variant="huge"
          m="0 0 4px 0"
          onClick={handleSetMBTCFormat}
        >
          {isBTC && mBTCFormat ? mBTCValue : balanceFormat}{" "}
          {isBTC && mBTCFormat ? `m${chainCoin}` : chainCoin}
        </Typography>
      )}

      {isBTC && pendingValue ? (
        <PendingBTCBalance variant="link">
          {`${mbtcPendingValue} ${mBTCFormat ? "mBTC" : "BTC"}`} (pending)
        </PendingBTCBalance>
      ) : null}
      {isLoadingCoinPrice || isLoadingBalance ? (
        <Row justifyContent="center">
          <Skeleton count={1} height="16px" width="45px" borderRadius="12px" />
        </Row>
      ) : (
        <Typography ta="center" variant="link" color="hint">
          {`$${numberWithCommas(Number(balanceUsdt || 0).toFixed(2))}`}
        </Typography>
      )}
      {isBTC ? null : (
        <Row
          alignItems="center"
          justifyContent="space-between"
          m="20px 0 8px 0"
        >
          <Typography variant="h2">{t("tokens").toUpperCase()}</Typography>
          <NavLink to={`${linkRoutes.addToken}/${currentChain?.id}`}>
            <PlusButton />
          </NavLink>
        </Row>
      )}
      {isBTC ? (
        <Addresses
          mBTCFormat={mBTCFormat}
          withRef={tokensRef}
          formatting={formattingAddressValue}
          addresses={addressesSorted}
          tokensHeight={contentHeight}
        />
      ) : !balanceReady ? (
        <CardSkeleton
          count={3}
          height="62px"
          width="100%"
          borderRadius="12px"
        />
      ) : (
        <Tokens
          sortFunc={sortTokens}
          tokensHeight={contentHeight}
          withRef={tokensRef}
          tokens={tokens}
        />
      )}

      <NavigationBlock ref={navigationRef}>
        <Navigation routes={walletControls} />
      </NavigationBlock>
    </Wrapper>
  );
};
