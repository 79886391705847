import React, { useEffect, useMemo, useState } from "react";
import {
  BackArrowIconStyled,
  Box,
  Link,
  NextArrowIconStyled,
  Row,
  Typography,
} from "../../UI";
import styled from "styled-components";
import { ButtonHorizontal } from "../../components";
import { useGetRouter, useScreen } from "../../hooks";
import { useHistory } from "react-router-dom";
import { useTranslate } from "../../contexts";
import { useSelector } from "react-redux";
import { INTERVAL_FETCH_MS } from "../../config";
import { SpinnerStyled } from "./styled";

export const PROCESSING_STATUSES = {
  success: "success",
  processing: "processing",
  error: "error",
};

const Wrapper = styled(Box)`
  height: calc(100vh - 50px);
  width: 100%;
  justify-content: flex-end;
  align-content: center;
`;

const ProcessingContent = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <Wrapper>
      <Box m={`0 0 ${margin}% 0`}>
        <Row justifyContent="center" m="0 0 18px 0">
          <SpinnerStyled />
        </Row>
        <Typography ta="center" variant="h1">
          {t("processing")}
        </Typography>
        <Typography ta="center" m="16px 0 8px 0" variant="link">
          Application for card card topping up successfully completed! Wait for
          the successful card top up notification.
        </Typography>
        <Typography ta="center" variant="link">
          If you have any troubles, contact our tech support.
        </Typography>
      </Box>
      <ButtonHorizontal
        bottomMarginForScreen
        variant="small"
        onClick={() => cb && cb()}
        title={
          <div>
            <BackArrowIconStyled /> Back to services
          </div>
        }
      />
    </Wrapper>
  );
};

export const ContentWrapper = ({
  content,
  button,
  cb,
  Button,
  bottomMarginForScreen,
}) => {
  return (
    <Wrapper>
      {content}
      {Button || (
        <ButtonHorizontal
          bottomMarginForScreen={bottomMarginForScreen}
          variant="small"
          onClick={() => cb && cb()}
          title={<div>{button}</div>}
        />
      )}
    </Wrapper>
  );
};

const SuccessContent = ({ margin, cb, amount, cardId }) => {
  const { t } = useTranslate();

  return (
    <Wrapper>
      <Box m={`0 0 ${margin}% 0`}>
        <Typography ta="center" fs="100px">
          ✅
        </Typography>
        <Typography ta="center" variant="h1">
          Congrats!
        </Typography>
        <Typography ta="center" m="16px 0 0 0" variant="link">
          {t("yourVirtualCard", {
            cardNumber: cardId || "0000 0000 0000 0000",
          })}
        </Typography>
        <Link ta="center">Payment guide.</Link>
        <Typography m="10px 0 0 0" ta="center" variant="link">
          {t("youCanTopUpYourCard", { amount })}
        </Typography>
      </Box>
      <ButtonHorizontal
        bottomMarginForScreen
        variant="small"
        onClick={() => cb && cb()}
        title={
          <div>
            Continue <NextArrowIconStyled />
          </div>
        }
      />
    </Wrapper>
  );
};

const ErrorContent = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <Wrapper>
      <Box m={`0 0 ${margin}% 0`}>
        <Typography ta="center" fs="100px">
          ❌
        </Typography>
        <Typography ta="center" variant="h1">
          {t("error")}
        </Typography>
        <Typography ta="center" m="16px 0 0 0" variant="link">
          {t("procedureFailed")}
        </Typography>
      </Box>
      <ButtonHorizontal
        bottomMarginForScreen
        variant="small"
        onClick={() => cb && cb()}
        title={
          <div>
            {t("continue")} <NextArrowIconStyled />
          </div>
        }
      />
    </Wrapper>
  );
};

export const ProcessingPage = ({
  cb,
  cbRoute,
  fetch,
  finishedFetch,
  initProps,
  initStatus,
  CongratsComponent,
  ProcessingComponent,
  processingDataBeforeRequest,
  ErrorComponent,
  fetchingWithInitStatus,
  processingRoute,
  continueRoute,
  fromPage,
  errorRoute,
}) => {
  const { windowSize } = useScreen();
  const [status, setStatus] = useState("processing");
  const [props, setProps] = useState({});
  const { handleRoute } = useGetRouter();

  useEffect(() => {
    if (initStatus) {
      setStatus(initStatus);
      setProps(initProps || {});
    }
  }, [initStatus, initProps]);

  const margin = useMemo(
    () => ((windowSize.height - 44) / 100) * 9,
    [windowSize]
  );

  useEffect(() => {
    if (status === "none" && cb) {
      // cb();
    }
  }, [status]);

  useEffect(() => {
    if (processingDataBeforeRequest) {
      setProps(processingDataBeforeRequest);
    }
  }, [processingDataBeforeRequest]);

  useEffect(() => {
    if (
      status !== PROCESSING_STATUSES.processing ||
      (initStatus && !fetchingWithInitStatus)
    )
      return;
    const interval = setInterval(() => {
      if (fetch) {
        fetch()?.then(async (res) => {
          if (res.status !== status) {
            setStatus(res.status);
          }

          let finishedFetchData;
          if (finishedFetch) {
            finishedFetchData = await finishedFetch();
          }

          const finishedData = finishedFetchData?.data?.result || {};
          const initPropsData = initProps || {};

          const data = { ...res.data, ...finishedData, ...initPropsData };

          if (data) {
            setProps((prev) => ({ ...prev, ...data }));
          }
        });
      }
    }, INTERVAL_FETCH_MS);

    return () => {
      clearInterval(interval);
    };
  }, [status, initStatus, fetchingWithInitStatus]);

  const Content = {
    [PROCESSING_STATUSES.processing]: ProcessingComponent || ProcessingContent,
    [PROCESSING_STATUSES.success]: CongratsComponent || SuccessContent,
    [PROCESSING_STATUSES.error]: ErrorComponent || ErrorContent,
  }[status];

  const callbacks = {
    processing: () => {
      handleRoute(processingRoute);
      if (cb) cb();
      if (cbRoute) cbRoute();
    },
    success: (data) => {
      const funcRoute =
        typeof continueRoute === "function"
          ? continueRoute(data)
          : continueRoute;
      handleRoute(funcRoute);
      if (cb) cb();
      if (cbRoute) cbRoute();
    },
    error: () => {
      handleRoute(errorRoute);
      if (cb) cb();
      if (cbRoute) cbRoute();
    },
  }[status];

  return (
    <Content
      {...props}
      fromPage={fromPage}
      onRoute={handleRoute}
      margin={margin}
      cb={callbacks}
    />
  );
};
