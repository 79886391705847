import React from "react";
import { Button, handleClickThatBackHere } from "../../components";
import { Box, Row } from "../../UI";
import { useTranslate } from "../../contexts";
import { STAGES } from "./constants";
import {
  DEPOSIT_ROUTE,
  FROM_PROJECT,
  MDAO_MAKER_BUY_ALLOCATION_ROUTE,
  MDAO_MAKER_PROJECT_ADDRESS,
  MDAO_MAKER_STAKING_ROUTE,
  mdaoMakerBuyTicketsRoute,
  PASS_KYC_ROUTE,
} from "../../routes/routes";
import { useGetRouter } from "../../hooks";
import { MenuBlock } from "./styled";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { FROM_MAKER_PROJECT } from "../../config";

const KYCAMLButtonRow = styled(Row)`
  display: grid;
  grid-template-columns: ${({ row }) => row || "1fr 1fr"};
  grid-gap: 8px 8px;
`;

const KYCAMLButtons = ({ aml, kyc, onCheckAML }) => {
  const { t } = useTranslate();
  const { pathname } = useLocation();
  const { handleRoute } = useGetRouter();
  let row = "";

  if (aml) {
    row += "1fr";
  }
  if (kyc) {
    row += " 1fr";
  }

  return (
    <KYCAMLButtonRow row={row}>
      {aml ? (
        <Button
          activeButton
          emoji="🔬️"
          title={t("freeAmlCheck")}
          onClick={() => onCheckAML && onCheckAML()}
        />
      ) : null}
      {kyc ? (
        <Button
          activeButton
          emoji="🛂️"
          title={t("passKyc")}
          onClick={() => {
            handleClickThatBackHere({ pathname, from: FROM_MAKER_PROJECT })();
            handleRoute(PASS_KYC_ROUTE);
          }}
        />
      ) : null}
    </KYCAMLButtonRow>
  );
};

const ControlsMyParams = ({
  projectId,
  amlRequired,
  kycRequired,
  onCheckAML,
  availableTickets,
  have1TicketsForAml,
}) => {
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();
  const usdtStakingButton =
    process.env.REACT_APP_USDT_STAKING_BUTTON === "true";

  return (
    <Box>
      <KYCAMLButtons
        aml={amlRequired && have1TicketsForAml}
        kyc={kycRequired}
        onCheckAML={onCheckAML}
      />
      {availableTickets ? (
        <Box m="8px 0 0 0">
          {usdtStakingButton ? (
            <MenuBlock>
              <Button
                activeButton
                emoji="💼️️️"
                title={t("stakeUsdt")}
                onClick={() =>
                  handleRoute(`${MDAO_MAKER_STAKING_ROUTE}/${projectId}`)
                }
              />
              <Button
                activeButton
                emoji="🎟️️️"
                title={t("buyTickets")}
                onClick={() => handleRoute(mdaoMakerBuyTicketsRoute(projectId))}
              />
            </MenuBlock>
          ) : (
            <Button
              activeButton
              emoji="🎟️️️"
              title={t("buyTickets")}
              onClick={() => handleRoute(mdaoMakerBuyTicketsRoute(projectId))}
            />
          )}
        </Box>
      ) : usdtStakingButton ? (
        <Box m="8px 0 0 0">
          {" "}
          <Button
            activeButton
            emoji="💼️️️"
            title={t("stakeUsdt")}
            onClick={() =>
              handleRoute(`${MDAO_MAKER_STAKING_ROUTE}/${projectId}`)
            }
          />
        </Box>
      ) : null}
    </Box>
  );
};

const ControlsHolding = ({ subscribed }) => {
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();

  return (
    <Box>
      {subscribed && (
        <Button
          activeButton
          emoji="📥️️️"
          title={t("depositUsdt")}
          onClick={() => handleRoute(DEPOSIT_ROUTE)}
        />
      )}
    </Box>
  );
};

const ControlsSubscribe = ({
  onSubscribe,
  onCheckAML,
  amlRequired,
  subscribed,
  usdtBalanceEnought,
  kycRequired,
}) => {
  const { t } = useTranslate();
  const disable = amlRequired || kycRequired;

  return (
    <Box>
      <KYCAMLButtons
        aml={amlRequired && usdtBalanceEnought}
        kyc={kycRequired}
        onCheckAML={onCheckAML}
      />
      {subscribed || disable || !usdtBalanceEnought ? null : (
        <Box m="8px 0 0 0">
          <Button
            activeButton
            emoji="✍️️️"
            title={t("subscribe")}
            onClick={onSubscribe}
          />
        </Box>
      )}
    </Box>
  );
};

const ControlsPublicBuyAllocation = ({
  subscribed,
  projectId,
  amlRequired,
  kycRequired,
  aml,
  have1TicketsForAml,
  onCheckAML,
  haveOneTicketForAllocation,
}) => {
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();
  const buyAllocationButton =
    process.env.REACT_APP_BUY_ALLOCATION_PROJECT_BUTTON === "true";

  return (
    <Box>
      {subscribed && (
        <Box>
          <KYCAMLButtons
            aml={amlRequired && have1TicketsForAml}
            kyc={kycRequired}
            onCheckAML={onCheckAML}
          />
          {buyAllocationButton &&
          haveOneTicketForAllocation &&
          !kycRequired &&
          !amlRequired ? (
            <Row m="8px 0 0 0">
              <Button
                activeButton
                emoji="💸️️️"
                title={t("buyAllocationButton")}
                onClick={() =>
                  handleRoute(`${MDAO_MAKER_BUY_ALLOCATION_ROUTE}/${projectId}`)
                }
              />
            </Row>
          ) : null}
        </Box>
      )}
    </Box>
  );
};

const ControlsPrivateBuyAllocation = ({
  projectId,
  amlRequired,
  kycRequired,
  onCheckAML,
  have1TicketsForAml,
  aml,
  haveOneTicketForAllocation,
}) => {
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();
  const buyAllocationButton =
    process.env.REACT_APP_BUY_ALLOCATION_PROJECT_BUTTON === "true";

  return (
    <Box>
      <KYCAMLButtons
        aml={amlRequired && have1TicketsForAml}
        kyc={kycRequired}
        onCheckAML={onCheckAML}
      />
      {buyAllocationButton &&
      haveOneTicketForAllocation &&
      !kycRequired &&
      !amlRequired ? (
        <Row m="8px 0 0 0">
          <Button
            activeButton
            emoji="💸️️️"
            title={t("buyAllocationButton")}
            onClick={() =>
              handleRoute(`${MDAO_MAKER_BUY_ALLOCATION_ROUTE}/${projectId}`)
            }
          />
        </Row>
      ) : null}
    </Box>
  );
};

const ControlsPrivateTicketDistribution = ({
  amlRequired,
  kycRequired,
  onCheckAML,
  have1TicketsForAml,
}) => {
  return (
    <Box>
      <KYCAMLButtons
        aml={amlRequired && have1TicketsForAml}
        kyc={kycRequired}
        onCheckAML={onCheckAML}
      />
    </Box>
  );
};

const ControlsDistribution = ({ projectId, projectName, viewSetAddress }) => {
  const { handleRoute } = useGetRouter();
  const { t } = useTranslate();

  return (
    <Box>
      {/*<Button*/}
      {/*  activeButton*/}
      {/*  emoji="💸️️️"*/}
      {/*  title={t("claimToken", { tokenName })}*/}
      {/*  onClick={() => handleRoute(MAIN_ROUTE)}*/}
      {/*/>*/}
      {viewSetAddress ? (
        <Button
          activeButton
          emoji="💎️️️️"
          title={t("setProjectAddressButton")}
          onClick={() =>
            handleRoute(`${MDAO_MAKER_PROJECT_ADDRESS}/${projectId}`)
          }
        />
      ) : null}
    </Box>
  );
};

const ControlsEmpty = () => {
  return <Box></Box>;
};

const stagesControls = {
  [STAGES.PREPARATION]: ControlsMyParams,
  [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: ControlsPrivateTicketDistribution,
  [STAGES.SUBSCRIPTIONS]: ControlsSubscribe,
  [STAGES.HOLDING]: ControlsHolding,
  [STAGES.PUBLIC_TICKET_DISTRIBUTION]: ControlsEmpty,
  [STAGES.TOKEN_DISTRIBUTION]: ControlsDistribution,
  [STAGES.PROJECT_COMPLETE]: ControlsEmpty,
  [STAGES.PRIVATE_SALE]: ControlsPrivateBuyAllocation,
  [STAGES.PUBLIC_SALE]: ControlsPublicBuyAllocation,
};

const ButtonWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 16px 16px 36px 16px;
  //max-width: 480px;
`;
export const MakerProjectControl = ({
  stage,
  withRef,
  projectId,
  tokenName,
  viewSetAddress,
  onSubscribe,
  aml,
  have1TicketsForAml,
  amlRequired,
  subscribed,
  kycRequired,
  usdtBalanceEnought,
  projectName,
  availableTickets,
  onCheckAML,
  haveOneTicketForAllocation,
  hideControls,
  dirtyWallet,
}) => {
  const Component = stagesControls[stage] || ControlsEmpty;

  return (
    <ButtonWrapper ref={withRef}>
      {dirtyWallet ? null : (
        <Component
          aml={aml}
          have1TicketsForAml={have1TicketsForAml}
          viewSetAddress={viewSetAddress}
          usdtBalanceEnought={usdtBalanceEnought}
          onCheckAML={onCheckAML}
          haveOneTicketForAllocation={haveOneTicketForAllocation}
          availableTickets={availableTickets}
          amlRequired={amlRequired}
          subscribed={subscribed}
          kycRequired={kycRequired}
          tokenName={tokenName}
          projectName={projectName}
          projectId={projectId}
          onSubscribe={onSubscribe}
          hideControls={hideControls}
        />
      )}
    </ButtonWrapper>
  );
};
