import React, { useEffect, useState } from "react";
import { Spinner } from "../Spinner/Spinner";
import styled from "styled-components";
import { Box } from "../../UI";
import { AnimatePresence } from "framer-motion";
import { motion } from "framer-motion";

const Wrapper = styled.div`
  position: relative;
`;

const WrapperLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: fit-content;
  height: fit-content;
  z-index: 50001;
`;

const ContentWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`;

const Overlay = styled.div`
  position: absolute;
  left: -16px;
  right: -16px;
  top: -16px;
  bottom: -16px;
  background-color: ${({ theme }) => theme.colors.background};
  opacity: 0.3;
  transition: 0.3s;
  z-index: 5000;
`;

export const PageLoader = ({ children, loading, overlayOff, fullSize }) => {
  return (
    <Wrapper>
      {loading ? (
        <
          // height={fullSize ? "100%" : "auto"}
          // width={fullSize ? "100%" : "auto"}
        >
          <WrapperLoader>
            <Spinner />
          </WrapperLoader>
          {!overlayOff && <Overlay />}
        </>
      ) : null}
      {children}
    </Wrapper>
  );
};
