import { createGlobalStyle } from "styled-components";

const bot = window.Telegram?.WebApp;

export const GlobalStyles = createGlobalStyle`


    body {
      //height: 100%;
	  //  position: fixed;
	    width: 100%;
	  //  height: 100%;
	    //overflow: hidden;
      //max-height: 100vh;
      //min-height: 100vh;

       background-color: ${({ theme }) => theme.colors.background};

      //overflow: hidden;
      #root {
	      //overflow-y: scroll;
	      height: 100%;
	      width: 100%;
        //height: 100vh;

        //height: fit-content;
	      //overflow: hidden;
        //overflow: scroll;

      }

      *::-webkit-scrollbar {
        display: none;
      }

      * {
        box-sizing: border-box;

      }

      a {
        text-decoration: none;
      }

      .notistack-SnackbarContainer {
        flex-direction: column;
        top: 0 ;
        padding-top: 10px;
        width: 100%;

        @media (min-width: 480px) {
          //max-width: 440px;
        }

        @media (min-width: 600px) {
          align-items: stretch;
        }

        & > div {
          //width: 100%;
        }
      } 
    }`;
