import styled from "styled-components";
import { Box, Paper } from "../../UI";

export const PaperStyled = styled(Paper)`
  padding: 0;
  display: flex;
  overflow: hidden;
`;

export const ChainItem = styled(Box)`
  padding: 14px 0;
  text-align: center;
  min-height: 60px;
  justify-content: center;
  background-color: ${({ theme, active }) =>
    active ? theme.colors.button : "transparent"};
  border-right: ${({ theme }) => `1px solid ${theme.colors.background}`};
  opacity: ${({ disabled }) => (disabled ? "0.1" : 1)};
  transition: background-color 0.2s;

  &:last-child {
    border-right: none;
  }
`;
