import { AxiosGet, AxiosPost } from "../rest/axios";
import { URLS } from "../api";
import { STATUSES } from "../pages/Maker/helpers";
import { CARD_STATUTES } from "../config";

export default {
  state: {
    products: [],
    templates: [],
    schemes: [],
    transactions: null,
    requirements: null,
    buyUsdtRequirements: null,
    operations: [],
    bins: [],
    cards: null,
  },
  reducers: {
    setCards(state, payload) {
      return {
        ...state,
        cards: payload,
        // cards: [],
      };
    },
    setTemplates(state, payload) {
      return {
        ...state,
        templates: payload,
      };
    },
    setProducts(state, payload) {
      return {
        ...state,
        products: payload,
      };
    },
    setTransactions(state, payload) {
      return {
        ...state,
        transactions: payload,
      };
    },
    setRequirements(state, payload) {
      return {
        ...state,
        requirements: payload,
      };
    },
    setBuyUsdtRequirements(state, payload) {
      return {
        ...state,
        buyUsdtRequirements: payload,
      };
    },
    setOperations(state, payload) {
      return {
        ...state,
        operations: payload,
      };
    },
    setSchemes(state, payload) {
      return {
        ...state,
        schemes: payload,
      };
    },
    setBins(state, payload) {
      return {
        ...state,
        bins: payload,
      };
    },
  },
  effects: {
    getCards(data) {
      return AxiosGet({
        url: URLS.getCards,
        cache: data?.cache,
      })
        .then((res) => this.setCards(res?.data?.result))
        .catch((e) => console.error(e.message));
    },
    getCard({ cardId, cache }) {
      return AxiosGet({
        url: URLS.getCard,
        params: {
          cardId,
        },
        cache,
      });
    },
    getBins() {
      return AxiosGet({
        url: URLS.getBins,
      }).then((res) => {
        // this.setBins(res.data.result);
        this.setTemplates(
          res?.data?.result?.filter((template) => template.active)
        );
        this.setSchemes(
          res?.data?.result.reduce((acc, el) => {
            acc[el.binId] = { ...el, scheme: el.scheme, country: el.country };
            return acc;
          }, {})
        );
        return res;
      });
    },
    getProducts() {
      return AxiosGet({
        url: URLS.getProducts,
      })
        .then((res) => this.setProducts(res?.data?.result))
        .catch((e) => console.error(e.message));
    },
    getTemplates() {
      return AxiosGet({
        url: URLS.getBins,
      })
        .then((res) => {
          this.setTemplates(
            res?.data?.result?.filter((template) => template.active)
          );
          this.setSchemes(
            res?.data?.result.reduce((acc, el) => {
              acc[el.binId] = { ...el, scheme: el.scheme, country: el.country };
              return acc;
            }, {})
          );
          return res;
        })
        .catch((e) => console.error(e.message));
    },
    getCardTransactions({ cardId }) {
      return AxiosGet({
        url: URLS.getCardTransaction,
        params: {
          cardId,
        },
      })
        .then((res) => {
          this.setTransactions(res?.data?.result);
          return res;
        })
        .catch((e) => console.error(e.message));
    },
    getRequirements() {
      return AxiosGet({
        url: URLS.getRequirements,
      })
        .then((res) => {
          this.setRequirements(res?.data?.result);
        })
        .catch((e) => console.error(e.message));
    },
    getTopupPrice({ cardId, deposit, paymentType }) {
      return AxiosGet({
        url: URLS.getTopupPrice,
        params: {
          cardId,
          deposit,
          paymentType,
        },
      });
    },

    getCreateCardPrice({ binId, deposit, paymentType, promoCode }) {
      return AxiosGet({
        url: URLS.getCreateCardPrice,
        params: {
          binId,
          deposit,
          paymentType,
          promoCode,
        },
      });
    },
    closeCard({ cardId, code }) {
      return AxiosPost({
        url: URLS.closeCard,
        data: {
          cardId,
          code,
        },
      });
    },
    createCard({ binId, deposit, paymentType, promoCode, code }) {
      return AxiosPost({
        url: URLS.createCard,
        data: {
          binId,
          deposit,
          paymentType,
          code,
          promoCode,
        },
      });
    },
    topup({ cardId, deposit, paymentType, code }) {
      return AxiosPost({
        url: URLS.topupCard,
        data: {
          cardId,
          deposit,
          paymentType,
          code,
        },
      });
    },
    freezeCard({ cardId, code }) {
      return AxiosPost({
        url: URLS.freezeCard,
        data: {
          cardId,
          code,
        },
      });
    },
    unfreezeCard({ cardId, code }) {
      return AxiosPost({
        url: URLS.unfreezeCard,
        data: {
          cardId,
          code,
        },
      });
    },
    getOperations({ offset = 10, cardId, cache }) {
      return AxiosGet({
        url: URLS.getCardOperations,
        params: { offset, cardId },
        cache,
      }).then((res) => {
        this.setOperations(res?.data.result);

        return res;
      });
    },
    getCardOperation({ operationId }) {
      return AxiosGet({
        url: URLS.getCardOperation,
        params: { operationId },
        cache: false,
      });
    },
    getCardOperationStatus({ operationId }) {
      return AxiosGet({
        url: URLS.getOperationStatus,
        params: { operationId },
        cache: false,
      });
    },
    getBuyUsdtOperations({ offset = 0 }) {
      return AxiosGet({
        url: URLS.getBuyUsdtOperations,
        params: { offset },
      });
    },
    getBuyUsdtOperationStatus({ operationId }) {
      return AxiosGet({
        url: URLS.getBuyUsdtOperationStatus,
        params: { operationId },
        cache: false,
      });
    },
    getBuyUsdtOperation({ operationId }) {
      return AxiosGet({
        url: URLS.getBuyUsdtOperation,
        params: { operationId },
        cache: false,
      });
    },
    getBuyUsdtPrice({ amount }) {
      return AxiosGet({
        url: URLS.getBuyUsdtPrice,
        params: { amount },
      });
    },
    getBuyUsdtRequirements() {
      return AxiosGet({
        url: URLS.getBuyUsdtRequirements,
      })
        .then((res) => {
          this.setBuyUsdtRequirements(res?.data?.result);
        })
        .catch((e) => console.error(e.message));
    },
    buyUsdtMethod({ amount, code }) {
      return AxiosPost({
        url: URLS.buyUsdt,
        data: { amount, code },
      });
    },
  },
};
