import Axios from "axios";
import { setupCache } from "axios-cache-interceptor";

const bot = window.Telegram?.WebApp;
const API_URL = process.env.REACT_APP_API_URL;

export const axiosInstance = Axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

const instance = setupCache(axiosInstance, { debug: console.log });

const dataInit = process.env.REACT_APP_LOCAL_DATA_INIT || bot.initData;

const parsePath = (path) => {
  if (path.indexOf(API_URL) === 0) {
    return `Stage1 | ${path.slice(path.indexOf(API_URL) + API_URL?.length)}`;
  }
  return path;
};

const AxiosGet = ({ url, params, headers, data, cache }) => {
  const objReq = {
    method: "get",
    url,
    data,
    params,
    cache: cache !== undefined ? cache : true,
    headers: {
      "Content-Type": "application/json",
      "telegram-init-data": dataInit,
      ...headers,
    },
  };

  return instance(objReq)
    .then((res) => {
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        console.log(`%c AXIOS GET ${parsePath(url)}`, "color: green", res.data);
      }

      if (
        res?.data?.code &&
        res?.data?.code === 74106 &&
        !localStorage.getItem("SE")
      ) {
        localStorage.setItem("SE", "true");
        window.location.href = "/session-expired";
      } else {
        return res;
      }
    })
    .catch((err) => {
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        console.log(
          `%c AXIOS GET ERROR ${parsePath(url)}`,
          "color: red",
          err.response
        );
      }

      const res = err.response;
      if (
        res?.data?.code &&
        res?.data?.code === 74106 &&
        !localStorage.getItem("SE")
      ) {
        localStorage.setItem("SE", "true");
        window.location.href = "/session-expired";
      } else {
        console.log(`%c AXIOS GET ERROR ${parsePath(url)}`, "color: red", err);
        throw err;
      }
    });
};

const AxiosPost = ({ url, data, headers }) => {
  const objReq = {
    method: "post",
    url,
    data,
    headers: {
      "Content-Type": "application/json",
      "telegram-init-data": dataInit,
      ...headers,
    },
  };

  return instance(objReq)
    .then((res) => {
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        console.log(
          `%c AXIOS POST ${parsePath(url)}`,
          "color: green",
          res.data
        );
      }

      if (
        res?.data?.code &&
        res?.data?.code === 74106 &&
        !localStorage.getItem("SE")
      ) {
        localStorage.setItem("SE", "true");
        window.location.href = "/session-expired";
      }

      return res;
    })
    .catch((err) => {
      if (process.env.REACT_APP_NODE_ENV !== "production") {
        console.log(
          `%c AXIOS POST ERROR ${parsePath(url)}`,
          "color: red",
          err.response
        );
      }

      const res = err.response;

      if (
        res?.data?.code &&
        res?.data?.code === 74106 &&
        !localStorage.getItem("SE")
      ) {
        localStorage.setItem("SE", "true");
        window.location.href = "/session-expired";
      } else {
        throw err;
      }
    });
};

export { AxiosGet, AxiosPost };
