import React, { useEffect, useRef, useState } from "react";
import { Box, Link, Row, Typography } from "../../UI";
import { ButtonHorizontal, LinkComponent, PageHeader } from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";
import styled from "styled-components";
import { SuccessPage } from "./ProcessingPages/SuccessPage";
import { ProcessingPage } from "./ProcessingPages/ProcessingPage";
import { BUY_USDT_ROUTE, PAY_FOR_SERVICES_ROUTE } from "../../routes/routes";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import {
  FAILED,
  findErrorCode,
  FROM_PAGE,
  LOCAL_STORAGE_PASS_KYC_URL_FROM,
  NONE,
  SUCCESS,
} from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { PROCESSING_STATUSES } from "../IFrame/Processing-Page";
import { useHistory } from "react-router-dom";

const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
  justify-content: space-between;
`;

export const PassKycPage = () => {
  const { t } = useTranslate();
  const history = useHistory();
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { showErrorCode } = useSnackbar();
  const { isKyc } = useSelector(({ wallet }) => wallet);

  const [paymentLink, setPaymentLink] = useState("");
  const [processingBefore, setProcessingBefore] = useState(false);

  const URL_FROM = localStorage
    .getItem(LOCAL_STORAGE_PASS_KYC_URL_FROM)
    ?.replace("/", "");

  const handleCleanFromRoute = () => {
    localStorage.removeItem(LOCAL_STORAGE_PASS_KYC_URL_FROM);
  };

  useEffect(() => {
    if (!paymentLink) {
      walletDispatch
        .startKyc()
        .then((res) => {
          if (res?.data.status === SUCCESS) {
            setPaymentLink(res?.data.result);
          }
          return res;
        })
        .catch((e) => {
          showErrorCode(e);
        });
    }
  }, [walletDispatch]);

  const handleRefreshStatus = () => {
    return walletDispatch.getKycStatus().then((res) => {
      if (res.result === "processing" || res.result === "none") {
        return PROCESSING_STATUSES.processing;
      }
      if (res.result === SUCCESS) {
        return PROCESSING_STATUSES.success;
      }
      if (res.result === FAILED) {
        return PROCESSING_STATUSES.error;
      }
    });
  };

  const handleCleanFrom = () => {
    localStorage.removeItem(FROM_PAGE);
  };

  const handleBackRouteFrom = () => {
    history.replace(localStorage.getItem(LOCAL_STORAGE_PASS_KYC_URL_FROM));
    localStorage.removeItem(LOCAL_STORAGE_PASS_KYC_URL_FROM);
  };

  return (
    <IFrameLayout
      processingFetch={handleRefreshStatus}
      CongratsComponent={SuccessPage}
      ProcessingComponent={ProcessingPage}
      ErrorComponent={ProcessingPage}
      title={t("passKyc")}
      processingRoute={URL_FROM}
      processingBefore={processingBefore}
      continueRoute={URL_FROM}
      errorRoute={URL_FROM}
      cbRoute={handleCleanFromRoute}
      cb={() => {
        setPaymentLink(null);
      }}
    >
      <Wrapper>
        <Box gap="10px">
          <PageHeader title={t("passKyc")} cb={handleCleanFrom} />
          <Typography variant="link">{t("dearUserToPayInRub")}</Typography>
          <Typography variant="link">
            {t("ifYouHaveAlreadyPassedKyc")}
          </Typography>
          <Typography variant="link">
            {t("toPassKycPleaseHaveYourPassport")}
          </Typography>{" "}
          <Typography variant="link">{t("toCompleteKycProcedure")}</Typography>
          <Typography variant="link">{t("tapPassKYC")}</Typography>
          <Typography variant="link">{t("ifForAnyReason")}</Typography>
          <Box flexFlow="wrap">
            <Typography variant="link">{t("doYoHaveTroubles")}</Typography>
            <Box m="0 0 0 4px"></Box>
            <LinkComponent
              to={t("guides.linkReadGuide")}
              title={t("readGuide")}
            />
            <Typography variant="link">
              {t("experiencingDifficulties")}
            </Typography>
          </Box>
        </Box>
        {paymentLink && (
          <LinkComponent to={paymentLink}>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              title={t("passKyc")}
              onClick={() => handleBackRouteFrom()}
            />
          </LinkComponent>
        )}
      </Wrapper>
    </IFrameLayout>
  );
};
