import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Web3 from "web3";
import TronWeb from "tronweb";
import { useDispatch, useSelector } from "react-redux";
import { validate } from "bitcoin-address-validation";
import {
  convertNumberToSafe,
  fromSatoshi,
  getBalanceNumber,
  getEthBalanceNumber,
  toSatoshi,
} from "../utils/formatting";
import { convertGasToCoin } from "../utils/converting-tools";
import { CHAINS, mainCoins } from "../config";
import { abiToken } from "../config/abies";

const createWeb3Provider = async (rpc) => {
  if (rpc) {
    return new Web3(
      new Web3.providers.HttpProvider(rpc, {
        timeout: 10000,
      })
    );
  }
};

const createTronProvider = async (rpc) =>
  await new TronWeb({
    fullHost: rpc,
  });

const switchProviders = async (chain, rpc) => {
  switch (chain) {
    case "ETH":
    case "BSC":
    case "POL":
      return await createWeb3Provider(rpc);
    case "TRX":
      return await createTronProvider(rpc);
    default:
      return await createWeb3Provider(rpc);
  }
};

export const useWeb3 = () => {
  const [provider, setProvider] = useState(null);
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const providerRef = useRef(null);
  const dispatch = useDispatch();
  const dispatchWallet = dispatch.wallet;

  const ignoreProviders = [CHAINS.BTC];
  const web3CacheProviders = useRef({});

  const web3Interface = useCallback(
    async (chainName, prov) => {
      if (!prov) return;

      switch (chainName) {
        case "TRX":
          return {
            getBalance: async ({ account }) => {
              const balance = prov && (await prov.trx?.getBalance(account));
              const decimals = mainCoins[chainName]?.decimals;

              return {
                convertedBalance: convertNumberToSafe(
                  getBalanceNumber(balance, decimals)
                ),
                unconvertedBalance: balance,
              };
            },
            getGasPrice: async ({ amount, address, chain }) => {
              const response = await dispatchWallet.getWithdrawFee({
                chain,
                amount,
                address,
              });

              const result = response.data.result;

              return {
                convertedValue: result,
                unconvertedValue: result,
              };
            },
            getTokenGasPrice: async ({ wallet, amount, chain, tokenId }) => {
              const response = await dispatchWallet.getWithdrawTokenFee({
                chain,
                amount,
                address: wallet,
                tokenId,
              });

              return response.data.result;
            },
            getContract: async ({ address, abi }) => {
              try {
                await prov.setAddress(address);
                return await prov.contract().at(address);
              } catch (e) {
                return e;
              }
            },
            checkValidAddress: (wallet) => {
              return wallet && prov?.isAddress(wallet);
            },
          };
        case "BTC":
          return {
            getBalance: ({ balances }) => {
              return new Promise((rs, rj) => {
                const balance = balances?.length
                  ? balances.reduce((acc, balance) => {
                      if (balance?.balance === balance?.unconfirmedBalance) {
                        acc += Number(fromSatoshi(balance?.balance));
                      }
                      return acc;
                    }, 0)
                  : 0;
                rs({
                  convertedBalance: convertNumberToSafe(balance),
                  unconvertedBalance: balance,
                });
              });
            },
            getGasPrice: async ({ amount, address, chain, satB }) => {
              const response = await dispatchWallet.getWithdrawFee({
                chain,
                amount: toSatoshi(amount),
                address,
                satB,
              });

              const result = response.data.result;
              // const result = 1450;

              return {
                convertedValue: fromSatoshi(result),
                unconvertedValue: result,
              };
            },
            getTokenGasPrice: async ({ wallet, amount, contract }) => {
              return 0;
            },
            getGasPriceToken: async ({ amount, address, chain, tokenId }) => {
              return 0;
            },
            getContract: async ({ address }) => {
              return null;
            },
            checkValidAddress: (wallet) => validate(wallet),
          };
        default:
          return {
            getBalance: async ({ account }) => {
              const balance = await prov.eth?.getBalance(account);

              return {
                convertedBalance: Number(
                  convertNumberToSafe(getEthBalanceNumber(balance))
                ),
                unconvertedBalance: balance,
              };
            },
            getGasPrice: async ({ value, chain }) => {
              const gas = await prov.eth.getGasPrice();

              const convertedValue = convertNumberToSafe(
                convertGasToCoin({
                  chain,
                  gas,
                  gasFactor: value,
                })
              );

              return { convertedValue, unconvertedValue: gas };
            },
            getTokenGasPrice: async ({ wallet, amount, contract }) => {
              const { methods } = new prov.eth.Contract(abiToken, contract);

              const gas = await methods
                .transfer(wallet, amount)
                .estimateGas({ from: user?.wallet });

              const gasPrice = await prov.eth.getGasPrice();
              return gas * gasPrice;
            },
            getContract: ({ abi, address, contractOptions }) => {
              return (
                prov.eth?.Contract &&
                new prov.eth.Contract(abi, address, contractOptions)
              );
            },
            checkValidAddress: (address) => {
              return !!(
                prov?.utils?.isAddress && prov?.utils?.isAddress(address)
              );
            },
          };
      }
    },
    [provider, user]
  );

  const providerInterface = useCallback(
    async (chain) => {
      let chainsHas = chains;

      if (!chainsHas?.length) {
        const res = await dispatchWallet.getChains();
        chainsHas = res?.data?.result;
      }

      const foundChain = chainsHas?.find((el) => el?.chain === chain);

      if (chain && chainsHas?.length) {
        if (!web3CacheProviders.current?.[chain]) {
          const initProvider = await switchProviders(
            foundChain?.chain,
            foundChain?.provider?.rpc
          );

          if (
            !ignoreProviders.includes(chain) &&
            (!provider || provider !== initProvider)
          ) {
            web3CacheProviders.current[chain] = initProvider;
            setProvider(initProvider);
            providerRef.current = initProvider;
          }

          const resultProvider = initProvider
            ? await initProvider
            : await provider;

          return await web3Interface(chain, resultProvider);
        } else {
          const cacheProvider = web3CacheProviders.current[chain];
          setProvider(cacheProvider);
          providerRef.current = cacheProvider;
          return await web3Interface(chain, cacheProvider);
        }
      }
    },
    [provider, web3Interface, chains]
  );

  return { web3: provider, web3Interface: providerInterface };
};
