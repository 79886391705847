import Skeleton from "react-loading-skeleton";
import { Box } from "../../UI";
import React from "react";
import styled from "styled-components";

export const CardSkeleton = styled(Skeleton)`
  margin-bottom: 8px;
`;

export const ListSkeletons = () => {
  return (
    <Box gap="10px">
      <Skeleton
        count={1}
        height="21px"
        width="162px"
        style={{ marginTop: "16px" }}
        borderRadius="12px"
      />
      <CardSkeleton count={3} height="62px" width="100%" borderRadius="12px" />
    </Box>
  );
};
