import React, { useEffect, useState } from "react";

export const useGetColorsError = ({
  balance,
  amount,
  successColor,
  errorColor,
  readyValue,
}) => {
  const [notEnoughFunds, setNotEnoughFunds] = useState(false);

  useEffect(() => {
    (async () => {
      if (Number(balance) < Number(amount)) {
        setNotEnoughFunds(true);
      } else {
        setNotEnoughFunds(false);
      }
    })();
  }, [balance, amount]);

  const readyValueHas = readyValue !== undefined;

  const colorError = notEnoughFunds ? errorColor : successColor;
  const color = readyValueHas
    ? readyValue
      ? colorError
      : successColor
    : colorError;

  return {
    color,
    hasError: notEnoughFunds,
  };
};
