import styled from "styled-components";
import { Paper } from "../../UI";

export const Item = styled(Paper)`
  width: 100%;
  box-sizing: border-box;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const IconImage = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
`;

export const StubImage = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.background};
  align-items: center;
`;
