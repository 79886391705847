import styled from "styled-components";
import {
  Box,
  Paper,
  SUCCESS_FIELD_NAME,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import { Card, CardIcon } from "../../components/Cards/Cards";
import React from "react";

export const ChooseCardWrapper = styled(Paper)`
  position: relative;
  padding: 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ChooseCardHeaderWrapper = styled(Box)`
  width: 100%;
`;

export const ChooseCardContentWrapper = styled(Box)`
  padding: 12px 16px;
  width: 100%;
  display: grid;
  grid-gap: 0 7%;
  grid-template-columns: 1fr 1fr 1fr;
`;

export const CardStyled = styled(Card)`
  width: 100%;
  box-shadow: ${({ theme }) => theme.shadows.shadow1};
`;

export const TypographyListStyled = styled(Typography)`
  position: relative;
  display: flex;
  align-items: center;
  width: fit-content;
  margin-bottom: 4px;

  &:before {
    content: "";
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: white;
    margin-right: 4px;
  }
`;

export const ProductWrapper = styled(Paper)`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ active, theme }) =>
    active ? theme.colors.button : theme.colors.background2};
`;

export const ChooseByServicesWrapper = styled(Box)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 8px;
`;

export const ContentWrapper = styled(Box)`
  overflow: scroll;
`;

export const Wrapper = styled(Box)`
  overflow: hidden;
  position: relative;
`;

export const ButtonWrapper = styled(Box)`
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0px 16px 16px 16px;
  //max-width: 480px;
`;
export const ChooseCardElement = ({
  bin,
  cardNumber,
  maskNumber,
  activeProducts,
  onClick,
  balance,
  title,
  subTitle,
  products,
}) => {
  return (
    <ChooseCardWrapper onClick={onClick}>
      <ChooseCardHeaderWrapper>
        <CardStyled
          cardIconVariant={1}
          bin={bin}
          cardNumber={cardNumber || ""}
          IconComponent={CardIcon}
          subTitle={subTitle}
          title={title}
        />
      </ChooseCardHeaderWrapper>
      <ChooseCardContentWrapper>
        {products?.map((product, i) => {
          const active = activeProducts?.includes(String(product.productId));
          return (
            <TypographyListStyled
              key={i}
              variant="small"
              fw="400"
              color={active ? SUCCESS_FIELD_NAME : WHITE_FIELD_NAME}
            >
              {product.title}
            </TypographyListStyled>
          );
        })}
      </ChooseCardContentWrapper>
    </ChooseCardWrapper>
  );
};
