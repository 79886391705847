export const getProductsByBin = (products) =>
  products?.reduce((acc, el) => {
    const { bins, title, productId } = el;

    bins.forEach((bin) => {
      if (!acc[bin]) {
        acc[bin] = [{ title, productId }];
      } else {
        acc[bin].push({ title, productId });
      }
    });

    return acc;
  }, {});
