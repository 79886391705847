import React from "react";
import styled, { css } from "styled-components";
import { ReactComponent as InfoTipIcon } from "../../assets/svg/info-tip.svg";
import { useModal, useSnackbar, useTranslate } from "../../contexts";
import { CONFIRM_MODAL, INFO_TIP_MODAL } from "../Modals";
import { Box } from "../../UI";

export const InfoIcon = styled(InfoTipIcon)`
  width: 16px;
  height: 16px;
`;

const positions = {
  rightCenter: css`
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    height: 100%;
    display: flex;
    align-items: center;
  `,
  leftCenter: css`
    left: calc(0% + 8px);
    top: 50%;
    transform: translate(-100%, -50%);
  `,
  topCenter: css`
    left: 50%;
    top: calc(0% + 8px);
    transform: translate(-50%, -100%);
  `,
  bottomCenter: css`
    left: 50%;
    bottom: calc(0% - 8px);
    transform: translate(-50%, 100%);
  `,
};

const Wrapper = styled.div`
  position: relative;
  width: fit-content;

  ${({ styles }) => styles};
`;

const IconWrapper = styled.div`
  position: absolute;

  ${({ position }) => position};
`;

export const InfoTip = ({
  children,
  wrapperStyles,
  message,
  isModal,
  positionMargin,
  position = "rightCenter",
}) => {
  const { showInfo } = useSnackbar();
  const { t } = useTranslate();
  const { open } = useModal();

  const handleShowInfo = (info) => {
    if (!info) return;

    if (isModal) {
      open(CONFIRM_MODAL, {
        title: `ℹ️️ ${t("info")}️`,
        description: info,
        buttons: [
          {
            title: t("modalTexts.close"),
            isClose: true,
            color: "success",
          },
        ],
      });
    } else {
      showInfo(info);
    }
  };

  return (
    <Wrapper styles={wrapperStyles}>
      {children}
      <IconWrapper
        position={css`
          ${positions[position]};
          ${positionMargin?.name}: ${positionMargin?.value};
        `}
      >
        <InfoIcon onClick={() => handleShowInfo(message)} />
      </IconWrapper>
    </Wrapper>
  );
};
