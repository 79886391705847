import styled from "styled-components";
import { ReactComponent as BackIcon } from "../../assets/svg/back.svg";
import { NavLink } from "react-router-dom";
import { Box, Row, Typography } from "../../UI";
import React from "react";

const SettingItem = styled.div`
  padding: 10px 15px 10px 20px;
  background-color: ${({ theme }) => theme.colors.background2};
  border-radius: 12px;
`;

const BackIconStyled = styled(BackIcon)`
  width: 20px;
  transform: rotate(180deg);
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 500;
`;

export const SettingItemComponent = ({
  to,
  title,
  subTitle,
  noArrow,
  disable,
}) => {
  const Link = disable ? Box : NavLink;
  return (
    <Link to={to || ""}>
      <SettingItem>
        <Row width="100%" justifyContent="space-between">
          <Box>
            <Typography variant="tiny" color="hint">
              {subTitle}
            </Typography>
            <Title variant="h2">{title}</Title>
          </Box>
          {!noArrow && <BackIconStyled />}
        </Row>
      </SettingItem>
    </Link>
  );
};
