import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  ERROR_FIELD_NAME,
  Row,
  Typography,
  WHITE_FIELD_NAME,
} from "../../UI";
import {
  ButtonHorizontal,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
} from "../../components";
import { VARIANT_PAY_BEP20 } from "../../config";
import { useSnackbar, useTranslate, useWallet } from "../../contexts";
import { convertNumberToSafe, numberFormat } from "../../utils/formatting";
import { Wrapper } from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { MDAO_MAKER_PROJECT_ROUTE } from "../../routes/routes";
import { updateStatus } from "../../api";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import { useGetMakerProject, useGetMakerProjectStats } from "../../hooks";
import { ROUNDS } from "./constants";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { useGetColorsError } from "../../hooks/useGetColorsError";
import { BuyAllocationSuccessPage } from "./ProcessingPages/SuccessPage";
import { BuyAllocationProcessingPage } from "./ProcessingPages/ProcessingPage";
import { ErrorPage } from "./ProcessingPages/ErrorPage";

export const MakerBuyAllocationPage = () => {
  const { t } = useTranslate();
  const { showError, showErrorCode } = useSnackbar();
  const dispatch = useDispatch();
  const makerDispatch = dispatch.maker;
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const { requirements } = useSelector(({ cards }) => cards);

  const tokens = user?.tokens;
  const { project_id } = useParams();
  const [ticketsAmount, setTicketsAmount] = useState("");
  const [loading, setLoading] = useState(false);
  const {
    balanceCoin,
    isLoadingBalance: balanceLoading,
    setRefreshBalance,
  } = useWallet();

  const [show, setShow] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [allocationRequirements, setAllocationRequirements] = useState(null);
  const [operationId, setOperationId] = useState(null);
  const TFA = user?.tfaStatus;

  const { project, projectLoading } = useGetMakerProject({
    projectId: project_id,
  });
  const { token, ticketPrice } = project || {};

  const { projectStats, loadingProjectStats } =
    useGetMakerProjectStats(project_id);

  const projectRest = useMemo(() => {
    return { ...project, ...projectStats };
  }, [project, projectStats]);

  const coinBalance = balanceCoin?.convertedBalance;

  const tokenId = useMemo(
    () => requirements?.[VARIANT_PAY_BEP20.idTokenKey],
    [requirements]
  );
  const tokenIds = useMemo(() => [tokenId], [tokenId]);

  const { tokenBalance, tokenBalancesLoading } = useGetTokenBalances({
    chains,
    chain: VARIANT_PAY_BEP20.chain,
    balanceCurrentTokenId: tokenId,
    userData: user,
    tokens,
    tokenIds,
    noCache: true,
  });

  const {
    round,
    discount,
    ticketPrice: ticketPriceWithCommission,
    availableTickets,
  } = allocationRequirements || {};

  const globalLoading = projectLoading || loading;

  const roundType = useMemo(() => {
    return {
      [ROUNDS.private]: t("private"),
      [ROUNDS.public]: t("public"),
    }[round];
  }, [round, t]);

  const { tokensPerTicket, ticketFee } = projectRest || {};

  const serviceFee = (ticketFee || 0) * 100;
  const calculateResult = serviceFee - discount * 100;

  const finalAmountWithCommission = ticketsAmount * ticketPriceWithCommission;
  const finalAmountWithoutCommission = ticketsAmount * ticketPrice;
  const commission = finalAmountWithCommission - finalAmountWithoutCommission;

  const notEnoughtAvailableTickets = ticketsAmount > availableTickets;

  const handleSetTickets = (value) => {
    setTicketsAmount(value);
  };
  const handlePay = (code) => {
    if (!project?.projectId) {
      showError(t("somethingWrong"));
      return;
    }

    setLoading(true);

    return makerDispatch
      .buyAllocation({ projectId: project_id, code, tickets: ticketsAmount })
      .then((res) => {
        const id = res.data?.result?.operationId;
        if (id) {
          setOperationId(id);
          setShowProcessing(true);
        }
        return res;
      })
      .catch((e) => {
        showErrorCode(e);
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handlePayTFA = () => {
    if (TFA) {
      setShow(true);
    } else {
      handlePay();
    }
  };

  const handleUpdateStatus = () => {
    return updateStatus(
      makerDispatch.getMakerAllocationStatus,
      { operationId },
      { count: tokensAmount, token },
      null,
      "data.result"
    );
  };

  useEffect(() => {
    setRefreshBalance();
    if (project_id) {
      try {
        makerDispatch
          .getAllocationRequirements({ projectId: project_id, cache: false })
          .then((res) => {
            setAllocationRequirements(res?.data?.result);
          });
      } catch (e) {
        showError(e);
      }
    }
  }, [makerDispatch, project_id]);

  const handleMaxAmount = () => setTicketsAmount(availableTickets);

  const { color: rightColorAvailableTickets } = useGetColorsError({
    balance: availableTickets,
    amount: ticketsAmount,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });

  const { color: rightColorTokenBalance, hasError } = useGetColorsError({
    balance: tokenBalance,
    amount: finalAmountWithCommission,
    successColor: WHITE_FIELD_NAME,
    errorColor: ERROR_FIELD_NAME,
  });
  const disableButton =
    notEnoughtAvailableTickets ||
    !ticketsAmount ||
    availableTickets === 0 ||
    hasError;

  const tokensAmount = ticketsAmount * tokensPerTicket;
  const ROUTE = `${MDAO_MAKER_PROJECT_ROUTE}/${project_id}`;

  return (
    <PageLoader loading={globalLoading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handlePay}
      >
        <IFrameLayout
          processingBefore={showProcessing}
          CongratsComponent={BuyAllocationSuccessPage}
          ProcessingComponent={BuyAllocationProcessingPage}
          ErrorComponent={ErrorPage}
          processingFetch={handleUpdateStatus}
          processingRoute={ROUTE}
          continueRoute={ROUTE}
          errorRoute={ROUTE}
        >
          <Wrapper>
            <Box>
              <PageHeader
                // to={mdaoMakerToProjectRoute(project_id)}
                title={t("buyProjectAllocation", {
                  projectName: token || "... ",
                })}
              />
              <Box m="10px 0 0 0">
                <Input
                  onlyWholeNumbers
                  type="number"
                  onChange={handleSetTickets}
                  value={ticketsAmount}
                  placeholder={t("ticketsToUse")}
                  actionButtonTitle={t("max").toLowerCase()}
                  actionButton={handleMaxAmount}
                />
                <Box m="16px 0 0 0">
                  <LineInfo
                    leftTitle={`${t("round")}:`}
                    rightTitle={roundType}
                  />
                  <LineInfo
                    leftTitle={`${t("ticketLefToPurchaseButAllocation")}:`}
                    rightColor={rightColorAvailableTickets}
                    rightTitle={availableTickets || 0}
                  />
                </Box>
                <Box m="16px 0 0 0">
                  <LineInfo
                    leftTitle={`${t("tokensPerTicket")}:`}
                    rightTitle={tokensPerTicket}
                  />
                  <LineInfo
                    leftTitle={`${t("ticketPrice")}:`}
                    rightTitle={numberFormat(ticketPriceWithCommission || 0)}
                  />{" "}
                  <LineInfo
                    leftTitle={`${t("serviceFee")}:`}
                    rightTitle={
                      discount ? (
                        <Row>
                          <Typography variant="link">
                            {`${Number(serviceFee || 0).toFixed(2)}% - `}
                          </Typography>
                          &nbsp;
                          <Typography variant="link" color="success">
                            {`${Number(discount * 100).toFixed(2)}%`}
                          </Typography>
                          &nbsp;=&nbsp;
                          <Typography variant="link" color="success">
                            {`${Number(calculateResult).toFixed(2)}% `}
                          </Typography>
                        </Row>
                      ) : (
                        <Typography variant="link">
                          {`${Number(serviceFee).toFixed(2)}% `}
                        </Typography>
                      )
                    }
                  />
                </Box>
                <Box m="16px 0 16px 0">
                  <LineInfo
                    leftTitle={`${t("yourCurrencyBalance", {
                      currency: VARIANT_PAY_BEP20.name,
                    })}`}
                    valueLoading={tokenBalancesLoading}
                    rightTitle={`${convertNumberToSafe(tokenBalance) || 0} ${
                      VARIANT_PAY_BEP20.currencyName
                    }`}
                    rightColor={rightColorTokenBalance}
                  />
                  <LineInfo
                    leftTitle={`${t("yourCurrencyBalance", {
                      currency: VARIANT_PAY_BEP20.coinName,
                    })}`}
                    valueLoading={balanceLoading}
                    rightTitle={`${String(coinBalance || 0)} ${
                      VARIANT_PAY_BEP20.coinName
                    }`}
                  />
                </Box>
                <Row width="100%" justifyContent="space-between">
                  <Typography variant="bold" m="0 0 16px 0">
                    {t("totalAmountDue")}
                  </Typography>
                  <Typography variant="bold" m="0 0 16px 0">
                    {`${Number(finalAmountWithoutCommission || 0).toFixed(2)} ${
                      VARIANT_PAY_BEP20.currencyName
                    } + ${Number(commission || 0).toFixed(2)} ${
                      VARIANT_PAY_BEP20.currencyName
                    } (fee)`}
                  </Typography>
                </Row>
                <Row justifyContent="space-between">
                  <Typography variant="bold" m="0 0 16px 0">
                    {`${t("tokenAmount")}:`}
                  </Typography>
                  <Typography variant="bold" m="0 0 16px 0">
                    {`${tokensAmount || 0} ${token || ""}`}
                  </Typography>
                </Row>
              </Box>
            </Box>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              disable={disableButton}
              onClick={handlePayTFA}
              title={t("payAmount", {
                amount: Number(finalAmountWithCommission || 0)?.toFixed(2),
                currencyName: VARIANT_PAY_BEP20.currencyName,
              })}
            />
          </Wrapper>
        </IFrameLayout>
      </TFACheckContainer>
    </PageLoader>
  );
};
