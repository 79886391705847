import React, { memo } from "react";

import styled, { useTheme } from "styled-components";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Box, Typography } from "../../UI";
const Wrapper = styled.div`
  position: relative;
  width: 110px;
  height: 110px;
`;

const TextWrapper = styled(Box)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const Donut = memo(({ percent, color, passed, title }) => {
  const { colors } = useTheme();
  const donutColor = passed ? colors.button : colors.orange;
  return (
    <Box>
      <Wrapper>
        <CircularProgressbar
          value={percent}
          strokeWidth={15}
          styles={buildStyles({
            strokeLinecap: "butt",
            pathTransitionDuration: 0.3,
            pathColor: donutColor,
            trailColor: colors.background2,
          })}
        />
        <TextWrapper>
          <Typography variant="h2">{Number(percent).toFixed(2)}%</Typography>
        </TextWrapper>
      </Wrapper>
      <Typography m="8px 0 0 0" ta="center" variant="link">
        {title}
      </Typography>
    </Box>
  );
});
