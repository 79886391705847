import React, { useMemo } from "react";
import { useHistory, useLocation, useParams } from "react-router-dom";

export const useQueryParams = () => {
  const router = useHistory();
  const { pathname } = useLocation();
  const routerParams = useParams();
  const params = new URLSearchParams(window.location.search);

  const methods = useMemo(() => {
    return {
      getParam: (name) => {
        return params.get(name);
      },
      setParam: (name, value) => {
        params.set(name, value);

        router.replace({
          pathname,
          query: { ...params },
          search: params.toString(),
        });
      },
    };
  }, [params, router, pathname]);

  return { ...methods, params, routerParams };
};
