import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  PageHeader,
  PageLoader,
  Stages,
  ButtonHorizontal,
  Social,
  Donut,
  Accordion,
} from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  BackArrowIconStyled,
  Box,
  HINT_FIELD_NAME,
  Paper,
  Row,
  Typography,
} from "../../UI";
import { firstCharUpper, getPercentFromNumbers } from "../../utils/formatting";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  allocationHoldParams,
  allocationHoldParams_v1,
  allocationParams,
  allocationParams_v1,
  allocationPreparationParams,
  allocationPreparationParams_v1,
  allocationPrivateSaleParams,
  allocationPrivateSaleParams_v1,
  allocationPrivateTicketsDistributionParams,
  allocationPrivateTicketsDistributionParams_v1,
  allocationPublicTicketDistributionParams,
  allocationPublicTicketDistributionParams_v1,
  allocationTokenDistributionParams,
  allocationTokenDistributionParams_v1,
  myParamsSchemaHolding,
  myParamsSchemaPreparation,
  myParamsSchemaPrivateSale,
  myParamsSchemaPrivateTicketsDistribution,
  myParamsSchemaSubscription,
  myParamsSchemaTicketDistribution,
  myParamsSchemaTokenDistribution,
  participantParamsSchema,
  participantParamsSchema_v1,
  projectParamsPublicHoldSchema,
  projectParamsPublicSaleSchema,
  projectParamsPublicSubscriptionSchema,
  projectParamsPublicTicketDistributionSchema,
  projectParamsSchema,
} from "./helpers";
import {
  COMPLETE_PROJECTS_STAGES,
  FINALS_PROJECTS_STAGES,
  getStageTimerTitles,
  STAGES,
} from "./constants";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import { MakerProjectControl } from "./MakerProjectControl";
import { countdownTimer, getSafeTranslate } from "../../utils/utils";
import { Params } from "./Params";
import {
  AthButton,
  ContentWrapper,
  ProjectContentWrapper,
  ProjectIcon,
  StatusButton,
  Wrapper,
  WrapperContent,
} from "./styled";
import { MAKER_ROUTE, MDAO_MAKER_PROJECT_ROUTE } from "../../routes/routes";
import { SUCCESS } from "../../config";
import {
  useGetMakerProject,
  useGetMakerProjectStats,
  useGetMakerRequirements,
  useGetMakerStakingInit,
  useGetMakerUserProjectParams,
  useScreen,
} from "../../hooks";
import { FreeCheckAMLProcessingPage } from "./ProcessingPages/ProcessingPage";
import { ErrorPage } from "../BuyUSDT/ProcessingPages/ErrorPage";
import { IFrameLayout } from "../IFrame/IFrameLayout";
import { updateStatus } from "../../api";
import { AMLCheckResultPage } from "./ProcessingPages/AML-Result-Page";
import {
  ACCESS_STAGE_PRIVATE_ROUND_DIAGRAM,
  ACCESS_STAGE_PUBLIC_ROUND_DIAGRAM,
  getStageTitles,
} from "./constants";
import Skeleton from "react-loading-skeleton";
import styled, { useTheme } from "styled-components";

const DELAY_BEFORE_UPDATE_NEXT_STAGE_MS = 10000;

const IconProject = styled.img`
  min-width: 28px;
  height: 28px;
  margin-right: 12px;
`;

const BlockPaper = styled(Paper)`
  margin-bottom: 12px;
  padding: 16px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const AccordionStyled = styled(Accordion)`
  & > div {
    padding: 0;
  }
`;

const HeaderWrapper = styled(Row)`
  position: relative;
  width: calc(100% - 60px);
`;

const ProjectHeader = ({ src, title }) => {
  return (
    <HeaderWrapper alignItems="center" justifyContent="center">
      <IconProject src={src} />
      <Typography variant="h1">{title?.toUpperCase()}</Typography>
    </HeaderWrapper>
  );
};

export const MakerProjectPage = () => {
  const { t, language } = useTranslate();
  const { project_id } = useParams();
  const { windowSize } = useScreen();
  const { showError, showErrorCode, showSuccess } = useSnackbar();
  const dispatch = useDispatch();
  const { tickets, projects, kyc, aml } = useSelector(({ maker }) => maker);
  const { colors } = useTheme();

  const makerDispatch = dispatch.maker;
  const amlDispatch = dispatch.aml;
  const [subscribeLoading, setSubscribeLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [freeAmlCheckLoading, setFreeAmlCheckLoading] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [operationId, setOperationId] = useState(null);
  const [timeToRefreshProject, setTimeToRefreshProject] = useState(null);
  const { project, projectLoading, setProject, getMakerProjectMethod } =
    useGetMakerProject({
      projectId: project_id,
      initCache: false,
      ignoreInitLoad: true,
    });

  const { projectStats, projectLoadingStats } =
    useGetMakerProjectStats(project_id);

  const { userProjectParams, method: getUserProjectParamsMethod } =
    useGetMakerUserProjectParams(project_id, false);

  const { projectRequirements } = useGetMakerRequirements(project_id);

  const { usdtBalance } = useGetMakerStakingInit();

  const {
    title,
    icon,
    stage,
    timestamp,
    amlRequired,
    kycRequired,
    stages,
    ticketPrice,
    ticketFee,
  } = project || {};

  const currentStage = stages?.find((elStage) => elStage.stage === stage);
  const nextStage = stages?.find(
    (elStage) => elStage.index === currentStage?.index + 1
  );

  const navigationRef = useRef();
  const contentRef = useRef();

  const currentTicket = tickets.find(
    (ticket) => ticket.projectId === Number(project_id)
  );

  const userProjectParamsData = userProjectParams || {};

  useEffect(() => {
    if (project_id) {
      if (projects?.length) {
        const currentProject = projects.find(
          (projectEl) => projectEl.projectId === Number(project_id)
        );

        if (currentProject) {
          if (!project || project?.projectId !== currentProject?.projectId) {
            setProject(currentProject);
          }
        } else {
          getMakerProjectMethod(project_id, false);
        }
      } else {
        getMakerProjectMethod(project_id, false);
      }
    }
  }, [projects, project_id]);

  const projectRest = useMemo(
    () => ({
      ...project,
      ...projectRequirements,
      ...projectStats,
      userProjectParamsData,
      currentTicket,
      aml,
      kyc,
    }),
    [
      project,
      projectRequirements,
      currentTicket,
      aml,
      kyc,
      userProjectParamsData,
      projectStats,
    ]
  );

  const subscribed = userProjectParams?.subscribed;

  const operation = useMemo(() => {
    return { minusIndent: 0 };
  }, []);

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    operation,
    [projectRest, navigationRef.current, windowSize?.height]
  );

  const [timer, setTimer] = useState("");

  const clearInterval = useRef(false);

  const stageUpdated = useRef(false);
  const stageUpdateTimerId = useRef(null);

  const getTimeToNextStage = (timestamp, currentStage, nextTageName) => {
    const init = () => {
      countdownTimer(
        timestamp,
        currentStage,
        nextTageName,
        (titleTimer, timeToRefresh) => {
          setTimer(titleTimer);
          setTimeToRefreshProject(timeToRefresh);
        },
        (timeMinus) => {
          clearInterval.current = timeMinus;
        },
        t
      );
    };

    init();

    return setInterval(() => {
      init();
    }, 1000);
  };

  const handleSubscribe = () => {
    if (!project_id) return;

    try {
      setSubscribeLoading(true);
      makerDispatch
        .subscribe({ projectId: project_id })
        .then((res) => {
          if (res.data.status === SUCCESS) {
            showSuccess(t("subscribedSuccessfully"));
            getUserProjectParamsMethod(project_id, false);
          }
        })
        .catch((e) => {
          showErrorCode(e);
        })
        .finally(() => {
          setSubscribeLoading(false);
        });
    } catch (e) {
      showError(e);
      setSubscribeLoading(false);
    }
  };

  const handleFreeAMLCheck = () => {
    setFreeAmlCheckLoading(true);

    makerDispatch
      .freeAmlCheck({ projectId: project_id })
      .then((res) => {
        if (res.data.status === SUCCESS) {
          setOperationId(res.data.result);
          setShowProcessing(true);
        }
        setFreeAmlCheckLoading(false);
        return res;
      })
      .catch((error) => {
        showErrorCode(error);
        setFreeAmlCheckLoading(false);
      });
  };

  const handleCloseProcessing = () => {
    setShowProcessing(false);
    setOperationId(null);
  };

  const handleSuccessCallback = () => {
    setShowProcessing(false);
    setOperationId(null);
  };

  const handleUpdateStatus = () => {
    return updateStatus(
      amlDispatch.getAmlOperation,
      { operationId, cache: false },
      {
        disableRoute: true,
        operationId,
        description: (risk) =>
          t("amlCheckResultRiskIsWithoutPDF", {
            type: risk || "...",
          }),
        Button: ({ onClick }) => (
          <ButtonHorizontal
            bottomMarginForScreen
            variant="small"
            onClick={() => onClick && onClick()}
            title={
              <>
                <BackArrowIconStyled /> {t("continue")}
              </>
            }
          />
        ),
      }
    );
  };

  const startTimer = useRef(false);
  const intervalId = useRef(null);

  const ignoreTimerStages = [
    STAGES.TOKEN_DISTRIBUTION,
    STAGES.PROJECT_COMPLETE,
  ];

  useEffect(() => {
    const ignoreTimer = ignoreTimerStages.includes(nextStage?.stage);

    if (!timestamp || !nextStage?.stage || !t) return;

    if (!clearInterval.current && !startTimer.current && !ignoreTimer) {
      startTimer.current = true;

      intervalId.current = getTimeToNextStage(
        timestamp,
        currentStage,
        getStageTitles(t, nextStage.stage)
      );
    }

    if (clearInterval.current) {
      setTimer(`0${t("D")} 0${t("H")} 0${t("M")}`);
      clearTimeout(intervalId.current);
    }
  }, [
    timestamp,
    currentStage,
    nextStage,
    t,
    clearInterval.current,
    intervalId.current,
  ]);

  useEffect(() => {
    if (!project_id) return;

    if (
      timeToRefreshProject &&
      timeToRefreshProject <= 0 &&
      !stageUpdated.current
    ) {
      startTimer.current = false;
      stageUpdated.current = true;

      stageUpdateTimerId.current = setTimeout(async () => {
        setLoading(true);
        try {
          await getUserProjectParamsMethod(project_id, false);
          await getMakerProjectMethod(project_id, false);

          clearInterval.current = false;
          setLoading(false);
        } catch (e) {
          setLoading(false);
          showError(e);
        }
      }, DELAY_BEFORE_UPDATE_NEXT_STAGE_MS);
    }

    return () => {
      clearTimeout(stageUpdateTimerId.current);
    };
  }, [timeToRefreshProject, project_id]);

  const myStatsStage = useMemo(() => {
    return (
      {
        [STAGES.PREPARATION]: myParamsSchemaPreparation,
        [STAGES.PRIVATE_SALE]: myParamsSchemaPrivateSale,
        [STAGES.PRIVATE_TICKETS_DISTRIBUTION]:
          myParamsSchemaPrivateTicketsDistribution,
        [STAGES.SUBSCRIPTIONS]: myParamsSchemaSubscription,
        [STAGES.PUBLIC_TICKET_DISTRIBUTION]: myParamsSchemaTicketDistribution,
        [STAGES.PUBLIC_SALE]: myParamsSchemaTicketDistribution,
        [STAGES.TOKEN_DISTRIBUTION]: myParamsSchemaTokenDistribution,
        [STAGES.PROJECT_COMPLETE]: myParamsSchemaTokenDistribution,
        [STAGES.HOLDING]: myParamsSchemaHolding,
      }[project?.stage] || []
    );
  }, [project]);

  const versionProject = (version, variants) =>
    variants[Number(version) - 1] || [];

  const allocationStats = useMemo(() => {
    return (
      {
        [STAGES.SUBSCRIPTIONS]: versionProject(project?.version, [
          allocationParams_v1,
          allocationParams,
        ]),
        [STAGES.PREPARATION]: versionProject(project?.version, [
          allocationPreparationParams_v1,
          allocationPreparationParams,
        ]),
        [STAGES.PRIVATE_SALE]: versionProject(project?.version, [
          allocationPrivateSaleParams_v1,
          allocationPrivateSaleParams,
        ]),

        [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: versionProject(
          project?.version,
          [
            allocationPrivateTicketsDistributionParams_v1,
            allocationPrivateTicketsDistributionParams,
          ]
        ),
        [STAGES.PUBLIC_TICKET_DISTRIBUTION]: versionProject(project?.version, [
          allocationPublicTicketDistributionParams_v1,
          allocationPublicTicketDistributionParams,
        ]),

        [STAGES.PUBLIC_SALE]: versionProject(project?.version, [
          allocationPublicTicketDistributionParams_v1,
          allocationPublicTicketDistributionParams,
        ]),
        [STAGES.TOKEN_DISTRIBUTION]: versionProject(project?.version, [
          allocationTokenDistributionParams_v1,
          allocationTokenDistributionParams,
        ]),
        [STAGES.HOLDING]: versionProject(project?.version, [
          allocationHoldParams_v1,
          allocationHoldParams,
        ]),
        [STAGES.PROJECT_COMPLETE]: versionProject(project?.version, [
          allocationTokenDistributionParams_v1,
          allocationTokenDistributionParams,
        ]),
      }[project?.stage] || []
    );
  }, [project]);

  const projectParams = useMemo(() => {
    return (
      {
        [STAGES.SUBSCRIPTIONS]: projectParamsPublicSubscriptionSchema,
        [STAGES.PREPARATION]: projectParamsSchema,
        [STAGES.PRIVATE_SALE]: projectParamsSchema,
        [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: projectParamsSchema,
        [STAGES.PUBLIC_TICKET_DISTRIBUTION]:
          projectParamsPublicTicketDistributionSchema,
        [STAGES.PUBLIC_SALE]: projectParamsPublicSaleSchema,
        [STAGES.TOKEN_DISTRIBUTION]: projectParamsSchema,
        [STAGES.PROJECT_COMPLETE]: projectParamsSchema,
        [STAGES.HOLDING]: projectParamsPublicHoldSchema,
      }[project?.stage] || []
    );
  }, [project]);

  const privateDiagramPercent = useMemo(
    () =>
      getPercentFromNumbers(
        projectRest?.privateRoundLeft,
        projectRest.privateRoundVolume
      )?.invertValue,
    [projectRest]
  );

  const publicDiagramPercent = useMemo(
    () =>
      getPercentFromNumbers(
        projectRest?.publicRoundLeft,
        projectRest?.publicRoundVolume
      ).invertValue,
    [projectRest]
  );

  const handleOpenPresentation = useCallback(() => {
    if (projectRest?.content?.presentation) {
      window.open(projectRest?.content?.presentation, "_blank");
    }
  }, [projectRest?.content?.presentation]);

  const globalLoading =
    projectLoading ||
    projectLoadingStats ||
    freeAmlCheckLoading ||
    subscribeLoading;

  const needKyc = kycRequired ? kyc !== SUCCESS : false;

  const have1Tickets =
    userProjectParamsData?.payableTickets +
      userProjectParamsData?.usdtStakedTickets >
    0;

  const have1TicketsForBuyAllocation =
    (userProjectParamsData?.payableTickets || 0) +
      (userProjectParamsData?.usdtHeldTickets || 0) +
      (userProjectParamsData?.usdtStakedTickets || 0) >
    0;

  const needAml = amlRequired ? aml === null : false;
  const BACK_TO_PROJECT_ROUTE = `${MDAO_MAKER_PROJECT_ROUTE}/${project_id}`;

  const privateRoundDiagrams =
    ACCESS_STAGE_PRIVATE_ROUND_DIAGRAM.includes(stage);

  const publicRoundDiagrams =
    project?.version === "1" ||
    ACCESS_STAGE_PUBLIC_ROUND_DIAGRAM.includes(stage);

  const usdtBalanceEnought =
    usdtBalance >= (projectRest?.usdtPerHeldTicket || 0);

  const dirtyWallet = kycRequired && amlRequired && aml === false;

  const isComplete = COMPLETE_PROJECTS_STAGES.includes(project?.stage);
  const finalStage = FINALS_PROJECTS_STAGES.includes(project?.stage);

  const participantsParams = versionProject(project?.version, [
    participantParamsSchema_v1,
    participantParamsSchema,
  ]);

  const isV1Project = project?.version === "1";
  const pageLoading = projectLoadingStats || projectLoading || loading;
  const diagramColor = finalStage ? colors.button : null;
  const isDonuts = privateRoundDiagrams || publicRoundDiagrams;
  const viewSetAddress =
    project?.dropWalletRequired &&
    project?.stage === STAGES.TOKEN_DISTRIBUTION &&
    currentTicket?.dropWallet === null &&
    currentTicket?.allocationPurchased > 0;

  const scrollContentAboutRef = useRef(null);

  const [openAbout, setOpenAbout] = useState(false);

  useEffect(() => {
    if (scrollContentAboutRef.current && openAbout && contentRef.current) {
      setTimeout(() => {
        contentRef.current.scrollTo({
          top: scrollContentAboutRef.current.offsetTop - 64,
          behavior: "smooth",
        });
      }, 300);
    }
  }, [scrollContentAboutRef, contentRef, openAbout]);

  return (
    <PageLoader loading={pageLoading}>
      <IFrameLayout
        processingBefore={showProcessing}
        CongratsComponent={AMLCheckResultPage}
        ProcessingComponent={FreeCheckAMLProcessingPage}
        ErrorComponent={ErrorPage}
        processingFetch={handleUpdateStatus}
        processingRoute={BACK_TO_PROJECT_ROUTE}
        continueRoute={BACK_TO_PROJECT_ROUTE}
        errorRoute={BACK_TO_PROJECT_ROUTE}
        cbRoute={handleCloseProcessing}
        cb={handleSuccessCallback}
      >
        <Wrapper>
          <WrapperContent>
            <PageHeader
              title={title}
              to={MAKER_ROUTE}
              content={
                <ProjectHeader src={project?.icon} title={project?.title} />
              }
            />

            <ProjectContentWrapper
              ref={contentRef}
              height={contentHeight + "px"}
            >
              {project?.stage !== STAGES.PROJECT_COMPLETE ? (
                <StatusButton complete={finalStage}>
                  <Box>
                    <Typography variant="faqTitle" ta="center">
                      {getStageTitles(t, stage)}
                    </Typography>
                    {currentStage?.to && timer && (
                      <Row justifyContent="center">
                        <Typography variant="small" ta="center">
                          {currentStage?.to
                            ? t("untilNextStage", {
                                timer,
                              })
                            : null}
                          &nbsp;
                          <strong style={{ fontWeight: 700 }}>
                            {getStageTimerTitles(t, nextStage?.stage)}
                          </strong>
                        </Typography>
                      </Row>
                    )}
                  </Box>
                </StatusButton>
              ) : (
                <AthButton>
                  <Box>
                    <Typography variant="h1" ta="center">
                      {projectRest?.ath ? `x${projectRest?.ath}` : "--"}
                    </Typography>
                    <Typography variant="small" ta="center">
                      ATH
                    </Typography>
                  </Box>
                </AthButton>
              )}
              <Box m="0px 0 0 0">
                <BlockPaper>
                  <Params
                    loading={loading}
                    paramsSchema={myStatsStage}
                    project={projectRest}
                    title={t("myStats")?.toUpperCase()}
                  />
                </BlockPaper>
                <BlockPaper>
                  <Typography variant="h2" m="0 0 16px 0">
                    {t("projectInfo").toUpperCase()}
                  </Typography>
                  <Params
                    loading={loading}
                    paramsSchema={projectParams}
                    project={projectRest}
                    ticketPrice={ticketPrice}
                    ticketFee={ticketFee}
                  />
                  <Box m="24px 0 0 0">
                    <AccordionStyled
                      noAbsolute
                      multiAccordion
                      contentStyles={{ marginLeft: "-24px" }}
                      ContentHeader={() => (
                        <Box height="32px" justifyContent="center">
                          <Typography variant="bold">
                            {t("showAllStages")}
                          </Typography>
                        </Box>
                      )}
                      Content={() =>
                        loading ? null : (
                          <Stages
                            stages={projectRest?.stages}
                            passed={isComplete}
                            finalStage={finalStage}
                          />
                        )
                      }
                    />
                  </Box>
                </BlockPaper>
                <BlockPaper>
                  <AccordionStyled
                    onOpen={setOpenAbout}
                    noAbsolute
                    multiAccordion
                    contentStyles={{ marginLeft: "-16px" }}
                    withRef={scrollContentAboutRef}
                    AdditionHeader={
                      <Box m="8px 0 0 0">
                        <Typography variant="small" color={HINT_FIELD_NAME}>
                          {t("description")}
                        </Typography>
                        <Typography variant="link" m="4px 0 20px 0">
                          {getSafeTranslate(project?.description, language)}
                        </Typography>
                        <ButtonHorizontal
                          variant="small"
                          disable={!projectRest?.content?.presentation}
                          onClick={handleOpenPresentation}
                          title={t("viewPresentation")}
                        />
                      </Box>
                    }
                    ContentHeader={() => (
                      <Box height="32px" justifyContent="center">
                        <Typography variant="h2">
                          {t("aboutProject").toUpperCase()}
                        </Typography>
                      </Box>
                    )}
                    Content={() => (
                      <Box ref={scrollContentAboutRef}>
                        <Box m="0px 0 0 0">
                          <Typography variant="small" color={HINT_FIELD_NAME}>
                            {t("description")}
                          </Typography>
                          {loading ? (
                            <Skeleton
                              height="64px"
                              width="100%"
                              borderRadius="12px"
                              style={{ marginTop: "8px" }}
                            />
                          ) : (
                            <Typography m="8px 0 0 0" variant="link">
                              {getSafeTranslate(
                                projectRest?.description,
                                language
                              )}
                            </Typography>
                          )}
                        </Box>
                        <Box m="20px 0 20px 0">
                          {/*<Typography variant="bold" m="0 0 12px 0">*/}
                          {/*  {t("termsConditions").toUpperCase()}*/}
                          {/*</Typography>*/}
                          <Params
                            loading={loading}
                            paramsSchema={participantsParams}
                            project={projectRest}
                            title={t("termsConditions")?.toUpperCase()}
                          />
                        </Box>
                        <Box m={isDonuts ? "0 0 8px 0" : "0 0 36px 0"}>
                          <Params
                            loading={loading}
                            paramsSchema={allocationStats}
                            project={projectRest}
                            title={t("allocationStats")?.toUpperCase()}
                          />
                        </Box>
                        {isDonuts && (
                          <Row m="16px 0 0px 0" justifyContent="space-around">
                            {privateRoundDiagrams && !isV1Project && (
                              <Donut
                                percent={privateDiagramPercent}
                                passed={finalStage}
                                color={diagramColor}
                                title={t("privateRound")}
                              />
                            )}
                            {publicRoundDiagrams && (
                              <Donut
                                color={diagramColor}
                                percent={publicDiagramPercent}
                                passed={finalStage}
                                title={t("publicRound")}
                              />
                            )}
                          </Row>
                        )}

                        {Object.keys(projectRest?.links || {}).length ? (
                          <Box m="20px 0 0 0">
                            <Social links={projectRest?.links} />
                          </Box>
                        ) : null}
                        <ButtonHorizontal
                          variant="small"
                          disable={!projectRest?.content?.presentation}
                          onClick={handleOpenPresentation}
                          title={t("viewPresentation")}
                        />
                      </Box>
                    )}
                  />
                </BlockPaper>
              </Box>
            </ProjectContentWrapper>
          </WrapperContent>
          <MakerProjectControl
            onCheckAML={handleFreeAMLCheck}
            amlRequired={needAml}
            aml={aml}
            dirtyWallet={dirtyWallet}
            have1TicketsForAml={have1Tickets}
            haveOneTicketForAllocation={have1TicketsForBuyAllocation}
            availableTickets={projectRest?.available}
            kycRequired={needKyc}
            viewSetAddress={viewSetAddress}
            stage={project?.stage}
            projectName={project?.title}
            withRef={navigationRef}
            tokenName={project?.token}
            onSubscribe={handleSubscribe}
            subscribed={subscribed}
            usdtBalanceEnought={usdtBalanceEnought}
            projectId={project_id}
          />
        </Wrapper>
      </IFrameLayout>
    </PageLoader>
  );
};
