import { useContext } from "react";
import { WalletContext } from "./WalletProvider";

export const useWallet = () => {
  const context = useContext(WalletContext);

  if (!context) {
    throw Error("Not found WalletContext context");
  }

  return context;
};
