import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Box, Row, Typography } from "../../UI";
import {
  ButtonHorizontal,
  DepositItems,
  Input,
  LineInfo,
  PageHeader,
  PageLoader,
} from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  CHAINS,
  SUCCESS,
  VARIANT_PAY_BEP20,
  VARIANT_PAY_RUB,
} from "../../config";
import styled from "styled-components";
import { IFrameLayout, INTERVAL_FETCH_MS } from "../IFrame/IFrameLayout";
import { BUY_USDT_KYC_ROUTE, WALLET_ROUTE } from "../../routes/routes";
import { SuccessPage } from "./ProcessingPages/SuccessPage";
import { ErrorPage } from "./ProcessingPages/ErrorPage";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "../../components/Spinner/Spinner";
import { TFACheckContainer } from "../../containers/TFACheckContainer";
import { updateStatus } from "../../api";
import { ProcessingPage } from "./ProcessingPages/ProcessingPage";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";

const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
  justify-content: space-between;
`;

export const BuyUSDTPage = () => {
  const { t } = useTranslate();
  const dispatch = useDispatch();
  const cardsDispatch = dispatch.cards;
  const walletDispatch = dispatch.wallet;
  const { showError, showErrorCode } = useSnackbar();
  const { user, chains } = useSelector(({ wallet }) => wallet);
  const { buyUsdtRequirements } = useSelector(({ cards }) => cards);

  const [amount, setAmount] = useState("");
  const [finalAmount, setFinalAmount] = useState("");
  const [rate, setRate] = useState(0);
  const [show, setShow] = useState(false);
  const [focusForm, setFocusForm] = useState(false);
  const [recalculate, setRecalculate] = useState(false);
  const [showProcessing, setShowProcessing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingPrice, setLoadingPrice] = useState(false);
  const [refreshStatus, setRefreshStatus] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);
  const [operationId, setOperationId] = useState(null);

  const tokenId = useMemo(
    () => buyUsdtRequirements?.[VARIANT_PAY_BEP20.idTokenKey],
    [buyUsdtRequirements]
  );

  const tokenIds = useMemo(() => [tokenId], [tokenId]);
  const tokens = user?.tokens;

  const { tokenBalance } = useGetTokenBalances({
    chains,
    chain: VARIANT_PAY_BEP20.chain,
    userData: user,
    balanceCurrentTokenId: tokenId,
    tokens,
    tokenIds,
  });

  useEffect(() => {
    const chain = chains.find((chain) => chain.chain === CHAINS.BSC);

    if (chain && chain.chain === CHAINS.BSC) {
      walletDispatch.setCurrentChain({
        ...chain,
        id: chain.chain,
        title: chain.title,
      });
    }
  }, [walletDispatch, chains]);

  const TFA = user?.tfaStatus;

  const { minBuyAmount, maxBuyAmount } = buyUsdtRequirements || {};
  // TODO
  const amountNotValid = amount < minBuyAmount || amount > maxBuyAmount;

  const handleChangeDepositAmount = useCallback((value) => {
    setAmount(value);
  }, []);

  const depositValues = [50, 75, 100, 200, 300, 400, 500, 1000];
  const activeItem = useMemo(() => {
    return (
      depositValues.find((v) => v?.toString() === amount.toString()) && amount
    );
  }, [amount]);

  const validateAmount = (value) => {
    if (value && (value < minBuyAmount || value > maxBuyAmount) && !focusForm) {
      showError(
        t("checkAmountCorrect", {
          min: minBuyAmount,
          max: maxBuyAmount,
        })
      );
      return false;
    }
    return true;
  };

  const handleUpdateStatus = useCallback(() => {
    return updateStatus(
      cardsDispatch.getBuyUsdtOperationStatus,
      { operationId },
      { usdtBalance: tokenBalance },
      null,
      "data.result"
    );
  }, [cardsDispatch, operationId, tokenBalance]);

  const handleBuyUsdt = (code) => {
    if (!amount) {
      showError(t("amountMustBeFilled"));
      return;
    }

    const valueIsCorrect = validateAmount(amount);

    if (!valueIsCorrect) return;

    setLoading(true);
    return cardsDispatch
      .buyUsdtMethod({ amount, code })
      .then((res) => {
        if (res?.data.status === SUCCESS) {
          if (res?.data.result.paymentUrl) {
            setRefreshStatus(true);
            setPaymentLink(res?.data.result.paymentUrl);
          } else {
            setShowProcessing(true);
          }

          setOperationId(res?.data.result.operationId);
        }

        return res;
      })
      .catch((e) => {
        showErrorCode(e);
      })
      .finally(() => setLoading(false));
  };

  const handleCallIFrameCallback = useCallback(() => {
    setRefreshStatus(false);
    setPaymentLink(null);
  }, []);

  const handlePayWithTFA = () => {
    if (!amount) {
      showError(t("amountMustBeFilled"));
      return;
    }

    const valueIsCorrect = validateAmount(amount);

    if (!valueIsCorrect) return;

    setShow(true);

    return handleBuyUsdt;
  };

  useEffect(() => {
    cardsDispatch.getBuyUsdtRequirements();
  }, [cardsDispatch]);

  useEffect(() => {
    const valueIsCorrect = validateAmount(amount);

    if (amount && !amountNotValid && valueIsCorrect) {
      setLoadingPrice(true);
      try {
        cardsDispatch
          .getBuyUsdtPrice({ amount: Number(amount) })
          .then((res) => {
            setFinalAmount(res?.data.result.sum);
            setRate(res?.data.result.rate);
          })
          .finally(() => setLoadingPrice(false));
      } catch (e) {
        showError(e.message);
      }
    }
  }, [cardsDispatch, focusForm, amount, amountNotValid]);

  const submitMethod = TFA ? handlePayWithTFA : handleBuyUsdt;

  const disableButton =
    !amount || amountNotValid || !finalAmount || !rate || loadingPrice;

  const handleFinishedFetch = () => {
    return cardsDispatch.getBuyUsdtOperation({ operationId });
  };

  return (
    <PageLoader loading={loading}>
      <TFACheckContainer
        show={show}
        loading={loading}
        setShow={setShow}
        onSuccess={handleBuyUsdt}
      >
        <IFrameLayout
          processingBefore={showProcessing}
          updatingWhenIFrameOpened={refreshStatus}
          view={!!paymentLink}
          link={paymentLink}
          CongratsComponent={SuccessPage}
          ProcessingComponent={ProcessingPage}
          ErrorComponent={ErrorPage}
          processingFetch={handleUpdateStatus}
          finishedFetch={handleFinishedFetch}
          title={t("buyUsdt")}
          processingRoute={BUY_USDT_KYC_ROUTE}
          continueRoute={WALLET_ROUTE}
          errorRoute={BUY_USDT_KYC_ROUTE}
          arrowRoute={BUY_USDT_KYC_ROUTE}
          cb={handleCallIFrameCallback}
        >
          <Wrapper>
            <Box>
              <PageHeader title={t("buyUsdt")} />
              <Box m="10px 0 8px 0">
                <Input
                  onlyWholeNumbers
                  value={amount}
                  type="number"
                  placeholder={t("amount")}
                  onChange={setAmount}
                  onFocus={() => setFocusForm(true)}
                  onBlur={() => setFocusForm(false)}
                />
              </Box>
              <DepositItems
                onChange={handleChangeDepositAmount}
                items={depositValues}
                transform={(el) => `$${el}`}
                activeItem={activeItem}
              />
              <Box m="16px 0 0 0">
                <LineInfo
                  leftTitle={t("usdExchangeRate")}
                  rightTitle={`~ ${Number(rate).toFixed(2)} RUB`}
                />
              </Box>
              <Row width="100%" justifyContent="space-between">
                <Typography variant="bold" m="0 0 16px 0">
                  {t("totalAmountDue")}
                </Typography>
                <Typography variant="bold" m="0 0 16px 0">
                  {`${Number(finalAmount)?.toFixed(2) || 0} ${
                    VARIANT_PAY_RUB?.currencyName
                  }`}
                </Typography>
              </Row>
              <Typography variant="link">
                {t("uponSuccessfulPayment")}
              </Typography>
            </Box>
            <ButtonHorizontal
              bottomMarginForScreen
              variant="small"
              onClick={submitMethod}
              disable={disableButton}
              title={
                loadingPrice ? (
                  <Spinner />
                ) : (
                  t("payAmount", {
                    amount: finalAmount || 0,
                    currencyName: VARIANT_PAY_RUB.currencyName,
                  })
                )
              }
            />
          </Wrapper>
        </IFrameLayout>
      </TFACheckContainer>
    </PageLoader>
  );
};
