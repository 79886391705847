export const CHAINS_COIN = {
  BSC: "BNB",
  ETH: "ETH",
  TRX: "TRX",
  BTC: "BTC",
  POL: "POL",
};

export const CHAINS = {
  BSC: "BSC",
  ETH: "ETH",
  TRX: "TRX",
  BTC: "BTC",
  POL: "POL",
};

export const CARD_STATUTES = {
  INACTIVE: "INACTIVE",
  CLOSED: "CLOSED",
  ACTIVE: "ACTIVE",
};

export const BTC_TRANSACTION_SPEEDS = {
  SLOW: "slow",
  MEDIUM: "medium",
  FAST: "fast",
};
export const TRANSACTIONS_FEE = {
  COIN: 21000,
  TOKEN: 45000,
};

export const ETH_NETWORK_FEE_DELTA = 0.0001;

export const MAX_AMOUNT_POWDER_LENGTH = {
  [CHAINS.POL]: 8,
};

export const REFRESH_TIME_KYC_STATUS_MS = 5000;

export const MIN_POWDER = 0.000001;
export const BUY_ZAVOD_ITEM_MIN_BNB_TO_FEE = 0.0005;
export const SAFE_AMOUNT_LENGTH = 6;

export const INTERVAL_FETCH_MS =
  process.env.REACT_APP_INTERVAL_FETCH_OPERATION_MS || 5000;

export const LOCAL_STORAGE_PASS_KYC_URL_FROM = "passKycUrlFrom";
export const FROM_PAGE = "fromPage";
export const FROM_TOP_UP_CARD = "topUpCard";
export const FROM_MAKER_PROJECT = "makerProject";
export const FROM_PAY_FOR_SERVICES = "payForServices";
export const TOP_UP_FROM_PAY_FOR_SERVICES = "topUpFromPayFoServices";
export const ISSUE_CARD = "chooseCard";
export const CREATE_CARD = "createCard";
