import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Box } from "../../UI";
import { DropdownChain, PageHeader, CopyField } from "../../components";
import { useDispatch, useSelector } from "react-redux";
import { QRCodeStyled, Wrapper } from "./styled";
import { useSnackbar, useTranslate, useWallet } from "../../contexts";
import { DropdownToken } from "../../components/Dropdown/DropdownToken";
import styled from "styled-components";
import { CHAINS, mainCoins } from "../../config";
import { QRCodeComponent } from "../../components/QR/QRCode";
import * as clipboard from "clipboard-polyfill";
import { useGetTokenBalances } from "../../hooks/useGetTokenBalances";
import { WALLET_ROUTE } from "../../routes/routes";
import { useParams } from "react-router-dom";
import { useQueryParams } from "../../hooks";
import { sortTokens } from "../../utils/dataFiltering";

const bot = window.Telegram?.WebApp;

const ContentBlock = styled(Box)`
  gap: 10px;
`;

export const DepositPage = () => {
  const refButton = useRef();
  const refContent = useRef();
  const dispatch = useDispatch();
  const { from } = useParams();
  const { showInfo } = useSnackbar();
  const { balanceCoin } = useWallet();
  const { t } = useTranslate();
  const walletDispatch = dispatch.wallet;
  const btcDispatch = dispatch.btc;
  const { chains, currentChain, user, tokenBalances } = useSelector(
    ({ wallet }) => wallet
  );
  const [differentValue, setDifferentValue] = useState(0);
  const [heightQRCode, setHeightQRCode] = useState(0);
  const [btcWallet, setBtcWallet] = useState(null);
  const [currentToken, setCurrentToken] = useState(null);

  const isBTC = currentChain?.chain === CHAINS.BTC;
  const wallet = isBTC ? btcWallet : user?.wallet;

  const { getParam } = useQueryParams();

  const queryActiveTokenParam = getParam("activeToken");

  const tokens = useMemo(() => {
    if (queryActiveTokenParam) {
      if (queryActiveTokenParam === "native") {
        return [];
      }

      const tokens = user?.tokens.filter(
        (token) => token.tokenId === Number(queryActiveTokenParam)
      );

      if (tokens?.length) {
        return tokens;
      } else {
        return user?.tokens || [];
      }
    } else {
      return user?.tokens || [];
    }
  }, [user?.tokens, queryActiveTokenParam]);

  const tokenId = useMemo(() => {
    return Number(queryActiveTokenParam);
  }, [queryActiveTokenParam]);

  const tokensId = useMemo(() => {
    return [tokenId];
  }, [queryActiveTokenParam]);

  const { balancesLoading } = useGetTokenBalances({
    chains,
    tokens,
    tokenId,
    tokensId,
    userData: user,
    chain: currentChain?.chain,
  });

  const balance = useMemo(
    () =>
      balanceCoin?.convertedBalance <= 0 ? 0 : balanceCoin?.convertedBalance,
    [currentChain, balanceCoin]
  );

  const mainToken = useMemo(
    () => ({
      ...mainCoins[currentChain?.chain],
      balance,
    }),
    [balance, balanceCoin]
  );

  useEffect(() => {
    setHeightQRCode(bot.viewportHeight || window.innerHeight);
  }, [bot]);

  useEffect(() => {
    if (currentChain?.id && currentChain.id === CHAINS.BTC) {
      btcDispatch.getAddressDeposit().then((res) => {
        setBtcWallet(res?.data?.result?.wallet);
      });
    } else {
      setBtcWallet(user?.wallet);
    }
  }, [user, currentChain]);

  useEffect(() => {
    if (queryActiveTokenParam) {
      const token = tokens.find(
        (token) => token.tokenId === Number(queryActiveTokenParam)
      );
      if (token) {
        setCurrentToken(token);
        return;
      }
    }

    if (
      currentToken?.chain !== mainToken?.chain ||
      (currentToken?.chain === mainToken?.chain &&
        currentToken?.balance !== mainToken?.balance)
    ) {
      setCurrentToken(mainToken);
    }
  }, [tokens, currentChain, mainToken, queryActiveTokenParam]);

  const handleSetToken = (data) => {
    setCurrentToken(data);
  };

  const handleSetChain = (chain) => {
    walletDispatch.setCurrentChain(chain);
  };

  const handleCopyWallet = useCallback(async () => {
    if (wallet && navigator?.clipboard?.writeText) {
      clipboard
        .writeText(wallet)
        .then((res) => {
          showInfo(t("notificationTexts.copied"));
        })
        .catch((e) => {
          showInfo(t("notificationTexts.somethingWrong"));
        });
    }
  }, [wallet, navigator, showInfo]);

  useEffect(() => {
    if (refContent.current && refButton.current) {
      const contentRect = refContent.current.getBoundingClientRect();
      const buttonRect = refButton.current.getBoundingClientRect();

      const differentValue = buttonRect.top - contentRect.bottom;

      if (differentValue < 10) {
        setDifferentValue(10 - differentValue);
      }
    }
  }, [refContent, refButton]);

  const tokensOfUser = useMemo(
    () => [{ ...mainCoins[currentChain?.id], balance }, ...tokens],
    [currentChain, tokens, balance]
  );

  const qrCodeHeight = useMemo(() => (heightQRCode / 100) * 40, [heightQRCode]);

  return (
    <Wrapper width="100%" height="100%" justifyContent="space-between">
      <Box>
        <PageHeader
          title={t("deposit")}
          to={from ? from.replace("*", "/") : WALLET_ROUTE}
        />
        <Box m="20px 0 0 0">
          <QRCodeComponent
            value={wallet}
            width={
              differentValue ? `${qrCodeHeight - differentValue}px` : "100%"
            }
          />
        </Box>
        <ContentBlock m="28px 0 8px 0" ref={refContent}>
          <DropdownChain
            subTitle={t("chain")}
            chains={chains}
            currentChain={currentChain}
            setChain={handleSetChain}
            z={2}
          />
          <DropdownToken
            sortFunc={sortTokens}
            tokens={tokensOfUser}
            currentToken={currentToken}
            setToken={handleSetToken}
          />
        </ContentBlock>
      </Box>
      <Box>
        <CopyField
          withRef={refButton}
          value={wallet}
          title={t("wallet")}
          onClick={handleCopyWallet}
        />
      </Box>
    </Wrapper>
  );
};
