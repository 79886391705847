import styled from "styled-components";
import { Box, Paper } from "../../UI";

export const ConfirmModalWrapper = styled(Paper)`
  background-color: ${({ theme }) => theme.colors.background2};
  padding: 0;
  position: fixed;
  width: 292px;
  height: fit-content;
  top: 50%;
  left: 50%;
  right: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  box-shadow: ${({ theme }) => theme.shadows.shadow3};
  z-index: 104234;
`;

export const Wrapper = styled(Box)`
  width: 292px;
  height: fit-content;
  padding: 24px 24px 16px 24px;
`;

export const ControlsBlock = styled(Paper)`
  border-radius: 0 0 12px 12px;
  display: flex;
  justify-content: space-between;
  padding: 0;
  width: 292px;
  height: 50px;
  border-top: ${({ theme }) => `1px solid ${theme.colors.background}`};
`;

export const ControlButtonStyled = styled(Box)`
  display: flex;
  justify-content: center;
  border-right: ${({ theme }) => `1px solid ${theme.colors.background}`};

  &:last-child {
    border-right: none;
  }
`;
