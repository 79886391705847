import React from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import { Box, Link, NextArrowIconStyled, Row, Typography } from "../../../UI";
import { useTranslate } from "../../../contexts";
import { VARIANT_PAY_BEP20 } from "../../../config";
import { SelectedLink } from "../../../components";
import { useSelector } from "react-redux";
import { shortWallet } from "../../../utils/formatting";

export const SuccessPage = ({
  margin,
  cb,
  usdtBalance,
  payoutTxId,
  usdtAmount,
}) => {
  const { t } = useTranslate();
  const { currentChain } = useSelector(({ wallet }) => wallet);

  const balance = (Number(usdtBalance || 0) + Number(usdtAmount || 0)).toFixed(
    2
  );

  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          {t("continue")} <NextArrowIconStyled />
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Typography ta="center" fs="100px">
            ✅
          </Typography>
          <Typography ta="center" variant="h1">
            {t("congrats")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("youvSuccessfullyPurchased")} <br />
            <Row justifyContent="center">
              {"Tx:"}&nbsp;
              <SelectedLink
                txLink={currentChain?.provider?.tx}
                type="tx"
                link={payoutTxId}
              >
                <Link m="0px 0 0 0">
                  {shortWallet(payoutTxId, 6, String(payoutTxId)?.length - 4)}
                </Link>
              </SelectedLink>
            </Row>
          </Typography>

          <Typography ta="center" variant="link">
            {t("youCanViewYourCrypto", {
              balance,
              currencyName: VARIANT_PAY_BEP20.currencyName,
            })}
          </Typography>
        </Box>
      }
    />
  );
};
