export const STAGES = {
  PREPARATION: "preparation",
  PRIVATE_TICKETS_DISTRIBUTION: "privateTicketsDistribution",
  PRIVATE_SALE: "privateSale",
  SUBSCRIPTIONS: "publicSubscription",
  HOLDING: "publicHold",
  LOTTERY: "lottery",
  PUBLIC_TICKET_DISTRIBUTION: "publicTicketsDistribution",
  PUBLIC_SALE: "publicSale",
  TOKEN_DISTRIBUTION: "tokenDistribution",
  PROJECT_COMPLETE: "projectComplete",
};

export const ROUNDS = {
  private: "private",
  public: "public",
};

export const COMPLETE_PROJECTS_STAGES = [STAGES.PROJECT_COMPLETE];
export const FINALS_PROJECTS_STAGES = [
  STAGES.PROJECT_COMPLETE,
  STAGES.TOKEN_DISTRIBUTION,
];

export const ACCESS_STAGE_PRIVATE_ROUND_DIAGRAM = [
  STAGES.PRIVATE_SALE,
  STAGES.PUBLIC_SALE,
  STAGES.PROJECT_COMPLETE,
  STAGES.HOLDING,
  STAGES.PUBLIC_TICKET_DISTRIBUTION,
  STAGES.TOKEN_DISTRIBUTION,
  STAGES.SUBSCRIPTIONS,
];
export const ACCESS_STAGE_PUBLIC_ROUND_DIAGRAM = [
  STAGES.PUBLIC_SALE,
  STAGES.TOKEN_DISTRIBUTION,
  STAGES.PROJECT_COMPLETE,
];

export const getProjectCardTitles = (t, stage) => {
  return {
    [STAGES.PREPARATION]: t("preparation"),
    [STAGES.PRIVATE_SALE]: t("privateSale"),
    [STAGES.PUBLIC_TICKET_DISTRIBUTION]: t(
      "publicTicketDistributionProjectCard"
    ),
    [STAGES.SUBSCRIPTIONS]: t("subscriptionPrivateRoundTickets"),
    [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: t(
      "privateTicketsDistributionProjectCard"
    ),
    [STAGES.PUBLIC_SALE]: t("publicSale"),
    [STAGES.HOLDING]: t("holding"),
    [STAGES.TOKEN_DISTRIBUTION]: t("tokenDistribution"),
    [STAGES.TOKEN_DISTRIBUTION]: t("tokenDistributionProjectCard"),
    [STAGES.PROJECT_COMPLETE]: t("allocationClosed"),
  }[stage];
};

export const getStageTitles = (t, stage) => {
  return {
    [STAGES.PREPARATION]: t("preparation"),
    [STAGES.PRIVATE_SALE]: t("privateSale"),
    [STAGES.PUBLIC_TICKET_DISTRIBUTION]: t("publicTicketDistribution"),
    [STAGES.SUBSCRIPTIONS]: t("subscriptionPrivateRoundTickets"),
    [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: t("privateTicketsDistribution"),
    [STAGES.PUBLIC_SALE]: t("publicSale"),
    [STAGES.HOLDING]: t("holding"),
    [STAGES.LOTTERY]: t("lottery"),
    [STAGES.TOKEN_DISTRIBUTION]: t("tokenDistribution"),
    [STAGES.PROJECT_COMPLETE]: t("allocationClosed"),
  }[stage];
};
export const getStageTimerTitles = (t, stage) => {
  return {
    [STAGES.PREPARATION]: t("preparationTimer"),
    [STAGES.PRIVATE_SALE]: t("privateSaleTimer"),
    [STAGES.PUBLIC_TICKET_DISTRIBUTION]: t("publicTicketDistributionTimer"),
    [STAGES.SUBSCRIPTIONS]: t("subscriptionPrivateRoundTicketsTimer"),
    [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: t("privateTicketsDistributionTimer"),
    [STAGES.PUBLIC_SALE]: t("publicSaleTimer"),
    [STAGES.HOLDING]: t("holdingTimer"),
    [STAGES.TOKEN_DISTRIBUTION]: t("tokenDistributionTimer"),
    [STAGES.PROJECT_COMPLETE]: t("allocationClosedTimer"),
  }[stage];
};

export const InfoTipsTexts = (stage) => {
  return (
    {
      [STAGES.PREPARATION]: "infoTipsStages.preparation",
      [STAGES.SUBSCRIPTIONS]: "infoTipsStages.subscription",
      [STAGES.PRIVATE_SALE]: "infoTipsStages.privateSale",
      [STAGES.PUBLIC_TICKET_DISTRIBUTION]: "infoTipsStages.ticketDistribution",
      [STAGES.SUBSCRIPTIONS]: "infoTipsStages.subscription",
      [STAGES.PRIVATE_TICKETS_DISTRIBUTION]:
        "infoTipsStages.privateTicketsDistribution",
      [STAGES.PUBLIC_SALE]: "infoTipsStages.publicSale",
      [STAGES.LOTTERY]: "infoTipsStages.lottery",
      [STAGES.HOLDING]: "infoTipsStages.holding",
      [STAGES.HOLDING]: "infoTipsStages.holding",
      [STAGES.TOKEN_DISTRIBUTION]: "infoTipsStages.tokenDistribution",
      [STAGES.PROJECT_COMPLETE]: "infoTipsStages.projectComplete",
    }[stage] || "descriptionNotFound"
  );
};
