import { useContext } from "react";
import { ModalContext } from "./ModalProvider";

export const useModal = () => {
  const context = useContext(ModalContext);

  if (!context) {
    throw Error("Not found ModalContext context");
  }

  return context;
};
