import { useContext, useEffect, useRef, useState } from "react";
import { Web3Context } from "./Web3Provider";
import { abi } from "../../config/abies";
import { useSnackbar, useTranslate } from "../index";

export const useGetWeb3Context = () => {
  const context = useContext(Web3Context);

  if (!context) {
    throw Error("Not found Web3Context");
  }

  return context;
};

export const useAsyncWeb3Methods = (chain) => {
  const { web3Methods } = useGetWeb3Context();
  const [asyncWeb3Methods, setAsyncWeb3Methods] = useState(null);

  const prevChain = useRef(null);
  useEffect(() => {
    if (chain && prevChain.current !== chain) {
      (async () => {
        prevChain.current = chain;
        const methods = await web3Methods(chain);

        setAsyncWeb3Methods(methods);
      })();
    }
  }, [web3Methods, chain]);

  return asyncWeb3Methods || {};
};

const TRXGetTokenInfo = async ({ getContract, contractAddress }) => {
  const contract = await getContract({ address: contractAddress });

  const name = await contract?.name()?.call();
  const symbol = await contract?.symbol()?.call();
  const decimals = Number(await contract?.decimals()?.call());

  return { name, symbol, decimals };
};

const ETHGetTokenInfo = async ({ getContract, contractAddress, abi }) => {
  const tokenContract = await getContract({ abi, address: contractAddress });

  const name = await tokenContract?.methods?.name().call();
  const symbol = await tokenContract?.methods?.symbol().call();
  const decimals = await tokenContract?.methods?.decimals().call();

  return { name, symbol, decimals };
};

const switchGetTokenInfoMethod = (chainName) => {
  switch (chainName) {
    case "TRX":
      return TRXGetTokenInfo;
    default:
      return ETHGetTokenInfo;
  }
};

export const useGetTokenInfo = (
  chain,
  contractAddress,
  { onComplete, onError }
) => {
  const { getContract } = useAsyncWeb3Methods(chain);
  const { showError } = useSnackbar();
  const { t } = useTranslate();
  const getTokenInfo = switchGetTokenInfoMethod(chain);
  return async () => {
    try {
      const { name, symbol, decimals } = await getTokenInfo({
        abi,
        getContract,
        contractAddress,
      });

      if (!name && !symbol) {
        showError(t("notificationTexts.tokenNotFound"));
      }

      if (onComplete) {
        onComplete({ name, symbol, decimals });
      }

      return { name, symbol, decimals };
    } catch (e) {
      console.error(e);
      if (onError) {
        onError(e);
      }
      showError(t("notificationTexts.wrongContractAddress"));
    }
  };
};
