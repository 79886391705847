import { ReactComponent as Cosmic } from "../../assets/svg/cosmic-fomo.svg";
import { ReactComponent as Mars } from "../../assets/svg/mars-dao.svg";
import { ReactComponent as Unknown } from "../../assets/svg/unknown-token.svg";
import { ReactComponent as Copy } from "../../assets/svg/copy.svg";

const tokenIcons = {
  cosmic: Cosmic,
  mars: Mars,
};

export const Icons = {
  copy: Copy,
};

export const getTokenIcons = (name) => tokenIcons[name] || Unknown;
export const getIcons = (name) => Icons[name] || Icons.copy;
