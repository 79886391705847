import React from "react";
import { Box, Typography } from "../../UI";
import {
  ConfirmModalWrapper,
  ControlButtonStyled,
  ControlsBlock,
} from "./styled";

const ControlButton = ({ title, onClick, color, width }) => {
  return (
    <ControlButtonStyled onClick={onClick} width={width}>
      <Typography ta="center" variant="link" color={color || "white"}>
        {title}
      </Typography>
    </ControlButtonStyled>
  );
};

export const createModal = (ModalContent) => (props) => {
  const { buttons, ...rest } = props || {};
  const widthButton = 100 / buttons?.length;

  return (
    <ConfirmModalWrapper>
      <ModalContent {...rest} />
      <ControlsBlock>
        {buttons?.map((button, i) => {
          const handleClick = () => {
            if (button.isClose && rest?.close) {
              rest.close();
            }

            if (button.onClick) {
              button.onClick();
            }
          };
          return (
            <ControlButton
              color={button.color}
              width={widthButton + "%"}
              title={button.title}
              onClick={handleClick}
              key={i}
            />
          );
        })}
      </ControlsBlock>
    </ConfirmModalWrapper>
  );
};
