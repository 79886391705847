import styled from "styled-components";
import { Box } from "../../UI";
import { MenuBlock } from "../Menu/styled";

export const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
  justify-content: space-between;
  position: relative;
`;

export const MenuBlockStyled = styled(MenuBlock)`
  grid-template-columns: 1fr;
`;

export const ContentWrapper = styled(Box)`
  //overflow: scroll;
`;
