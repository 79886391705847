import React from "react";
import { ContentWrapper } from "../../IFrame/Processing-Page";
import { BackArrowIconStyled, Box, Row, Typography } from "../../../UI";
import { useTranslate } from "../../../contexts";
import { SpinnerStyled } from "../../IFrame/styled";

export const ProcessingPage = ({ margin, cb }) => {
  const { t } = useTranslate();
  return (
    <ContentWrapper
      cb={cb}
      button={
        <>
          <BackArrowIconStyled /> {t("backToCard")}
        </>
      }
      content={
        <Box m={`0 0 ${margin}% 0`}>
          <Row justifyContent="center" m="0 0 18px 0">
            <SpinnerStyled />
          </Row>
          <Typography ta="center" variant="h1">
            {t("processing")}
          </Typography>
          <Typography ta="center" m="16px 0 8px 0" variant="link">
            {t("applicationForCardTopping")}
          </Typography>
          <Typography ta="center" variant="link">
            {t("inCaseOfAnyDifficulties")}
          </Typography>
        </Box>
      }
    />
  );
};
