import React from "react";
import { Box, Typography } from "../../UI";
import { PaperStyled } from "./styled";

export const Operation = ({
  onClick,
  operation,
  title,
  subTitle,
  value,
  subValue,
  titleColor,
  subTitleColor,
  valueColor,
  subValueColor,
}) => {
  return (
    <PaperStyled onClick={onClick}>
      <Box>
        <Typography variant="link">{title}</Typography>
        <Typography
          m="4px 0 0 0"
          variant="small"
          color={subTitleColor || "hint"}
        >
          {subTitle}
        </Typography>
      </Box>
      <Box>
        <Typography ta="end" variant="link" color={valueColor || "white"}>
          {value || "--"}
        </Typography>
        <Typography
          color={subValueColor || "white"}
          ta="end"
          m="4px 0 0 0"
          variant="small"
        >
          {subValue}
        </Typography>
      </Box>
    </PaperStyled>
  );
};
