import { Box, Typography } from "../../UI";
import { Wrapper } from "./styled";
import React from "react";

export const ConfirmActionModal = ({ title, description }) => {
  return (
    <Box>
      <Wrapper>
        {title && (
          <Typography ta="center" m="0 0 16px 0" variant="h2">
            {title}
          </Typography>
        )}
        <Typography ta="center" variant="link">
          {description}
        </Typography>
      </Wrapper>
    </Box>
  );
};
