import React, { useEffect, useMemo, useRef, useState } from "react";
import { Box, Link, Paper, Row, Typography } from "../../UI";
import {
  ButtonHorizontal,
  LinkComponent,
  PageHeader,
  Switcher,
} from "../../components";
import { useTranslate } from "../../contexts";
import { useSelector } from "react-redux";
import { useGetMainContentHeight } from "../../hooks/useMainContentHeight";
import {
  ButtonWrapper,
  ChooseByServicesWrapper,
  ChooseCardElement,
  ContentWrapper,
  ProductWrapper,
  Wrapper,
} from "./styled";
import {
  CARDS_ROUTE,
  createCardRoute,
  SELECT_OR_ISSUE_CHOOSE_CARDS_ROUTE,
} from "../../routes/routes";
import { useGetRouter } from "../../hooks";
import { getProductsByBin } from "./helpers";

const CHOOSE_BY_BIN = { titleKey: "chooseByBIN", id: 0 };
const CHOOSE_BY_SERVICES = { titleKey: "chooseByServices", id: 1 };

const variantsPay = [CHOOSE_BY_BIN, CHOOSE_BY_SERVICES];

const ChooseByBIN = ({ templates, productsByBin, requirements }) => {
  const { t } = useTranslate();
  const { handleRoute } = useGetRouter();

  return (
    <Box>
      {templates?.map((template, i) => {
        const foundProducts = productsByBin?.[template.binId];
        const createRoute = createCardRoute(template.binId);

        return (
          <ChooseCardElement
            key={i}
            bin={template.binId}
            title={
              t("cardIssuanceCost", {
                cost: requirements?.createCardFee,
              }) + " USDT"
            }
            subTitle={(scheme) => {
              return `Rechargeable Debit ${scheme}`;
            }}
            products={foundProducts}
            onClick={() => handleRoute(createRoute)}
          />
        );
      })}
    </Box>
  );
};

const ChooseByServices = ({
  activeServices,
  products,
  binsOn,
  setActiveServices,
}) => {
  const { handleRoute } = useGetRouter();
  const { t } = useTranslate();
  const navigationRef = useRef();
  const contentRef = useRef();

  const optionsToContentHeight = useMemo(() => {
    return {
      minusIndent: 16,
    };
  }, [navigationRef.current, contentRef.current]);

  const { contentHeight } = useGetMainContentHeight(
    contentRef,
    navigationRef,
    optionsToContentHeight
  );

  const bins = products.reduce((acc, el) => {
    if (activeServices.includes(el.productId)) {
      acc = [...acc, ...el.bins];
    }

    return [...new Set(acc)];
  }, []);

  const activeProductsModels = products.filter((product) =>
    activeServices.includes(product.productId)
  );

  const matchesBins = Object.entries(
    activeProductsModels.reduce((acc, el) => {
      el.bins?.forEach((bin) => {
        if (acc[bin]) {
          acc[bin]++;
        } else {
          acc[bin] = 1;
        }
      });
      return acc;
    }, {})
  )
    .filter(
      ([id, count]) =>
        binsOn.includes(Number(id)) && count === activeServices.length
    )
    .map(([key]) => key);

  return (
    <Wrapper>
      <ContentWrapper ref={contentRef} height={contentHeight + "px"}>
        <ChooseByServicesWrapper>
          {products?.map((product, i) => {
            const active = activeServices?.includes(product.productId);
            return (
              <ProductWrapper
                key={i}
                active={active}
                onClick={() =>
                  setActiveServices && setActiveServices(product.productId)
                }
              >
                <Typography variant="h2">{product.title}</Typography>
              </ProductWrapper>
            );
          })}
        </ChooseByServicesWrapper>
      </ContentWrapper>
      <ButtonWrapper ref={navigationRef}>
        <Box display="flex">
          <ButtonHorizontal
            bottomMarginForScreen
            variant="small"
            disable={!matchesBins?.length}
            onClick={() =>
              handleRoute(
                `${SELECT_OR_ISSUE_CHOOSE_CARDS_ROUTE}/${matchesBins.join(
                  ","
                )}/${activeServices.join(",")}`
              )
            }
            title={t("showBins", {
              count: matchesBins?.length,
            })}
          />
        </Box>
      </ButtonWrapper>
    </Wrapper>
  );
};

export const SelectOrIssueCardPage = () => {
  const { t } = useTranslate();
  const [activeSwitch, setActiveSwitch] = useState(variantsPay[0]);
  const [activeServices, setActiveServices] = useState([]);
  const [activeBins, setActiveBins] = useState([]);
  const { products, templates, requirements } = useSelector(
    ({ cards }) => cards
  );

  const binsOn = templates.map((el) => el.binId);
  const [showCards, setShowCards] = useState(false);

  const productsByBin = useMemo(() => getProductsByBin(products), [products]);

  const handleSwitch = (value) => {
    setActiveSwitch(value);
    setActiveBins([]);
    setActiveServices([]);
  };

  const handleActiveServices = (value) => {
    const find = activeServices.find((el) => el === value);

    if (find) {
      const filtered = activeServices.filter((el) => el !== value);
      setActiveServices(filtered);
    } else {
      setActiveServices((prev) => {
        return [...prev, value];
      });
    }
  };

  const handleShowBins = (value) => {
    setActiveBins(value);
    setShowCards(true);
  };

  const Component = {
    [CHOOSE_BY_BIN.id]: ChooseByBIN,
    [CHOOSE_BY_SERVICES.id]: ChooseByServices,
  }[activeSwitch.id];

  return (
    <Box>
      <Box>
        <PageHeader title={t("selectOrIssueCard")} to={CARDS_ROUTE} />
        <Typography variant="link" m="10px 0 0 0">
          {t("issueVirtualCardToPay")}
        </Typography>
        <Row m="8px 0 0 0" justifyContent="space-between">
          <Box width="50%">
            <LinkComponent
              title={t("servicePaymentGuide")}
              to={t("guides.servicePaymentGuideLink")}
            />
          </Box>
          <Box width="50%">
            <LinkComponent
              title={t("cardsBinCompare")}
              to={t("guides.cardsBinCompare")}
            />
          </Box>
        </Row>
        <Typography variant="link" m="16px 0 0 0">
          {t("checkPayInRub")}
        </Typography>
        <Box m="8px 0 0 0">
          <LinkComponent
            to={t("guides.kycComplianceGuideUsdtPurchase")}
            title={t("kycComplianceGuide")}
          />
        </Box>
        <Box m="16px 0 16px 0">
          <Switcher
            variantsPay={variantsPay}
            activeVariant={activeSwitch}
            onChange={handleSwitch}
          />
        </Box>
        <Component
          binsOn={binsOn}
          templates={templates}
          activeSwitch={activeSwitch}
          activeServices={activeServices}
          productsByBin={productsByBin}
          products={products}
          handleShowBins={handleShowBins}
          requirements={requirements}
          setActiveServices={handleActiveServices}
        />
      </Box>
    </Box>
  );
};
