import React, { useEffect, useRef } from "react";
import { Container, GlobalStyles } from "../../UI";
import styled from "styled-components";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
const Wrapper = styled.div`
  //overflow: hidden;/
  //max-height: 98vh;
  width: 100%;
  //height: ${({ height }) => height + "px"};
  //height: 100vh;
  //height: var(--app-height);

  //max-height: 100vh;
  // background-color: ${({ theme }) => theme.colors.background};
`;

const HideInput = styled.input`
  opacity: 0;
  position: absolute;
  left: -100%;
  top: -100%;
`;
export const Layout = ({ children }) => {
  useEffect(() => {}, []);

  const hideInputRef = useRef(null);
  const isKeyboardOpen = useDetectKeyboardOpen();

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    const isAndroid = /android/i.test(userAgent);

    if (!hideInputRef.current || !isAndroid) return;

    document.addEventListener("visibilitychange", (event) => {
      setTimeout(() => {
        hideInputRef.current?.focus();
        hideInputRef.current?.blur();
      }, 100);
    });
  }, [hideInputRef]);

  return (
    // <Wrapper height={window.innerHeight}>
    <Wrapper>
      <HideInput ref={hideInputRef} />
      <GlobalStyles />
      <Container>{children}</Container>
    </Wrapper>
  );
};
