import BigNumber from "bignumber.js";
import { format } from "date-fns";
import { ethers } from "ethers";
import { CHAINS, MIN_POWDER, SAFE_AMOUNT_LENGTH } from "../config";
import bigInt from "big-integer";

export const BIG_TWO = new BigNumber(2);
export const BIG_TEN = new BigNumber(10);
export const BIG_ZERO = new BigNumber(0);
export const DEFAULT_TOKEN_DECIMAL = new BigNumber(10).pow(18);
export const ONE_SATOSHI = 100000000;

export const numberWithCommas = (x) =>
  (x || "").toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

export const shortWallet = (
  wallet,
  startLength,
  endLength,
  customEmptyText
) => {
  if (!wallet) {
    return customEmptyText || "--";
  }
  return `${String(wallet || "")?.slice(0, startLength || 6)}...${String(
    wallet || ""
  )?.slice(endLength || -4)}`;
};

export const splitStringBySeparator = (str, separator = /(?=[A-Z])/) =>
  str && typeof str === "string"
    ? str
        .split(separator)
        .reduce((acc, curr) => {
          return `${acc} ${curr}`;
        }, "")
        .trim()
    : str;

export const firstCharUpper = (str) => {
  return str && typeof str === "string"
    ? str[0].toUpperCase() + str.slice(1)
    : str;
};

export const replaceStr = (str, length) => {
  if (str?.length < length) {
    return str;
  }
  return `${str?.slice(0, length)}...`;
};

export const fixedToSecond = (value) => Number(value).toFixed(2);

export const convertCardNumberSpace = (str) =>
  str?.replace(/\W/gi, "").replace(/(.{4})/g, "$1 ");

export const getBalanceNumber = (balance, decimals = 18) => {
  if (!balance) {
    return 0;
  }

  const balanceBN = new BigNumber(balance);
  const divisor = new BigNumber(10).pow(decimals);
  const convertedBalance = balanceBN.dividedBy(divisor).toFixed();

  return convertedBalance < MIN_POWDER ? 0 : convertedBalance;
};

export const numberToPixel = (number) => `${number}px`;

export const lastFourCardNumbers = (number) =>
  number?.toString()?.slice(number.length - 4, number.length);

export const getMaxAmountCoin = (unconvertedBalance, unconvertedFee, gas) => {
  const result = Number(unconvertedBalance) - Number(unconvertedFee) * gas;

  if (!result) {
    return;
  }

  const resultCalculate =
    Number(unconvertedBalance) - Number(unconvertedFee) * gas;

  if (resultCalculate > 0) {
    return convertNumberToSafe(
      getEthBalanceNumber(bigInt(resultCalculate).value)
    );
  } else {
    return 0;
  }
};

export const getMaxAmountToken = (
  unconvertedBalance,
  unconvertedFee,
  decimals
) => {
  return getBalanceNumber(unconvertedBalance - unconvertedFee, decimals);
};

export const getEthBalanceNumber = (balance) => {
  return ethers.formatEther(balance);
};

export const convertToRemovePowder = (
  balance,
  minPowderLength = MIN_POWDER.toString().length
) => {
  const balanceString = balance?.toString();
  if (!balanceString) {
    return;
  }

  if (balanceString && balanceString?.length > minPowderLength) {
    return balanceString?.slice(0, minPowderLength);
  }
  return balance;
};

export const convertNumberToSafe = (
  numberString,
  length = SAFE_AMOUNT_LENGTH
) => {
  const string = String(numberString);
  let dotPos = string.indexOf(".");
  let commaPos = string.indexOf(",");

  let pos = dotPos !== -1 ? dotPos : commaPos;

  if (pos === -1) {
    return string;
  }

  let integerPart = string.slice(0, pos + 1);
  let decimalPart = string.slice(pos + 1, pos + 1 + length);

  const hasNotZeroNumber = decimalPart
    ?.split("")
    ?.find((el) => Number(el) !== 0);

  if (hasNotZeroNumber) {
    return integerPart + decimalPart;
  } else {
    return string.slice(0, pos);
  }
};

export const convertBalance = (chain, balance, powder) => {
  if (!balance || typeof balance !== "bigint") {
    return 0;
  }

  if (chain === CHAINS.BSC || chain === CHAINS.ETH || chain === CHAINS.POL) {
    const convertedBalance = getEthBalanceNumber(balance);
    return powder ? convertedBalance : convertNumberToSafe(convertedBalance);
  }

  return balance;
};

export const fromSatoshi = (number) => {
  return Number(Number(number / ONE_SATOSHI)?.toFixed(8));
};

export const toSatoshi = (number) =>
  Number((Number(number) * ONE_SATOSHI).toFixed(0));

export const getFirstGreaterZero = (str) =>
  str?.split("")?.reduce((acc, el, index) => {
    if (Number(el) && Number(el) > 0 && acc === 0) {
      acc = index + 1;
    }

    return acc;
  }, 0);

export const firstNotZeroFormat = (str) => {
  if (!str) {
    return "0.00";
  }

  const string = String(str);
  const firstNotZero = getFirstGreaterZero(string);
  const nextNumber = string.charAt(firstNotZero);
  const shiftSlice = Number(nextNumber) !== 0 ? 1 : 0;
  return string?.slice(0, firstNotZero + shiftSlice);
};

export const dateFormat = (date, formatType = "yyyy-MM-dd HH:mm:ss") =>
  date && format(date, formatType);

export const declOfNum = (number, words) => {
  const numb = Number(number);
  const integer = Number.isInteger(numb);
  return words[
    !integer
      ? 1
      : numb % 100 > 4 && numb % 100 < 20
      ? 2
      : [2, 0, 1, 1, 1, 2][numb % 10 < 5 ? Math.abs(numb) % 10 : 5]
  ];
};

export const allBigFirstChar = (str) =>
  str?.replace(/\b\w/g, (char) => {
    return char.toUpperCase();
  });

export const getPercentFromNumbers = (a, b) => {
  if (a === undefined || b === undefined) {
    return { value: 0, invertValue: 0 };
  }

  const value = (a / b) * 100;
  const invertValue = 100 - value;

  return { value, invertValue: invertValue || 0 };
};

export const numberFormat = (number) => {
  return Number(Number(number).toFixed(2)).toLocaleString("en-US");
};
