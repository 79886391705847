import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSnackbar, useTranslate } from "../../contexts";
import {
  Box,
  ERROR_FIELD_NAME,
  HINT_FIELD_NAME,
  SUCCESS_FIELD_NAME,
  WHITE_FIELD_NAME,
  YELLOW_FIELD_NAME,
} from "../../UI";
import { Operations, PageLoader } from "../../components";
import { dateFormat, lastFourCardNumbers } from "../../utils/formatting";

const STATUSES_COLORS = {
  DECLINED: ERROR_FIELD_NAME,
  FAILED: ERROR_FIELD_NAME,
  SUCCESS: SUCCESS_FIELD_NAME,
  PENDING: YELLOW_FIELD_NAME,
  REVERSED: HINT_FIELD_NAME,
  APPROVED: HINT_FIELD_NAME,
};

export const TransactionsSection = React.memo(
  ({
    transactionItems,
    loadingOperations,
    tKey,
    handleGetOperations,
    minHeight,
    withRef,
    height,
  }) => {
    const dispatch = useDispatch();
    const { t } = useTranslate();
    const cardsDispatch = dispatch.cards;
    const { showError } = useSnackbar();
    const { cards } = useSelector(({ cards }) => cards);

    const cardsHash = useMemo(
      () =>
        cards?.reduce((acc, card) => {
          acc[card.cardId] = card;
          return acc;
        }, {}),
      [cards]
    );

    const getTitlesTypeOperation = useCallback(
      (transaction) => {
        return {
          createCard: t("createCardBIN", { bin: transaction?.binId }),
        };
      },
      [t, cardsHash]
    );

    const operationsListFormatted = transactionItems?.map((transaction) => {
      return {
        colors: {
          subValueColor: STATUSES_COLORS[transaction.status] || STATUSES_COLORS,
          valueColor:
            transaction.amount === 0
              ? WHITE_FIELD_NAME
              : transaction.amount < 0
              ? ERROR_FIELD_NAME
              : SUCCESS_FIELD_NAME,
        },
        value: `${
          transaction.amount ? (transaction.amount < 0 ? "-" : "+") : ""
        }$${Math.abs(transaction.amount.toFixed(2))}`,
        subValue: transaction?.status?.toLowerCase(),

        title: dateFormat(transaction.timestamp, "yyyy-MM-dd HH:mm"),
        subTitle: transaction.amount < 0 ? t("credit") : t("debit"),
      };
    });

    const [transactionLoading, setTransactionLoading] = useState(false);

    return (
      <Operations
        withLoader
        overlayOff
        setLoading={setTransactionLoading}
        loading={transactionLoading}
        emptyTitle={tKey}
        withRef={withRef}
        height={height}
        minHeight={minHeight}
        schemeStatuses={getTitlesTypeOperation}
        operationsList={operationsListFormatted}
        fetch={handleGetOperations}
        cardsHash={cardsHash}
      />
    );
  }
);
