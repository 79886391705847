import { Actions } from "./actions";

export const initialState = {
  balanceCoin: null,
  balanceUsdt: null,
};

export const reducer = (state, action) => {
  const handlers = {
    [Actions.SET_BALANCE_COIN]: (state) => {
      return { ...state, balanceCoin: action.payload };
    },
    [Actions.SET_BALANCE_IN_USDT]: (state) => {
      return { ...state, balanceUsdt: action.payload };
    },
  };

  const act = handlers[action.type];

  const newState = act(state);

  return { ...newState };
};
