import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  BUTTON_FIELD_NAME,
  HINT_FIELD_NAME,
  ORANGE_FIELD_NAME,
  Paper,
  Row,
  SUCCESS_FIELD_NAME,
  Typography,
} from "../../UI";
import styled from "styled-components";
import { declOfNum, firstCharUpper } from "../../utils/formatting";
import { getProjectCardTitles, STAGES } from "../../pages/Maker/constants";
import { ReactComponent as MakerIcon } from "../../assets/svg/maker.svg";
import { ReactComponent as StarIcon } from "../../assets/svg/star.svg";
import { ReactComponent as ArrowIcon } from "../../assets/svg/next-arrow.svg";
import { useTranslate } from "../../contexts";
import { countdownTimer } from "../../utils/utils";
import {
  getStageTimerTitles,
  getStageTitles,
} from "../../pages/Maker/constants";
import { useSelector } from "react-redux";

const WrapperPaper = styled(Paper)`
  padding: 0;
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const CardHeader = styled(Row)`
  border-radius: 12px;
  position: relative;
`;

const Icon = styled(MakerIcon)``;
const StarIconStyled = styled(StarIcon)`
  fill: ${({ active, theme }) =>
    active ? theme.colors.orange : theme.colors.white};
  opacity: ${({ active }) => (active ? 1 : 0.5)};
  margin-top: -3px;
`;

const InfoBlock = styled.div`
  display: grid;
  grid-template-columns: fit-content(100%) fit-content(100%) fit-content(100%) fit-content(
      100%
    );
  grid-gap: 8px 32px;
  margin-right: 24px;

  &:last-child {
    margin-right: 0;
  }
`;

const IconImg = styled.img`
  width: 35px;
  height: 35px;
  object-fit: contain;
`;

const UsdtStaked = () => {
  const { t } = useTranslate();
  const { usdtStaking } = useSelector(({ maker }) => maker);

  return (
    <Typography variant="link" color={HINT_FIELD_NAME}>
      {t("usdtStakeAmount", { amount: usdtStaking || 0 })}
    </Typography>
  );
};

const ArrowIconStyled = styled(ArrowIcon)`
  height: 28px;
  width: 28px;
  fill: ${({ theme }) => theme.colors.addition};
`;

const BlockInfoColumn = ({ title, value }) => {
  return (
    <Box>
      <Typography variant="tiny" color="hint" m="0 0 4px 0">
        {title}
      </Typography>
      <Typography variant="link" ta="left">
        {value}
      </Typography>
    </Box>
  );
};

const PublicRoundTickets = ({
  staking,
  stakingUsed,
  payable,
  payableUsed,
  held,
  heldUsed,
}) => {
  const { t } = useTranslate();

  const value =
    (payable || 0) -
    (payableUsed || 0) +
    (staking || 0) -
    (stakingUsed || 0) +
    (held || 0) -
    (heldUsed || 0);

  return (
    <Typography variant="link" color="success">
      {t("publicRoundTicketAmount", { amount: value || 0 })}
    </Typography>
  );
};

const Subscribed = ({ subscribed }) => {
  const { t } = useTranslate();
  const title = subscribed ? t("subscribed") : t("notSubscribed");
  const colorTitle = subscribed ? SUCCESS_FIELD_NAME : HINT_FIELD_NAME;

  return (
    <Typography variant="link" color={colorTitle}>
      {title}
    </Typography>
  );
};

const USDTHeld = ({ usdtHeld }) => {
  const { t } = useTranslate();

  return (
    <Typography variant="link" color={SUCCESS_FIELD_NAME}>
      {`${t("usdtHeld")}: ${(usdtHeld || 0).toLocaleString("en-US")}`}
    </Typography>
  );
};

const Allocation = ({ allocationPurchased, tokenName }) => {
  const { t } = useTranslate();

  return (
    <Typography variant="link" color={SUCCESS_FIELD_NAME}>
      {t("allocationAmount", { amount: allocationPurchased || 0, tokenName })}
    </Typography>
  );
};

const ATH = ({ ath }) => {
  const { t } = useTranslate();

  return (
    <Typography variant="link" color={HINT_FIELD_NAME}>
      {`${t("ath")}: x${ath || "--"}`}
    </Typography>
  );
};

const Tickets = React.memo(({ staking, stakingUsed, payable, payableUsed }) => {
  const { t } = useTranslate();

  const value =
    (staking || 0) - (stakingUsed || 0) + (payable || 0) - (payableUsed || 0);

  return (
    <Typography variant="link" color={HINT_FIELD_NAME}>
      {`${firstCharUpper(
        declOfNum(value, [t("ticket1"), t("ticket3"), t("ticket2")])
      )}: ${value}`}
    </Typography>
  );
});

const DefaultComponent = ({ subscribed }) => {
  const { t } = useTranslate();
  const title = subscribed ? t("subscribed") : t("notSubscribed");
  const colorTitle = subscribed ? SUCCESS_FIELD_NAME : HINT_FIELD_NAME;
  return (
    <Typography variant="link" color={colorTitle}>
      {title}
    </Typography>
  );
};

const stagesControls = {
  [STAGES.PREPARATION]: () => UsdtStaked,
  [STAGES.PRIVATE_TICKETS_DISTRIBUTION]: () => Tickets,
  [STAGES.PRIVATE_SALE]: () => Tickets,
  [STAGES.SUBSCRIPTIONS]: () => Subscribed,
  [STAGES.HOLDING]: (subscribed) => (subscribed ? USDTHeld : Subscribed),
  [STAGES.PUBLIC_TICKET_DISTRIBUTION]: (subscribed) =>
    subscribed ? PublicRoundTickets : Subscribed,
  [STAGES.PUBLIC_SALE]: (subscribed) =>
    subscribed ? PublicRoundTickets : Subscribed,
  [STAGES.TOKEN_DISTRIBUTION]: () => Allocation,
  [STAGES.PROJECT_COMPLETE]: () => ATH,
};

export const MakerProjectCard = React.memo(
  ({
    token,
    onClick,
    isPart,
    tge,
    allocation,
    tokenPrice,
    timestamp,
    projectName,
    subscribed,
    projectId,
    ath,
    icon,
    stages,
    stage,
    usdtBalance,
    ticket,
  }) => {
    const { t } = useTranslate();
    const [timer, setTimer] = useState("");

    const currentStage = stages?.find((elStage) => elStage.stage === stage);
    const nextStage = stages?.find(
      (elStage) => elStage.index === currentStage?.index + 1
    );

    const clearInterval = useRef(false);
    const start = useRef(false);
    const intervalId = useRef(null);

    const getTimeToNextStage = (timestamp, currentStage, nextTageName) => {
      const init = () =>
        countdownTimer(
          timestamp,
          currentStage,
          nextTageName,
          setTimer,
          (timeMinus) => (clearInterval.current = timeMinus),
          t
        );

      init();

      return setInterval(() => {
        init();
      }, 1000);
    };

    useEffect(() => {
      if (!timestamp || !nextStage?.stage || !t) return;

      if (!clearInterval.current && !start.current) {
        start.current = true;
        intervalId.current = getTimeToNextStage(
          timestamp,
          currentStage,
          getStageTitles(t, nextStage.stage)
        );
      }

      if (clearInterval.current) {
        setTimer(`0${t("D")} 0${t("H")} 0${t("M")}`);
        clearTimeout(intervalId.current);
      }
    }, [
      timestamp,
      currentStage,
      nextStage,
      t,
      clearInterval.current,
      intervalId.current,
    ]);

    const Component = stagesControls[stage](subscribed) || DefaultComponent;

    const finished = [
      STAGES.PROJECT_COMPLETE,
      STAGES.TOKEN_DISTRIBUTION,
    ].includes(stage);

    const ticketProps = ticket || {};

    return (
      <WrapperPaper onClick={onClick}>
        <CardHeader>
          <Row
            width={"100%"}
            p="12px 12px 0 12px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Row alignItems="center">
              {icon ? <IconImg src={icon} /> : <Icon />}
              <Row>
                <Box m="0 0 0 12px">
                  <Row m="0 0 4px 0" height="100%" alignItems="center">
                    <Typography variant="input" m="0 4px 0 0">
                      {projectName?.toUpperCase()}
                    </Typography>
                    <StarIconStyled active={isPart} />
                  </Row>
                  <Box minHeight="30px">
                    <Component
                      {...ticketProps}
                      tokenName={token}
                      ath={ath}
                      mdaoBalance={usdtBalance}
                      projectId={projectId}
                      subscribed={subscribed}
                    />
                    <Typography
                      variant="small"
                      m="0 4px 0 0"
                      color={finished ? BUTTON_FIELD_NAME : ORANGE_FIELD_NAME}
                    >
                      {getStageTitles(t, stage)}
                    </Typography>
                  </Box>
                </Box>
              </Row>
            </Row>

            <ArrowIconStyled />
          </Row>
        </CardHeader>
        <Row width="100%" p="12px">
          <InfoBlock>
            <BlockInfoColumn title={`${t("token")}`} value={token} />
            <BlockInfoColumn title={`${t("tge")}`} value={tge} />
            <BlockInfoColumn
              title={`${t("allocation")}`}
              value={`$${allocation?.toLocaleString("en-US")}`}
            />
            <BlockInfoColumn
              title={`${t("tokenPrice")}`}
              value={tokenPrice ? `$${tokenPrice}` : "--"}
            />
          </InfoBlock>
        </Row>
      </WrapperPaper>
    );
  }
);
