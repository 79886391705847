import styled from "styled-components";
import QRCode from "react-qr-code";
import { Box } from "../../UI";

export const Wrapper = styled(Box)`
  min-height: calc(100vh - 50px);
`;

export const QRCodeStyled = styled(QRCode)`
  width: 100vw;
  height: 100vw;
  padding: 15px;
  box-sizing: border-box;
  background-color: white;
  border-radius: 12px;
`;
