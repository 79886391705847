import { dateFormat } from "./formatting";

export const DEFAULT_LANG = "en";

export const copyClipboard = (copy) => navigator.clipboard.writeText(copy);

export const countdownTimer = (
  timestamp,
  nextStage,
  nextStageName,
  cb,
  getHasMinusTime,
  t
) => {
  const targetDate = new Date(nextStage?.to);
  const now = new Date();
  const difference = targetDate - now;

  const days = Math.floor(difference / (1000 * 60 * 60 * 24));
  const hours = Math.floor(
    (difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));

  cb(`${days}${t("D")} ${hours}${t("H")} ${minutes}${t("M")}`, difference);
  getHasMinusTime(difference < 0);
};

export const randomNumber = (min, max, fixed = 0) =>
  (Math.random() * (max - min) + min).toFixed(fixed);

export const getFutureDate = ({ period, format, formatLess }) => {
  const date = new Date();

  const time = {
    seconds: 0,
    months: 0,
    hours: 0,
    minutes: 0,
    days: 0,
    milliseconds: 0,
    ...period,
  };

  date.setUTCSeconds(date.getUTCSeconds() + time.seconds);
  date.setUTCMinutes(date.getUTCMinutes() + time.minutes);
  date.setUTCDate(date.getUTCDate() + time.days);
  date.setUTCHours(date.getUTCHours() + time.hours);
  date.setUTCMonth(date.getUTCMonth() + time.months);
  date.setUTCMilliseconds(date.getUTCMilliseconds() + time.milliseconds);

  return formatLess ? date : dateFormat(date, format || "yyyy-MM-dd HH:mm:ss");
};

export const getSafeTranslate = (obj, lang) => {
  return obj?.[lang] || obj?.[DEFAULT_LANG] || "--";
};
