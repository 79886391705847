import { PROCESSING_STATUSES } from "../pages/IFrame/Processing-Page";
import get from "lodash.get";

export const updateStatus = (
  request,
  data,
  dataToResponse = {},
  statuses,
  pathToStatus
) => {
  const operationStatues = statuses || {
    waiting: PROCESSING_STATUSES.processing,
    pending: PROCESSING_STATUSES.processing,
    paid: PROCESSING_STATUSES.processing,
    success: PROCESSING_STATUSES.success,
    expired: PROCESSING_STATUSES.error,
    failed: PROCESSING_STATUSES.error,
  };

  return request(data).then((res) => {
    const dataToNext =
      typeof res.data.result === "object" ? res.data.result : {};

    return {
      status:
        operationStatues?.[get(res, pathToStatus || "data.result.status")] ||
        PROCESSING_STATUSES.error,
      data: { ...dataToNext, ...dataToResponse },
    };
  });
};
