import { memo } from "react";
import { Box } from "../../UI";
import styled from "styled-components";
import { LinkComponent } from "../Link/Link";

export const SelectedLink = memo(
  ({ type, txLink, walletLink, link, children }) => {
    const select = {
      tx: txLink,
      wallet: walletLink,
    };

    return (
      <Box>
        <LinkComponent to={`${select[type]}${link}`}>{children}</LinkComponent>
      </Box>
    );
  }
);
