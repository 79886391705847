export const URLS = {
  getChains: "/wallet/chains",
  getInfo: "/wallet/info",
  getTokens: "/wallet/tokens",
  getAddressDeposit: "/wallet/deposit",
  addToken: "/wallet/add_token",
  withdraw: "/wallet/withdraw",
  getNetworkFee: "/wallet/network_fees",
  getAddresses: "/wallet/addresses",
  withdrawToken: "/wallet/withdraw_tokens",
  withdrawFee: "/wallet/withdraw_fee",
  withdrawalFee: "/wallet/withdrawal_fee",
  withdrawTokenFee: "/wallet/withdrawal_tokens_fee",
  getProfile: "/user/profile",
  setLanguage: "/user/set_language",
  get2FA: "/user/request_tfa",
  enable2FA: "/user/enable_tfa",
  disable2FA: "/user/disable_tfa",
  getKycStatus: "/user/kyc_status",
  startKyc: "/user/start_kyc",
  kycWebhook: "/user/kyc_webhook",
  getProducts: "/cards/products",
  getCard: "/cards/card",
  getMakerAllocationStatus: "/maker/allocation/operation_status",
  getMakerTicketStatus: "/maker/tickets/operation_status",
  getBins: "/cards/bins",
  getCards: "/cards/list",
  getCardTransaction: "/cards/card_transactions",
  getTemplates: "/cards/templates",
  createCard: "/cards/create",
  topupCard: "/cards/topup",
  freezeCard: "/cards/freeze",
  unfreezeCard: "/cards/unfreeze",
  closeCard: "/cards/close",
  getFaq: "/docs/faq",
  getCardsFaq: "/docs/card_faq",
  getCardsInfo: "/docs/card_info",
  getRequirements: "/cards/requirements",
  getCreateCardPrice: "/cards/create_price",
  getTopupPrice: "/cards/topup_price",
  getBtcMaxAmount: "/wallet/withdrawal_max_amount",
  getCardOperations: "/cards/card_operations",
  getCardOperation: "/cards/card_operation",
  getOperationStatus: "/cards/card_operation_status",
  getCreditOperationStatus: "/aml/credits_operation_status",
  getBuyUsdtPrice: "/buy_usdt/buy_price",
  buyUsdt: "/buy_usdt/buy",
  getBuyUsdtOperations: "/buy_usdt/operations",
  getBuyUsdtOperation: "/buy_usdt/operation",
  getBuyUsdtOperationStatus: "/buy_usdt/operation_status",
  getBuyUsdtRequirements: "/buy_usdt/requirements",
  getAmlOperation: "/aml/operation",
  getAmlOperationStatus: "/aml/operation_status",
  getAmlHistory: "/aml/history",
  getAmlBuyPrice: "/aml/buy_price",
  amlBuy: "/aml/buy",
  amlRequest: "/aml/request",
  getAmlBalance: "/aml/balance",
  getAmlRequirements: "/aml/requirements",
  getMakerProjects: "/maker/projects",
  getMakerSubscription: "/maker/subscription",
  makerSubscribe: "/maker/subscribe",
  getMakerTickets: "/maker/tickets/all",
  buyMakerTickets: "/maker/tickets/buy_tickets",
  getMakerTicketsRequirements: "/maker/tickets/requirements",
  getMakerProject: "/maker/project",
  getMakerProjectParams: "/maker/user_project_params",
  getMakerUserParams: "/maker/user_params",
  getMakerProjectStats: "/maker/stats",
  freeAmlCheck: "/aml/free_request",
  getMakerOperations: "/maker/operations",
  getMakerOperation: "/maker/operation",
  getStakins: "/maker/staking/stakings",
  getStakingMakerRequirements: "/maker/staking/requirements",
  getStakingRequirements: "/zavod_operations/requirements",
  addStake: "/maker/staking/add",
  topupStake: "/maker/staking/topup",
  closeStake: "/maker/staking/close",
  prolongStake: "/maker/staking/prolong",
  buyAllocation: "/maker/allocation/buy",
  setDropWallet: "/maker/set_drop_wallet",
  getAllocationOperation: "/maker/allocation/operation",
  getAllocationRequirements: "/maker/allocation/requirements",
  getTicketOperation: "/maker/tickets/operation",
  getZavodOperation: "/zavod_operations/operation",
  getZavodOperationStatus: "/zavod_operations/operation_status",
  payZavodItem: "/zavod_operations/pay",
};
