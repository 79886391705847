import React, { useEffect, useMemo, useState } from "react";
import { Box } from "../../UI";
import {
  Button,
  Dropdown,
  PageHeader,
  SettingItemComponent,
} from "../../components";
import { useSnackbar, useTranslate } from "../../contexts";

import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { PROCESSING, SUCCESS } from "../../config";
import { FA_APP_ROUTE, MAIN_ROUTE, PASS_KYC_ROUTE } from "../../routes/routes";
import useCheckKeyStatus from "../../hooks/useCheckKeyStatus";

const languages = [
  { id: "en", title: "English" },
  { id: "ru", title: "Русский" },
  { id: "es", title: "Español" },
  { id: "fr", title: "Français" },
  { id: "vi", title: "Việt" },
  { id: "id", title: "Bahasa" },
  { id: "hi", title: "हिन्दी" },
  { id: "it", title: "Italiano" },
];

const Wrapper = styled(Box)`
  height: calc(100vh - 42px);

  justify-content: space-between;
`;

export const SettingsPage = () => {
  const { language, t, reloadResources, setLanguage } = useTranslate();
  const { showSuccess } = useSnackbar();
  const dispatch = useDispatch();
  const walletDispatch = dispatch.wallet;
  const { user } = useSelector(({ wallet }) => wallet);
  const [settings, setSettings] = useState({});
  const FA = user?.tfaStatus;
  const FAValue = useMemo(
    () => (FA ? t("enabled") : t("disabled")),
    [FA, language]
  );

  const handleSetSetting = (value, name) => {
    setSettings((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    walletDispatch.getKycStatus();
  }, []);

  useCheckKeyStatus();

  useEffect(() => {
    if (language && language !== settings.language) {
      handleSetSetting(
        languages.find((el) => el.id === language),
        "language"
      );
    }
  }, [language]);

  const handleSaveChanges = () => {
    if (settings.language?.id !== language) {
      setLanguage(settings.language?.id);
      reloadResources();
    }
    showSuccess(
      t("notificationTexts.changesSaved", { lng: settings.language?.id })
    );
  };

  const { isKyc } = useSelector(({ wallet }) => wallet);

  const kycTranslateStatus =
    {
      processing: t("pending"),
      success: t("passed"),
      none: t("notPassed"),
      failed: t("notPassed"),
    }[isKyc] || "--";

  const isKycSuccess = isKyc === SUCCESS;
  const isKycProcessing = isKyc === PROCESSING;

  return (
    <Wrapper>
      <Box>
        <PageHeader title={t("settings")} to={MAIN_ROUTE} />
        <Dropdown
          subTitle={t("language")}
          setValue={(value) => handleSetSetting(value, "language")}
          items={languages}
          currentValue={settings?.language}
        />
        <Box m="10px 0 10px 0">
          <SettingItemComponent
            subTitle={t("tfaSecuritySubTitle")}
            title={FAValue}
            to={FA_APP_ROUTE}
          />
        </Box>
        <SettingItemComponent
          disable={isKycSuccess || isKycProcessing}
          subTitle={t("kyc").toUpperCase()}
          title={kycTranslateStatus.toUpperCase()}
          to={PASS_KYC_ROUTE}
          noArrow={isKycSuccess || isKycProcessing}
        />
      </Box>
      <Button
        activeButton
        emoji="💾"
        title={t("saveChanges")}
        onClick={handleSaveChanges}
      />
    </Wrapper>
  );
};
