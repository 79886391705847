import React from "react";
import {
  Box,
  Row,
  Typography,
  BackArrowIconStyled,
  NextArrowIconStyled,
} from "../../UI";
import { ReactComponent as GooglePlayIcon } from "../../assets/svg/google-play.svg";
import { ReactComponent as AppStoreIcon } from "../../assets/svg/app-store.svg";
import { FAWrapper } from "./2FAWrapper";
import { ButtonStyled } from "./styled";
import { useTranslate } from "../../contexts";
import {
  GOOGLE_AUTHENTICATOR_ANDROID,
  GOOGLE_AUTHENTICATOR_IOS,
} from "../../config";

export const FAAppPage = ({ onNext, stepsLength, activeStep, onBack }) => {
  const { t } = useTranslate();
  return (
    <FAWrapper
      stepsLength={stepsLength}
      activeStep={activeStep}
      title={t("tfaSetupTitle")}
      content={
        <Box
          alignItems="center"
          flexGrow="1"
          justifyContent="center"
          p="0 5px 0 5px"
        >
          <Typography variant="h1" ta="center">
            LINK Google Authenticator
          </Typography>
          <Typography m="24px 0 24px 0" variant="link" color="hint" ta="center">
            {t("googleAuthenticatorDescription")}
          </Typography>
          <a href={GOOGLE_AUTHENTICATOR_ANDROID} target="_blank">
            <GooglePlayIcon />
          </a>
          <Box m="10px 0 24px 0">
            <a href={GOOGLE_AUTHENTICATOR_IOS} target="_blank">
              <AppStoreIcon />
            </a>
          </Box>
          <Typography variant="link" color="hint" ta="center">
            {t("goToApp")}
          </Typography>
        </Box>
      }
      controls={
        <Row>
          <ButtonStyled
            variant="small"
            onClick={onNext}
            title={
              <div>
                {t("next")} <NextArrowIconStyled />
              </div>
            }
          />
        </Row>
      }
    />
  );
};
