import React, { useEffect, useRef } from "react";
import { Row } from "../../UI";

export const EllipsisText = ({
  value,
  startLength,
  endLength,
  customEmpty,
}) => {
  // const start = value?.slice(0, startLength);
  // const end = value?.slice(endLength, value?.length);

  const str = value
    ? `${String(value || "")?.slice(0, startLength || 6)}...${String(
        value || ""
      )?.slice(endLength || -4)}`
    : customEmpty;
  const fullTextId = `full-text-${Math.random().toString(36).substr(2, 9)}`;
  const wrapperRef = useRef(null);

  useEffect(() => {
    if (!wrapperRef.current) return;
    wrapperRef.current.addEventListener("copy", (event) => {
      const selection = document.getSelection();
      const selectedText = selection.toString();
      const fullTextElement = document.querySelector(`[id^='full-text-']`);

      if (fullTextElement && selectedText.includes("...")) {
        event.clipboardData.setData("text/plain", fullTextElement.textContent);
        event.preventDefault();
      }
    });
  }, [wrapperRef]);

  return (
    <Row ref={wrapperRef}>
      <span>{str}</span>
      <span id={fullTextId} style={{ position: "absolute", left: "-9999px" }}>
        {value}
      </span>
    </Row>
  );
};
