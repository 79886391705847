import React, { useMemo } from "react";
import { Box, Paper, setStyle, Typography } from "../../UI";
import { shortWallet } from "../../utils/formatting";
import { getIcons } from "../Icons/Icons";
import styled from "styled-components";
import { useScreen } from "../../hooks";
import { CHAINS } from "../../config";
import { useSelector } from "react-redux";
import { copyClipboard } from "../../utils/utils";
import { useSnackbar, useTranslate } from "../../contexts";
import { EllipsisText } from "../EllipsisText/Ellipsis";

export const CopyIcon = styled(getIcons("copy"))`
  width: 15px;
  height: ${setStyle("h", "16px")};
  margin-left: 8px;
`;

export const WalletField = styled(Paper)`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  justify-content: space-between;
  align-items: center;
`;

const TypographyStyled = styled(Typography)`
  line-height: ${setStyle("lh", "normal")};
`;

const variants = {
  small: {
    fontSize: "input",
    lengthSizeWidth: 15,
    padding: "10px 0px 10px 20px",
    lineHeightSubTitle: "11px",
    lineHeightTitle: "19px",
    height: "34px",
  },
};

export const CopyField = ({
  value,
  widthContent,
  hideDisabled,
  title,
  copyValue,
  txLink,
  onClick,
  variant,
  className,
  notTransform,
  withRef,
}) => {
  const { windowSize } = useScreen();
  const { t } = useTranslate();
  const { showInfo } = useSnackbar();
  const { currentChain } = useSelector(({ wallet }) => wallet);
  const variantStyles = variants[variant];

  const lengthFromChain = useMemo(() => {
    const lengths = {
      [CHAINS.TRX]: 19,
    };

    return lengths[currentChain?.chain] || 17;
  }, [currentChain]);

  const strLength =
    windowSize.width < 480
      ? Math.floor(
          (windowSize.width || 480) /
            (variantStyles?.lengthSizeWidth || lengthFromChain)
        )
      : 37;

  const handleCopy = () => {
    if (!onClick) {
      copyClipboard(copyValue || value);
      showInfo(t("notificationTexts.copied"));
    } else {
      onClick();
    }
  };

  const offHide = strLength + 4 >= value?.length;

  return (
    <WalletField
      onClick={handleCopy}
      className={className}
      ref={withRef}
      p={variantStyles?.padding || "20px 10px 20px 20px;"}
    >
      <Box width={widthContent}>
        <TypographyStyled
          color="hint"
          variant="tiny"
          lh={variantStyles?.lineHeightSubTitle}
        >
          {title}
        </TypographyStyled>
        <TypographyStyled
          variant={variantStyles?.fontSize || "h2"}
          color="text"
          lh={variantStyles?.lineHeightTitle}
        >
          {hideDisabled || notTransform || offHide ? (
            value
          ) : (
            <EllipsisText value={value} startLength={strLength - 1} />
          )}
        </TypographyStyled>
      </Box>
      <Box m="0 8px 0 0">
        <CopyIcon h={variantStyles?.height} />
      </Box>
    </WalletField>
  );
};
