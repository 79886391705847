import { css } from "styled-components";

export const fonts = {
  huge: css`
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  `,
  h1: css`
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  h2: css`
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  faqTitle: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  `,
  bold: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  `,
  link: css`
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  input: css`
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  small: css`
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  tiny: css`
    font-size: 9px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  `,
  notices: css`
    font-size: 9px;
    font-style: normal;
    font-weight: 700;
    line-height: 9px;
  `,
};
