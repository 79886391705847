import React from "react";
import { CardToken } from "../Cards/Card-Token";
import { Box } from "../../UI";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { convertNumberToSafe, getBalanceNumber } from "../../utils/formatting";

const Wrapper = styled(Box)`
  overflow-y: scroll;
`;

const CardTokenStyled = styled(CardToken)`
  margin-bottom: 8px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const sort = (tokenBalances) => (a, b) =>
  convertNumberToSafe(getBalanceNumber(tokenBalances[b.contract], b.decimals)) -
  convertNumberToSafe(getBalanceNumber(tokenBalances[a.contract], a.decimals));

export const Tokens = React.memo(
  ({ tokens, withRef, tokensHeight, sortFunc }) => {
    const { tokenBalances } = useSelector(({ wallet }) => wallet);

    const sorted = sortFunc ? tokens?.sort(sortFunc(tokenBalances)) : tokens;

    return (
      <Wrapper ref={withRef} height={`${tokensHeight}px`}>
        {sorted?.map((token, i) => {
          if (token.tokenId) {
            return <CardTokenStyled key={i} noArrow data={token} />;
          }
        })}
      </Wrapper>
    );
  }
);
