import React from "react";
import { Row, Typography, Box } from "../../UI";
import { useSelector } from "react-redux";
import { IconImage, Item, StubImage } from "./styled";
import { convertNumberToSafe, getBalanceNumber } from "../../utils/formatting";
import Skeleton from "react-loading-skeleton";

const Image = React.memo(({ src }) => {
  return <IconImage src={src} />;
});

export const TokenItem = React.memo(({ image, balance, name, symbol }) => {
  return (
    <Row alignItems="center" width="100%" justifyContent="space-between">
      <Row>
        {image ? (
          <Image src={image} />
        ) : (
          <StubImage>
            <Typography variant="h2">
              {symbol?.slice(0, 2)?.toUpperCase()}
            </Typography>
          </StubImage>
        )}
        <Box m="0 0 0 8px" justifyContent="space-around">
          <Typography variant="link" color="white">
            {name || "..."}
          </Typography>
          <Typography variant="link" color="hint">
            {`${balance} ${symbol || "..."}`}
          </Typography>
        </Box>
      </Row>
    </Row>
  );
});

export const CardToken = React.memo(({ data, setValue, className }) => {
  const { tokenBalances } = useSelector(({ wallet }) => wallet);
  const { name, image, contract, symbol } = data || {};
  const balance = tokenBalances[contract];

  const balanceValue =
    data?.balance ??
    convertNumberToSafe(getBalanceNumber(balance, data?.decimals) || 0) ??
    0;

  return (
    <Item onClick={() => setValue && setValue(data)} className={className}>
      <TokenItem
        balance={isNaN(balanceValue) ? 0 : balanceValue}
        name={name}
        image={image}
        symbol={symbol}
        decimals={data?.decimals}
      />
    </Item>
  );
});
