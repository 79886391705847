export const COLIBRICRYPTO_LINK = "https://t.me/colibricrypto";

export const HOW_TO_SELL_CRYPTOCURRENCY_COLIBRI_LINK =
  "https://medium.com/mars-dao/how-to-sell-cryptocurrency-through-the-colibri-exchange-in-marsdao-wallet-701d7d67599f";

export const DAOMARS_EMAIL =
  process.env.REACT_APP_SUPPORT_DAOMARS_EMAIL || "support@antipad.io";

export const GOOGLE_AUTHENTICATOR_ANDROID =
  "https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2";
export const GOOGLE_AUTHENTICATOR_IOS =
  "https://apps.apple.com/ru/app/google-authenticator/id388497605";
